import React, { useState } from 'react'
import { useContextualPane } from '../../../../../contexts/contextualPane'
import { Button, FormControl, FormLabel, List, ListItem, ListItemDecorator, Radio, RadioGroup, Box } from '@mui/joy';
import CitationOptions from './SearchForAssociations/CitationOptions';
import DefinitionOptions from './SearchForAssociations/DefinitionOptions';

export default function SearchAssociationList() {

    const { newDraftAssociation, 
        setProgressStep, 
        handleSetNewDraftAssociation, 
        activeItem,
        handleStepTwoContinue,
        handleStepTwoBack,
        handleStepTwoCitationContinue,
        secondaryAssociationMode,
        secondNewDraftAssociation,
        handleStepTwoContinueForSecondary,
        handleStepTwoCitationContinueSecondary
    } = useContextualPane();

    const [selectedValue, setSelectedValue] = useState('');

    // console.log("activeItem", activeItem)

    const handleChange = (event) => {
        //console.log("handleChange", event.target.value)
        setSelectedValue(event.target.value);
    };

    return (
        secondaryAssociationMode 
            ?
                secondNewDraftAssociation.associationType === 'strong' 
                ?
                    <FormControl sx={{ mt: 2 }}>
                        {/* Add definitions */}
                        <DefinitionOptions handleChange={handleChange} />
                        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                            {/* <Button onClick={() => handleStepTwoBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="primary">
                                Close
                            </Button> */}
                            <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepTwoContinueForSecondary(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                                Continue
                            </Button>
                        </Box>
                    </FormControl>
                :
                    <FormControl sx={{ mt: 2 }}>
                        {/* Add citation options */}
                        <CitationOptions handleChange={handleChange} />
                        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                            {/* <Button onClick={() => handleStepTwoBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                                Back
                            </Button> */}
                            <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepTwoCitationContinueSecondary(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                                Continue
                            </Button>
                        </Box>
                    </FormControl>
            :
                newDraftAssociation.associationType === 'strong' 
                ?
                    <FormControl sx={{ mt: 2 }}>
                        {/* Add definitions */}
                        <DefinitionOptions handleChange={handleChange} />
                        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                            <Button onClick={() => handleStepTwoBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="primary">
                                Back
                            </Button>
                            <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepTwoContinue(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                                Continue
                            </Button>
                        </Box>
                    </FormControl>
                :
                    <FormControl sx={{ mt: 2 }}>
                        {/* Add citation options */}
                        <CitationOptions handleChange={handleChange} />
                        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                            <Button onClick={() => handleStepTwoBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                                Back
                            </Button>
                            <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepTwoCitationContinue(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                                Continue
                            </Button>
                        </Box>
                    </FormControl>
    )
}