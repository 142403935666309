import EmojiPicker from 'emoji-picker-react';

export default function EmojiInlinePicker({ skinTonesDisabled, customEmojis, width, defaultEmojis, handleReaction }) {
  return (
    <EmojiPicker 
      reactions={defaultEmojis} 
      skinTonesDisabled={skinTonesDisabled} 
      width={`${width}%`} 
      customEmojis={customEmojis}
      reactionsDefaultOpen={true} 
      onEmojiClick={handleReaction} 
    />
  )
}