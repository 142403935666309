import { useAuth } from "../../../../authContext"  
import Avatar from "@mui/joy/Avatar"
import { getCombinedCharacters } from "../../../utils/utils"
import PersonIcon from '@mui/icons-material/Person'
import Box from '@mui/joy/Box'

export default function AccountAvatar({ size = '40px', src = null }) {

  const auth = useAuth()

  return (
    src !== null ?
    <>
    <Avatar
      size={size}
      src={src}
      srcSet={src}
      sx={{ maxWidth: size, maxHeight: size, minWidth: size, minHeight: size }}
    />
    </>
    :
    auth.currentUser._delegate && auth.currentUser._delegate.photoURL && auth.currentUser._delegate.photoURL !== null ?
    <>
    <Avatar
      size={size}
      src={auth.currentUser._delegate.photoURL}
      srcSet={auth.currentUser._delegate.photoURL}
      sx={{ maxWidth: size, maxHeight: size, minWidth: size, minHeight: size }}
    />
    </>
    :
    <>
    <Avatar size={size} sx={{ '--Avatar-size': size, minWidth: size, minHeight: size }} color="primary">
      { auth.currentUser._delegate && auth.currentUser._delegate.displayName && auth.currentUser._delegate.displayName !== null ?
          getCombinedCharacters(auth.currentUser._delegate.displayName)
        :
          <PersonIcon />
      }
    </Avatar>
    </>
  )
}