import React from 'react';
import { Box, Button, IconButton, List, ListItem, FormControl, FormLabel, Input, Typography } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

export default function ListComponent({ gridColumns, data, id, updateHandler, deleteHandler, editMode = true }) {

  const [ editing, setEditing ] = React.useState(false);
  const [ newTitle, setNewTitle ] = React.useState('');

  return (
    editMode ?
    <>
    <FormControl>
      <FormLabel sx={{ flex: 1, width: "100%", flexDirection: "row", justifyContent: "space-between" }} onClick={() => { setNewTitle(data.field_title); setEditing(true) }}>
        { editing ?
          <Input 
            size="sm" 
            value={newTitle} 
            onChange={(e) => setNewTitle(e.target.value)}
            onBlur={() => {
              updateHandler(prevState => {
                const updatedArray = prevState.map(item => {
                  if (item.id === id) {
                    return { ...item, field_title: newTitle };
                  }
                  return item;
                });
              
                return updatedArray;
              });
              setEditing(false);
            }}
            onKeyDown={(e) => {
              e.stopPropagation();
              if (e.keyCode === 13) {
                updateHandler(prevState => {
                  const updatedArray = prevState.map(item => {
                    if (item.id === id) {
                      return { ...item, field_title: newTitle };
                    }
                    return item;
                  });
                
                  return updatedArray;
                });
                setEditing(false);
              } else if (e.keyCode === 27) {
                setEditing(false);
              }
            }}
          />
          
        : data && data.field_title ? 
          <>
          <Box flex={1}>
            { data.field_title }
          </Box>
          <Box
            flex={1}
            sx={{
              minHeight: 32,
              justifyContent: "end",
              display: "flex",
            }}
          >
            <IconButton onClick={() => deleteHandler({ idToDelete: id })} className='delete-item' sx={{ display: "none" }} size="sm">
              <CloseIcon />
            </IconButton>
          </Box> 
          </>
          : '' }
      </FormLabel>
    </FormControl>
    
    <List sx={{ mt: 1 }} marker="disc">
      { data && data.items && data.items.length > 0 && data.items.map((value, index) => (
        <ListItem key={index}>
        <Input 
          sx={{ pr: .5 }} 
          value={`${value}`} 
          onChange={(e) => {
            const newValue = e.target.value;
            updateHandler(prevState => {
              const updatedArray = prevState.map(item => {
                if (item.id === id) {
                  return { 
                    ...item, 
                    items: item.items.map((itemValue, itemIndex) => 
                      itemIndex === index ? newValue : itemValue
                    )
                  };
                }
                return item;
              });
            
              return updatedArray;
            });
          }}
          endDecorator={
            <IconButton
              aria-label="delete"
              size="sm"
              sx={{ m: 0.5, mr: 0 }}
              variant="outlined"
              color="neutral"
              onClick={() => {
                updateHandler(prevState => {
                  const updatedArray = prevState.map(item => {
                    if (item.id === id) {
                      return { 
                        ...item, 
                        items: item.items.filter((_, i) => i !== index)
                      };
                    }
                    return item;
                  });
                
                  return updatedArray;
              })}}
            >
              <CloseIcon />
            </IconButton>
          }
        />
      </ListItem>
      ))}
    </List>
    <Box sx={{ ml: 4 }} flex={1}>
      <Button size="sm" variant="outlined" 
        onClick={() => {
          updateHandler(prevState => {
            const updatedArray = prevState.map(item => {
              if (item.id === id) {
                return { 
                  ...item, 
                  items: [ ...item.items, "New item" ]
                };
              }
              return item;
            });
          
            return updatedArray;
        })}}
      >
        Add Item
      </Button>
    </Box>
    </>
    :
    <Box sx={{
      borderBottom: data.block_location !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
      ml: gridColumns === 1 ? 0 : 4, 
      mr: gridColumns === 1 ? 0 : 4, 
      pb: 2.5
    }}>
      <Typography level="body-md" color="neutral">
          { data.field_title }:
      </Typography>
      <List sx={{ mt: 1 }} marker="disc">
        { data && data.items && data.items.length > 0 && data.items.map((value, index) => (
          <ListItem key={index}>
            <Typography level='body-sm' sx={{ fontWeight: "bold" }}>{value}</Typography>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}