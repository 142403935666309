import { useEffect, useState } from 'react';
import { CardContent, Box, Typography, Checkbox, Grid, Link } from "@mui/joy";
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import { getNote } from '../../../database/notes-db';
import { getHighlight } from '../../../database/highlights-db';
import HighlightIcon from '@mui/icons-material/Highlight';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../../redirects';

function HighlightItem({ tag, reference }) {

    const [ researchTitle, setResearchTitle ] = useState(null);
    const [ researchId, setResearchId ] = useState(null);
    const { accountId, selectedProjectId } = useParams();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '?noteId=' + researchId);
    }

    const lookupResearchItemTitle = async () => {
        // first use the highlight id to get the research id
        getHighlight({ highlightId: reference.id, accountId }).then((highlight) => {
            if (!highlight) return;
            if (!highlight.researchId) return;
            getNote({ noteId: highlight.researchId, accountId }).then((note) => {
                setResearchTitle(note.noteTitle);
                setResearchId(note.id);
            });
        });
    }

    useEffect(() => {
        if (reference.id) {
            const setTitleBasedonRefId = async () => {
                await lookupResearchItemTitle();
            }

            setTitleBasedonRefId();
        }
    }, [reference]);

    return (
        <>
        <Grid item xs={10} xl={10}>
            <Box sx={{ mr: 1, display: { xl: 'flex', xs: 'none' }, flexDirection: 'column' }}>
                {/* Tag title */}
                <Typography startDecorator={<HighlightIcon style={{ color: tag.colour[100] }} fontSize='8' />} level="body-md">
                    <Link color="neutral" onClick={() => handleClick()} variant="plain" > 
                        { researchTitle }
                    </Link>
                </Typography>
            </Box>
        </Grid>
        <Grid sx={{ textAlign: 'right' }} item xs={2} xl={2}>
            {/* checkbox */}
            <Checkbox />
        </Grid>
        <Grid item xs={12} xl={12}>
            <CardContent sx={{ fontSize: 14, lineHeight: 2, pr: 2 }}>
                <Box 
                    component="span" 
                    sx={{ 
                    display: 'inline-block',  
                    }}
                >
                    <Typography component={'span'} sx={{ borderRadius: 8, display: 'inline-block', px: 1.5, py: 0.75, backgroundColor: tag.colour[100], fontSize: 12 }}>
                        <Link color="neutral" onClick={() => handleClick()} variant="plain" > 
                            {reference.summaryTitle}
                        </Link>
                    </Typography>
                </Box>
            </CardContent>
        </Grid>
        </>
    )
}

export default HighlightItem;