export const setActiveAndOverItems = ({ array, active, over }) => {
  let activeRow = null;
  let activeColumn = null;
  let activeComponent = null;
  let overRow = null;
  let activeOverColumn = null;
  let activeOverComponent = null;
  // Update the column / row value
  array.forEach((row, rowIndex) => {
    row && row.children && row.children.length > 0 && row.children.forEach((column, columnIndex) => {
      if (column && column.children && column.children.length > 0) {
        column.children.forEach((component, componentIndex) => {
          if (component.id === active.id) {
            //console.log("active column: ", column, ' and row.rowId ', row.rowId)
            activeRow = { ...row, rowIndex };
            activeComponent = { ...component, rowIndex, columnIndex, componentIndex };
            activeColumn = { ...column, rowIndex, columnIndex };
          }
          if (component.id === over.id) {
            //console.log("over column: ", column, ' and row.rowId ', row.rowId)
            overRow = { ...row, rowIndex }; 
            activeOverColumn = { ...column, rowIndex, columnIndex };
            activeOverComponent = { ...component, rowIndex, columnIndex, componentIndex };
          }
        });
      } else {
        if (column.id === active.id) {
          activeRow = { ...row, rowIndex };
          activeColumn = { ...column, rowIndex, columnIndex };
        }
        if (column.id === over.id) {
          overRow = { ...row, rowIndex }; 
          activeOverColumn = { ...column, rowIndex, columnIndex };
        }
      }
    });
  });
  return { activeRow, activeColumn, activeComponent, overRow, activeOverColumn, activeOverComponent };
}

export function cutComponentFromArray({ array, activeColumn, activeRow, activeComponent, active }) {
  let movedComponent = null;

  const modifiedArray = array.map((row, index) => {
    if (activeRow.rowIndex === index) {
      if (activeColumn && activeComponent) {
        if (!movedComponent) {
          [movedComponent] = row.children[activeComponent.columnIndex].children.splice(activeComponent.componentIndex, 1);
          return row;
        }        
      }
    }

    return row;
  });

  return { modifiedArray, movedComponent };
}

export function pasteComponentIntoArray({ array, overRowIndex, overColumnIndex, movedComponent }) {

  console.log("pasteComponentIntoArray:", array, overRowIndex, overColumnIndex, movedComponent)

  if (overRowIndex && overColumnIndex) {

    const newItems = array.map((row, index) => {
      if (overRowIndex === index) {
        const newChildren = row.children.map((column, columnIndex) => {
          if (overColumnIndex === columnIndex) {
            column.children.push(movedComponent);
            return { ...column, children: column.children };
          }
          return column;
        });
  
        return { ...row, children: newChildren };
      }
      return row;
    });
  
    return newItems;

  } else {
    console.log("Sorry, there was an issue. Please try again or refresh the page.")
    return array
  }
 
}