import { formatDistanceToNow, intlFormatDistance  } from 'date-fns';
import {$isAtNodeEnd} from '@lexical/selection';
import {ElementNode, RangeSelection, TextNode} from 'lexical';

export const returnTenWordSummaryFromString = (text) => {
  if (typeof text !== 'string' || text.trim() === '') return;
  const words = text.split(' ');
  let summary = words.slice(0, 10).join(' ');
  if (words.length > 10) {
    summary += '...';
  }
  return summary;
}

export const openSidebar = () => {
  if (typeof document !== 'undefined') {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
  }
};

export const closeSidebar = () => {
  if (typeof document !== 'undefined') {
    document.documentElement.style.removeProperty('--SideNavigation-slideIn');
    document.body.style.removeProperty('overflow');
  }
};

export const toggleSidebar = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
    if (slideIn) {
      closeSidebar();
    } else {
      openSidebar();
    }
  }
};

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}

export const openMessagesPane = () => {
  if (typeof document !== 'undefined') {
    // commented below because it was causing an issue where user couldn't scroll content of note if editor not in focus
    // document.body.style.overflow = 'hidden';
    document.documentElement.style.setProperty('--MessagesPane-slideIn', '1');
  }
};

export const closeMessagesPane = () => {
  if (typeof document !== 'undefined') {
    document.documentElement.style.removeProperty('--MessagesPane-slideIn');
    document.body.style.removeProperty('overflow');
  }
};

export const toggleMessagesPane = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--MessagesPane-slideIn');
    if (slideIn) {
      closeMessagesPane();
    } else {
      openMessagesPane();
    }
  }
};

export const formatJSON = (string) => {
  // Use a regular expression to find the JSON portion
  const jsonRegex = /```json\s*\n([\s\S]+?)\n```/;  // Match anything between ```json ... ```
  const jsonMatch = string.match(jsonRegex);

  // Initialize variables for cleaned versions
  let jsonArray = []
  let preJsonText = '';
  let postJsonText = '';

  // Check if there is a JSON match
  if (jsonMatch && jsonMatch.length > 1) {
    const jsonString = jsonMatch[1];  // Use index 1 to get the content inside the triple backticks

    // Remove triple backticks from the JSON string
    const cleanedJsonString = jsonString.replace(/`/g, '');

    // Extract pre and post text
    const startIndex = jsonMatch.index; // Start index of the match
    const endIndex = startIndex + jsonMatch[0].length; // End index of the match

    preJsonText = string.slice(0, startIndex);
    postJsonText = string.slice(endIndex);

    try {
      // Parse the cleaned JSON string
      jsonArray = JSON.parse(cleanedJsonString);

      // Now you have the parsed JSON array, preJsonText, and postJsonText
      //console.log('jsonArray:', jsonArray);
      //console.log('preJsonText:', preJsonText);
      //console.log('postJsonText:', postJsonText);
    } catch (error) {
      console.error('Error parsing JSON:', error);
    }
    return { jsonArray, preJsonText, postJsonText}
  }
}

// Check if the user is a super admin
export const isSuperAdmin = (user) => {
  return user && user.role && user.role === 'super';
}

// Check if the user is a admin
export const isAdminAndAbove = (user) => {
  return user && user.role && (user.role === 'admin' || user.role === 'super');
}

// Check if the user is a manager and above
export const isManagerAndAbove = (user) => {
  return user && user.role && (user.role === 'manager' || user.role === 'admin' || user.role === 'super');
}

// Check if the user is a contributor and above
export const isContributorAndAbove = (user) => {
  return user && user.role && (user.role === 'contributor' || user.role === 'manager' || user.role === 'admin' || user.role === 'super');
}

// Create a two character avatar of a given string
export const getCombinedCharacters = (name) => {
  const words = name.split(' ');
  let combined = '';
  if (words.length > 1) {
    combined = words[0].charAt(0) + words[1].charAt(0);
  } else {
    combined = words[0].substring(0, 2);
  }
  return combined.toUpperCase();
};

export const getFileTypeFromFilePath = (filePath) => {
  const urlParts = filePath.split('/');
  const fileNameWithParams = urlParts[urlParts.length - 1];
  const fileName = fileNameWithParams.split('?')[0];
  const fileExtension = fileName.split('.').pop().toLowerCase();
  return fileExtension;
}

export const getFileAttributes = (fileName) => {
  // Extract the file name from the URL
  const urlParts = fileName.split('/');
  const fileNameWithExtension = urlParts[urlParts.length - 1].split('?')[0]; // Remove query parameters
  // console.log("fileNameWithExtension", fileNameWithExtension)

  // Extract the extension from the file name
  const parts = fileNameWithExtension.split('.');
  const extension = parts.length > 1 ? parts.pop() : '';

  // Rest of the function remains the same
  switch (extension) {
    case 'jpg':
    case 'png':
    case 'gif':
      return 'image';
    case 'mp4':
    case 'avi':
    case 'mov':
      return 'video';
    case 'pdf':
      return 'pdf';
    case 'txt':
      return 'text';
    case 'ppt':
    case 'pptx':
      return 'presentation';
    default:
      return 'unknown';
  }
};

export function base64ToBlob(base64, mimeType='') {
  // Decode base64 string
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], {type: mimeType});
}

export function TimeAgo({ timestamp, short = false }) {
  // Add validation for timestamp
  if (!timestamp || timestamp === 'Invalid Date' || timestamp === 'Date') {
    return <span>No date</span>;
  }

  try {
    let timeAgo;
    if (short) {
      timeAgo = formatDistanceToNowShort(new Date(timestamp));
    } else {
      timeAgo = formatDistanceToNow(new Date(timestamp), { addSuffix: true });
    }
    return <span>{timeAgo}</span>;
  } catch (error) {
    console.error('Error formatting date:', error);
    return <span>Invalid date</span>;
  }
}

// Utility function to shorten the output
const formatDistanceToNowShort = (date) => {
  const distance = formatDistanceToNow(date);
  return distance
    .replace('about ', '')
    .replace('less than a minute', '<1m')
    .replace('minute', 'm')
    .replace('minutes', 'm')
    .replace('hour', 'h')
    .replace('hours', 'h')
    .replace('day', 'd')
    .replace('days', 'd')
    .replace('month', 'mo')
    .replace('months', 'mo')
    .replace('year', 'y')
    .replace('years', 'y')
    .replace(' ago', '');
};

export class Point {
  _x;
  _y;

  constructor(x, y) {
    this._x = x;
    this._y = y;
  }

  get x() {
    return this._x;
  }

  get y() {
    return this._y;
  }

  equals({x, y}) {
    return this.x === x && this.y === y;
  }

  calcDeltaXTo({x}) {
    return this.x - x;
  }

  calcDeltaYTo({y}) {
    return this.y - y;
  }

  calcHorizontalDistanceTo(point) {
    return Math.abs(this.calcDeltaXTo(point));
  }

  calcVerticalDistance(point) {
    return Math.abs(this.calcDeltaYTo(point));
  }

  calcDistanceTo(point) {
    return Math.sqrt(
      Math.pow(this.calcDeltaXTo(point), 2) +
        Math.pow(this.calcDeltaYTo(point), 2),
    );
  }
}

export function getSelectedNode(
  selection,
) {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

export function getDOMRangeRect(
  nativeSelection,
  rootElement,
) {
  const domRange = nativeSelection.getRangeAt(0);

  let rect;

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild;
    }
    rect = inner.getBoundingClientRect();
  } else {
    rect = domRange.getBoundingClientRect();
  }

  return rect;
}

export function isPoint(x) {
  return x;
}

export function isHTMLElement(x) {
  return x;
}

export class Rect {
  _left;
  _top;
  _right;
  _bottom;

  constructor(left, top, right, bottom) {
    const [physicTop, physicBottom] =
      top <= bottom ? [top, bottom] : [bottom, top];

    const [physicLeft, physicRight] =
      left <= right ? [left, right] : [right, left];

    this._top = physicTop;
    this._right = physicRight;
    this._left = physicLeft;
    this._bottom = physicBottom;
  }

  get top() {
    return this._top;
  }

  get right() {
    return this._right;
  }

  get bottom() {
    return this._bottom;
  }

  get left() {
    return this._left;
  }

  get width() {
    return Math.abs(this._left - this._right);
  }

  get height() {
    return Math.abs(this._bottom - this._top);
  }

  equals({top, left, bottom, right}) {
    return (
      top === this._top &&
      bottom === this._bottom &&
      left === this._left &&
      right === this._right
    );
  }

  contains(target) {
    if (isPoint(target)) {
      const {x, y} = target;

      const isOnTopSide = y < this._top;
      const isOnBottomSide = y > this._bottom;
      const isOnLeftSide = x < this._left;
      const isOnRightSide = x > this._right;

      const result =
        !isOnTopSide && !isOnBottomSide && !isOnLeftSide && !isOnRightSide;

      return {
        reason: {
          isOnBottomSide,
          isOnLeftSide,
          isOnRightSide,
          isOnTopSide,
        },
        result,
      };
    } else {
      const {top, left, bottom, right} = target;

      return (
        top >= this._top &&
        top <= this._bottom &&
        bottom >= this._top &&
        bottom <= this._bottom &&
        left >= this._left &&
        left <= this._right &&
        right >= this._left &&
        right <= this._right
      );
    }
  }

  intersectsWith(rect) {
    const {left: x1, top: y1, width: w1, height: h1} = rect;
    const {left: x2, top: y2, width: w2, height: h2} = this;
    const maxX = x1 + w1 >= x2 + w2 ? x1 + w1 : x2 + w2;
    const maxY = y1 + h1 >= y2 + h2 ? y1 + h1 : y2 + h2;
    const minX = x1 <= x2 ? x1 : x2;
    const minY = y1 <= y2 ? y1 : y2;
    return maxX - minX <= w1 + w2 && maxY - minY <= h1 + h2;
  }

  generateNewRect({
    left = this.left,
    top = this.top,
    right = this.right,
    bottom = this.bottom,
  }) {
    return new Rect(left, top, right, bottom);
  }

  static fromLTRB(
    left,
    top,
    right,
    bottom,
  ) {
    return new Rect(left, top, right, bottom);
  }

  static fromLWTH(
    left,
    width,
    top,
    height,
  ) {
    return new Rect(left, top, left + width, top + height);
  }

  static fromPoints(startPoint, endPoint) {
    const {y: top, x: left} = startPoint;
    const {y: bottom, x: right} = endPoint;
    return Rect.fromLTRB(left, top, right, bottom);
  }

  static fromDOM(dom) {
    const {top, width, left, height} = dom.getBoundingClientRect();
    return Rect.fromLWTH(left, width, top, height);
  }
}

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

export function setFloatingElemPosition(
  targetRect,
  floatingElem,
  anchorElem,
  isLink = false,
  verticalGap = VERTICAL_GAP,
  horizontalOffset = HORIZONTAL_OFFSET,
) {
  const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.transform = 'translate(-10000px, -10000px)';
    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  let top = targetRect.top - floatingElemRect.height - verticalGap;
  let left = targetRect.left - horizontalOffset;

  if (top < editorScrollerRect.top) {
    // adjusted height for link element if the element is at top
    top +=
      floatingElemRect.height +
      targetRect.height +
      verticalGap * (isLink ? 9 : 2);
  }

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.transform = `translate(${left}px, ${top}px)`;
}
