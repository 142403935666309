import { Stack, Button, Typography, Input, Chip, Box, IconButton } from "@mui/joy";
import CheckIcon from '@mui/icons-material/Check';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

function ToggleCheckmark({ checked, onClick, text }) {
  return (
    <Button sx={{ width: '100%', mr: .5 }} size="xs" color="neutral" variant="outlined" onClick={onClick}>
      { checked ? <RadioButtonUncheckedIcon fontSize="xs" /> : <CheckIcon fontSize="xs" /> }&nbsp;{ text }
    </Button>
  );
}

export default ToggleCheckmark;