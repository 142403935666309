import Home from "./Pages/Home";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { palette } from "../incitefulTheme";
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import '../PrivateSite/styles/styles.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ProjectsProvider } from "../PrivateSite/contexts/projectsContext";
import { LoadingProvider } from "../PrivateSite/contexts/loadingContext";

const theme = extendTheme({ 
  fontFamily: {
    display: '"Poppins", var(--joy-fontFamily-fallback)',
    body: '"Poppins", var(--joy-fontFamily-fallback)',
  },
  body: {
    backgroundColor: '#F4F1F8',
  },
  colorSchemes: {
    light: { palette },
    dark: { palette },
  },
});

export default function PublishedSites() {

  return (
    <CssVarsProvider disableTransitionOnChange theme={theme}>
      <CssBaseline />
      <HelmetProvider>
      <Helmet>
          <title>The Notes App for Digital Product People: smrt cookie</title>
          <meta name="description" content="The Notes App for Digital Product People: smrt cookie" />
      </Helmet>
      <LoadingProvider>
      <ProjectsProvider>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </ProjectsProvider>
      </LoadingProvider>
      </HelmetProvider>
    </CssVarsProvider>      
  )
}