import Chip from '@mui/joy/Chip';
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';

export default function MosCow({ priority }) {

  const getBackgroundColor = (priority) => {
    switch (priority) {
        case "4":
            return teal[500]; // Must Have
        case "3":
            return yellow[700]; // Should Have
        case "2":
            return pink[200]; // Could Have
        case "1":
            return grey[500]; // Won't Have
        default:
            return grey[800];
    }
};

    return (
        <>
        { priority &&
            <Chip component="span" size="sm" sx={{ px: 1.5, py: .5, color: '#fff', borderRadius: 6, fontWeight: 600, backgroundColor: getBackgroundColor(priority) }} variant="soft">
              {priority === "4" ? "Must Have" : priority === "3" ? "Should Have" : priority === "2" ? "Could Have" : priority === "1" && "Won't Have"}
            </Chip>
        }
        </>
    )
}