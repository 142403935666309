import { Box } from "@mui/joy";
import { useNotes } from "../../../../contexts/notesContext";
import ExistingLinkCard from "../../../Modals/ViewUpdateLink/ExistingLinkCard";
import ViewPrivateItemInput from "../../../ViewPrivateItemInput";
import NoteTitle from "../../NoteTitle";
import NotesPaneHeaderView from "../../NotesPaneHeaderView";
import BinnedNotesHeader from "../../../MyNotes/BinnedNotesHeader";
import BinnedNotesPaneHeader from "../../BinnedNotesPaneHeader";
import ViewUpdateLink from "../../../Modals/ViewUpdateLink";

function LinkLayout({showSidePane, content}) {
  const { binPage, passedPasswordCheck } = useNotes();

  return (
    binPage ?
      <>
        <BinnedNotesPaneHeader noteToDisplay={content} />
        <ExistingLinkCard 
          noteTitle={content.noteTitle} 
          defaultUrl={content.url} 
          defaultDescription={content.description}
        />
      </>
    :
    <Box
      sx={{
        flex: 1,
        flexDirection: 'column',
        borderRadius: {
          xs: 0,
        },
        backgroundColor: '#fff', // Ivory: FFFFF0, Lavender: F4F1F8,
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        my: {
          xs: 0,
        },
        maxWidth: {
          xs: '100vw'
        },
        minHeight: '50dvh', // very sensitive value to the height of the window causing a scroll
        maxHeight: '50dvh',
        overflow: 'auto',
      }}
    >
      <Box sx={{ ml: 2, mb: 2, mr: 2 }}>
        { !content.private ? 
          <>
            <Box sx={{ mb: 2 }}>
              <NotesPaneHeaderView content={content} />
            </Box>
            <ExistingLinkCard 
              noteTitle={content.noteTitle} 
              defaultUrl={content.url} 
              defaultDescription={content.description}
            />
          </>
          :
          !passedPasswordCheck ?
          <Box sx={{ width: '300%' }}>
            <ViewPrivateItemInput content={content} />
          </Box>
          :
          <>
            <Box sx={{ mb: 2 }}>
              <NotesPaneHeaderView content={content} />
            </Box>
            <ExistingLinkCard 
              noteTitle={content.noteTitle} 
              defaultUrl={content.url} 
              defaultDescription={content.description}
            />
          </>
        }
      </Box>
      <ViewUpdateLink content={content} />
    </Box>
  )
}

export default LinkLayout