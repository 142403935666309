import { Box, Typography, ModalClose, IconButton } from "@mui/joy";
import MinimizeIcon from '@mui/icons-material/Minimize';
import MaximizeIcon from '@mui/icons-material/Maximize';
import { useDockableContent } from "../../../contexts/dockableContentContext";

function DockPaneHeader() {

  const { dockableContentVisibility, dockableContent, toggleDockablePanel, toggleDockablePanelContent } = useDockableContent();

  return (
    <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography level="title-sm">{dockableContent.noteTitle}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ModalClose id="close-icon" onClick={toggleDockablePanel} />
        <IconButton sx={{ mt: -.5, mr: 4 }} size="sm" variant="plain" color="neutral" onClick={toggleDockablePanelContent}>
          { dockableContentVisibility ? <MinimizeIcon /> : <MaximizeIcon /> }
        </IconButton>
      </Box>
    </Box>
  );
}

export default DockPaneHeader;