import { useState } from "react";
import { Avatar, AspectRatio, Card, Link, CardContent, Box, Typography } from "@mui/joy"
import { useNotes } from "../../contexts/notesContext";
import LinkIcon from '@mui/icons-material/Link';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import SlideshowIcon from '@mui/icons-material/Slideshow';

function UploadItem({ id, noteTitle, description, url, mode, handleViewUpload, fileExtension, ...note }) {
  const { loadNoteContent, setNewSelection } = useNotes();
  const selected = note.id === id;

  const handleClick = async () => {
    const fullNote = await loadNoteContent(id);
    
    if (mode === 'nav') {
      setNewSelection(true)
    }
    
    const newNote = { 
      id, 
      noteTitle, 
      description, 
      url, 
      fileExtension, 
      ...note,
      ...fullNote 
    }
    
    handleViewUpload(newNote)
  };

  return (
    <Card className={ selected ? 'selected note-item' : 'note-item' }
      sx={{
        flexDirection: 'column',
        alignItems: 'initial',
        gap: 1,
        marginBottom: 1.85,
        paddingTop: .5,
        paddingBottom: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        backgroundColor: '#fbf8ff',
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' },
      }}
      variant="soft"
      orientation="vertical"
    >
      <CardContent>
        <Box sx={{ flex: 1 }}>
          { fileExtension === 'image' &&
            <>
              <Link
                overlay
                underline="none"
                onClick={handleClick}
                sx={{ color: 'text.tertiary', wordBreak: 'break-all' }}
              >
                <AspectRatio ratio="1" sx={{ minWidth: 60 }}>
                  <img
                    srcSet={`${url}?h=120&fit=crop&auto=format&dpr=2 2x`}
                    src={`${url}?h=120&fit=crop&auto=format`}
                    alt={noteTitle}
                  />
                </AspectRatio>
                <Box sx={{ mx: 1 }}>
                  <Typography level="title-sm" sx={{ wordBreak: 'break-all' }}>{noteTitle}</Typography>
                </Box>
              </Link>
            </>
          }
          { fileExtension === 'video' &&
            <Link
              overlay
              underline="none"
              onClick={handleClick}
              sx={{ color: 'text.tertiary' }}
            >
              <Typography sx={{ wordBreak: 'break-all' }} startDecorator={<PlayArrowIcon fontSize="sm" />} level="title-sm"> 
                <Link variant="plain" color="neutral" href={url} rel="noreferrer" title={description} target="_blank">
                  { noteTitle }
                </Link>
              </Typography>
            </Link>
          }
          { fileExtension === 'pdf' &&
            <Link
              overlay
              underline="none"
              onClick={handleClick}
              sx={{ color: 'text.tertiary' }}
            >
              <Typography sx={{ wordBreak: 'break-all' }} startDecorator={<PictureAsPdfIcon fontSize="sm" />} level="title-sm"> 
                <a href={`https://` + url} rel="noreferrer" title={description} target="_blank">
                  { noteTitle }
                </a>
              </Typography>
            </Link>
          }
          { fileExtension === 'text' &&
            <Link
              overlay
              underline="none"
              onClick={handleClick}
              sx={{ color: 'text.tertiary' }}
            >
              <Typography sx={{ wordBreak: 'break-all' }} startDecorator={<ArticleIcon fontSize="sm" />} level="title-sm"> 
                <a href={`https://` + url} rel="noreferrer" title={description} target="_blank">
                  { noteTitle }
                </a>
              </Typography>
            </Link>
          }
          { fileExtension === 'presentation' &&
            <Link
              overlay
              underline="none"
              onClick={handleClick}
              sx={{ color: 'text.tertiary' }}
            >
              <Typography sx={{ wordBreak: 'break-all' }} startDecorator={<SlideshowIcon fontSize="sm" />} level="title-sm"> 
                <a href={`https://` + url} rel="noreferrer" title={description} target="_blank">
                  { noteTitle }
                </a>
              </Typography>
            </Link>
          }
          { fileExtension === 'unknown' &&
            <Typography sx={{ wordBreak: 'break-all' }} level="title-sm"> 
              { noteTitle }
            </Typography>
          }
        </Box>
      </CardContent>
    </Card>
  )
}

export default UploadItem;