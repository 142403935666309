import { useState, useEffect } from 'react';
import { Input, Button } from '@mui/joy';
import database, { auth, firebaseApp } from '../../../configs/firebaseConfig';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {signInAnonymously} from 'firebase/auth';

const app = firebaseApp();

async function vectorSearch(text) {

  // Initialize Firebase Functions with the specified region
  const functions = getFunctions(app, 'us-central1');

  // Sign in anonymously
  const queryCallable = httpsCallable(
    functions,
    'ext-firestore-vector-search-queryCallable'
  );

  await queryCallable({query: text})
    .then(result => {
      // Read result
      console.log("result of ext-firestore-vector-search-queryCallable", result.data);
    })
    .catch(error => {
      console.error('Error querying function:', error);
    });
}

function NewBasicSearch() {

  const [search, setSearch] = useState('');

  const submitSearch = async () => {
    console.log('submit search:', search);
    vectorSearch(search)
  }

  useEffect(() => {
    console.log('search:', search);
  }, [search]);

  return (
    <>
      <p>New Basic Search</p>
      <Input onChange={(e) => setSearch(e.target.value)} placeholder="Search" />
      <Button onClick={() => submitSearch()}>Search</Button>
    </>
  )
}

export default NewBasicSearch;