import * as React from 'react';
import Box from '@mui/joy/Box';
import MyProfile from '../../Components/MyProfile';

export default function AccountHome() {
  return (
    <Box
      component="main"
      className="MainContent"
      sx={{
        pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
        pb: { xs: 2, sm: 2, md: 3 },
        flexGrow: 1,
        display: 'flex',
        height: '100dvh',
        gap: 1,
        overflow: 'auto',
      }}
    >
      <MyProfile />
    </Box>
  )
}