import { Modal, ModalDialog, ModalClose, Button, Stack, DialogTitle, DialogContent, Typography } from '@mui/joy';
import { useContextualPane } from '../../../../../contexts/contextualPane';
import SelectAssociationType from '../SelectAssociationType';
import AddAssociationProgress from '../AddAssociationProgress';
import SearchAssociationList from '../SearchAssociationsList';
import SearchForAssocitations from '../SearchAssociationsList/SearchForAssociations';
import AddComment from '../AddComment';
import FilterAndCreateIncite from '../../FilterAndCreateIncite';
import SelectPageToAssociate from '../SelectPageToAssociate';

export default function PersonaActiveItemContent() {
    const { 
        manageAssociationModalOpen, 
        setManageAssociationModalOpen,
        itemPropsAvailableForIncites,
        progressStep,
        newDraftAssociation,
        activeItem
    } = useContextualPane();

    return (
        <>
        <DialogTitle sx={{ mt: 2, mx: 3 }}>
            { progressStep === 0 && "Select the entire document or an element to link:" }
            { progressStep === 1 && "What type of link shall we create?" }
            { progressStep === 2 && newDraftAssociation.associationType === 'strong' && "Select the type of document you want to link below:" }
            { progressStep === 2 && newDraftAssociation.associationType === 'citation' && "What a document type you would like to link to below:" }
            { progressStep === 3 && "Now select want you want to connect:" }
            { progressStep === 4 && "You can add a comment and/or add a link to explain you're thinking:" }
            {/* Associating the "{ activeItem.elementSelected === "Entire document" ? 'Entire document"' : activeItem.elementSelected + '" field' } within the persona "{ activeItem.item.name }, { activeItem.item.role }" */}
        </DialogTitle>

        <DialogContent sx={{ mt: 0, px: 3 }}>
            {/* <AddAssociationProgress /> */}
            {/* STEP 0: Select scope to associate */}
            { progressStep === 0 && <FilterAndCreateIncite /> }
            {/* STEP 1: Select association type */}
            {/* // THIS STEP IS NOW SKIPPED AS NOT REQURIED BASED ON NEW DATA STRUCTURE */}
            {/* { progressStep === 1 && <SelectAssociationType />} */}
            {/* STEP 2: Select association */}
            { progressStep === 2 && <SearchAssociationList />}
            {/* STEP 3: Select association instance */}
            { progressStep === 3 && <SearchForAssocitations />}
            {/* STEP 10: Select page to associate when toType === pageset */} 
            { progressStep === 10 && <SelectPageToAssociate />}
            {/* STEP 4: Add comment */}
            { progressStep === 4 && <AddComment />}
        </DialogContent>
        </>
    )
}