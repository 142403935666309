import { useState, useEffect } from 'react';
import { Box, Chip, Switch, Typography } from '@mui/joy';
import Free from './Free';
import Paid from './Paid';
import Team from './Team';
import { useAuth } from '../../../authContext';

function ComparePlans({ monthly, setMonthly, freePlanName, paidPlanName, teamPlanName, freePriceData, paidPriceData, teamPriceData }) {

  const { currentUser } = useAuth();

  return (
    <>
    <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'left', gap: 2, mb: 4 }}>
      <Typography 
        onClick={() => setMonthly(false)} 
        sx={{ cursor: 'pointer' }} 
        endDecorator={<Chip variant="outlined" color="success">2 months free</Chip>}
      >
        Pay Annually
      </Typography>
      <Switch checked={monthly} onChange={() => setMonthly(!monthly)} />
      <Typography onClick={() => setMonthly(true)} sx={{ cursor: 'pointer' }}>
        Pay Monthly
      </Typography>
    </Box>
    <Box
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 2,
      }}
    >
      {/* SWITCH ANNUAL/MONTHLY PRICING */}
      {currentUser.plan === 'free' ? <Free name={freePlanName} pricing={freePriceData} selected={true} monthly={monthly} /> : <Free name={freePlanName} pricing={freePriceData} selected={false} monthly={monthly} />}
      {currentUser.plan === 'paid' ? <Paid name={paidPlanName} pricing={paidPriceData} selected={true} monthly={monthly} /> : <Paid name={paidPlanName} pricing={paidPriceData} selected={false} monthly={monthly} />}
      {currentUser.plan === 'team' ? <Team name={teamPlanName} pricing={teamPriceData} selected={true} monthly={monthly} /> : <Team name={teamPlanName} pricing={teamPriceData} selected={false} monthly={monthly} />}
    </Box>
    </>
  )
}

export default ComparePlans;