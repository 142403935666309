import * as React from 'react';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Button from '@mui/joy/Button';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import { useJourneys } from '../../contexts/journeysContext';
import { Link } from '@mui/joy';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';

export default function SelectPersonaSelect({ personas, setMode }) {

  const [ newPersona, setNewPersona ] = React.useState(null)
  const [ cardsData, setCardsData ] = React.useState([])
  const { handleSavePersonaForJourney } = useJourneys()
  const { accountId, selectedProjectId } = useParams()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (Array.isArray(personas)) {
      // Initialize variables for the three values
      let cardsArray = [];

      // Iterate over each persona in the personas array
      personas.forEach(persona => {
        // Check if the persona has a 'data' property that is an array
        if (Array.isArray(persona.data)) {
          // Initialize variables for the three values and id
          let nameValue = '';
          let imageValue = '';
          let roleValue = '';
          let id = persona.id;

          // Iterate over each object in the 'data' array
          persona.data.forEach(dataObj => {
            if (dataObj.field_title === 'Name') {
              nameValue = dataObj.field_value;
            }
            if (dataObj.type === 'image') {
              imageValue = dataObj.field_value;
            }
            if (dataObj.field_title === 'Role') {
              roleValue = dataObj.field_value;
            }
          });

          // Push the values into the array
          cardsArray.push({ name: nameValue, image: imageValue, role: roleValue, id });
        }
      });

      // Set the array of items to the state
      setCardsData(cardsArray);
    }
  }, [personas]);

  const handleLinkToPersonas = () => {
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/personas')
  }

  return (
    <>
    { personas && personas.length > 0 ?
    <>
    <Select
      multiple
      onChange={(event, values) => {
        console.log("values", values);
        setNewPersona(values);
      }}
      slotProps={{
        listbox: {
          sx: {
            '--ListItemDecorator-size': '48px',
          },
        },
      }}
      sx={{
      }}
    >
      { cardsData && cardsData.length > 0 && cardsData.map((data, index) => (
        <Option
          key={`personasummarycard` + index}
          value={data.id}
          label={data.role}
        >
          { data.image && 
            <ListItemDecorator>
              <Avatar src={data.image} />
            </ListItemDecorator>
          }
          <Box component="span" sx={{ display: 'block' }}>
            <Typography component="span" level="title-sm">
              {data.role}
            </Typography>
            <Typography level="body-xs">{data.name}</Typography>
          </Box>
          {/* <Chip
            size="sm"
            variant="outlined"
            color={colors[data.role]}
            sx={{
              ml: 'auto',
              borderRadius: '2px',
              minHeight: '20px',
              paddingInline: '4px',
              fontSize: 'xs',
              bgcolor: `${colors[data.role]}.softBg`,
            }}
          >
            {data.role}
          </Chip> */}
        </Option>
      ))}
    </Select>
    <Button onClick={() => { setMode('view'); handleSavePersonaForJourney(newPersona) }} sx={{ mt: .5 }} size="sm" variant="outlined">
      Assign persona
    </Button>
    </>
    :
    <Typography level="body-md">No personas found, you can create <Link variant="plain" onClick={handleLinkToPersonas}>one here</Link> for linking to this persona.</Typography>
    }
    </>
  );
}