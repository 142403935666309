import React from 'react';
import { Box, Button, ButtonGroup, FormControl, FormLabel, IconButton, Input, Modal, ModalDialog, Tooltip, Typography } from '@mui/joy';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Remove, Handle } from '../SitemapTree/components/Item';
import EditIcon from '@mui/icons-material/Edit';
import { useJourneys } from '../../contexts/journeysContext';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

export default function TableHeader({ rowData, updateRow, setDragMode, id, row, rowIndex, colIndex, title, initialData, setInitialData, setColumns, columns, columnsSpan, ...props }) {

  const [ editingTitle, setEditingTitle ] = React.useState(false);
  const [ draftTitle, setTitle ] = React.useState(title);
  const { handleIncreaseColumnSize, handleDecreaseColumnSize } = useJourneys();

  const handleTitleChange = (value) => {
    setTitle(value);
  }

  const handleIncreaseColumnClick = () => {
    handleIncreaseColumnSize({ colSpan: rowData.columnsSpan ? rowData.columnsSpan : columnsSpan, colIndex });
  }

  const handleDecreaseColumnClick = () => {
    handleDecreaseColumnSize({ colSpan: rowData.columnsSpan ? rowData.columnsSpan : columnsSpan, colIndex });
  }

  const handleSaveTitleName = () => {
    setEditingTitle(false);
    console.log('save title name');
    updateRow({ rowIndex, colIndex, topHeader: row ? false : true, data: { title: draftTitle } });
  }

  const handleEditTitle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setEditingTitle(true);
  }

  const handleRemoveRow = ({ rowIndex }) => {
    const newItems = initialData.filter((row, index) => index !== rowIndex);
    setInitialData( newItems );
  }

  const handleRemoveCol = ({ rowIndex, colIndex, setColumns, columns, columnsSpan }) => {
    const newItems = initialData.map((row, index) => {
      const newChildren = row.children.filter((child, childIndex) => childIndex !== colIndex);
      return {
        ...row,
        children: newChildren,
      }
    })

    setColumns(columns - columnsSpan) // for grid layout purposes

    setInitialData( newItems );
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id, data: { dragMode: row ? 'row' : 'column' } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    row 
    ?
      <>
        <Box display="flex" alignItems="center" sx={{ '&:hover .title-icon-edit': {
          display: 'block',
        }}}>
        <Tooltip title="Drag row to move" variant="outlined" color="neutral" placement="top">
          <div className='table-header' ref={setNodeRef} style={style} {...attributes} {...listeners} onMouseEnter={() => setDragMode('row')} onMouseLeave={() => setDragMode('item')}><Handle /></div>
        </Tooltip>
        {editingTitle ?
          <Input
            autoFocus={true}
            onChange={(e) => handleTitleChange(e.target.value)}
            onBlur={() => setEditingTitle(false)}
            value={draftTitle}
            defaultValue={title}
            size="sm"
            sx={{ flexGrow: 1 }}
            onKeyDown={(e) => {
            if (e.keyCode === 13) {
                handleSaveTitleName();
              } else if (e.keyCode === 27) {
                setEditingTitle(false);
              }
            }}
          />
        : 
          <>
          <Typography 
            level="title-sm" 
            sx={{ ml: 1, pr: 3, cursor: 'pointer', fontWeight: 800 }} 
            endDecorator={
              <Box className="title-icon-edit" sx={{ display: 'none' }}>
                <Tooltip title="Edit title" variant="outlined" color="neutral" placement="top">
                  <IconButton size="sm">
                    <EditIcon fontSize="10" onClick={handleEditTitle} />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          >
            {title}
          </Typography>
          
          </>
        }
      </Box>
      <Box>
        <Tooltip title="Remove row" variant="solid" color="danger" placement="top">
          <IconButton size="sm">
            <Remove onClick={() => handleRemoveRow({ rowIndex: rowIndex + 1 })} />
          </IconButton>
        </Tooltip>
      </Box>
      </>
    :
      <>
      <Box position="relative" display="flex" alignItems="center" sx={(theme) => ({ minHeight: '60px', '&:hover .title-icon-edit': {
          display: 'flex', 
        },  })} >
        <Tooltip title="Drag column to move" variant="outlined" color="neutral" placement="top">
          <div onMouseEnter={() => setDragMode('column')} onMouseLeave={() => setDragMode('item')} ref={setNodeRef} style={style} {...attributes} {...listeners} className='table-header'><Handle /></div>
        </Tooltip>
        {editingTitle ?
          <Input
            autoFocus={true}
            onChange={(e) => handleTitleChange(e.target.value)}
            onBlur={() => setEditingTitle(false)}
            value={draftTitle}
            defaultValue={title}
            size="sm"
            sx={{ flexGrow: 1 }}
            onKeyDown={(e) => {
            if (e.keyCode === 13) {
                handleSaveTitleName();
              } else if (e.keyCode === 27) {
                setEditingTitle(false);
              }
            }}
          />
        : 
          <Typography 
            level="title-sm" 
            sx={{ ml: 1, pr: 3, cursor: 'pointer', fontWeight: 800 }} 
            endDecorator={
              <Box className="title-icon-edit" sx={{ display: 'none' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box flex={1} sx={{ mr: .4 }}>
                    <Tooltip title="Edit title" variant="plain" placement="top">
                      <IconButton size="sm">
                        <EditIcon fontSize="10" onClick={handleEditTitle} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box flex={1} sx={{ mr: .4 }}>
                    <Tooltip title="Remove column" variant="solid" color="danger" placement="top">
                      <IconButton size="sm">
                        <Remove onClick={() => handleRemoveCol({ rowIndex, colIndex: colIndex, setColumns, columns, columnsSpan }) } />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box flex={1} sx={{ ml: .75 }}>
                    <Box>
                      <ButtonGroup size="sm">
                        <Tooltip title="Remove step column" variant="plain" placement="top">
                          <IconButton>
                            <RemoveIcon fontSize="10" onClick={() => rowData.columnsSpan > 2 && handleDecreaseColumnClick()} sx={{ color: rowData.columnsSpan === 2 ? 'lightgray' : 'inherit' }} />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Add step column" variant="outlined" color="neutral" placement="top">
                          <IconButton>
                            <AddIcon fontSize="10" onClick={handleIncreaseColumnClick} />
                          </IconButton>
                        </Tooltip>
                      </ButtonGroup>
                    </Box>
                  </Box>
                  
                </Box>
              </Box>
            }
          >
            {title}
          </Typography>
        }
        {/* <Box className="resize-col" sx={(theme) => ({ position: 'absolute', backgroundColor: `${theme.palette.primary.outlinedBorder}`, mr: 1, right: 0, top: 10, bottom: 10, width: '2px', cursor: 'col-resize' })} /> */}
      </Box>
      </>
  )
}