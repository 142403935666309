import React from 'react';
import { Box, FormControl, FormLabel, Input, Typography } from '@mui/joy';

export default function QuoteComponent({ gridColumns, data, id, updateHandler, deleteHandler, editMode = true }) {
  return (
    editMode ?
    <FormControl
      sx={{
        display: {
          sm: 'flex-column',
          md: 'flex-row',
        },
      }}
    >
      <FormLabel>
        { data.field_title } { data.cantDelete && ' (required)' }
      </FormLabel>
      <Input 
        size="sm" 
        onBlur={(e) => updateHandler(prevState => {
          const updatedArray = prevState.map(item => {
            if (item.id === id) {
              return { ...item, field_value: e.target.value };
            }
            return item;
          });
        
          return updatedArray;
        })}
        defaultValue={data && data.field_value ? data.field_value : ''} 
        placeholder="What might this person say?" 
      />
    </FormControl>
    :
    data.field_value !== '' &&
    <Box
      sx={{ 
        borderBottom: data.block_location !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
        ml: gridColumns === 1 ? 0 : 4, 
        mr: gridColumns === 1 ? 0 : 4, 
        pb: 2.5
      }}
    >
      <Typography level="body-md" sx={{ fontWeight: 'bold' }} color="neutral">
        { data.field_value ? data.field_value : '' }
      </Typography>
    </Box>
  )
}