import {$isElementNode} from 'lexical'

export function getElementNodesInSelection( selection ) {
  const nodesInSelection = selection.getNodes();

  if (nodesInSelection.length === 0) {
    return new Set([
      selection.anchor.getNode().getParentOrThrow(),
      selection.focus.getNode().getParentOrThrow(),
    ]);
  }

  return new Set(
    nodesInSelection.map((n) => ($isElementNode(n) ? n : n.getParentOrThrow())),
  );
}

export function $elementHasChildren(elementNode) {
  if (elementNode && elementNode.__children && elementNode.__children.length > 0) {
    return true
  } else {
    return false
  }
}