import { Box } from "@mui/joy";
import DockPaneHeader from "../DockPaneHeader";
import { useDockableContent } from "../../../contexts/dockableContentContext";
import LinkCard from "../../MyNotes/LinkCard";
import { useNotes } from "../../../contexts/notesContext";
import Uploads from "../../MessagesPane/Uploads/Images";

function DockedUpload() {

  const { dockableContent } = useDockableContent();
  const { setNewSelection } = useNotes();

  return (
    <>
      <DockPaneHeader />
      <Box
        className="dock-content"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexShrink: 0 }}
      >
        <Uploads
          selectedNote={dockableContent}
        />
      </Box>
    </>
  );
}

export default DockedUpload;