import { Card, CardContent, Grid } from "@mui/joy";
import HighlightItem from "./HighlightItem";
import PersonaItem from "./PersonaItem";

function ExploreTagCard({ reference, tag }) {
    return (
        <Card 
            sx={{
                flexDirection: 'column',
                alignItems: 'initial',
                gap: 1,
                marginBottom: 1.85,
                pt: { xs: 2, lg: 4 },
                pb: { xs: 4, lg: 4 },
                px: 3,
                boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
                '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' },
            }}
            variant="soft"
            orientation="vertical"
        >
            <CardContent>
                <Grid container spacing={2}>
                    {/* Card content */}
                    { reference.type === "highlight" &&
                        tag && reference &&
                            <HighlightItem reference={reference} tag={tag} />
                    }
                    { reference.type === "persona" &&
                        tag && reference &&
                            <PersonaItem reference={reference} tag={tag} />
                    }
                </Grid>
            </CardContent>
        </Card>
    )
}

export default ExploreTagCard;