import React from 'react';
import { Table, Typography, Box, Button } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { retrieveInvitationsForAccount, cancelInvitation } from '../../database/invitations-db';

function InvitedUsersTable({ invitationsSent, setInvitationsSent }) {

  const { accountId } = useParams();

  const cancelInvite = async (invitationId) => {
    console.log("Cancelling invitation", invitationId);
    await cancelInvitation({ accountId, invitationId });
    // retrieve the updated invitations
    const updatedInvitations = await retrieveInvitationsForAccount({ accountId });
    setInvitationsSent(updatedInvitations);
  }

  console.log("invitationsSent", invitationsSent);

  return (
    invitationsSent && invitationsSent.length > 0 &&
    <>
      <Typography level="h3" sx={{ mt: 5, mb: 2 }} component="h2">Invited Users</Typography>
      <Table
        aria-label="table with ellipsis texts"
        noWrap
        sx={{ '--TableCell-headBackground': '#fff',
          '--Table-headerUnderlineThickness': '1px',
          '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
          '--TableCell-paddingY': '12px',
          '--TableCell-paddingX': '6px',
          width: '100%',
          pb: 5 }}
      >
        <thead>
          <tr>
            <th style={{ width: '40%' }}>User</th>
            <th>
              Role
            </th>
            <th>
              Status
            </th>
            <th>
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          {invitationsSent && invitationsSent.length > 0 && invitationsSent.map((user) => (
            <tr key={user.id}>
              <td>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                  <Box sx={{ minWidth: 0 }}>
                    <Typography noWrap sx={{ fontWeight: 'lg' }}>
                      {user.email}
                    </Typography>
                  </Box>
                </Box>
              </td>
              <td>
                {user.role}
              </td>
              <td>
                {user.status}
              </td>
              <td>
                <Button size="sm" variant="outlined" color="neutral" onClick={() => cancelInvite(user.id)}>
                  Cancel invite
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default InvitedUsersTable;