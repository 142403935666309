import React from 'react'
import { Box, Button, Grid, Sheet, Switch, Typography } from '@mui/joy'
import PersonaEditorDefaultLayout from './Default';

let slotLocations = ['tl_1_1', 'tl_2_1', 'tr_1_1', 'tr_2_1', 'tr_2_2', 'tr_3_2', 'tr_4_1', 'tr_5_1', 'tr_5_2', 'tr_6_1', 'tr_6_2']

const generateNewId = (slotLocations) => {
  const highestNumber = slotLocations.reduce((max, slot) => {
    const match = /tr_(\d+)_\d+/.exec(slot);
    const number = match ? parseInt(match[1], 10) : 0;
    return number > max ? number : max;
  }, 0);

  const newId = `tr_${highestNumber + 1}_1`; // Assuming the second part is always 1 for a new slot
  return newId;
};

export default function PersonaEditorLayout({ layout = "default", setPersonaFields, personaFields, editChecked }) {
  const [slots, setSlots] = React.useState(null)
  const [emptySlots, setEmptySlots] = React.useState([])
  const [newSlots, setNewSlots] = React.useState([])

  const handleDeleteField = ({ idToDelete }) => {
    // Assuming personaFields is an array of objects
    if (idToDelete) {
      // Create a new array without the object with the matching idToDelete
      const updatedPersonaFields = personaFields.filter(field => field.id !== idToDelete);

      // Update the state with the modified array
      setPersonaFields(updatedPersonaFields);
    }
  };  

  const handleAddNewRow = () => {
    const newId = generateNewId(slotLocations);

    // Add item to slotLocations array
    slotLocations.push(newId);

    // Push a new item to the newSlots array
    setNewSlots([...newSlots, newId]);

  }

  React.useEffect(() => {
    if (personaFields) {
      console.log("personaFields changed", personaFields)
      // Iterate through personaFields and find matches with slotLocations
      const matchedSlots = new Map();

      Object.keys(personaFields).forEach((key) => {
        const field = personaFields[key];
        if (field && field.block_location && slotLocations.includes(field.block_location)) {
          matchedSlots.set(field.block_location, { ...field }); // Copy the matched object
        }
      });

      // Update the slots state with the matched slots
      setSlots(matchedSlots);

      // Iterate through personalFields and find empty slots
      const emptySlots = slotLocations.filter((slot) => !matchedSlots.has(slot));
      setEmptySlots(emptySlots);

    }
  }, [personaFields])

  return (
    layout === "default" &&
      <PersonaEditorDefaultLayout 
        slots={slots} 
        setPersonaFields={setPersonaFields} 
        handleDeleteField={handleDeleteField} 
        editChecked={editChecked}
        newSlots={newSlots}
        emptySlots={emptySlots}
        handleAddNewRow={handleAddNewRow}
      />
  )
  
}