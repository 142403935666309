import * as React from 'react';
import { Grid, Typography } from '@mui/joy';

function ModalWithSidePane({ children, sidePaneOpen = true, setSidePaneOpen, sideWidth = '33.333%', SideContent, MainContent }) {
  return (
    <Grid container sx={{ position: 'relative', overflow: 'hidden' }}>
      <Grid xs={12} sx={{ 
        transition: 'all 0.3s ease-in-out',
        marginRight: sidePaneOpen ? sideWidth : 0,
        flexGrow: 1,
        width: '100%'
      }}>
        {MainContent}
      </Grid>

      <Grid sx={{
        transition: 'all 0.3s ease-in-out',
        transform: sidePaneOpen ? 'translateX(0)' : 'translateX(100%)',
        opacity: sidePaneOpen ? 1 : 0,
        visibility: sidePaneOpen ? 'visible' : 'hidden',
        position: 'absolute',
        right: 0,
        top: 0,
        width: sideWidth,
        height: '100%',
      }}>
        {SideContent}
      </Grid>
    </Grid>
  )
}

export default ModalWithSidePane;