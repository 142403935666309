import React from 'react';
import { styled } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import Grid from '@mui/joy/Grid';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Done from '@mui/icons-material/Done';
import Sheet from '@mui/joy/Sheet';
import { radioClasses } from '@mui/joy/Radio';

const colours = [
    blue, 
    red, 
    pink, 
    purple, 
    indigo, 
    lightBlue, 
    teal, 
    green, 
    lightGreen, 
    lime, 
    yellow, 
    orange, 
    brown, 
    grey
]

export default function ChooseColourSelector({ setOpen, selectedColour, handleColourChange, groupId, tag, colourStrength = 100 }) {

  const [selectedColor, setSelectedColor] = React.useState(selectedColour);

  const handleChangeColour = (event) => {
    setSelectedColor(JSON.parse(event.target.value));
    setOpen(false);
    if (tag && tag.id) {
      handleColourChange({ color: JSON.parse(event.target.value), groupdId: groupId, tagId: tag.id })
    } else {
      handleColourChange({ color: JSON.parse(event.target.value), groupdId: groupId })
    }
  };

  React.useEffect(() => {
    setSelectedColor(selectedColour);
  }, [selectedColour]);

  return (
    <Box sx={{ resize: 'horizontal', px: 1, pt: 1 }}>
      <RadioGroup
        aria-labelledby="product-color-attribute"
        defaultValue={selectedColour}
        value={selectedColor}
        onChange={handleChangeColour}
        sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 1 }}
      >
        { colours.map((color,i) => (
        <Sheet
            key={`color` + i}
            sx={{
              position: 'relative',
              width: 24,
              height: 24,
              flexShrink: 0,
              bgcolor: color[colourStrength],
              borderRadius: 7,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Radio
              overlay
              variant="solid"
              color={color[colourStrength]}
              checkedIcon={<Done fontSize="xl2" />}
              checked={JSON.stringify(color) === JSON.stringify(selectedColor)}
              value={JSON.stringify(color)}
              slotProps={{
                input: { 'aria-label': color[colourStrength] },
                radio: {
                  sx: {
                    display: 'contents',
                    '--variant-borderWidth': '2px',
                  },
                },
              }}
              sx={{
                '--joy-focus-outlineOffset': '4px',
                '--joy-palette-focusVisible': (theme) =>
                  color[colourStrength],
                [`& .${radioClasses.action}.${radioClasses.focusVisible}`]: {
                  outlineWidth: '2px',
                },
              }}
            />
          </Sheet>
        ))}
      </RadioGroup>
    </Box>
  )
}