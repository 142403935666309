import React, { useState, useEffect, useRef, createContext, useContext, useCallback } from 'react';
import { deleteJourney, retrieveJourneysFromFirestore, saveToFirestore } from '../database/journeys-db';
import { useParams } from 'react-router-dom';
import { useContextualPane } from './contextualPane';
import { useProjects } from './projectsContext';
import { initialDefaultData } from '../Components/JourneyMap/initialData';
import { set } from 'date-fns';
import { v4 } from 'uuid';
import { cutComponentFromArray, pasteComponentIntoArray, setActiveAndOverItems } from '../Components/JourneyMap/utils';
import { arrayMove } from '@dnd-kit/sortable';

// Create the context
const JourneysContext = createContext();

// Create a provider component
export function JourneysProvider({ children }) {

  const [ initialData, setInitialData ] = useState([]);
  const [ flattenedItems, setFlattenedItems ] = useState([]);
  const [ columns, setColumns ] = useState(16);
  const [ columnsSpan, setColumnsSpan ] = useState(2);
  const [ defaultWidth, setDefaultWidth ] = useState('96vw');
  const [ columnIds, setColumnIds ] = useState([]);
  const [ rowIds, setRowIds ] = useState([]);
  const [ overDropZone, setOverDropZone ] = useState(false);
  const [ dropZoneVisible, setDropZoneVisible ] = useState(false);
  const [ hoveredCell, setHoveredCell ] = useState(null);

  const { accountId, selectedProjectId } = useParams()
  const [ journeys, setJourneys ] = useState()
  const [ open, setOpen ] = useState(false);
  const { activeItem, setActiveItem } = useContextualPane();
  const { selectedProject } = useProjects()
  const [ draftJourney, setDraftJourney ] = useState({}) // [draftJourney, setDraftJourney] = useState({})
  const [ dragMode, setDragMode ] = useState('item'); // column | row | item
  const [ showRowTypeModal, setShowRowTypeModal ] = useState(false);
  const [ newRowType, setNewRowType ] = useState(null);
  const [ zoom, setZoom ] = useState(1);
  const [ size, setSize ] = useState({ width: '96vw', height: '100vh' }); // size of resizable journey
  const [ columnsOffset, setColumnsOffset ] = useState(0); // for grid layout purposes when the header columns have their own custom span values
  const resizableRef = useRef(null);

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = useState('')

  // Route 1: Clicking on create journey
  // Retrieve local template
  // Set template to activeItem
  // Set other variables
  const handleCreateJourney = async () => {
    const localResults = await retrieveInitialData()
    handleSetJourney(localResults[0])
    setOpen(true)
  }

  const setJourney = (value) => {
    setActiveItem({ item: { ...value }, itemType: 'journey' });
  }

  // Route 2: Clicking on individual existing journey
  // Use passed in journey to populate activeItem
  // Set other variables
  const handleSetJourney = (value) => {
    setActiveItem( { item: { ...value }, itemType: 'journey' } );
    setInitialData(value.items)

    setColumns(value.columns)
    setColumnsSpan(value.columnsSpan)
    setDefaultWidth(value.width)
    setMinWidth(value.width)
    setOpen(true)
  }

  // console.log("activeItem", activeItem)

  const handleShowSnackbar = ({ reason, message }) => {
    if (reason === 'success') {
      setSnackbarSeverity('success')
      setSnackbarMsg(message)
      setShowSnackbar(true)
    }
  }

  const handleUpdateCurrentJourney = async () => {
    
    // handles remote save
    const journey = {
      ...activeItem.item,
      columns: columns,
      columnsSpan: columnsSpan,
      width: resizableRef.current.size.width,
      items: initialData
    };

    const response = await saveToFirestore({ journey, accountId, selectedProjectId });
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Journey updated'})
    const newJourneys = await retrieveJourneysFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newJourneys) {
      setJourneys(newJourneys)
    }
  }

  const handleJourneyDeletion = async (values) => {
    // handles remote deletion
    const response = await deleteJourney({ journeyId: values.id, accountId: accountId })
    ////console.log("response from saving feature", response)
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Journey deleted'})
    const newJourneys = await retrieveJourneysFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newJourneys) {
        setJourneys(newJourneys)
    }
  }

  const updateDescriptionOfActiveItem = (description) => {
    setActiveItem((prevState) => ({
      ...prevState,
      item: {
        ...prevState.item,
        description: description
      }
    }));
  }

  const updateColumnsOfActiveItem = ({ columns, columnsSpan, width }) => {
    setActiveItem((prevState) => ({
      ...prevState,
      item: {
        ...prevState.item,
        columns: columns,
        columnsSpan: columnsSpan,
        width: width
      }
    }));
  }

  const updateTitleOfActiveItem = (title) => {
    setActiveItem((prevState) => ({
      ...prevState,
      item: {
        ...prevState.item,
        title: title
      }
    }));
  }

  const retrieveInitialData = async () => {
    const results = await initialDefaultData
    return results
  }

  const handleSavePersonaForJourney = (persona) => {
    // TO DO Save association against the persona
    setActiveItem((prevState) => ({
      ...prevState,
      item: {
        ...prevState.item,
        persona: persona
      }
    }));
    // TO DO Also store association against the journey and the persona (strong and citation)
  }

  const fetchJourneys = async () => {
    const results = await retrieveJourneysFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (results.length > 0) { 
      setJourneys(results)
    }
  }

  useEffect(() => {
    if (initialData && initialData.length > 0) {
      // Set flattened items for draggable
      setFlattenedItems(initialData.reduce((acc, row) => {
        if (row && row.type && row.type !== 'rowHeader') {
          return acc;
        }

        let rowItems = []

        if (row && row.children && row.children.length > 0) {
          rowItems = row.children
            .filter(column => column.type === "columnCell")
            .filter(column => column.children && column.children.length > 0)
            .flatMap(column => column.children.map(component => ({
              ...component,
              id: component.id,
              type: component.type,
              title: component.title,
            })));
        }

        return [...acc, ...rowItems];
      }, []));

      // Set column ids for draggable
      setColumnIds(initialData.reduce((acc, row) => {
        if (row && row.type && row.type !== 'rowHeaders') {
          return acc;
        }

        if (row && row.children && row.children.length > 0) {
          return [...acc, ...row.children.map(column => column.id)];
        }
      }, []));

      // Set row ids for draggable
      setRowIds(initialData.reduce((acc, row) => {
        if (row && row.type && row.type !== 'rowHeader') {
          return acc;
        }

        if (row && row.id) {
          return [...acc, row.id];
        }
      }, []));

    }
  }, [initialData]);

  useEffect(() => {
    // console.log("initialData: ", initialData);
    // Copy initialData to activeItems to ensure that any CRUD operations are saved
    setDraftJourney( initialData );
  }, [initialData]);

  useEffect(() => {
    //console.log("columnIds: ", columnIds);
  }, [columnIds]);

  useEffect(() => {
    // console.log("journeys: ", journeys);
  }, [journeys]);

  // useEffect(() => {
  //   console.log("activeItem in journeysContext: ", activeItem)
  //   if (activeItem && activeItem.item && activeItem.item.items && activeItem.item.columns) {
  //     if (activeItem.item.columns) {
  //       console.log("Setting columns: ", activeItem.item.columns)
  //       setColumns(activeItem.item.columns);
  //     }
  //     if (activeItem.item.columnsSpan) {
  //       console.log("Setting columnsSpan: ", activeItem.item.columnsSpan)
  //       setColumnsSpan(activeItem.item.columnsSpan);
  //     }
  //     if (activeItem.item.items && activeItem.item.items) {
  //       console.log("Setting initialData: ", activeItem.item.items)
  //       setInitialData(activeItem.item.items);
  //     }
  //     if (activeItem.item.width) {
  //       console.log("Setting defaultWidth: ", activeItem.item.width)
  //       setDefaultWidth(activeItem.item.width);
  //       setMinWidth(activeItem.item.width);
  //     }
  //   }
  // }, [activeItem]);

  const handleApplyTemplateToJourney = (items, columns, columnsSpan, width) => {
    setInitialData(items)
    setColumns(columns)
    setColumnsSpan(columnsSpan)
    setDefaultWidth(width)
    setMinWidth(width)
  }

  const handleAddElementClick = ({ rowIndex, colIndex, types }) => {
    if (types === "journeyStepCard") {
      const newItems = initialData.map((row, index) => {
        if (index === rowIndex + 1) {
          const newChildren = row.children.map((column, columnIndex) => {
            if (columnIndex === colIndex) {
              return {
                ...column,
                children: [
                  ...column.children,
                  {
                    id: v4(),
                    type: "journeyStepCard",
                    satisfaction: "somewhatnot",
                    title: "New Step",
                    description: "This is a new step."
                  }
                ]
              }
            }
            return column;
          });

          return {
            ...row,
            children: newChildren,
          }
        }
        return row;
      });

      setInitialData( newItems );
    }
    if (types === "journeyLinkCard") {
      const newItems = initialData.map((row, index) => {
        if (index === rowIndex + 1) {
          const newChildren = row.children.map((column, columnIndex) => {
            if (columnIndex === colIndex) {
              return {
                ...column,
                children: [
                  ...column.children,
                  {
                    id: v4(),
                    type: "journeyLinkCard",
                    title: "New Step",
                    link: "https://www.google.com"
                  }
                ]
              }
            }
            return column;
          });

          return {
            ...row,
            children: newChildren,
          }
        }
        return row;
      });

      setInitialData( newItems );
    }
  }

  const handleNewRowTypeValue = (e, value) => {
    setNewRowType(e.target.value);
  }

  const validateAndSubmitNewRowValue = () => {
    if (newRowType) {
      handleAddRow();
      setShowRowTypeModal(false);
    } else {
      // TO DO: Show error message
      console.log("Please select a row type");
    }
  }

  const createNewHeaderColumn = () => {
    return {
      id: v4(),
      title: "New Column",
      type: "topHeader",
    };
  }

  const handleDecreaseColumnSize = ({ colSpan, colIndex }) => {

    const newItems = initialData.map((row) => {
      if (row.type === "rowHeaders") {
        const newChildren = row.children.map((child, childIndex) => {
          if (childIndex === colIndex) {
            return { 
              ...child, 
              columnsSpan: colSpan - 2,
            };
          }
          return child;
        });

        return {
          ...row,
          children: newChildren,
        }
      } else if (row.type === "rowHeader") {
        let newChildren = [...row.children];
        newChildren.splice(colIndex+1, 1);
        return {
          ...row,
          children: newChildren,
        }
      }

      return row;
    })

    setColumns((prevColumns) => {
      return prevColumns - 2;
    });

    decreaseWidth()

    setInitialData( newItems );

  }

  const handleIncreaseColumnSize = ({ colSpan, colIndex }) => {

    const newEmptyRowColumnValues = {
      id: v4(),
      type: "columnCell",
      children: [],
    }

    // Within the initialData array, loop through and find and update all occurances where initialData.type === "rowHeader" 
    // add to matching items initialData.children[colIndex] and add a 

    const newItems = initialData.map((row) => {
      if (row.type === "rowHeaders") {
        const newChildren = row.children.map((child, childIndex) => {
          if (childIndex === colIndex) {
            return { 
              ...child, 
              columnsSpan: colSpan + 2,
            };
          }
          return child;
        });

        return {
          ...row,
          children: newChildren,
        }
      } else if (row.type === "rowHeader") {
        let newChildren = [...row.children];
        newChildren.splice(colIndex+1, 0, newEmptyRowColumnValues);
        return {
          ...row,
          children: newChildren,
        }
      }

      return row;
    })

    setColumns((prevColumns) => {
      return prevColumns + 2;
    });

    increaseWidth()

    setInitialData( newItems );

  }

  const handleAddColumn = () => {

    const newHeaderColumn = createNewHeaderColumn();

    const newEmptyCellValues = {
      columnId: newHeaderColumn.columnId,
      columnType: "columnCell",
      children: [],
    }

    const newItems = initialData.map((row) => {
      if (row.type === "rowHeaders") {
        return {
          ...row,
          children: [...row.children, newHeaderColumn],
        }
      } else if (row.type === "rowHeader") {
        return {
          ...row,
          children: [...row.children, newEmptyCellValues ],
        }
      }
  
      return row;
    })

    setColumns(columns + columnsSpan) // for grid layout purposes

    increaseWidth()

    setInitialData( newItems );
  }

  const chooseNewRowType = () => {
    setShowRowTypeModal(true);
  }

  const handleAddRow = () => {

    const getNumberOfColumns = () => {
      return initialData.filter(row => row.type === "rowHeaders")[0].children.length;
    }

    const createNewRow = (emptyCols) => {
      return {
        id: v4(),
        title: "New Row",
        type: "rowHeader",
        types: newRowType,
        children: [...emptyCols],
      };
    }

    const numberOfCols = getNumberOfColumns()

    const emptyCols = (numberOfCols) => {
      return Array.from({ length: numberOfCols }, () => ({
        id: v4(),
        type: "columnCell",
        children: [],
      }))
    }

    const newRow = createNewRow(emptyCols(numberOfCols));

    const newItems = [...initialData, newRow];

    setInitialData( newItems );
  }

  const updateRow = ({ topHeader, rowIndex, colIndex, data }) => {
    if (topHeader) {
      const newItems = initialData.map((row, index) => {
        const newChildren = row.children.map((child, childIndex) => {
          if (childIndex === colIndex) {
            return { ...child, ...data };
          }
          return child;
        });

        return {
          ...row,
          children: newChildren,
        }
      });

      setInitialData( newItems );
    } else {
      const newItems = initialData.map((row, index) => {
        if (index === rowIndex + 1) {
          return { ...row, ...data };
        }
        return row;
      });

      setInitialData( newItems );
    }
  }

  const updateComponent = ({ componentIndex, colIndex, rowIndex, data }) => {

    const newItems = initialData.map((row, index) => {
      if (index === rowIndex+1) {
        const newChildren = row.children.map((child, childIndex) => {
          if (childIndex === colIndex) {
            const newComponent = { ...child.children[componentIndex], ...data };
            child.children[componentIndex] = newComponent;
            return child;
          }
          return child;
        });

        return {
          ...row,
          children: newChildren,
        }
      }
      return row;
    });

    setInitialData( newItems );

  }

  const handleRemove = ({ componentIndex, colIndex, rowIndex }) => {
    
    // Create a copy of the items array
    const newItems = [...initialData];

    // Access the row, column, and component directly using the provided indices
    const row = newItems[rowIndex+1];
    if (row && row.type === "rowHeader") {
      const column = row.children[colIndex];
      if (column) {
        // Remove the component from the column's components array
        column.children.splice(componentIndex, 1);
      }
    }

    setInitialData( newItems );
  }

  const handleDragStart = (event) => {
    const {active} = event;

    if (active.id && dragMode === "item") {
      setDropZoneVisible(true);
    }

  }

  const handleDragEnd = (event) => {
    const {active, over} = event;

    setDropZoneVisible(false);


    if (active.id && over.id) {
      if (active.id !== over.id) {

        // Fetch active items
        const { activeRow, activeColumn, activeComponent, overRow, activeOverColumn, activeOverComponent } = setActiveAndOverItems({ array: initialData, active, over })
  
        if ( active.data.current.dragMode === 'item' ) {
          
          setInitialData((prevData) => {
  
            // Determine if item has been dropped to a new dropzone
            if (overDropZone) {
              // DROPZONE
  
              // Cut component from array and return modified array and component for re-insertion
              const { modifiedArray, movedComponent } = cutComponentFromArray({ array: prevData, activeColumn, activeRow, activeComponent, active })
  
              // Get row id and column id from over.id
              let [overRowIndex, overColumnIndex] = over.id.split('_');
              // overRowId + 1 as this is filtered out the header row in logic below where this is set
  
              overRowIndex = parseInt(overRowIndex);
              overRowIndex = overRowIndex + 1;
              overColumnIndex = parseInt(overColumnIndex);
  
              // Insert component into the overRow
              const result = pasteComponentIntoArray({ array: modifiedArray, overRowIndex: overRowIndex, overColumnIndex, movedComponent });
  
              return result;
  
            } else {
              // HAS ROW CHANGED?
              if (activeComponent && activeOverComponent && activeComponent.rowIndex === activeOverComponent.rowIndex) {
  
                // CHECK IF COLUMNS ARE CHANGING
                if (activeComponent.columnIndex === activeOverComponent.columnIndex) {
                  const newComponentPositions = arrayMove(prevData[activeComponent.rowIndex].children[activeComponent.columnIndex].children, activeComponent.componentIndex, activeOverComponent.componentIndex);
  
                  const newItems = prevData.map((row, index) => {
                    if (index === activeComponent.rowIndex) {
                      const newChildren = row.children.map((child, childIndex) => {
                        if (childIndex === activeOverComponent.columnIndex) {
                          return {
                            ...child,
                            children: newComponentPositions,
                          }
                        }
                        return child;
                      });
  
                      return {
                        ...row,
                        children: newChildren,
                      }
                    }
                    return row;
                  });
  
                  return newItems;
                } else {
                  // Cut component from array and return modified array and component for re-insertion
                  const { modifiedArray, movedComponent } = cutComponentFromArray({ array: prevData, activeColumn, activeRow, activeComponent, active })
  
                  // Insert component into the overRow
                  const result = pasteComponentIntoArray({ array: modifiedArray, overRowIndex: activeOverComponent.rowIndex, overColumnIndex: activeOverComponent.columnIndex, movedComponent });
  
                  return result;
                }
  
              } else {
  
                if (activeComponent && activeOverComponent) {
                
                  // Cut component from array and return modified array and component for re-insertion
                  const { modifiedArray, movedComponent } = cutComponentFromArray({ array: prevData, activeColumn, activeRow, activeComponent, active })
  
                  // Insert component into the overRow
                  const result = pasteComponentIntoArray({ array: modifiedArray, overRowIndex: activeOverComponent.rowIndex, overColumnIndex: activeOverComponent.columnIndex, movedComponent });
  
                  return result;
                } else {
                  return prevData;
                }
  
              }
  
            }
            
          });
  
        }
        else if ( active.data.current.dragMode === 'column' ) {
  
          const activeRowIndex = activeColumn.rowIndex;
          const activeColIndex = activeColumn.columnIndex;
          const overColIndex = activeOverColumn.columnIndex;
          
          setInitialData((prevData) => {
  
            // Move header columns first
            const newHeaderItems = arrayMove( prevData[activeRowIndex].children, activeColIndex, overColIndex );
  
            const newDataWithModifiedColumnHeaders = prevData.map((item, index) => {
              if (index !== activeRowIndex) {
                return item;
              }
  
              return { ...item, children: newHeaderItems };
            });
  
            // Move the components from all rows to the new active column
            const newItems = newDataWithModifiedColumnHeaders.map((row) => {
              if (row && row.type && row.type === 'rowHeader') {
                const newColumnItems = arrayMove(row.children, activeColIndex, overColIndex);
                return { ...row, children: newColumnItems };
              }
              return row;
            });
  
            return newItems;
          });
        } 
        else if ( active.data.current.dragMode === 'row' ) {
          setInitialData((prevData) => {
  
            const activeIdIndex = prevData.findIndex(item => item.id === active.id);
            const overIdIndex = prevData.findIndex(item => item.id === over.id);
  
            const newItems = arrayMove(prevData, activeIdIndex, overIdIndex);
            return newItems;
          });
        }
      }
    }

    
  }

  const handleZoomIn = () => {
    setZoom(prevZoom => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    setZoom(prevZoom => prevZoom - 0.1);
  };

  const handleResizeStop = (e, direction, ref, d) => {

    //setMinWidth(prevMinWidth => prevMinWidth + d.width);

    // setSize({
    //   width: ref.style.width,
    //   height: ref.style.height,
    // });
  };

  const setMinWidth = (newWidth) => {
    if (resizableRef.current) {
      resizableRef.current.updateSize({ width: newWidth, height: size.height });
    }
  };

  const increaseWidth = () => {
    if (resizableRef.current) {
      const currentSize = resizableRef.current.size;
      const newWidth = currentSize.width + window.innerWidth * 0.3;
      resizableRef.current.updateSize({ width: newWidth, height: currentSize.height });
      setDefaultWidth(newWidth)
      return newWidth
    }
  };

  const decreaseWidth = () => {
    if (resizableRef.current) {
      const currentSize = resizableRef.current.size;
      const newWidth = currentSize.width - window.innerWidth * 0.3;
      resizableRef.current.updateSize({ width: newWidth, height: currentSize.height });
      setDefaultWidth(newWidth)
      return newWidth
    }
  };

  const handleIncreaseColumnWidth = () => {

    // 1. Set incremental span
    const newDraftColumnsSpan = columnsSpan + 1;

    // 2. Retrieve the number of data columns
    const numberOfColumnHeaders = initialData.filter(row => row && row.type && row.type === 'rowHeaders')[0].children.length;

    // 3. Take the columnsSpan and add 1 to each
    const numberToIncreaseGridColumnsBy = (numberOfColumnHeaders * newDraftColumnsSpan);

    // 4. Set the new columns
    setColumns(numberToIncreaseGridColumnsBy + 2);

    // 5. Set the new columns span
    setColumnsSpan(newDraftColumnsSpan);

    // 6. Widen the container by 10% also
    const newWidth = increaseWidth()

    // 7. Save to db
    updateColumnsOfActiveItem({ columns: numberToIncreaseGridColumnsBy + 2, columnsSpan: newDraftColumnsSpan, width: newWidth });
  }

  const handleDecreaseColumnWidth = () => {

    if (columnsSpan === 2) {
      // TO DO handle error saying this cant be done as its too low
    } else {
      // 1. Set incremental span
      const newDraftColumnsSpan = columnsSpan - 1;

      // 2. Retrieve the number of data columns
      const numberOfColumnHeaders = initialData.filter(row => row && row.type && row.type === 'rowHeaders')[0].children.length;
 
      // 3. Take the columnsSpan and add 1 to each
      const numberToIncreaseGridColumnsBy = (numberOfColumnHeaders * newDraftColumnsSpan);
 
      // 4. Set the new columns
      setColumns(numberToIncreaseGridColumnsBy + 2);

      // 5. Set the new columns span
      setColumnsSpan(newDraftColumnsSpan);

      // 6. Widen the container by 10% also
      const newWidth = decreaseWidth()

      // 7. Save to db
      updateColumnsOfActiveItem({ columns: numberToIncreaseGridColumnsBy + 2, columnsSpan: newDraftColumnsSpan, width: newWidth });
    }
  }

  useEffect(() => {
    if (resizableRef.current) {
      setMinWidth(defaultWidth);
    }
  }, [resizableRef.current]);

  useEffect(() => {
    fetchJourneys()
  },[])

  useEffect(() => {
    if (selectedProject) {
      fetchJourneys()
    }
  },[selectedProject])

  useEffect(() => {
    if (!open) {
      setDraftJourney([])
      setActiveItem({ itemType: 'journey', item: { title: '', items: [], columns: 14, columnsSpan: 2 } })
    }
  },[open])

  useEffect(() => {
    //console.log("Selected project changed, refetch personas")
    const fetchData = async () => {
      try {
        const data = await retrieveJourneysFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId});
        return data;
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        throw error; // Rethrow the error so that the outer catch block can handle it
      }
    };
    if (selectedProjectId) {
      const getData = async () => {
        try {
          const data = await fetchData();
          // if item was deleted, then select something again
          // if item was updated, then maintain selection
          // selectedNote will be emptied on deletion of record, so use this to tell
          if (data.length > 0) {
            setJourneys(data);
          }
        } catch (error) {
          // Handle the error if needed
          //console.log("Error fetching features", error)
        }
      };
      getData();
    }
  },[selectedProjectId])

  const value = {
    journeys,
    activeItem,
    setJourneys,
    handleCreateJourney,
    handleSetJourney,
    handleUpdateCurrentJourney,
    handleJourneyDeletion,
    setJourney,
    open,
    setOpen,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMsg,
    setSnackbarMsg,
    updateTitleOfActiveItem,
    draftJourney,
    setDraftJourney,
    handleSavePersonaForJourney,
    updateDescriptionOfActiveItem,
    updateColumnsOfActiveItem,
    handleShowSnackbar,
    dragMode,
    setDragMode, 
    handleAddElementClick, 
    handleNewRowTypeValue, 
    validateAndSubmitNewRowValue, 
    handleAddColumn, 
    handleIncreaseColumnSize,
    handleDecreaseColumnSize,
    chooseNewRowType, 
    handleAddRow, 
    updateRow, 
    updateComponent, 
    handleRemove, 
    handleDragStart, 
    handleDragEnd, 
    handleZoomIn, 
    handleZoomOut, 
    handleResizeStop, 
    increaseWidth, 
    decreaseWidth, 
    handleIncreaseColumnWidth, 
    handleDecreaseColumnWidth,
    defaultWidth,
    showRowTypeModal,
    setShowRowTypeModal,
    newRowType, 
    setNewRowType,
    zoom,
    size,
    resizableRef,
    fetchJourneys,    
    initialData, 
    setInitialData,
    flattenedItems, 
    setFlattenedItems,
    columns, setColumns,
    columnsSpan, setColumnsSpan,
    columnIds, setColumnIds,
    rowIds, setRowIds,
    overDropZone, setOverDropZone,
    dropZoneVisible, setDropZoneVisible,
    hoveredCell, setHoveredCell,
    handleApplyTemplateToJourney,
    columnsOffset, setColumnsOffset
  };

  return <JourneysContext.Provider value={value}>{children}</JourneysContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useJourneys() {
    return useContext(JourneysContext);
}