import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import UseFloatingSmartLinkEditorToolbar from './UseFloatingSmartLinkEditorToolbar';

export default function FloatingSmartLinkEditorPlugin({
  anchorElem = document.body,
  isLinkEditMode,
  setIsLinkEditMode,
}) {
  const [editor] = useLexicalComposerContext();
  return <UseFloatingSmartLinkEditorToolbar editor={editor} anchorElem={anchorElem} isLinkEditMode={isLinkEditMode} setIsLinkEditMode={setIsLinkEditMode} />
}