import React, { useState, createContext, useContext } from 'react';
import { useAiPane } from './aiPaneContext';

// Create the context
const ConnectionsPaneContext = createContext();

// Create a provider component
export function ConnectionsPaneProvider({ children }) {
  const { setAiPaneVisible } = useAiPane()
  const [result, setResult] = useState("");
  const [connectionsPaneVisible, setConnectionsPaneVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorContent, setEditorContent] = useState(false);
  const [insertText, setInsertText] = useState(false);
  const [type, setType] = useState("")

  const handleCloseConnectionsPane = () => {
    setConnectionsPaneVisible(false)
  }

  const handleOpenConnectionsPane = () => {
    // ensure aiPane is closed
    setAiPaneVisible(false)
    // open connections pane
    setConnectionsPaneVisible(true)
  }

  const value = {
    setResult,
    result,
    loading,
    setLoading,
    connectionsPaneVisible,
    setConnectionsPaneVisible,
    handleOpenConnectionsPane,
    handleCloseConnectionsPane,
    editorContent,
    setEditorContent,
    insertText,
    setInsertText,
    type
  };

  return <ConnectionsPaneContext.Provider value={value}>{children}</ConnectionsPaneContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useConnectionsPane() {
  return useContext(ConnectionsPaneContext);
}