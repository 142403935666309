import React, { createContext, useContext, useState, useEffect } from 'react';
import { format } from 'date-fns';
import database from "../../configs/firebaseConfig";
import { retrieveToDosFromFirestore, saveToDoToFirestore, deleteToDo, saveMultipleToDosToFirestore } from "../database/todos-db";
import { updateTagInGroup } from "../database/tags-db";
import { arrayMove } from '@dnd-kit/sortable';

const TodosContext = createContext();

export function TodosProvider({ children }) {
  const [toDos, setToDos] = useState([]);
  const [completedToDos, setCompletedToDos] = useState([]);
  const [filteredToDos, setFilteredToDos] = useState([]);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showOverdue, setShowOverdue] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [saveAutoTriggered, setSaveAutoTriggered] = useState(false);
  const [overdueCount, setOverdueCount] = useState(0);

  const db = database.firestore();

  function addWorkingDays(date, days) {
    const workingDays = [1, 2, 3, 4, 5];
    const result = new Date(date);
  
    while (days > 0) {
      result.setDate(result.getDate() + 1);
      if (workingDays.includes(result.getDay())) {
        days--;
      }
    }
    return result;
  }

  const returnNextWorkingDayDate = () => {
    const today = new Date();
    return addWorkingDays(today, 1);
  };

  const parseAndListToDos = (todos) => {
    todos.forEach(todo => {
      const dateObject = new Date(todo.dueBy);
      todo.dueByInput = format(dateObject, 'yyyy-MM-dd');
    });
    
    const completed = todos.filter(todo => todo.isCompleted);
    const incomplete = todos.filter(todo => !todo.isCompleted);
    
    setCompletedToDos(completed);
    setToDos(incomplete);
    updateOverdueCount(incomplete);
  };

  const fetchToDos = async ({ accountId, selectedProjectId }) => {
    const results = await retrieveToDosFromFirestore({ accountId, selectedProjectId });
    if (results.length > 0) {
      parseAndListToDos(results);
    } else {
      setToDos([]);
      setCompletedToDos([]);
    }
  };

  const handleUpdateToDo = async ({ values, accountId, selectedProjectId, currentUser }) => {
    if (values.tags) {
      values.tags.forEach(async tag => {
        if (tag.boardId && tag.accountId && tag.projectId && tag.groupId && tag.id) {
          await updateTagInGroup({ 
            boardId: tag.boardId, 
            accountId: tag.accountId, 
            projectId: tag.projectId,
            groupId: tag.groupId, 
            tagId: tag.id, 
            updatedBy: currentUser,
            tagData: { ...tag },
            references: tag.references 
              ? [...tag.references, { type: 'todo', id: values.id, summaryTitle: values.text }] 
              : [{ type: 'todo', id: values.id, summaryTitle: values.text }]
          });
        }
      });
    }
    await saveToDoToFirestore({ todo: { ...values }, accountId, selectedProjectId });
    await fetchToDos({ accountId, selectedProjectId });
  };

  const handleCreateToDo = async ({ accountId, selectedProjectId }) => {
    try {
      const newTodo = {
        id: db.collection(`accounts/${accountId}/todos`).doc().id,
        text: 'Describe your to do',
        isCompleted: false,
        dueBy: returnNextWorkingDayDate(),
        order: 1,
        project: selectedProjectId,
        tags: selectedTags || [],
        created: new Date(),
        updated: new Date()
      };
      
      const updatedTodos = toDos.map((todo, index) => ({
        ...todo,
        order: index + 2
      }));
      
      await saveMultipleToDosToFirestore({ 
        todos: [newTodo, ...updatedTodos],
        accountId, 
        selectedProjectId 
      });

      await fetchToDos({ accountId, selectedProjectId });
      return { success: true };
    } catch (error) {
      console.error('Error creating todo:', error);
      return { success: false, error };
    }
  };

  const handleDeleteToDo = async ({ todoId, accountId, selectedProjectId }) => {
    await deleteToDo({ todoId, accountId });
    await fetchToDos({ accountId, selectedProjectId });
    return { success: true };
  };

  const handleDragEnd = async ({ active, over }) => {
    if (active.id !== over.id) {
      setToDos((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const updatedItems = arrayMove(items, oldIndex, newIndex);
        
        updatedItems.forEach((item, index) => {
          item.order = index + 1;
        });
  
        return updatedItems;
      });
      setSaveAutoTriggered(true);
    }
  };

  const handleMoveToTop = async (id) => {
    setToDos((items) => {
      const oldIndex = items.findIndex((item) => item.id === id);
      const updatedItems = arrayMove(items, oldIndex, 0);
      updatedItems.forEach((item, index) => {
        item.order = index + 1;
      });
      return updatedItems;
    });
    setSaveAutoTriggered(true);
  };

  useEffect(() => {
    if (showOverdue) {
      const overdueTodos = toDos.filter(todo => {
        const dueDate = new Date(todo.dueBy);
        return dueDate < new Date();
      });
      setFilteredToDos(overdueTodos);
    } else {
      setFilteredToDos([]);
    }
  }, [showOverdue, toDos]);

  const updateOverdueCount = (todos) => {
    const overdue = todos.filter(todo => {
      const dueDate = new Date(todo.dueBy);
      return !todo.isCompleted && dueDate < new Date();
    }).length;
    setOverdueCount(overdue);
  };

  const value = {
    toDos,
    completedToDos,
    filteredToDos,
    showCompleted,
    showOverdue,
    selectedTags,
    overdueCount,
    setShowCompleted,
    setShowOverdue,
    setSelectedTags,
    setFilteredToDos,
    fetchToDos,
    handleUpdateToDo,
    handleCreateToDo,
    handleDeleteToDo,
    handleDragEnd,
    handleMoveToTop,
  };

  return <TodosContext.Provider value={value}>{children}</TodosContext.Provider>;
}

export function useTodos() {
  const context = useContext(TodosContext);
  if (context === undefined) {
    throw new Error('useTodos must be used within a TodosProvider');
  }
  return context;
}