import React, { useEffect } from 'react';
import { Grid, Sheet } from '@mui/joy';
import SitemapCards from './SitemapCard';
import { styled } from '@mui/joy/styles';

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
  ...theme.typography['body-sm'],
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

function SitemapsListCards({ sitemaps, handleSetSitemap }) {

  useEffect(() => {
  }, [sitemaps]);

  const handleSitemapClick = (sitemap) => {
    handleSetSitemap(sitemap)
  }

  return (
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      sx={{ flexGrow: 1, backgroundColor: '#f4f1f8', borderRadius: 7 }}
    >
      { sitemaps && sitemaps.map((sitemap, index) => (
        <Grid key={index} xs={2} sm={4} md={4}>
          <SitemapCards handleSitemapClick={handleSitemapClick} sitemap={sitemap} />
        </Grid>
      ))}
    </Grid>
  );
}

export default SitemapsListCards;
