import { useEffect, useState } from 'react';
import { getStorage, getDownloadURL, ref } from 'firebase/storage';
import Card from '@mui/joy/Card';
import { useAuth } from '../../../../../authContext';  

function Text({ contentItem }) {
  const [content, setContent] = useState('');
  const { currentUser } = useAuth();

  useEffect(() => {
    if (contentItem && contentItem.url) {
      const storage = getStorage();
      const storageRef = ref(storage, `users/${currentUser._delegate.uid}/uploads/${contentItem.noteTitle}`);
      getDownloadURL(storageRef)
        .then((url) => {
          return fetch(url);
        })
        .then(response => {
          if (response.ok && response.headers.get('content-type').includes('text/plain')) {
            return response.text();
          } else {
            throw new Error('Failed to fetch text file or incorrect content type');
          }
        })
        .then(data => setContent(data))
        .catch(error => console.error('Error fetching the text file:', error));
    }
  }, [contentItem]);

  return (
    <Card>
      <pre>{content}</pre>
    </Card>
  );
}

export default Text;