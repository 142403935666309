import React from 'react';
import { Box, Button, IconButton, FormControl, FormLabel, Slider, Input, Typography } from '@mui/joy';
import { sliderClasses } from '@mui/joy/Slider';
import CloseIcon from '@mui/icons-material/Close';

export default function SliderComponent({ gridColumns, data, id, updateHandler, deleteHandler, editMode = true }) {

  const [ editing, setEditing ] = React.useState(false);
  const [ newTitle, setNewTitle ] = React.useState('');

  return (
    editMode ?
    <>
      <FormControl>
        <FormLabel sx={{ flex: 1, width: "100%", flexDirection: "row", justifyContent: "space-between" }} onClick={() => { setNewTitle(data.field_title); setEditing(true) }}>
          { editing ?
            <Input 
              size="sm" 
              value={newTitle} 
              onChange={(e) => setNewTitle(e.target.value)}
              onBlur={() => {
                updateHandler(prevState => {
                  const updatedArray = prevState.map(item => {
                    if (item.id === id) {
                      return { ...item, field_title: newTitle };
                    }
                    return item;
                  });
                
                  return updatedArray;
                });
                setEditing(false);
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
                if (e.keyCode === 13) {
                  updateHandler(prevState => {
                    const updatedArray = prevState.map(item => {
                      if (item.id === id) {
                        return { ...item, field_title: newTitle };
                      }
                      return item;
                    });
                  
                    return updatedArray;
                  });
                  setEditing(false);
                } else if (e.keyCode === 27) {
                  setEditing(false);
                }
              }}
            />
            
          : data && data.field_title ? 
            <>
            <Box flex={1}>
              { data.field_title }
            </Box>
            <Box
              flex={1}
              sx={{
                minHeight: 32,
                justifyContent: "end",
                display: "flex",
              }}
            >
              <IconButton onClick={() => deleteHandler({ idToDelete: id })} className='delete-item' sx={{ display: "none" }} size="sm">
                <CloseIcon />
              </IconButton>
            </Box> 
            </>
            : '' }
        </FormLabel>
      </FormControl>

      <Box sx={{ mt: 3 }}>
        { data.items && data.items && data.items.length > 0 && data.items.map((value, index) => (
          <>
            <Box key={`slideritem_` + index} flex={1} display={"flex"} sx={{ mt: .8, mb: 1, justifyContent: 'space-between' }}>
              <Box sx={{ pr: 0.5 }}>
                <Input 
                  size="sm" 
                  value={value.left_label ? value.left_label : ''} 
                  onChange={(e) => {
                    updateHandler(prevState => {
                      const updatedArray = prevState.map(item => {
                        if (item.id === id) {
                          return {
                            ...item,
                            items: item.items.map((itemValue, itemIndex) => {
                              if (itemIndex === index) {
                                return { ...itemValue, left_label: e.target.value };
                              }
                              return itemValue;
                            }),
                          };
                        }
                        return item;
                      });
                  
                      return updatedArray;
                    });
                  }}
                />
              </Box>
              <Box sx={{ pl: 0.5 }}>
                <Input 
                  size="sm" 
                  value={value.right_label ? value.right_label : ''} 
                  onChange={(e) => {
                    updateHandler(prevState => {
                      const updatedArray = prevState.map(item => {
                        if (item.id === id) {
                          return {
                            ...item,
                            items: item.items.map((itemValue, itemIndex) => {
                              if (itemIndex === index) {
                                return { ...itemValue, right_label: e.target.value };
                              }
                              return itemValue;
                            }),
                          };
                        }
                        return item;
                      });
                  
                      return updatedArray;
                    });
                  }}
                />
              </Box>
              <Box>
                <IconButton
                  aria-label="delete"
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  onClick={() => {
                    updateHandler(prevState => {
                      const updatedArray = prevState.map(item => {
                        if (item.id === id) {
                          return { 
                            ...item, 
                            items: item.items.filter((_, i) => i !== index)
                          };
                        }
                        return item;
                      });
                    
                      return updatedArray;
                  })}}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ px: 2, pr: 4 }}>
              <Slider
                marks
                value={value.value !== undefined ? value.value : 50}
                onChange={(e, value) => {
                  updateHandler(prevState => {
                    const updatedArray = prevState.map(item => {
                      if (item.id === id) {
                        return {
                          ...item,
                          items: item.items.map((itemValue, itemIndex) => {
                            if (itemIndex === index) {
                              return { ...itemValue, value: value };
                            }
                            return itemValue;
                          }),
                        };
                      }
                      return item;
                    });
                
                    return updatedArray;
                  });
                }}
                step={10}
                valueLabelDisplay="auto"
              />
            </Box>
          </>
        ))}

        <Box sx={{ mt: 2 }} flex={1}>
          <Button size="sm" variant="outlined" 
            onClick={() => {
              updateHandler(prevState => {
                const updatedArray = prevState.map(item => {
                  if (item.id === id) {
                    return { 
                      ...item, 
                      items: [ ...item.items, { left_label: 'Left title', right_label: 'Right title', value: 50 } ]
                    };
                  }
                  return item;
                });
              
                return updatedArray;
            })}}
          >
            Add Item
          </Button>
        </Box>
      </Box>
    </>
    :
    <Box sx={{
      borderBottom: data.block_location !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
      ml: gridColumns === 1 ? 0 : 4, 
      mr: gridColumns === 1 ? 0 : 4, 
      pb: 2.5
    }}>
      <Typography level="body-md" color="neutral">
          { data.field_title }:
      </Typography>
      <Box sx={{ mt: 1 }}>
        { data.items && data.items && data.items.length > 0 && data.items.map((value, index) => (
          <Box sx={{ mb: .5, backgroundColor: '#f4f1f8', borderRadius: 16, px: 2, pt: 0, pb: 2 }}>
            <Slider
              size="sm"
              value={value.value !== undefined ? value.value : 50}
              step={10}
              valueLabelDisplay="auto"
              disabled
              sx={{
                // Need both of the selectors to make it works on the server-side and client-side
                [`& [style*="left:0%"], & [style*="left: 0%"]`]: {
                  [`&.${sliderClasses.markLabel}`]: {
                    transform: 'none',
                  },
                  [`& .${sliderClasses.valueLabel}`]: {
                    left: 'calc(var(--Slider-thumbSize) / 2)',
                    borderBottomLeftRadius: 0,
                    '&::before': {
                      left: 0,
                      transform: 'translateY(100%)',
                      borderLeftColor: 'currentColor',
                    },
                  },
                },
                [`& [style*="left:100%"], & [style*="left: 100%"]`]: {
                  [`&.${sliderClasses.markLabel}`]: {
                    transform: 'translateX(-100%)',
                  },
                  [`& .${sliderClasses.valueLabel}`]: {
                    right: 'calc(var(--Slider-thumbSize) / 2)',
                    borderBottomRightRadius: 0,
                    '&::before': {
                      left: 'initial',
                      right: 0,
                      transform: 'translateY(100%)',
                      borderRightColor: 'currentColor',
                    },
                  },
                },
              }}
              marks={[
                {
                  value: 0,
                  label: value.left_label ? <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>{value.left_label}</Typography> : '0',
                },
                {
                  value: 100,
                  label: value.right_label ? <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>{value.right_label}</Typography> : '100',
                },
              ]}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}