import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Card from '@mui/joy/Card';
import { Box, Button } from '@mui/joy';
import CardContent from '@mui/joy/CardContent';
import CardOverflow from '@mui/joy/CardOverflow';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import ListItemButton from '@mui/joy/ListItemButton';
import BoltIcon from '@mui/icons-material/Bolt';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ViewIncites from '../Buttons/ViewIncites';
import ViewComments from '../Buttons/ViewComments';
import IncitesForTypeInstance from '../DrawerRight/IncitesForTypeInstance';
import TagChip from '../Tags/TagChip';

export default function PersonaCard({persona, handlePersonaClick, ratio=1}) {

  const [ cardData, setCardData ] = React.useState()

  React.useEffect(() => {
    //console.log("personaData", personaData);
    let personaData = persona.data
  
    if (typeof personaData === 'object' && personaData !== null) {
      // Convert the persona object into an array
      const personaArray = Object.values(personaData);
  
      // Initialize variables for the three values
      let nameValue = '';
      let imageValue = '';
      let roleValue = '';
  
      // Iterate over each object in the persona array
      personaArray.forEach(obj => {
        if (obj.field_title === 'Name') {
          nameValue = obj.field_value;
        }
        if (obj.type === 'image') {
          imageValue = obj.field_value;
        }
        if (obj.field_title === 'Role') {
          roleValue = obj.field_value;
        }
      });
  
      // Log the extracted values for debugging
      //console.log("Extracted Values:", { nameValue, imageValue, roleValue });
  
      // Set the three values to the state
      setCardData({ name: nameValue, image: imageValue, role: roleValue, tags: persona.tags });
    }
  }, [persona]);
  

  //console.log("cardData", cardData)

  return (
      <>
      <Card variant="outlined">
        <CardOverflow sx={{ cursor: 'pointer' }} onClick={() => handlePersonaClick(persona)}>
          <AspectRatio ratio={ratio}>
            { cardData && cardData.image &&
            <img
              src={cardData.image}
              srcSet={cardData.image}
              loading="lazy"
              alt=""
              style={{
                filter: 'grayscale(100%)',
                transition: 'filter 0.3s',
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
              onMouseEnter={(e) => e.target.style.filter = 'grayscale(0%)'}
              onMouseLeave={(e) => e.target.style.filter = 'grayscale(100%)'}
            />
            }
          </AspectRatio>
        </CardOverflow>
        <CardContent sx={{ cursor: 'pointer' }} onClick={() => handlePersonaClick(persona)}>
          { cardData && cardData.role &&
            <Typography level="title-md">{cardData.role}</Typography>
          }
          { cardData && cardData.name &&
            <Typography level="body-sm">{cardData.name}</Typography>
          }
          { cardData && cardData.tags && cardData.tags.length > 0 && 
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            { cardData.tags.map((tag, index) => (
              <TagChip key={index} tag={tag} />
            )) }
          </Box>            
          }
        </CardContent>
      </Card>
    </>
  );
}