import React from 'react';
import { Transition } from 'react-transition-group';
import { forwardRef } from 'react';
import { Modal, ModalDialog, DialogContent, Box } from '@mui/joy';

const ModalEditorFrame = forwardRef(({ children, open, setOpen }, ref) => {
  const nodeRef = React.useRef(null);

  return (
    <React.Fragment>
      <Transition in={open} timeout={400} nodeRef={nodeRef}>
        {(state) => (
          <Modal
            keepMounted
            ref={ref}
            open={!['exited', 'exiting'].includes(state)}
            onClose={() => setOpen(false)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: 'blur(2px)' },
                    entered: { opacity: 1, backdropFilter: 'blur(2px)' },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === 'exited' ? 'hidden' : 'visible',
            }}
          >
            <ModalDialog
              ref={nodeRef}
              layout="fullscreen"
              sx={{
                opacity: 0,
                transition: `opacity 300ms`,
                backgroundColor: '#fff',
                m: 1,
                p: 1,
                pt: 0,
                borderRadius: '20px',
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogContent>
                <Box>
                  {children}
                </Box>
              </DialogContent>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </React.Fragment>
  );
});

ModalEditorFrame.displayName = 'ModalEditorFrame';

export default ModalEditorFrame;