import React from 'react';
import { Card, CardActions, CardContent, Typography } from '@mui/joy';

export default function NoteItem({ note, setSelected }) {
  return (
    <>
    <Card onClick={() => setSelected( { ...note } )} sx={{ 
      gap: 1,
      py: 1.5,
      paddingLeft: 1.5,
      paddingRight: 1.5,
      backgroundColor: '#fbf8ff',
      cursor: 'pointer',
      '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' }, }}
    >
      <CardContent>
        <Typography level="body-sm" sx={{ fontWeight: 800 }}>{ note.noteTitle === "" ? "Untitle note" : note.noteTitle }</Typography>
      </CardContent>
    </Card>
    </>
  )
}