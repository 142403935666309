import { useState, useEffect } from "react";
import { Box, Button, IconButton, Typography } from "@mui/joy"
import { useAiPane } from "../../../contexts/aiPaneContext";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SummariseResult from './ResultComponents/SummariseResult/index';
import FeaturesResults from "./ResultComponents/FeaturesResults";
import PersonasResult from "./ResultComponents/PersonasResult";
import AiLoading from "./AiLoading";
import ToDoResults from "./ResultComponents/ToDoResults";
import { useAuth } from "../../../../authContext";
import { useAiResults } from "../../../contexts/aiResultsContext";

function AiPane({ mode, noteToDisplay }) {

  const { result, setResult, loading, handleClosePane, setInsertText, type, generateFeatures } = useAiPane()
  const { aiResults, setAiResults } = useAiResults();

  const { currentUser } = useAuth()
  const [generateMore, setGenerateMore] = useState(false)

  async function handleGenerateMoreFeatures() {
    console.log('handleGenerateMoreFeatures');
    setGenerateMore(true)
    if (noteToDisplay && noteToDisplay.type === "upload") {
      if (noteToDisplay.originalTitle) {
        await generateFeatures(currentUser._delegate.uid, noteToDisplay.originalTitle)
      }
    }
    setGenerateMore(false)
  }

  return (
    <Box sx={{ p: 3, background: 'linear-gradient(-128deg, rgba(135,14,184,0.1) 22%, rgba(252,106,79,0.1) 72%, rgba(114,79,155,0.1) 100%)' }}>
      { mode !== "panel" &&
        <IconButton sx={{ position: 'absolute', top: 35, right: 35 }} onClick={handleClosePane}>
          <CloseIcon />
        </IconButton>
      }
      <Box sx={{ mb: 6 }}>
        { type === "PERSONAS" &&
          <>
            { loading ? <AiLoading /> : 
              <>
              <Typography level="h3" sx={{ mb: 4, fontSize: '1.5rem' }}>Personas</Typography>
              <PersonasResult personas={aiResults} />
              </>
            }
          </>
        }
        { (type === "SUMMARY" || type === "SUMMARY_PDF") && 
          <>
            { loading ? <AiLoading /> : 
              <>
              <Typography level="h3" sx={{ mb: 4, fontSize: '1.5rem' }}>Summary</Typography>
              <SummariseResult result={aiResults} />
              </>
            }
          </>
        }
        { type === "FEATURES" &&
          <>
            { loading ? <AiLoading /> : 
            <>
            <Typography level="h3" sx={{ mb: 4, fontSize: '1.5rem' }}>Suggest Features</Typography>
            <FeaturesResults result={aiResults} setResult={setResult} />
            </>
            }
          </>
        }
        { type === "TODOS" &&
          <>
            { loading ? <AiLoading /> :
            <>
            <Typography level="h3" sx={{ mb: 4, fontSize: '1.5rem' }}>Suggest To Dos</Typography>
            <ToDoResults result={aiResults} setResult={setResult} />
            </>
            }
          </>
        }
        {/* { result && result !== "" && ( result ) } */}
      </Box>
      { type === "SUMMARY" && 
          result && result !== "" &&
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Button size="lg" sx={{ mr: 2 }} startDecorator={<AutorenewIcon />} variant="outlined" color="primary" onClick={() => {}}>Re-Generate</Button>
            <Button size="lg" variant="solid" color="primary" onClick={() => setInsertText(true)} sx={{ ml: 'auto' }}>Insert</Button>
          </Box>
      }
      { type === "SUMMARY_PDF" &&
        result && result !== "" &&
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left' }}>
          <Button size="lg" sx={{ mr: 2 }} startDecorator={<ContentCopyIcon />} variant="outlined" color="primary" onClick={() => navigator.clipboard.writeText(result)}>Copy to clipboard</Button>
        </Box>
      }
      { type === "FEATURES" &&
          result && result !== "" &&
            <Button loading={generateMore} loadingIndicator="Generating..." size="lg" sx={{ mr: 2 }} onClick={() => handleGenerateMoreFeatures()} startDecorator={<AutorenewIcon />} variant="outlined" color="primary">Generate More</Button>
      }
    </Box>
  )
}

export default AiPane