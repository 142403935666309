import database from '../../configs/firebaseConfig'; 
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';

const db = database.firestore(); // Access Firestore database

export async function setupAccount({ user, method }) {
  
  // Step 1: Create the account and empty subcollections
  // For indexing sake, we also need to set a field on the containing account to check it's existence at a later point
  const accountRef = db.collection('accounts').doc();

  const accountRefId = accountRef.id;
  const emptySubcollections = ['features','sitemaps','notes','personas','search','connections','journeys','highlights','pagesets','templates','todos'];
  const batch = db.batch();

  emptySubcollections.forEach((subcollection) => {
    const subcollectionRef = accountRef.collection(subcollection).doc();
    batch.set(subcollectionRef, {});
  });

  const result = await batch.commit();

  // Step 2: Create a default project to store documents to on first interaction
  const projectCollection = accountRef.collection('projects').doc();
  const projectSetResult = await projectCollection.set({
    name: 'Default project',
    createdBy: user.uid,
    created: new Date()
  });

  const projectCollectionGet = await projectCollection.get();

  // Step 3: Create a tagBoard to store tags to on first interaction
  const tagBoardCollection = accountRef.collection('tagBoards').doc();
  const tagBoardDoc = await tagBoardCollection.set({
    title: 'Untitled tag board',
    created: new Date(),
    updated: new Date(),
    lastupdatedBy: user.uid,
    project: projectCollectionGet.id,      
  });

  const boardGroupsCollection = tagBoardCollection.collection('boardGroups').doc();

  await boardGroupsCollection.set({
    title: 'Untitled group',
    created: new Date(),
    updated: new Date(),
    colour: blue,
    lastupdatedBy: user.uid,
  });
  
  // Step 4: Create a record within users collection to quickly retrieve which account they belong to in future
  // Also set a selectedProject field to the default project created above
  if (method === 'email') {
    const usersCollection = db.collection('users').doc(user.uid);
    const resultOfUserCollection = await usersCollection.set({
      email: user.email,
      id: user.uid,
      selectedProject: projectCollectionGet.id,
      accountId: accountRef.id,
      displayName: user.displayName ? user.displayName : '', // TO DO: decide on whether key member profile info is read from here or google auth token
      status: 'active',
      signupMethod: method,
      role: 'admin'
    })
    const setAccount = await db.collection('accounts').doc(accountRefId).set({
      created: new Date(),
      createdBy: user.uid
    })
    return { accountId: accountRefId, selectedProjectId : projectCollectionGet.id }
  } else {
    const usersCollection = db.collection('users').doc();
    const resultOfUserCollection = await usersCollection.set({
      email: user.email,
      accountId: accountRef.id,
      selectedProject: projectCollectionGet.id,
      displayName: user.name ? user.name : '', // TO DO: decide on whether key member profile info is read from here or google auth token
      status: 'active',
      signupMethod: method,
      role: 'admin'
    })
    const setAccount = await db.collection('accounts').doc(accountRefId).set({
      created: new Date(),
      createdBy: user.email
    })
    return { accountId: accountRefId, selectedProjectId : projectCollectionGet.id }
  } 
}

// Get the createdBy field from the account
export async function getCreatedBy(accountId) {
  const accountRef = db.collection('accounts').doc(accountId);
  const account = await accountRef.get();
  return account.data().createdBy;
}

export async function setNewSelectedProject(userId, selectedProjectId) {
  // Set the selected project for the user
  const accountRef = await db.collection('users').doc(userId);
  const result = accountRef.update({
    selectedProject: selectedProjectId
  })
  return result
}