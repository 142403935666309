import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, redirect, useNavigate, useParams } from 'react-router-dom';
import Editor from './Components/Editor'

import '@fontsource/poppins';
import '@fontsource/inter';
import '@fontsource/source-serif-pro';

import CssBaseline from '@mui/joy/CssBaseline';
import "./styles/styles.css";

import Box from '@mui/joy/Box';
import Notes from './Pages/Notes';
import Chats from './Pages/Chats';
import Sidebar from './Components/Sidebar';
import FullWidthHeader from './Components/FullWidthHeader';
import PersonasList from './Pages/Personas';
import FeaturesList from './Pages/Features';
import { useAuth } from '../authContext';  
import { checkAccountExists } from './database/utilities-db';
import AccountHome from './Pages/Account';
import TagsPage from './Pages/Tags';
import BinPage from './Pages/Notes/Bin';
import { NotesProvider } from './contexts/notesContext';
import { BoardProvider } from './contexts/boardsContext';
import { LoadingProvider } from './contexts/loadingContext';
import { ProjectsProvider } from './contexts/projectsContext';
import { PersonasProvider } from './contexts/personasContext';
import { PagesProvider } from './contexts/pagesContext';
import { UploadProvider } from './Components/Editor/plugins/ImagePluginModified';
import { TagsProvider } from './contexts/tagsContext';
import { DrawerRightProvider } from './contexts/drawerRightContext';
import { ContextualPaneProvider } from './contexts/contextualPane';
import { DockableContentProvider } from './contexts/dockableContentContext';
import { SnackbarProvider } from './contexts/snackbarContext';
import { BrowseTypesProvider } from './contexts/browseTypesContext';
import { AiPaneProvider } from './contexts/aiPaneContext';
import { JourneysProvider } from './contexts/journeysContext';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navigation from './Pages/Navigation';
import ConnectionsPage from './Pages/Connections';
import Team from './Pages/Team';
import { palette } from '../incitefulTheme'
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import JourneysPage from './Pages/Journeys';
import { NavigationsProvider } from './contexts/navigationsContext';
import FlowsPage from './Pages/Flows';
import PagesPage from './Pages/Pages';
import BrowseTagPage from './Pages/BrowseTag';
import TestApi from './Pages/TestApi';
import Plans from './Pages/Plans';
import { ConnectionsPaneProvider } from './contexts/connectionsPaneContext';
import ToDosPage from './Pages/ToDos';
import DockContentPanel from './Components/DockContentPanel';
import NewBasicSearch from './Pages/NewBasicSearch';
import { MultiTaskingProvider } from './contexts/multiTaskingContext';
import { MultiTaskingLayout } from './Components/MultiTaskingLayout';
import { AiResultsProvider } from './contexts/aiResultsContext';
import { TodosProvider } from './contexts/todoContext';

const theme = extendTheme({ 
  fontFamily: {
    display: '"Poppins", var(--joy-fontFamily-fallback)',
    body: '"Poppins", var(--joy-fontFamily-fallback)',
  },
  colorSchemes: {
    light: { palette },
    dark: { palette },
  },
  components: {
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '5px',
          boxShadow: '0px 0px 10px rgba(3,3,3,0.1)',
          transition: '80ms cubic-bezier(0.33, 1, 0.68, 1)',
          transitionProperty: 'color,background-color,box-shadow,border-color',
          ...(ownerState.size === 'md' && {
            fontWeight: 600,
            minHeight: '48px',
            fontSize: '18px',
            '--Button-paddingInline': '1rem',
          }),
          ...(ownerState.color === 'success' &&
            ownerState.variant === 'solid' && {
              '--gh-palette-focusVisible': 'rgba(46, 164, 79, 0.4)',
              border: '1px solid rgba(27, 31, 36, 0.15)',
              '&:active': {
                boxShadow: 'inset 0px 1px 0px rgba(20, 70, 32, 0.2)',
              },
            }),
          ...(ownerState.color === 'neutral' &&
            ownerState.variant === 'outlined' && {
              '&:active': {
                boxShadow: 'none',
              },
            }),
        })
      },
    },
    JoySelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#fff',
        }
      }
    },
    JoyTextarea: {
      styleOverrides: {
        textarea: {
          backgroundColor: '#fff',
        }
      }
    },
    JoyInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#fff',
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'red', // Replace '#yourColor' with your desired color
        },
      },
    },
    JoyIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&:hover': {
            '--Icon-color': 'currentColor',
            '--variant-plainHoverBg': 'transparent',
            backgroundColor: 'transparent',
            opacity: 0.6,
          }
        })
      }
    }
  },
});

const PrivateSite = () => {
  const { accountId } = useParams()
  const { currentUser } = useAuth()
  const navigate = useNavigate();
  const [newRunning, setNewRunning] = useState(false)

  const checkAccountIsValid = async (accountId) => {
    if (accountId !== 'undefined') {
      setNewRunning(true)
      //console.log("attempting account check with ", accountId)
      const result = await checkAccountExists(accountId)
      setNewRunning(false)
      if (!result) {
        navigate('/')
      }
    } else {
      //console.log("Not running as accountId is undefined")
    }
  }

  useEffect(() => {
    if (accountId && accountId !== 'undefined') {
      if (!newRunning) {
        checkAccountIsValid(accountId) 
      }
    }
  },[accountId])

  useEffect(() => {
    if (!currentUser) {
      //console.log("redirect to homepage")
      navigate("/");
    }
  },[currentUser])

  return (
    <CssVarsProvider theme={theme} disableTransitionOnChange>
      <HelmetProvider>
        <AiResultsProvider>
        <MultiTaskingProvider>
          <Helmet>
              <title>The Notes App for Digital Product People: smrt cookie</title>
              <meta name="description" content="The Notes App for Digital Product People: smrt cookie" />
          </Helmet>
          <AiPaneProvider>
          <LoadingProvider>
          <ProjectsProvider>
          <SnackbarProvider>
          <ConnectionsPaneProvider>
          <ContextualPaneProvider>
          <PersonasProvider>
          <UploadProvider>
          <DrawerRightProvider>
          <BrowseTypesProvider>
          <JourneysProvider>
          <BoardProvider>
          <NotesProvider>
          <NavigationsProvider>
          <PagesProvider>
          <TagsProvider>
          <DockableContentProvider>
            <CssBaseline />
            <TodosProvider>
            <FullWidthHeader />
            <Box sx={{ minHeight: 'calc(100dvh - var(--Header-height))', display: 'flex', overflow: 'hidden' }}>
                <Sidebar />
                <MultiTaskingLayout>
                  <Box sx={{ minHeight: 'calc(100dvh - var(--Header-height))', display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                    <Routes>
                      <Route path="/" element={<Notes />} />
                      <Route path="/testApi" element={<TestApi />} />
                      <Route path="/bin" element={<BinPage />} />
                      <Route path="/chats" element={<Chats />} />
                      <Route path="/personas" element={<PersonasList />} />
                      <Route path="/features" element={<FeaturesList />} />
                      <Route path="/nbs" element={<NewBasicSearch />} />
                      <Route path="/ia" element={<Navigation />} />
                      <Route path="/flows" element={<FlowsPage />} />
                      <Route path="/journeys" element={<JourneysPage />} />
                      <Route path="/pages" element={<PagesPage />} />
                      <Route path="/tags/b/:boardId" element={<TagsPage />} />
                      <Route path="/connections" element={<ConnectionsPage />} />
                      <Route path="/tags/b/:boardId/g/:groupId/t/:tagId" element={<BrowseTagPage />} />
                      <Route path="/account" element={<AccountHome />} />
                      <Route path="/todos" element={<ToDosPage />} />
                      <Route path="/team" element={<Team />} />
                      <Route path="/plans" element={<Plans />} />
                    </Routes>
                    <DockContentPanel />
                  </Box>
                </MultiTaskingLayout>
            </Box>
            </TodosProvider>
          </DockableContentProvider>
          </TagsProvider>
          </PagesProvider>
          </NavigationsProvider>
          </NotesProvider>
          </BoardProvider>
          </JourneysProvider>
          </BrowseTypesProvider>
          </DrawerRightProvider>
          </UploadProvider>
          </PersonasProvider>
          </ContextualPaneProvider> 
          </ConnectionsPaneProvider>
          </SnackbarProvider>
          </ProjectsProvider>
          </LoadingProvider>
          </AiPaneProvider>     
        </MultiTaskingProvider>
        </AiResultsProvider>
      </HelmetProvider>
    </CssVarsProvider>
  );
};

export default PrivateSite;
