import { useState } from 'react';
import { Box, Modal, ModalDialog, DialogTitle, Stack, FormControl, FormLabel, Input, Button, Textarea } from '@mui/joy';
import HttpIcon from '@mui/icons-material/Http';
import { addLink } from '../../../database/notes-db';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../authContext';  
import { useNotes } from '../../../contexts/notesContext';

function CreateLink({ createLinkModal, setCreateLinkModal }) {
  const [linkTitle, setLinkTitle] = useState('');
  const [linkAddress, setLinkAddress] = useState('');
  const [description, setDescription] = useState('');
  const { accountId, selectedProjectId } = useParams()
  const { currentUser } = useAuth()
  const { getAndSetData } = useNotes();

  const handleCreateLink = async () => {
    console.log('linkAddress', linkAddress);
    console.log('description', description);
    const user = currentUser;
    const resultOfAddLink = await addLink({ linkTitle, linkAddress, description, accountId, user, selectedProjectId });
    console.log('resultOfAddLink ', resultOfAddLink)
    await getAndSetData({ setNewCurrentNote: true, sortByPinned: false })
  }

  return (
    <Modal open={createLinkModal} onClose={() => setCreateLinkModal(false)}>
      <ModalDialog size="md" sx={{ width: 600 }}>
        <DialogTitle>Create link</DialogTitle>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setCreateLinkModal(false);
          }}
        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Link Address</FormLabel>
              <Input onChange={(e) => setLinkAddress(e.target.value)} size="sm" startDecorator={<HttpIcon />} autoFocus required />
            </FormControl>
            <FormControl>
              <FormLabel>Link Title</FormLabel>
              <Input onChange={(e) => setLinkTitle(e.target.value)} size="sm" required />
            </FormControl>
            <FormControl>
              <FormLabel>Description (optional)</FormLabel>
              <Textarea onChange={(e) => setDescription(e.target.value)} size="sm" minRows={3} />
            </FormControl>
            <Box>
              <Button onClick={handleCreateLink} type="submit">Create</Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  )

}

export default CreateLink;