import React from 'react';
import { Card, CardActions, CardContent, Typography } from '@mui/joy';

export default function NavigationItem({ navigation, setSelected }) {
  return (
    <>
    <Card onClick={() => setSelected( { ...navigation } )} sx={{ 
      gap: 1,
      py: 1.5,
      paddingLeft: 1.5,
      paddingRight: 1.5,
      backgroundColor: '#fbf8ff',
      cursor: 'pointer',
      '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' } }}>
      <CardContent>
        <Typography level="body-sm" sx={{ fontWeight: 800 }}>{ navigation.title === "" ? "Untitle navigation" : navigation.title }</Typography>
      </CardContent>
    </Card>
    </>
  )
}