import React, { useState } from 'react'
import { styled } from '@mui/joy/styles'
import Dropdown from '@mui/joy/Dropdown'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import IconButton from '@mui/joy/IconButton'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import ChooseColourSelector from './ChooseColourSelector'

export default function EditBoardTitleDropdown({ colour, handleGroupColourChange, setCurrentlyEditingGroup, groupId, handleDeleteGroup }) {
  
  const [ open, setOpen ] = useState(false)

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);
  
  // TO DO: Add 1) sorting functionality by alphabetical, date modified, etc. 2) Change background colour 3) Eventually, move to another board
  const handleClickOnDropdown = () => {
    //console.log("handleClickOnDropdown")
    setCurrentlyEditingGroup(groupId)
  }

  return (
    <Dropdown onOpenChange={handleOpenChange} open={open}>
      <MenuButton
        variant="plain"
        size="sm"
        sx={{
          maxWidth: '32px',
          maxHeight: '32px',
          borderRadius: '9999999px',
        }}
        onClick={handleClickOnDropdown}
      >
        <IconButton
          component="span"
          variant="plain"
          color="neutral"
          size="sm"
        >
          <MoreVertRoundedIcon />
        </IconButton>
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: '99999',
          p: 1,
          gap: 1,
          '--ListItem-radius': 'var(--joy-radius-sm)',
        }}
      >
        <ChooseColourSelector setOpen={setOpen} setCurrentlyEditingGroup={setCurrentlyEditingGroup} groupId={groupId} handleColourChange={handleGroupColourChange} selectedColour={colour} />
        {/* <MenuItem>
          <ShareRoundedIcon />
          Sort
        </MenuItem> */}
        <MenuItem sx={{ color: 'danger' }} onClick={() => handleDeleteGroup(groupId)}>
          <DeleteRoundedIcon />
          Delete group
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}