import React, { useState, useEffect, createContext, useContext } from 'react';
import { useNotes } from './notesContext';

// Create the context
const DockableContentContext = createContext();

// Create a provider component
export function DockableContentProvider({ children }) {
    const [dockableContentOpen, setDockableContentOpen] = useState(false);
    const [dockableContent, setDockableContent] = useState(null);
    const [dockableContentType, setDockableContentType] = useState(null);
    const [dockableContentVisibility, setDockableContentVisibility] = useState(true);
    const { selectedNote } = useNotes();

    const toggleDockablePanel = () => {  
      setDockableContentOpen(!dockableContentOpen);
    };

    const handleSetDockableContent = ({ type }) => {
      // If type === note, set the content to the selected note
      setDockableContentType(type);
      if (type) {
        if (selectedNote) {
          setDockableContent(selectedNote);
        }
      }
    }

    const toggleDockablePanelContent = () => {
      setDockableContentVisibility(!dockableContentVisibility);
    }
    
    useEffect(() => {
        // console.log("dockableContent ", dockableContent)
    },[dockableContent])

    useEffect(() => {
      // if dock is closed, reset the values
      if (!dockableContentOpen) {
        setDockableContent(null);
        setDockableContentType(null);
      }
    },[dockableContentOpen])

    const value = {
      dockableContentOpen,
      setDockableContentOpen,
      toggleDockablePanel,
      dockableContent,
      setDockableContent,
      handleSetDockableContent,
      dockableContentType,
      toggleDockablePanelContent,
      dockableContentVisibility
    };

  return <DockableContentContext.Provider value={value}>{children}</DockableContentContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useDockableContent() {
  return useContext(DockableContentContext);
}