import * as React from 'react';
import MessageInput from '../MessagesPane/MessageInput';
import Sheet from '@mui/joy/Sheet';

export default function MessageInputComponent({chats,chatMessages,setChatMessages,selectedChat,setSelectedChat,file,setFile,newMessageHandler}) {
  const [textAreaValue, setTextAreaValue] = React.useState('');

  const handleOnSubmit = () => {
      // Handle this in a newMessageHandler function:
      newMessageHandler({ content: textAreaValue, role: 'You' })
  }

  return (
    <Sheet
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        backgroundColor: '#F4F1F8', // Ivory: FFFFF0, Lavender: F4F1F8
      }}
    >
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onSubmit={handleOnSubmit}
        setFile={setFile}
        file={file}
      />
    </Sheet>
  )
}