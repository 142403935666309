import ReactLoading from 'react-loading';
import { Box } from '@mui/joy';
import { useAiPane } from '../../../../contexts/aiPaneContext';

function AiLoading() {
  const { loading } = useAiPane()

  return (
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      { loading && <ReactLoading type={"balls"} color={"#724F9B"} height={100} width={50} /> }
    </Box>
  )
}

export default AiLoading;