import React, { useState, useEffect } from 'react';
import { useAuth } from '../../../authContext'; 
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';
import { DEFAULT_PLAN_ID } from '../../../PrivateSite/database/plans-db';
import { checkUserExistsbyEmail } from '../../../PrivateSite/database/utilities-db';
import Alert from '@mui/joy/Alert';
import { BASE_PRIVATEAPP_URL, BASE_LOGIN } from '../../../redirects';
import { jwtDecode } from 'jwt-decode';
import database from '../../../configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import FeedbackSnackbar from '../../../PrivateSite/Components/Snackbar';
import { getConfig } from '../../../PrivateSite/database/configs-db';

const db = database.firestore(); // Access Firestore database

export default function AcceptInvitePage() {
  const [banner, setBanner] = useState('')
  const { signup } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [continueClicked, setContinueClicked] = useState(false);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [invitationId, setInvitationId] = useState('');
  const [inviteAccepted, setInviteAccepted] = useState(false);
  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral');
  const [snackbarMsg, setSnackbarMsg] = React.useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log("token", token);
  }, [token]);

  useEffect(() => {
    getConfig().then(config => setBanner(config.acceptInviteImage))
  }, [])

  const handleJoinTeam = async (e) => {
    e.preventDefault();
    setContinueClicked(true);
    try {
      const response = await fetch('/api/invitations/accept', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      // Check if the response is OK (status in the range 200-299)
      if (!response.ok) {
        console.log("error response", response)
        const errorData = await response.json(); // Parse the error response
        handleError(errorData); // Call handleError with the error
        return; // Exit the function after handling the error
      }

      const data = await response.json();
      console.log("response", response);
      
      console.log("User account created and joined team successfully", data);
      setShowSnackbar(true);
      setSnackbarSeverity('success');
      setSnackbarMsg('You have successfully joined the team!');
      setInviteAccepted(true);
      setTimeout(() => {
        // navigate(BASE_PRIVATEAPP_URL);
      }, 2000);
    } catch (error) {
      console.error(error);
      handleError(error.details.message); // Pass the error message to handleError
    } finally {
      setContinueClicked(false);
    }
  };

  const handleError = (error) => {
    console.log("handleError errorMessage ", error)
    switch(error.details.code) {
      case 'email-already-in-use':
        setError('There is already an account with this email. You cannot sign up with this email as only 1 account per email is allowed at this time.');
        break;
      case 'invitation-already-exists':
        setError('An invitation for this email is already pending.');
        break;
      case 'auth/invalid-password':
        setError(error.details.message)
        break;
      case 'invalid-email':
        setError('The email was invalid. Please retry entering your email address.');
        break;
      default:
        setError('There was an error on our side, please try again.');
    }
    setShowSnackbar(true);
    setSnackbarSeverity('error');
    setSnackbarMsg(error.details.message || 'Failed to accept invitation');
  };

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px',
            '--Cover-width': '50vw',
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: '#F4F1F8',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Header />
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            { !inviteAccepted ?
            <>
              <Stack gap={4} sx={{ mb: 2 }}>
                <Stack gap={1}>
                  <Typography level="h3">Join Team</Typography>
                </Stack>
              </Stack>

              {error !== '' && (
                <Alert variant='soft' color="warning">
                  {error}
                </Alert>
              )}
              <Stack gap={4} sx={{ mt: 2 }}>
                <form onSubmit={handleJoinTeam}>
                  <FormControl required>
                    <FormLabel>Password</FormLabel>
                    <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                  </FormControl>
                  <Stack gap={4} sx={{ mt: 2 }}>
                    <Button loading={continueClicked} loadingIndicator="Loading..." disabled={continueClicked} color={continueClicked ? "neutral" : "primary"} type="submit" fullWidth>
                      {continueClicked ? "Submitting" : "Join Team"}
                    </Button>
                  </Stack>
                </form>
              </Stack>
            </>
            :
            <>
              <Stack gap={4} sx={{ mb: 2 }}>
                <Stack gap={1}>
                  <Typography level="h3">You're in 👍</Typography>
                  <Button sx={{ mt: 3 }} variant="solid" size="sm" color="primary" onClick={() => navigate(BASE_LOGIN)}>Continue to login</Button>
                </Stack>
              </Stack>
            </>
          }
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © smrt cookie {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            `url(${banner})`,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              `url(${banner})`,
          },
        })}
      />
      <FeedbackSnackbar
        openSetting={showSnackbar}
        setShowSnackbar={setShowSnackbar}
        variantSetting={snackbarSeverity}
        colorSetting={snackbarSeverity}
        message={snackbarMsg}
      />
    </CssVarsProvider>
  );
}
