import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Button from '@mui/joy/Button';
import { useState, useEffect } from 'react';
import { retrieveTemplatesFromFirestore } from '../../../database/saveAsTemplate-db';
import { useParams } from 'react-router-dom';
import { FormControl, FormLabel } from '@mui/joy';
import SelectTemplate from '../../Modals/SelectTemplate';

export default function Templates({ open, setOpen, type, data, fetchData, updateDataHandler }) {
    return (
        data && data.length > 0 &&
            <>
                <Button size="lg" sx={{ mr: 2 }} variant="outlined" onClick={() => {setOpen(true)}}>Choose template</Button>
                <SelectTemplate type={type} open={open} setOpen={setOpen} options={data} fetchData={fetchData} onSelectHandler={updateDataHandler} />
            </>
      );
}