import { Card, CardContent, Link, Typography } from '@mui/joy';

function SimpleLinkCard({ text = "", link = "#", icon = null, handleClick }) {
  return (
    <Card onClick={() => handleClick(link)} sx={{ py: 4, px: 3, border: "none", backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>
      <CardContent sx={{ display: 'flex', flexDirection: 'row' }}>
        { icon && icon }
        <Typography level="title-md" sx={{ color: '#724F9B' }}>
          { text }
        </Typography>
      </CardContent>
    </Card>
  )
}

export default SimpleLinkCard