import { Box, Sheet, Stack } from "@mui/joy";
import ComparePlans from "../../Components/ComparePlans";

function Plans() {
  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        gridTemplateColumns: {
          xs: '1fr',
          //sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          sm: '1fr',
        },
      }}
    >
      <Stack
        sx={{
          p: 2,
          pb: 13,            
          backgroundColor: '#f4f1f8',
          minHeight: '100vh',
        }}
      >
        <h1>Plans</h1>
        <Box sx={{ mt: 5 }}>
          <ComparePlans />
        </Box>
      </Stack>
    </Sheet>
  );
}

export default Plans;