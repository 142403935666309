import React, { createContext, useState, useContext } from 'react';

const AiResultsContext = createContext();

export function AiResultsProvider({ children }) {
  const [aiResults, setAiResults] = useState(null);

  return (
    <AiResultsContext.Provider value={{ aiResults, setAiResults }}>
      {children}
    </AiResultsContext.Provider>
  );
}

export function useAiResults() {
  return useContext(AiResultsContext);
}