import React from 'react';
import Avatar from "@mui/joy/Avatar"
import PersonIcon from '@mui/icons-material/Person'
import Typography from '@mui/joy/Typography'
import Box from '@mui/joy/Box'

export default function PersonaCard({ persona }) {



  return (
    <>
    <Box sx={{ textAlign: "center" }}>
      <Avatar size='lg' sx={{ '--Avatar-size': 'lg', mb: ".5rem", minWidth: 70, minHeight: 70 }} color="primary">
        <PersonIcon />
      </Avatar>
      <Typography>{ persona }</Typography>
    </Box>
    </>
  )
}