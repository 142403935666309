import { is } from 'date-fns/locale';
import database from '../../configs/firebaseConfig';

const db = database.firestore(); // Access Firestore database

export async function saveAsTemplateToFirestore({ accountId, selectedProjectId, type, fields, title }) {
    let isArray = false;
    // check if fields is an array or an object
    if (Array.isArray(fields)) {
        isArray = true;
    } else {
        isArray = false;
    }

    try {
        await db.collection(`accounts/${accountId}/templates`).add({
        type: type,
        title: title,
        values: isArray ? [ ...fields ] : { ...fields },
        created: new Date(),
        updated: new Date(),
        project: selectedProjectId
        });
    } catch (error) {
        console.error("Error saving template to Firestore:", error);
    }
}

export async function retrieveTemplatesFromFirestore({ accountId, selectedProjectId, type }) {
    // console.log("retrieveTemplatesFromFirestore ", accountId, selectedProjectId, type)
    try {
        const snapshot = await db.collection(`accounts/${accountId}/templates`)
        .where("project", "==", selectedProjectId)
        .where("type", "==", type)
        .get();

        const templates = [];
        snapshot.forEach((doc) => {
            const data = doc.data();

            if (data && data.values) {
                templates.push({ data: data.values, title: data.title, id: doc.id, created: data.created, updated: data.updated, project: data.project });
            }
        });

        // console.log("Retrieved templates from Firestore:", templates);
        return templates;
    } catch (error) {
        console.error("Error retrieving templates from Firestore:", error);
        return [];
    }
}

export async function deleteTemplateFromFirestore({ accountId, selectedProjectId, templateId }) {
    try {
        await db.collection(`accounts/${accountId}/templates`).doc(templateId).delete();
        console.log("Template deleted from Firestore!");
    } catch (error) {
        console.error("Error deleting template from Firestore:", error);
    }
}