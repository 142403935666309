import React from 'react';
import { Stack, Typography, Input, Chip, Box, IconButton } from "@mui/joy";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { toggleMessagesPane } from '../../utils/utils';
import Button from '@mui/joy/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Add from '@mui/icons-material/Add';
import { set } from 'date-fns';
import { deleteNote, retrieveBinnedNotesFromFirestore } from '../../database/notes-db';
import { useParams } from 'react-router-dom';
import { useNotes } from '../../contexts/notesContext';

export default function BinnedNotesHeader() {
  
  const { handleDeleteNotes, handleEmptyBin, notes } = useNotes()
  const { confirmMultiDelete, setConfirmMultiDelete } = useNotes()
  const { multiDeleteModal, setMultiDeleteModal } = useNotes()

  return (
    <>
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
      p={2}
      pb={1.5}
    >
      <Typography
        fontSize={{ xs: 'md', md: 'lg' }}
        component="h1"
        fontWeight="lg"
        endDecorator={
          <Chip
            variant="soft"
            color="primary"
            size="md"
            slotProps={{ root: { component: 'span' } }}
          >
            {notes.length}
          </Chip>
        }
        sx={{ mr: 'auto' }}
      >
        Bin
      </Typography>

      <IconButton
        variant="plain"
        aria-label="edit"
        color="neutral"
        size="sm"
        onClick={() => {
          toggleMessagesPane();
        }}
        sx={{ display: { sm: 'none' } }}
      >
        <CloseRoundedIcon />
      </IconButton>

      { notes && notes.length > 0 && 
        <>
        <Box>
          <Button
            variant="outlined"
            aria-label="edit"
            color="danger"
            size="sm"
            sx={{ display: { xs: 'none', sm: 'unset' } }}
            onClick={handleEmptyBin}
          >
            Delete all
          </Button>
        </Box>
        </>
      }
    </Stack>
    
    <Modal open={multiDeleteModal} onClose={() => setMultiDeleteModal(false)}>
      <ModalDialog>
        <DialogTitle>Are you sure you want to <b>permanently</b> delete all of the notes?</DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <Button variant="solid" sx={{ mb: 1 }} onClick={() => setConfirmMultiDelete(true)}>Confirm</Button>
          <Button variant="outlined" onClick={() => setMultiDeleteModal(false)}>Cancel</Button>
        </DialogContent>
      </ModalDialog>
    </Modal>
    </>
  )
}