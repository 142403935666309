import React from 'react';
import { Button, Modal, ModalDialog, ModalClose, Typography, RadioGroup, FormControl, FormHelperText, Radio } from '@mui/joy';
import { v4 } from 'uuid';

export default function AddNewComponent({ gridColumns, updateHandler, location, editMode = true }) {
  
  const [ open, setOpen ] = React.useState(false);

  return (
    editMode &&
    <>
      <Button
        size="sm"
        variant="outlined"
        onClick={() => {
          setOpen(true);
        }}
      >
        Add field
      </Button>
      <Modal open={open}>
        <ModalDialog>
          <ModalClose onClick={ () => { setOpen(!open) } }/>
          <Typography>Which field would you like to add?</Typography>
          <RadioGroup name="new_field_type" sx={{ gap: 1, '& > div': { p: 1 } }} 
            onChange={(e) =>
              updateHandler((prevState) => {
                const selectedValue = e.target.value;
                let newItem;

                if (!selectedValue) {
                  return;
                } else if (selectedValue === "bigtext" || selectedValue === "text" || selectedValue === "textarea") {
                  newItem = {
                    id: v4(),
                    type: selectedValue,
                    field_title: "Your Field Title", // Replace with the desired title
                    field_value: "",
                    block_location: location,
                  };
            
                  return [...prevState, newItem];
                } else if (selectedValue === "list" || selectedValue === "slider") {
                  newItem = {
                    id: v4(),
                    type: selectedValue,
                    field_title: "Your Field Title", // Replace with the desired title
                    field_value: "",
                    items: [],
                    block_location: location,
                  };
            
                  return [...prevState, newItem];
                }
          
                return [...prevState];
              })}
          >
            <FormControl size="sm">
              <Radio overlay value="text" label="Text field" />
              <FormHelperText>
                Text input field, ideal for short pieces of text.
              </FormHelperText>
            </FormControl>
            <FormControl size="sm">
              <Radio overlay value="bigtext" label="Big text field" />
              <FormHelperText>
                Large text input field, ideal for short pieces of text with more prominence.
              </FormHelperText>
            </FormControl>
            <FormControl size="sm">
              <Radio overlay value="textarea" label="Text block" />
              <FormHelperText>
                Text blocks are ideal for longer pieces of text like biographies.
              </FormHelperText>
            </FormControl>
            <FormControl size="sm">
              <Radio overlay value="list" label="List" />
              <FormHelperText>
                Useful for listing points around a particular topic/subject.
              </FormHelperText>
            </FormControl>
            <FormControl size="sm">
              <Radio overlay value="slider" label="Spectrum" />
              <FormHelperText>
                Spectrums are ideal for showing where a persona falls on a scale for a given spectrum.
              </FormHelperText>
            </FormControl>
          </RadioGroup>
        </ModalDialog>
      </Modal>
    </>
  )
}