import { useEffect, useLayoutEffect } from 'react';
/* Lexical imports */
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext'
import { createCommand } from 'lexical';
import { getElementNodesInSelection, $elementHasChildren } from '../utilities/EditorUtils'
import {
  $getListDepth, 
  $isListItemNode, 
  insertList
} from '@lexical/list'
import {
  $getSelection, 
  $isRangeSelection,
  $createParagraphNode,
  COMMAND_PRIORITY_CRITICAL,
  INSERT_PARAGRAPH_COMMAND,
  $insertNodes,
  TextNode
} from 'lexical'
import {
  mergeRegister
} from '@lexical/utils'

export const SAVE_COMMAND = createCommand();

export function KeyUtilities() {
  const [editor] = useLexicalComposerContext()

  const makeAbulletIfShortkeyUsed = (node) => {

    const nodeText = node.getTextContent()
    
    const MAKEABULLET_REGEX = /^- /g

    const autoBulletStringMatch = MAKEABULLET_REGEX.exec(nodeText)

    if (autoBulletStringMatch) {
      node.setTextContent("")
      insertList(editor, 'bullet')
    }

  }

  useEffect(() => {
    const removeEditableListener = editor.registerNodeTransform(
      TextNode,
      makeAbulletIfShortkeyUsed,
    )
    return () => {
      removeEditableListener()
    }
  },[editor])

  useLayoutEffect(() => {
    const onKeyDown = (event) => { 
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        editor.dispatchCommand(SAVE_COMMAND, 'Save triggered via shortkey');
        // Trigger save
        //handleSave()
      }
    };

    return editor.registerRootListener((prevRootElement, rootElement) => {
      if (prevRootElement !== null) {
        prevRootElement.removeEventListener('keydown', onKeyDown);
      }
      if (rootElement !== null) {
        rootElement.addEventListener('keydown', onKeyDown);
      }
    })
  },[editor]) 

  useEffect(() => {

    return mergeRegister(
      editor.registerCommand(INSERT_PARAGRAPH_COMMAND,() => {
        const selection = $getSelection()

        if (!$isRangeSelection(selection)) {
          return false;
        }

        const elementNodesInSelection = getElementNodesInSelection(selection)

        for (const elementNode of elementNodesInSelection) {
          if ($isListItemNode(elementNode)) {
            if ($getListDepth(elementNode) == 1) {
              const parent = elementNode.getParent()
              if (parent.__listType === "check" || parent.__listType === "bullet" || parent.__listType === "number") {
                /* //console.log("elementNode", elementNode)
                if (!$elementHasChildren(elementNode)) {
                  //console.log("empty list item, create empty paragraph node")

                  const newPara = $createParagraphNode()
                  $insertNodes([newPara])
                  // elementNode.remove(true)
        
                  // prevent creation of new paragraph is this scenario
                  return false
                  
                } else {
                  return true
                } */
              }
            }
          }
        }
      },COMMAND_PRIORITY_CRITICAL),
    )    
  },[editor])

}