import { Box, ButtonGroup, Button, Card, Typography, Tooltip, IconButton } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import TextFieldsIcon from '@mui/icons-material/TextFields'; // Any text field
import LabelIcon from '@mui/icons-material/Label'; // tag
import RouteIcon from '@mui/icons-material/Route'; // user journey
import WebIcon from '@mui/icons-material/Web'; // content
import LinkIcon from '@mui/icons-material/Link'; // link
import SignpostIcon from '@mui/icons-material/Signpost'; // user flow
import ArticleIcon from '@mui/icons-material/Article'; // templates // pages
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // sitemap
import AddReactionIcon from '@mui/icons-material/AddReaction'; // feature
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'; // notes
import HighlightIcon from '@mui/icons-material/Highlight'; // highlight
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // persona
import BoltIcon from '@mui/icons-material/Bolt'; // incite
import NorthWestIcon from '@mui/icons-material/NorthWest'; // Open Create Docs (collapsed)
import SouthIcon from '@mui/icons-material/South'; // Close Create Docs (expanded)

function Sidepane() {
  return (
    <Box 
      sx={{
        p: 2,
        borderRadius: '10px',
        backgroundColor: 'background.surface',
        height: 'fit-content',
        minHeight: '100%',
        position: 'relative'
      }}
    >
      <ButtonGroup spacing={1} size="sm" orientation="vertical" sx={{ position: 'absolute', top: 10, right: 10 }}>
        {/* PERSONAS SIDE PANE */}
        <Tooltip variant="plain" color="neutral" title="AI Summary">
          <IconButton size="sm"><AssignmentRoundedIcon /></IconButton>
        </Tooltip>
        {/* <Tooltip variant="plain" color="neutral" title="AI Suggested Highlights">
          <IconButton size="sm" variant='plain'><HighlightsIcon /></IconButton>
        </Tooltip> */}
        <Tooltip variant="plain" color="neutral" title="AI Suggested Features">
          <IconButton size="sm"><AddReactionIcon /></IconButton>
        </Tooltip>
        {/* <Tooltip variant="plain" color="neutral" title="AI Suggested Personas">
          <IconButton size="sm" variant='plain'><PersonasIcon /></IconButton>
        </Tooltip> */}
        <Tooltip variant="plain" color="neutral" title="AI Suggested Journeys">
          <IconButton size="sm"><RouteIcon /></IconButton>
        </Tooltip>
        <Tooltip variant="plain" color="neutral" title="AI Suggested Sitemap">
          <IconButton size="sm"><AccountTreeIcon /></IconButton>
        </Tooltip>
      </ButtonGroup>
      <Box sx={{ mr: 5 }}>
        <Typography level="h5">Side Pane Title</Typography>
        <Card sx={{ mt: 2, border: '1px solid', borderColor: 'divider', backgroundColor: '#fff' }}>
          <Typography level="h6">Card</Typography>
        </Card>
      </Box>
    </Box>
  )
}

export default Sidepane;