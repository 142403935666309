import React from 'react';
import { Button, Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import MoreVert from '@mui/icons-material/MoreVert';
import Templates from '../../Modals/CreateTemplate';

export function IconButtonMenu({ fields, fetchData, type }) {

    const [open, setOpen] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);

    const handleOpenChange = React.useCallback((event, isOpen) => {
        setOpen(isOpen);
    }, []);

    const handleSaveAsTemplate = () => {
        // console.log("Save as template")
        setModalOpen(true)
    }

    return (
        <>
            <Dropdown open={open} onOpenChange={handleOpenChange}>
                <MenuButton
                    sx={{ borderRadius: 5, ml: .25 }}
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'solid', color: 'primary' } }}
                >
                    <MoreVert />
                </MenuButton>
                <Menu sx={{ zIndex: 9999 }} placement="top-end">
                    <MenuItem onClick={(e) => { 
                        handleSaveAsTemplate() 
                    }}>Save as template</MenuItem>
                </Menu>
            </Dropdown>
            <Templates 
                open={modalOpen} 
                setOpen={setModalOpen} 
                type={type}
                newTypeData={fields}
                fetchData={fetchData}
            />
        </>
    );
}



export default function SaveDropdown({ fetchData, saveHandler, fields, id = null, type, name, tags }) {
    // console.log("SaveDropdown tags", tags)
    return (
        <>
        { id ?
            <>
                <Button 
                    sx={{ alignSelf: 'flex-end' }} 
                    onClick={() => saveHandler(fields, id, name, tags)} 
                    size="lg" 
                    variant="solid" 
                >
                    Save
                </Button>
                <IconButtonMenu fields={fields} fetchData={fetchData} type={type} />
            </>
        : 
            <>
                <Button 
                    sx={{ alignSelf: 'flex-end' }} 
                    onClick={() => saveHandler(fields, null, name, tags)} 
                    size="lg" 
                    variant="solid" 
                >
                    Save
                </Button>
                <IconButtonMenu fields={fields} fetchData={fetchData} type={type} />
            </>
        }</>        
    )
}