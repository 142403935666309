import { Grid } from '@mui/joy';

function ModalWithPreview({ children, menuWidth = '33.333%', SideContent, MainContent }) {
    return (
      <Grid container sx={{ position: 'relative', overflow: 'hidden' }}>
        <Grid sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: menuWidth,
          height: '100%',
          px: 2,
          mt: 2,
        }}>
          {SideContent}
        </Grid>
        <Grid xs={12} sx={{
          marginLeft: menuWidth,
          flexGrow: 1,
          width: '100%'
        }}>
          {MainContent}
        </Grid>
      </Grid>
    )
}

export default ModalWithPreview;