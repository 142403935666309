import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Button,Box} from '@mui/joy';
import {createPortal} from 'react-dom';
import { v4 } from 'uuid'
import {
  Announcements,
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragOverlay,
  DragMoveEvent,
  DragEndEvent,
  DragOverEvent,
  MeasuringStrategy,
  DropAnimation,
  Modifier,
  defaultDropAnimation,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {
  buildTree,
  flattenTree,
  getProjection,
  getChildCount,
  removeItem,
  removeChildrenOf,
  setProperty,
} from './utilities';
import {sortableTreeKeyboardCoordinates} from './keyboardCoordinates';
import {SortableTreeItem} from './components';
import {CSS} from '@dnd-kit/utilities';
import { useBrowseTypes } from '../../contexts/browseTypesContext';
import { useContextualPane } from '../../contexts/contextualPane';

const initialItems = [
  {
    id: v4(),
    label: 'Home',
    children: [],
  },
  {
    id: v4(),
    label: 'About',
    children: [],
  },
  {
    id: v4(),
    label: 'Services',
    children: [
      {id: v4(), label: 'Web Development', children: []},
      {id: v4(), label: 'Design', children: []},
      {id: v4(), label: 'Marketing', children: []},
    ],
  },
  {
    id: v4(),
    label: 'Contact',
    children: [],
  },
];


const fashionInitialItems = [
  {
    id: v4(),
    label: 'Home',
    children: [],
  },
  {
    id: v4(),
    label: 'Collections',
    children: [
      {id: v4(), label: 'Spring', children: []},
      {id: v4(), label: 'Summer', children: []},
      {id: v4(), label: 'Fall', children: []},
      {id: v4(), label: 'Winter', children: []},
    ],
  },
  {
    id: v4(),
    label: 'About Us',
    children: [],
  },
  {
    id: v4(),
    label: 'My Account',
    children: [
      {id: v4(), label: 'Addresses', children: []},
      {id: v4(), label: 'Order History', children: []},
    ],
  },
];

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimationConfig = {
  keyframes({transform}) {
    return [
      {opacity: 1, transform: CSS.Transform.toString(transform.initial)},
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({active}) {
    active.node.animate([{opacity: 0}, {opacity: 1}], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

export function SortableTree({
  setSitemap, 
  sitemap,
  collapsible,
  defaultItems = initialItems,
  indicator = false,
  indentationWidth = 50,
  removable,
  showAddBtn = true,
  mode
}) {

  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [ editLabel, setEditLabel ] = useState(false);
  const [ newValue, setNewValue ] = useState('');
  const [ editingId, setEditingId ] = useState(null);
  const { setIndex, linkData, setLinkData, selectedIdAndValue, setSelectedIdAndValue } = useBrowseTypes();
  const { listAssociationResults, handleFetchOfAssociationTypes } = useContextualPane();

  const handleChangeValue = (e) => {
    setNewValue(e.target.value);
  }

  const handleEditValue = (id) => {
    setEditLabel(!editLabel);
    setEditingId(id)
  }

  const updateLabelRecursively = (item, id, newLabel, level) => {
    if (level > 5) {
      return item;
    }
  
    if (item.id === id) {
      return {
        ...item,
        label: newLabel,
      };
    }
  
    return {
      ...item,
      children: item.children.map(child => updateLabelRecursively(child, id, newLabel, level + 1)),
    };
  };

  const checkIfAssociationHasMatchingElement = ({ associationElement, id }) => {
    if (associationElement === id) {
      return true;
    } else {
      return false;
    }
  }

  const handleClick = ({ id, value }) => {
    setSelectedIdAndValue({ id, value })
    // Check if there is an association for the page item
    let resultFound = false;
    let pendingLinkDataArray = [];
    if (listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.length > 0) {
      listAssociationResults.definitions.map((association) => {
        const result = checkIfAssociationHasMatchingElement({ associationElement: association.element, id })
        if (result) {
          // If so, Open the links tab
          setIndex(1)
          resultFound = true
          pendingLinkDataArray.push(association)
        }
      })
    }
    if (pendingLinkDataArray.length > 0) {
      setLinkData(pendingLinkDataArray)
    }
    else if (pendingLinkDataArray.length === 0) {
      setLinkData([])
    }
    if (!resultFound) {
      // If not, Open the first Browse Documents tab
      setIndex(0)
    }
  }

  const handleSubmit = (e) => {

    if (newValue !== "") {
      setSitemap({
        ...sitemap,
        items: sitemap.items.map((item) => {
          return updateLabelRecursively(item, editingId, newValue, 0);
        }),
      });
    }

    setEditLabel(!editLabel);
    setEditingId(null);
    setNewValue('');
  };

  const handleAddNode = () => {
    setSitemap({
      ...sitemap,
      items: [
        ...sitemap.items,
        {
          id: v4(),
          label: `New page`,
          children: [],
        }
      ]
    });
  }

  const flattenedItems = useMemo(() => {
    if (sitemap && sitemap.items && sitemap.items.length > 0) {
      const flattenedTree = flattenTree(sitemap.items);
      const collapsedItems = flattenedTree.reduce(
        (acc, {children, collapsed, id}) =>
          collapsed && children.length ? [...acc, id] : acc,
        []
      );

      return removeChildrenOf(
        flattenedTree,
        activeId ? [activeId, ...collapsedItems] : collapsedItems
      );
    }
  }, [activeId, sitemap.items]);

  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  const [coordinateGetter] = useState(() =>
    sortableTreeKeyboardCoordinates(sensorContext, indicator, indentationWidth)
  );
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );

  const sortedIds = useMemo(() => {
    if (flattenedItems) {
      return flattenedItems.map(({ id }) => id);
    }
  }, [flattenedItems]);

  const activeItem = activeId ? flattenedItems.find(({id}) => id === activeId) : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const announcements = {
    onDragStart({active}) {
      return `Picked up ${active.id}.`;
    },
    onDragMove({active, over}) {
      return getMovementAnnouncement('onDragMove', active.id, over?.id);
    },
    onDragOver({active, over}) {
      return getMovementAnnouncement('onDragOver', active.id, over?.id);
    },
    onDragEnd({active, over}) {
      return getMovementAnnouncement('onDragEnd', active.id, over?.id);
    },
    onDragCancel({active}) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`;
    },
  };

  return (
    <DndContext
      accessibility={{announcements}}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      { sortedIds && flattenedItems &&
      <>
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map(({id, children, label, collapsed, depth}) => (
          <SortableTreeItem
            key={id}
            id={id}
            handleClick={handleClick}
            value={label}
            mode={mode}
            depth={id === activeId && projected ? projected.depth : depth}
            indentationWidth={indentationWidth}
            indicator={indicator}
            collapsed={Boolean(collapsed && children.length)}
            editLabel={editLabel}
            newValue={newValue}
            handleEditValue={handleEditValue}
            handleChangeValue={handleChangeValue}
            handleSubmit={handleSubmit}
            editingId={editingId}
            onCollapse={
              collapsible && children.length
                ? () => handleCollapse(id)
                : undefined
            }
            onRemove={
              removable 
                ? () => handleRemove(id) 
                : undefined
              }
          />
        ))}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <SortableTreeItem
                id={activeId}
                depth={activeItem.depth}
                clone
                childCount={getChildCount(sitemap.items, activeId) + 1}
                value={activeId.toString()}
                indentationWidth={indentationWidth}
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
      </>
      }
      { showAddBtn &&
      <Box>        
        <Button variant="outlined" color="primary" onClick={handleAddNode}>Add page</Button>
      </Box>
      }
    </DndContext>
  );

  function handleDragStart({active: {id: activeId}}) {
    setActiveId(activeId);
    setOverId(activeId);

    const activeItem = flattenedItems.find(({id}) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({delta}) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({over}) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({active, over}) {
    resetState();

    if (projected && over) {
      const {depth, parentId} = projected;
      const clonedItems = JSON.parse(
        JSON.stringify(flattenTree(sitemap.items))
      );
      const overIndex = clonedItems.findIndex(({id}) => id === over.id);
      const activeIndex = clonedItems.findIndex(({id}) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];

      clonedItems[activeIndex] = {...activeTreeItem, depth, parentId};

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
      const newItems = buildTree(sortedItems);

      setSitemap({ ...sitemap, items: newItems });
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function handleRemove(id) {
    setSitemap({
      ...sitemap,
      items: removeItem(sitemap.items, id)
    })
  }

  function handleCollapse(id) {
    if (id) {

      const returnVal = setProperty(sitemap.items, id, 'collapsed', (value) => {
        return !value;
      })

      setSitemap({ ...sitemap, items: returnVal });
    }
  }

  function getMovementAnnouncement(
    eventName,
    activeId,
    overId
  ) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems = JSON.parse(
        JSON.stringify(flattenTree(sitemap.items))
      );
      const overIndex = clonedItems.findIndex(({id}) => id === overId);
      const activeIndex = clonedItems.findIndex(({id}) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId = previousSibling.parentId;
            previousSibling = sortedItems.find(({id}) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}

const adjustTranslate = ({transform}) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};