import * as React from 'react';
import Stack from '@mui/joy/Stack';
import NoteMoreOptions from './NoteMoreOptions';
import Box from '@mui/joy/Box';
import { useNotes } from '../../contexts/notesContext';
import TagPicker from '../Tags/Editor/TagPicker';
import OpenButton from '../Editor/plugins/OpenButton';
import GoBack from '../Buttons/GoBack';
import ConnectionsDropdown from '../Connections/ConnectionsDropdown';
import GenAiQuickInlineSuggestions from '../GenAiQuickInlineSuggestions';
import AiSidepaneButton from '../Buttons/AiSidepaneButton';
import ModalEditorHeader from '../Layouts/ModalEditorHeader';

export default function NotesPaneHeaderEdit({sidePaneOpen, setSidePaneOpen, allowEditing, noteToDisplay}) {
  
  const { handleSave, updateTitle, noteTitle, setNoteEditorOpen } = useNotes();
  const [ selectedTags, setSelectedTags ] = React.useState(noteToDisplay.tags ? noteToDisplay.tags : []);
  const [ pendingTag, setPendingTag ] = React.useState([]);
  const [ loading, setLoading ] = React.useState(false);

  const handleUpdateTags = async (tags = pendingTag) => {
    setSelectedTags(tags);
    handleSave({ 
        note: noteToDisplay, 
        setNewCurrentNote: true, 
        pendingTag: tags, 
        tagsChanged: true 
    });
  }

  const handleSaveNote = async ({dontSave = false} = {}) => {
    if (dontSave) {
      setNoteEditorOpen(false);
      setSidePaneOpen(false);
      return;
    }
    setLoading(true);
    if (noteToDisplay.type === 'note' || noteToDisplay.type === 'upload' || noteToDisplay.type === 'link') {
      await handleSave({
        note: noteToDisplay,
        setNewCurrentNote: true
      })
      setLoading(false);
      setNoteEditorOpen(false);
      setSidePaneOpen(false);
    }
    if (noteToDisplay.type === 'persona') {
      console.log('save persona')
    }
  }

  React.useEffect(() => {
    setSelectedTags(noteToDisplay.tags ? noteToDisplay.tags : []);
  },[noteToDisplay])

  return (
    <ModalEditorHeader 
      setSidePaneOpen={setSidePaneOpen}
      sidePaneOpen={sidePaneOpen}
      handleSave={handleSaveNote}
      fields={noteToDisplay}
      document={noteToDisplay}
      name={noteTitle}
      pendingTag={pendingTag}
      setPendingTag={setPendingTag}
      selectedTags={selectedTags}
      handleUpdateTags={handleUpdateTags}
      allowEditing={allowEditing}
      handleChangeTitle={updateTitle}
      type="note"
      loading={loading}
      setLoading={setLoading}
    />
  );
}
