import * as React from 'react';
import { Button, Box, Grid, FormLabel, FormControl, Modal, ModalDialog, Input, Stack, Textarea, Typography } from '@mui/joy';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import SwitchControl from '../../../Radios/SwitchControl';

const generateOptions = [
  {
    type: 'summariseFiles',
    files: [],
    value: false,
    label: 'Summarise files',
    helpText: 'Summarise the files in the project',
    filesRequired: true
  },
  {
    type: 'suggestTodos',
    files: [],
    value: true,
    label: 'Suggest to dos',
    helpText: 'Get started with some suggested to dos',
    filesRequired: false
  },
  {
    type: 'suggestPersonas',
    files: [],
    value: true,
    label: 'Suggest personas',
    helpText: 'Get started with some suggested personas',
    filesRequired: false
  },
  {
    type: 'suggestUserJourneys',
    files: [],
    value: true,
    label: 'Suggest user journeys',
    helpText: 'Get started with some suggested user journeys',
    filesRequired: false
  },
  {
    type: 'suggestFeatures',
    files: [],
    value: true,
    label: 'Suggest features',
    helpText: 'Get started with some suggested features',
    filesRequired: false
  },
  {
    type: 'suggestPagesAndContent',
    files: [],
    value: true,
    label: 'Suggest pages and content',
    helpText: 'Get started with some suggested pages and content',
    filesRequired: false
  },
  {
    type: 'suggestNavigations',
    files: [],
    value: true,
    label: 'Suggest navigations',
    helpText: 'Get started with some suggested navigations',
    filesRequired: false
  },
  {
    type: 'suggestUserFlows',
    files: [],
    value: true,
    label: 'Suggest user flows',
    helpText: 'Get started with some suggested user flows',
    filesRequired: false
  },
]

function FirstStep({handleNewProjectWithGen,setOpen,data,setData}) {

  return (
    <Grid lgOffset={3} item xs={6}>
      {/* Heading */}
      <Box sx={{ mt: 5 }}>
        <Typography variant="h6" color="text.secondary" sx={{ textAlign: 'center', fontSize: '2.5rem', mt: 1, ml: 1 }}>Let's get going...</Typography>
      </Box>
      {/* Project Title */}
      <Box sx={{ mt: 5, p: 2, backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>
        <Stack spacing={2}>
          <FormControl sx={{ width: 320 }}>
            <FormLabel>Project title *</FormLabel>
            <Input sx={{ my: 1.5 }} autoFocus required onChange={(e) => setData({ ...data, name: e.target.value })} />
          </FormControl>
        </Stack>
      </Box>
      {/* Description */}
      <Box sx={{ mt: 5, p: 2, backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Describe your project *</FormLabel>
            <Textarea 
              placeholder="Describe your project to provide some context for the system and other readers, and we'll get cracking thinking through the experience... Use at least a paragraph or two." 
              minRows={2} 
              required 
              sx={{ p: 2, my: 1.5 }}
              onChange={(e) => setData({ ...data, description: e.target.value })} 
            />
          </FormControl>
        </Stack>
      </Box>
      {/* Options */}
      <Box sx={{ mt: 5, p: 2, backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>
        <Typography sx={{ mb: 2 }}>Generate Options</Typography>
        {/* <SwitchControl label={switchLabel} defaultValue={defaultValue} helpText={helpText} /> */}
        <Grid container>
        { generateOptions.map((option, index) => (
          <Grid xs={ 6 } sx={{ mt: 2.5, mb: 3, p: 3, py: 0 }}>
            <SwitchControl key={`o_` + index} defaultFiles={option.files} filesRequired={option.filesRequired} label={option.label} defaultValue={option.value} helpText={option.helpText} />
          </Grid>
        ))}
        </Grid>
      </Box>
      {/* Submit */}
      <Box sx={{ mt: 5, flexDirection: 'column', display: 'flex' }}>
        <Box sx={{ display: 'flex', alignContent: 'space-between' }}>
          <Box>
            <Button variant="outlined" onClick={() => setOpen(false)}>Cancel</Button>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <Button onClick={(e) => handleNewProjectWithGen()} startDecorator={<AutoFixNormalIcon />} type="submit">Generate</Button>
          </Box>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Typography level="title-sm" sx={{ mt: 2, ml: 2 }}>* Required field</Typography>
        </Box>
      </Box>
    </Grid>
  )
}

export default FirstStep;