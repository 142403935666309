import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import AvatarWithStatus from './AvatarWithStatus';
import ChatBubble from './ChatBubble';
import MessagesPaneHeader from './MessagesPaneHeader';
import { saveChatToFirestore } from '../../database/chats-db';
import LoadingBubble from './LoadingBubble';

export default function MessagesPane({ chatMessages = [], loading = false }) {
  return (
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 6,
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {chatMessages.map((message, index) => {
            const isYou = message.sender === 'You';
            return (
              <Stack
                key={index}
                direction="row"
                spacing={2}
                flexDirection={isYou ? 'row-reverse' : 'row'}
              >
                {message.sender !== 'You' && (
                  <AvatarWithStatus
                    // online={message.sender.online}
                    // src={message.sender.avatar}
                  />
                )}
                <ChatBubble variant={isYou ? 'sent' : 'received'} {...message} />
              </Stack>
            );
          })}
          { loading && 
          <Stack
                key='789456123'
                direction="row"
                spacing={2}
                flexDirection={'row'}
              >
                <LoadingBubble loading={loading} />
          </Stack>
          }
        </Stack>
      </Box>
  );
}