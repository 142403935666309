import React, { useEffect, useState } from "react";
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
// import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { ImageNode } from "./nodes/ImageNode/ImageNode";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import OnChangePlugin from './plugins/OnChangePlugin';
import { LoadNotePlugin } from "./plugins/LoadNotePlugin";
import { KeyUtilities } from "./plugins/KeyUtilities";
import { TabIndentationPlugin } from "./plugins/TabIndentationPlugin";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import { useNotes } from '../../contexts/notesContext';
import { UploadProvider } from "./plugins/ImagePluginModified";
import DraggableBlockPlugin from "./plugins/DraggableBlockPlugin";
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import TableCellActionMenuPlugin from "./plugins/TableActionMenuPlugin";
import TableCellResizer from "./plugins/TableCellResizer";
import { useSettings } from '../../contexts/useSettings';
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin";
import FloatingSmartLinkEditorPlugin from "./plugins/FloatingSmartLinkEditorPlugin";
import CurrentContentPlugin from "./plugins/CurrentContentPlugin";
import InsertTextPlugin from "./plugins/InsertTextPlugin";
import FloatingHighlightEditorPlugin from "./plugins/FloatingHighlightEditorPlugin";
import { HighlightNode } from './plugins/nodes/Highlight'
import { ExcalidrawNode } from "./plugins/nodes/ExcalidrawNode";
import ExcalidrawPlugin from "./plugins/ExcalidrawPlugin";
import SaveStatusAndVersionHistory from "./plugins/SaveStatusAndVersionHistory";
import { Alert } from "@mui/joy";

// context == main is the default editor, and context == dock is the docked content
export default function Editor({ 
  editable = false, 
  context = "main",
  // Add contentSource prop that can be selectedNote, dockableContent, etc.
  contentSource,
  onChange 
}) {

  const { handleOpenNoteInEditor, noteEditorOpen, titleRef, editorRef, onEditorChange, allowEditing, editorInnerRef } = useNotes();
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);

  const handleOpenNote = () => {
    if (!noteEditorOpen) {
      console.log("Opening note...", contentSource);
      handleOpenNoteInEditor(contentSource)       
    }
  }

  const {
    settings: {
      isCollab,
      isAutocomplete,
      isMaxLength,
      isCharLimit,
      isCharLimitUtf8,
      isRichText,
      showTreeView,
      showTableOfContents,
      shouldUseLexicalContextMenu,
      tableCellMerge,
      tableCellBackgroundColor,
    },
  } = useSettings();

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    // Editable or not
    editable: editable,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      ImageNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      HighlightNode,
      ExcalidrawNode,
    ]
  };

  function Placeholder() {
    return noteEditorOpen && allowEditing ? <div className="editor-placeholder">Let's get typing...</div> : null;
  }

  return (
    <>
    <div className="editor-shell">
      <LexicalComposer initialConfig={editorConfig}>
        <div ref={editorRef} className={`editor-container ${showTreeView ? 'tree-view' : ''} ${
          !isRichText ? 'plain-text' : ''
        }`} tabIndex="3">
          <div className="editor-scroller">
            <div className="editor">
              <div 
                className="editor-inner" 
                onClick={() => handleOpenNote()} 
                ref={(el) => {
                  onRef(el);
                  if (editorInnerRef) editorInnerRef.current = el;
                }}
              >
                { editable &&
                  <ToolbarPlugin noteToDisplay={contentSource} />
                }
                { noteEditorOpen && !allowEditing && <Alert sx={{ mb: 3, width: 'fit-content', marginLeft: 'auto', mr: 1 }}>Note: You can't edit documents that you didn't create.</Alert>}
                <RichTextPlugin
                  contentEditable={<ContentEditable className="editor-input" />}
                  placeholder={<Placeholder />}
                  ErrorBoundary={LexicalErrorBoundary}
                />
                <HistoryPlugin />
                {/* <TreeViewPlugin /> */}
                <AutoFocusPlugin />
                <CodeHighlightPlugin />
                <ListPlugin />
                <LinkPlugin />
                <CurrentContentPlugin onChange={onChange} />
                <InsertTextPlugin />
                <AutoLinkPlugin />
                <ExcalidrawPlugin />
                <TabIndentationPlugin titleRef={titleRef} />
                <ListMaxIndentLevelPlugin maxDepth={7} />
                <OnChangePlugin onChange={onEditorChange}/>
                <LoadNotePlugin 
                  context={context} 
                  editable={editable} 
                  contentSource={contentSource}
                />
                <TablePlugin 
                  hasCellMerge={tableCellMerge}
                  hasCellBackgroundColor={tableCellBackgroundColor}
                />
                <TableCellResizer />
                <DragDropPaste />
                <KeyUtilities />
                {floatingAnchorElem && editable &&
                  <>
                    <DraggableBlockPlugin anchorElem={floatingAnchorElem} />
                    {/* The plugin that shows on click of a link, also handles edit/delete */}
                    <FloatingSmartLinkEditorPlugin
                      anchorElem={floatingAnchorElem}
                      isLinkEditMode={isLinkEditMode}
                      setIsLinkEditMode={setIsLinkEditMode}
                    />
                    <TableCellActionMenuPlugin
                      anchorElem={floatingAnchorElem}
                      cellMerge={true}
                    />
                    <FloatingTextFormatToolbarPlugin 
                      anchorElem={floatingAnchorElem} 
                      isLinkEditMode={isLinkEditMode}
                      setIsLinkEditMode={setIsLinkEditMode}
                    />
                    <FloatingHighlightEditorPlugin 
                      noteToDisplay={contentSource}
                      anchorElem={floatingAnchorElem} 
                    />
                  </>
                }
                <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                { editable &&
                  <SaveStatusAndVersionHistory />
                }
              </div>
            </div>
          </div>
        </div>
      </LexicalComposer>
    </div>
    </>
  );
}
