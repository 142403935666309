import React, { useContext, useState, useEffect } from 'react';
import { auth } from './configs/firebaseConfig.js'; // Import your Firebase configuration
import { retrieveUserPlans, retrieveUserRole, retrieveUserInvitation, updateUser, retrieveUserAccountId } from './PrivateSite/database/user-db.js';
import { retrieveUsersForAccount } from './PrivateSite/database/users-db.js';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [accountUsers, setAccountUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  async function getIdToken() {
    console.log("getIdToken")
    if (auth.currentUser) {
      console.log("auth.currentUser", auth.currentUser)
      return auth.currentUser.getIdToken();
    }
    return null;
  }

  async function updateProfile({ name, email, image }) {
    //console.log("Attempting to update profile with ", name, email)
    if (auth && auth.currentUser) {
      const user = auth.currentUser;
      const result = await user.updateProfile({
        displayName: name,
        email: email,
        photoURL: image
      }).then(async (result) => {
        //console.log('User profile updated successfully.', result);
        // Also update the users collection with the new name, email and photoURL
        await updateUser({ userId: user.uid, name: name, email: email, photoURL: image });
        return true
      }).catch((error) => {
        console.error('Error updating user profile:', error);
        return error
      });
      return result
    }
  }

  async function getAccountUsers({accountId}) {
    return retrieveUsersForAccount({ accountId });
  }

  async function getUser() {
    if (auth && auth.currentUser) {
      const user = auth.currentUser;
      return user;
    } else {
      return null;  
    }
  }  

  async function sendPasswordReset(email) {
    auth.sendPasswordResetEmail(email)
      .then(() => {
        console.log('Password reset email sent.');
      })
      .catch((error) => {
        console.error('Error sending password reset email:', error);
      });
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in
        const metadata = user.metadata;
        const isNewUser = metadata.creationTime === metadata.lastSignInTime;

        const userId = user._delegate.uid;
        
        try {
          // Get user role, invitation, and plans
          const [userRole, userAccountId, userInvitation, userPlans] = await Promise.all([
            retrieveUserRole({ userId }),
            retrieveUserAccountId({ userId }),
            retrieveUserInvitation({ userId }),
            retrieveUserPlans({ userId }).catch(error => {
              console.warn('Error retrieving user plans:', error);
              return null; // Return null if there's an error (e.g., due to Firestore rules)
            })
          ]);

          let highestPlan = 'free'; // Default to 'free'

          if (userInvitation && userInvitation.invitationId) {
            // User has a valid invitation, set highestPlan to "team"
            highestPlan = 'team';
          } else if (userPlans && Array.isArray(userPlans) && userPlans.length > 0) {
            // Find the highest role
            for (const plan of userPlans) {
              if (plan.role === "team" && plan.status === "active") {
                highestPlan = plan.role;
                break;
              } else if (plan.role === "paid" && plan.status === "active" && highestPlan !== "team") {
                highestPlan = plan.role;
              } else if (plan.role === "free" && plan.status === "active" && highestPlan === "free") {
                highestPlan = plan.role;
              }
            }
          }

          // Create friendly label for plan
          let userPlanLabel = '';
          if (highestPlan === 'free') {
            userPlanLabel = 'Cookie Plan';
          } else if (highestPlan === 'paid') {
            userPlanLabel = 'Smart Cookie';
          } else if (highestPlan === 'team') {
            userPlanLabel = 'Cookie Jar';
          }

          setCurrentUser({
            ...user,
            isNewUser: isNewUser,
            plan: highestPlan,
            planLabel: userPlanLabel,
            role: userRole,
            accountId: userAccountId
          });

        } catch (error) {
          console.error('Error setting up user data:', error);
          // Handle the error appropriately, maybe set a default user state
          setCurrentUser({
            ...user,
            isNewUser: isNewUser,
            plan: 'free',
            planLabel: 'Cookie Plan',
            role: 'user' // or whatever your default role is
          });
        }

      } else {
        // User is signed out
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (accountUsers.length > 0) {
      console.log("accountUsers", accountUsers)
    }
  },[accountUsers])

  useEffect(() => {
    if (currentUser && currentUser.accountId) {
      (async () => {
        try {
          const users = await getAccountUsers({ accountId: currentUser.accountId });
          setAccountUsers(users);
        } catch (error) {
          console.error('Error fetching account users:', error);
        }
      })();
    }
    // Based on the auth method, create a standardised formattedData user object to use throughout the application
    if (currentUser && currentUser.credential && !currentUser.formattedData) {
      const newFormattedData = {
        email: currentUser.userObject.email,
        name: currentUser.userObject.name,
        picture: currentUser.userObject.picture,
        id: currentUser.uid ? currentUser.uid : ''
      };
      setCurrentUser({...currentUser, formattedData: newFormattedData})
    } else if (currentUser && currentUser.email && !currentUser.formattedData) {
      // email does not exist in the object for email/password login, hence this is logged in with email password
      const newFormattedData = {
        email: currentUser._delegate.email,
        name: currentUser._delegate.displayName ? currentUser._delegate.displayName : '',
        picture: currentUser._delegate.photoURL ? currentUser._delegate.photoURL : '',
        id: currentUser._delegate.uid ? currentUser._delegate.uid : ''
      };
      setCurrentUser({...currentUser, formattedData: newFormattedData})
    }
    //console.log("currentUser within authContext", currentUser)
  },[currentUser])

  const value = {
    currentUser,
    setCurrentUser,
    signup,
    login,
    logout,
    getUser,
    sendPasswordReset,
    updateProfile,
    getIdToken,
    accountUsers
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
