import { useEffect, useState } from 'react';
import { IconButton, Box, Modal, List, ListItem, Chip, ModalDialog, DialogTitle, Button, FormLabel, Checkbox, FormControl, Typography, Grid } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import { addUpload, uploadFile } from '../../../database/notes-db';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../authContext';  
import { useNotes } from '../../../contexts/notesContext';
import ImportFileUpload from '../../MessagesPane/InputFileUpload'
import { getFileTypeFromFilePath } from '../../../utils/utils';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import { useAiPane } from '../../../contexts/aiPaneContext';
import AiMagicButton from '../../Buttons/AiMagic';
import { startVideoTranscription } from '../../../database/transcriptions-db';
import InlineLoader from '../../Loaders/InlineLoader';

function FileUpload({ uploadFileModal, setUploadFileModal }) {
  const { accountId, selectedProjectId } = useParams()
  const { currentUser } = useAuth()
  const { getAndSetData } = useNotes();
  const { summariseThisPdf } = useAiPane()
  const [isDisabled, setIsDisabled] = useState(true)
  const [file, setFile] = useState(null)
  const [createSummary, setCreateSummary] = useState(false)
  const [createPersonas, setCreatePersonas] = useState(false)
  const [createJourneys, setCreateJourneys] = useState(false)
  const [createSitemap, setCreateSitemap] = useState(false)
  const [createPages, setCreatePages] = useState(false)
  const [createFeatures, setCreateFeatures] = useState(false)
  const [createTranscript, setCreateTranscript] = useState(false)
  const [fileType, setFileType] = useState(null)
  const [transcriptionStatus, setTranscriptionStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('file ', file)
    if (file && file !== "") {
      setIsDisabled(false)
      const fileType = getFileTypeFromFilePath(file.name)
      console.log('fileType ', fileType)
      setFileType(fileType)
    }
  }, [file])

  const handleUploadImage = async () => {
    setIsLoading(true);
    const user = currentUser;
    const fileOrDataURL = file;
    const resultOfAddLink = await uploadFile({ accountId, user, fileOrDataURL, selectedProjectId });
    setIsDisabled(true)
    
    if (resultOfAddLink.status === 'success') {
      const result = await addUpload({ 
        user, 
        accountId, 
        selectedProjectId, 
        fileName: resultOfAddLink.fileName, 
        downloadURL: resultOfAddLink.downloadURL 
      })
      
      await getAndSetData({ setNewCurrentNote: true, sortByPinned: false })

      if (createTranscript && (fileType === "video/mp4" || fileType === "mp4" || fileType === "mov" || fileType === "avi")) {
        const transcriptionRequest = await startVideoTranscription({
          userId: currentUser._delegate.uid,
          accountId,
          fileName: resultOfAddLink.fileName,
          fileUrl: resultOfAddLink.downloadURL,
          noteId: result.id // Store reference to the note
        });
        
        setTranscriptionStatus({
          id: transcriptionRequest.id,
          status: 'processing'
        });
      }

      if (createSummary) {
        console.log('summariseThisPdf ', resultOfAddLink.fileName)
        await summariseThisPdf(currentUser._delegate.uid, resultOfAddLink.fileName)
      }
    }
    
    setFile('')
    setUploadFileModal(false)
    setIsLoading(false);
  }

  const handleDeleteFile = () => {
    setFile('')
    setIsDisabled(true)
  }

  return (
    <Modal open={uploadFileModal} onClose={() => setUploadFileModal(false)}>
      <ModalDialog size="md" sx={{ width: 600, position: 'relative' }}>
        <IconButton
          aria-label="close"
          onClick={() => setUploadFileModal(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>Upload File</DialogTitle>
        <ImportFileUpload
          file={file}
          setFile={setFile}
        />
        { file && 
          <List>
            <ListItem>
              <Chip sx={{ maxWidth: 420 }}>{ file && file.name && file.name }</Chip>
              <Button onClick={handleDeleteFile} variant="outlined" size="sm" color="danger">Delete</Button>
            </ListItem>
          </List>
          }

          { file && (fileType === "video/mp4" || fileType === "mp4" || fileType === "mov" || fileType === "avi") &&
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createTranscript} onChange={() => setCreateTranscript(!createTranscript)} />
                      <Box sx={{ mt: .75, display: 'flex', alignItems: 'center' }}>
                        <FormLabel sx={{  mr: 1, ml: 1 }}>Create AI Generated Transcript</FormLabel>
                        <AiMagicButton />
                      </Box>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          }
          
          { file && (fileType === "doc" || fileType === "docx" || fileType === "pdf") &&
            <>
            <Box sx={{ background: 'radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 6px, transparent 6px) 0% 0%/7px 7px no-repeat, radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 6px, transparent 6px) 100% 0%/7px 7px no-repeat, radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 6px, transparent 6px) 0% 100%/7px 7px no-repeat, radial-gradient(circle at 0 0, #ffffff 0, #ffffff 6px, transparent 6px) 100% 100%/7px 7px no-repeat, linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 2px) calc(100% - 14px) no-repeat, linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 14px) calc(100% - 2px) no-repeat, linear-gradient(90deg, #7451a2 0%, #A778E4 100%)', borderRadius: 7, p: 1.75, boxSizing: 'border-box' }}>
              <Typography level="title-sm" sx={{ color: '#7451a2', fontWeight: 600, mb: 1.5 }}>
                <AiMagicButton /> AI Magic
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createSummary} onChange={() => setCreateSummary(!createSummary)} />
                      <FormLabel sx={{ ml: 1, mt: 1, mr: 2, mb: 1 }}>AI summary</FormLabel>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createPersonas} onChange={() => setCreatePersonas(!createPersonas)} />
                      <FormLabel sx={{ ml: 1, mt: 1, mr: 2, mb: 1 }}>AI personas</FormLabel>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createJourneys} onChange={() => setCreateJourneys(!createJourneys)} />
                      <FormLabel sx={{ ml: 1, mt: 1, mr: 2, mb: 1 }}>AI journeys</FormLabel>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createSitemap} onChange={() => setCreateSitemap(!createSitemap)} />
                      <FormLabel sx={{ ml: 1, mt: 1, mr: 2, mb: 1 }}>AI sitemap</FormLabel>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createPages} onChange={() => setCreatePages(!createPages)} />
                      <FormLabel sx={{ ml: 1, mt: 1, mr: 2, mb: 1 }}>AI pages</FormLabel>
                    </Box>
                  </FormControl>
                  <FormControl sx={{ mb: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                      <Checkbox size="sm" checked={createFeatures} onChange={() => setCreateFeatures(!createFeatures)} />
                      <FormLabel sx={{ ml: 1, mt: 1, mr: 2, mb: 1 }}>AI features</FormLabel>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            </>
          }

          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Button 
              variant={isDisabled || isLoading ? "outlined" : "solid"} 
              color={isDisabled || isLoading ? "disabled" : "primary"} 
              disabled={isDisabled || isLoading} 
              onClick={handleUploadImage} 
              type="submit"
              size="sm"
              sx={{ mr: 2 }}
            >
              {isLoading ? <InlineLoader width={24} height={24} loading={true} /> : 'Upload file'}
            </Button>
          </Box>
      </ModalDialog>
    </Modal>
  )

}

export default FileUpload;
