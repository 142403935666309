// Import the functions you need from the SDKs you need
import Firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/firestore'
import { getStorage } from 'firebase/storage'
import { getVertexAI, getGenerativeModel } from "firebase/vertexai-preview";

// TODO: Add SDKs for Firebase prod

// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
if (!Firebase.apps.length) {
  // Firebase.initializeApp(firebaseConfig)
  const app = Firebase.initializeApp(firebaseConfig)

  // Initialize the Vertex AI service
  const vertexAI = getVertexAI(app);
  console.log("vertexAI", vertexAI)

  // Initialize the generative model with a model that supports your use case
  // Gemini 1.5 models are versatile and can be used with all API capabilities
  const model = getGenerativeModel(vertexAI, { model: "gemini-1.5-flash" });
  console.log("model", model)
}

export function firebaseApp() {
  return Firebase.app()
}

export function firestore() {
  return Firebase.firestore()
}

export const auth = Firebase.auth(); // Include the auth module for Firebase Authentication

export const storage = getStorage(Firebase.app())

export default Firebase