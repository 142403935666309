import React from 'react';
import { Modal, ModalDialog, ModalClose, Button, Stack, DialogTitle, DialogContent } from '@mui/joy';
import { useContextualPane } from '../../../../contexts/contextualPane';
import PersonaActiveItemContent from './PersonaActiveItemContent';
import FeedbackSnackbar from '../../../Snackbar';

export default function ManageAssociationModal() {
    const { 
        manageAssociationModalOpen, 
        setManageAssociationModalOpen,
        itemPropsAvailableForIncites,
        activeItem,
    } = useContextualPane();

    return (
        <>
        <React.Fragment>
            <Modal open={!!manageAssociationModalOpen} onClose={() => setManageAssociationModalOpen(false)}>
                <ModalDialog sx={{ p: 6, width: 700 }} layout='center'>
                    <ModalClose />
                    {/* Was just when activeItem.type === persona, however, this should work for all types */}
                    { itemPropsAvailableForIncites && activeItem && (
                        <PersonaActiveItemContent />
                    )}
                </ModalDialog>
            </Modal>
        </React.Fragment>
        </>
    )
}