import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, Grid, Button, Modal, Sheet, ModalClose, Stack, FormControl,  FormLabel, FormHelperText, Radio, Drawer } from '@mui/joy';
import { Resizable } from "re-resizable";
import { useJourneys } from '../../../../../contexts/journeysContext';
import { useDroppable } from '@dnd-kit/core';
import { closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { useSensor, useSensors } from '@dnd-kit/core';
import { PointerSensor } from '@dnd-kit/core';
import { KeyboardSensor } from '@dnd-kit/core';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import AddRowBtn from '../../AddRowBtn';
import AddColumnBtn from '../../AddColumnBtn';
import TableHeader from '../../../TableHeader';
import AddElement from '../../../AddElement';
import ColumnDropZone from '../../../ColumnDropZone';
import JourneyStepCard from '../../JourneyStepCard/JourneyStepCard';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import WidthFullIcon from '@mui/icons-material/WidthFull';
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import { DndContext } from '@dnd-kit/core';
import JourneyLinks from '../../JourneyLinks/index';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TriggerContextualMenu from '../../../../Buttons/TriggerContextualMenu';
import DrawerRight from '../../../../DrawerRight';
import { useDrawerRight } from '../../../../../contexts/drawerRightContext';
import BrowseTypes from '../../../../DrawerRight/BrowseTypes';

const CustomHandle = props => (
  <Box
    sx={(theme) => ({
      borderRadius: '2px',
      borderLeft: `1px solid ${theme.palette.primary.outlinedBorder}`,
      height: '100%',
      width: '100%',
      pr: 4,
    })}
    className={'SomeCustomHandle'}
    {...props}
  />
);

const contextualMenu = () => {
  return (
    <Typography>
      <p>Contextual Menu</p>
    </Typography>
  )
} 

export default function UserJourneyGrid({ columnsOffset, setColumnsOffset, viewPane, setViewPane, initialData, setInitialData, columns, columnsSpan, defaultWidth }) {

  const [ openContext, setOpenContext ] = React.useState(false)

  const {
    dragMode, 
    setDragMode, 
    handleAddElementClick, 
    handleNewRowTypeValue, 
    validateAndSubmitNewRowValue, 
    handleAddColumn, 
    chooseNewRowType,
    updateRow, 
    updateComponent, 
    handleRemove, 
    handleDragStart, 
    handleDragEnd, 
    handleResizeStop,
    handleIncreaseColumnWidth, 
    handleDecreaseColumnWidth,
    increaseWidth,
    decreaseWidth,
    showRowTypeModal,
    setShowRowTypeModal,
    newRowType, 
    zoom,
    resizableRef,
    flattenedItems, 
    columnIds,
    rowIds,
    setOverDropZone,
    dropZoneVisible,
    hoveredCell, setHoveredCell,
    setColumns,
  } = useJourneys();

  const { drawerRightOpen, setDrawerRightOpen } = useDrawerRight();

  const BottomRightHandle = () => (
    <CustomHandle>
      <DragIndicatorIcon />
    </CustomHandle>
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const animateColumns = {
    transition: 'width 0.3s ease-in-out',
  };

  return (
    <>
      <Box sx={{ zIndex: 9999, position: 'absolute', bottom: 180, right: 50 }}>
        {/* TO DO the zoomin and out needs to affect the height and width of parent container in order to be useful as it doesnt show anyymore content */}
        {/* <IconButton onClick={handleZoomIn}><ZoomInIcon /></IconButton>
        <IconButton onClick={handleZoomOut}><ZoomOutIcon /></IconButton> */}
        <IconButton onClick={decreaseWidth}><WidthNormalIcon /></IconButton>
        <IconButton onClick={increaseWidth}><WidthFullIcon /></IconButton>
      </Box>
      <Box sx={{ overflowX: 'auto', width: '100vw', height: '100vh', overflowY: 'auto', transform: `scale(${zoom})`, transition: 'transform 0.3s ease-in-out' }}>  
        <Resizable 
          // defaultSize={{ width: '97vw', height: '100vh' }} 
          ref={resizableRef}
          defaultSize={{
            width: defaultWidth,
            height: '100vh',
          }}
          enable={{ right: true, bottomRight: true, topRight: true }}
          handleComponent={{
            right: <BottomRightHandle />,
          }}
          handleResizeStop={handleResizeStop}
          sx={{
            display: 'flex',
            alignItems: 'center',
            pr: 5,
            justifyContent: 'center',
            
          }}
        >
          <DndContext 
            sensors={sensors} 
            collisionDetection={closestCenter} 
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
            modifiers={ dragMode === "column" && [restrictToHorizontalAxis] || dragMode === "row" && [restrictToVerticalAxis] }
          >
            <SortableContext 
              items={
                dragMode === "item" && flattenedItems || 
                dragMode === "column" && columnIds || 
                dragMode === "row" && rowIds 
              }
              strategy={
                dragMode === "item" && rectSortingStrategy ||
                dragMode === "column" && horizontalListSortingStrategy ||
                dragMode === "row" && verticalListSortingStrategy                
              }
              >
              <Grid container style={animateColumns} columns={{ md: columns }} sx={{ pr: 5 }}>
                {/* Table Headers */}
                <Grid item xs={columnsSpan}></Grid>
                {/* Top Headers */}
                {initialData && initialData.length > 0 && initialData.filter(row => row && row.type && row.type === 'rowHeaders').map((row, rowIndex) => (
                  row.children.map((column, colIndex) => (
                    //console.log("NEW COLUMN: ", column.columnsSpan ? column.columnsSpan : columnsSpan),
                    <Grid item xs={column.columnsSpan ? column.columnsSpan : columnsSpan} style={animateColumns} key={`grid_${column.id}`}>
                      <TableHeader rowData={column} setDragMode={setDragMode} columns={columns} setColumns={setColumns} style={animateColumns} columnsSpan={columnsSpan} initialData={initialData} setInitialData={setInitialData} updateRow={updateRow} row={false} rowIndex={rowIndex} colIndex={colIndex} key={column.id} id={column.id} title={column.title} />
                    </Grid>
                    // <Box className="resize-col" sx={(theme) => ({ position: 'absolute', backgroundColor: `${theme.palette.primary.outlinedBorder}`, mr: 1, right: 0, top: 10, bottom: 10, width: '2px', cursor: 'col-resize' })} />
                  ))
                ))}
                
                <Grid item xs={columnsSpan}>
                  <AddColumnBtn handleAddColumn={handleAddColumn} />
                </Grid>

                {/* Rows */}
                { initialData && initialData.length > 0 && initialData.filter(row => row && row.type && row.type === 'rowHeader').map((row, rowIndex) => {
                  //console.log("NEW ROW: ", row)
                  return (
                    <>
                      <Grid item xs={columnsSpan} sx={(theme) => ({  })}>
                        <TableHeader rowData={row} setDragMode={setDragMode} style={animateColumns} columns={columns} setColumns={setColumns} columnsSpan={columnsSpan} initialData={initialData} setInitialData={setInitialData} rowIndex={rowIndex} colIndex={0} updateRow={updateRow} row={true} id={row.id} title={row.title} />
                      </Grid>
                      {
                        row.children && row.children.map((rowColumn, colIndex) => (
                          //console.log("NEW COLUMN: ", rowColumn),
                          <Grid sx={{ pb: 5 }} item style={animateColumns} xs={columnsSpan} key={rowColumn.id} onMouseEnter={() => setHoveredCell({ rowIndex, colIndex })} onMouseLeave={() => setHoveredCell(null)}>
                            { rowColumn && rowColumn.children && rowColumn.children.length > 0 && rowColumn.children.map((component, componentIndex) => {
                              //console.log("NEW COMPONENT: ", component)
                              return (
                                <>
                                <Box sx={{ position: 'relative' }}>
                                  {/* TO DO Add other types of card based on the row types */}
                                  { component.type === "journeyStepCard" &&
                                  <JourneyStepCard 
                                    key={component.id}
                                    id={component.id}
                                    satisfaction={component.satisfaction} 
                                    title={component.title} 
                                    linkTitle={component.linkTitle ? component.linkTitle : ''}
                                    url={component.link ? component.link : ''}
                                    handleRemove={handleRemove}
                                    description={component.description} 
                                    colIndex={colIndex}
                                    rowIndex={rowIndex}
                                    componentIndex={componentIndex}
                                    updateComponent={updateComponent}
                                  />
                                  }
                                  { component.type === "journeyLinkCard" &&
                                  <p>TO DO journey link card</p>
                                  }
                                </Box>
                                </>
                              )                              
                            })}
                            <Box sx={{ position: 'relative' }}>
                              { dropZoneVisible &&
                                <Box className="fade-in">
                                  <ColumnDropZone setOverDropZone={setOverDropZone} id={`${rowIndex}_${colIndex}`} key={`${row.id}_${rowColumn.id}`} />
                                </Box>
                              }
                              { hoveredCell && hoveredCell.rowIndex === rowIndex && hoveredCell.colIndex === colIndex && 
                                <AddElement types={row.types} rowIndex={rowIndex} colIndex={colIndex} handleAddElementClick={handleAddElementClick} />
                              }
                            </Box>
                          </Grid>
                        ))
                      }
                      
                      <Grid item xs={columnsSpan}>
                        &nbsp;
                      </Grid>
                    </>
                  );
                })}

                {/* Add Row Button */}
                <Grid item xs={columnsSpan}>
                  <AddRowBtn chooseNewRowType={chooseNewRowType} />
                </Grid>
              </Grid>

              <DrawerRight content={() => <BrowseTypes setViewPane={setViewPane} />} />

            </SortableContext>
          </DndContext>
        </Resizable>
      </Box>
      <Box>
        <TriggerContextualMenu bottom={230} open={drawerRightOpen} setOpen={setDrawerRightOpen} />
      </Box>
      <React.Fragment>
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-desc"
          open={showRowTypeModal}
          onClose={() => setShowRowTypeModal(false)}
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Sheet
            variant="outlined"
            sx={{
              maxWidth: 500,
              borderRadius: 'md',
              p: 3,
              boxShadow: 'lg',
            }}
          >
            <ModalClose variant="plain" sx={{ m: 1 }} />
            <Typography
              component="h2"
              id="modal-title"
              level="h4"
              textColor="inherit"
              fontWeight="lg"
              mb={1}
            >
              Which type of row would you like to add?
            </Typography>
            <Stack direction="column" spacing={2} sx={{ mt: 3, mb: 1 }}>
              <FormControl onChange={handleNewRowTypeValue} sx={{ p: 2, flexDirection: 'row', gap: 2 }}>
                <Radio 
                  id="rdo1"
                  overlay 
                  value="journeyStepCard" 
                  checked={newRowType === 'journeyStepCard'}
                />
                <div>
                  <FormLabel sx={{ fontWeight: 'bold' }}>Experience Detail</FormLabel>
                  <FormHelperText sx={{ my: 1.5, mb: 3 }}>Detail an item in the experience with a title, description and a changable icon.</FormHelperText>
                  <Box sx={{ ml: -1 }}>
                    <JourneyStepCard illustrationOnly={true} />
                  </Box>
                </div>
              </FormControl>
              <FormControl onChange={handleNewRowTypeValue} sx={{ p: 2, flexDirection: 'row', gap: 2 }}>
                <Radio 
                  id="rdo2"
                  overlay 
                  value="journeyLinkCard" 
                  checked={newRowType === 'journeyLinkCard'}
                />
                <div>
                  <FormLabel sx={{ fontWeight: 'bold' }}>Journey Link</FormLabel>
                  <FormHelperText sx={{ my: 1.5, mb: 3 }}>Reference any internal or external link.</FormHelperText>
                  <Box sx={{ ml: -1 }}>
                    <JourneyLinks illustrationOnly={true} />
                  </Box>
                </div>
              </FormControl>
              {/* TO DO: Add other options as per below */}
            </Stack>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={validateAndSubmitNewRowValue} size="md" variant="solid">
                Continue
              </Button>
            </Box>
            {/* TO DO Add types here */}
            {/* TO DO EXPERIENCE LINE */}
            {/* TO DO IMAGES */}
            {/* TO DO TOUCHPOINTS */}
            {/* TO DO HIGHLIGHTS */}
            {/* TO DO Add LINKS, QUOTES and TOUCHPOINTS to JourneyStepCard */}
            <JourneyLinks />
          </Sheet>
        </Modal>
      </React.Fragment>
    </>
  )

}