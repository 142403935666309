import React, { useState, createContext, useContext } from 'react';
import { useAuth } from '../../authContext'; 
import { retrieveBoardsFromFirestore, fetchBoardGroups, createMissingDefaultTagBoard, fetchTagBoardAndGroupsAndTagsWithId, fetchTagsInBoardGroup } from '../database/tags-db';
import { useParams } from 'react-router-dom';
import { useLoading } from './loadingContext';
import { set } from 'date-fns';

// Create the context
const BoardContext = createContext();

// Create a provider component
export function BoardProvider({ children }) {

  const [boards, setBoards] = useState([]);
  const [boardsGroupsTags, setBoardsGroupsTags] = useState([]); // [ {boardId, groupId, tagId}
  const { running, setRunning } = useLoading();

  const { currentUser } = useAuth();
  const { accountId, selectedProjectId, boardId } = useParams();

  const fetchBoards = async () => {
    try {
      let allBoards = await retrieveBoardsFromFirestore({accountId, projectId: selectedProjectId});
      if (allBoards && allBoards.length > 0) {
        // Fetch the boardGroups for each board and populate each board with boardGroups data
        const boardsWithGroupsAndTags = await Promise.all(allBoards.map(async board => {
          const boardGroups = await fetchBoardGroups({boardId: board.id, accountId});
          
          // Loop through each boardGroup and fetch the tags for each boardGroup
          const boardGroupsWithTags = await Promise.all(boardGroups.map(async boardGroup => {
            const tags = await fetchTagsInBoardGroup({
              boardId: board.id,
              accountId,
              projectId: selectedProjectId,
              groupId: boardGroup.id
            });
            return { ...boardGroup, tags: tags || [] };
          }));
          
          // Return the board with nested boardGroups and tags
          return { ...board, boardGroups: boardGroupsWithTags };
        }));
        
        setBoards(boardsWithGroupsAndTags);
        return boardsWithGroupsAndTags; // Return the nested structure
      }
      return allBoards; // Return empty array if no boards found
    } catch (error) {
      console.error('Error fetching tagBoards from Firestore:', error);
      throw error;
    }
  };

  const handleFetchBoards = async () => {
    setRunning(true)
    try {
      const data = await fetchBoards();
      // console.log("tagBoards fetched", data);
      if (data.length === 0) {
        //console.log("No tagBoards found, creating default tagBoard")
        if (!running) {
          //console.log("Not setting board")
          try {
            const result = await createMissingDefaultTagBoard({user: currentUser, accountId, projectId: selectedProjectId})
            //console.log("result of creating missing default tag board", result)
            setBoards([result])
          } catch (error) {
            console.error('Error creating missing default tag board:', error);
          }
        }
      } else {
        //console.log("Setting board", data)
        setBoards(data)
      }
      setRunning(false)
    } catch (error) {
      console.error('Error fetching tagBoards from Firestore:', error);
      setRunning(false)
    }
  }

  const handleFetchBoardsGroupsTags = async () => {
    try {
      setRunning(true)
    
      // Loop through boards and call fetchTagBoardAndGroupsAndTagsWithId for each board
      const boardsGroupsTags = await Promise.all(boards.map(async board => {
        const boardGroupsTags = await fetchTagBoardAndGroupsAndTagsWithId({accountId, projectId:selectedProjectId, boardId:board.id})
        return { ...boardGroupsTags, id: board.id }
      }))
      if (boardsGroupsTags && boardsGroupsTags.length > 0) {
        setBoardsGroupsTags(boardsGroupsTags)
      }    
    } catch (error) {
      console.error("Error fetching boards, groups, and tags:", error);
    } finally {
      setRunning(false)
    }
  }

  React.useEffect(() => {
    //console.log("boards changed", boards)
    handleFetchBoardsGroupsTags()
  },[boards])

  React.useEffect(() => {
    //console.log("selectedProjectId changed", selectedProjectId)
    handleFetchBoards()
  },[selectedProjectId])

  React.useEffect(() => {
    //console.log("boardId changed", boardId)
    if (!running) {
      handleFetchBoards();
    }
  },[boardId])

  React.useEffect(() => {
    // Onload fetch the boards from firestore
    if (!running) {
      retrieveBoardsFromFirestore({accountId,projectId:selectedProjectId})
      handleFetchBoards();
    }
  },[])

  const value = {
    boards,
    setBoards,
    handleFetchBoards,
    handleFetchBoardsGroupsTags,
    boardsGroupsTags
  };

  return <BoardContext.Provider value={value}>{children}</BoardContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useBoards() {
  return useContext(BoardContext);
}
