import React, { useState, createContext, useContext, useEffect } from 'react';
import { useAuth } from '../../authContext'; 
import { useParams, useNavigate } from 'react-router-dom';
import { retrieveAllForProjectAndType } from '../database/search-db';
import { addAssociationToFirestore, retrieveAllDefinitionsForId, updateCitationInStrongAssociation } from '../database/associations-db';
import { set } from 'date-fns';
import saveToFirestore from '../database/features-db';
import { BASE_PRIVATEAPP_URL } from '../../redirects/index';
import saveNoteToFirestore from '../database/notes-db';

// Create the context
const ContextualPaneContext = createContext();

// Create a provider component
export function ContextualPaneProvider({ children }) {

  const [ activeItem, setActiveItem ] = useState({});
  const [ manageAssociationModalOpen, setManageAssociationModalOpen ] = useState(false);
  const [ secondaryAssociationMode, setSecondaryAssociationMode ] = useState(false);
  const [ itemPropsAvailableForIncites, setItemPropsAvailableForIncites ] = useState(null);
  const [ newDraftAssociation, setNewDraftAssociation ] = useState({});
  const [ secondNewDraftAssociation, setSecondNewDraftAssociation ] = useState({});
  const [ progressStep, setProgressStep ] = useState(0);
  const [ searchResultsForAssociation, setSearchResultsForAssociation ] = useState([]);
  const [ alertMsg, setAlertMsg ] = useState("")
  const [ alert, setAlert ] = useState(false)
  const [ filterAssociationsByType, setFilterAssocitaionsByType ] = useState('strong');
  const [ listAssociationResults, setAssociationListResults ] = useState([])
  const [ listFilteredAssociationResults, setFilteredAssociationListResults ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ createDocsOpen, setCreateDocsOpen ] = useState(false)
  const [ quickAddMode, setQuickAddMode ] = useState('feature');
  const [ selectedItemInstance, setSelectedItemInstance ] = useState({ item: {}, type: '' })
  const [ associationIsFiltered, setAssociationIsFiltered ] = useState(false)

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')
  
  const { currentUser } = useAuth();
  const { accountId, selectedProjectId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // console.log("activeItem ", activeItem)
    // Refetch the latest citations and definitions for the active item
    if (activeItem && activeItem.item) {
      handleFetchOfAssociationTypes()
    }
  },[activeItem])
  
  useEffect(() => {
    //console.log("filterAssociationsByType ", filterAssociationsByType)
    if (filterAssociationsByType === null) {
      setFilterAssocitaionsByType('strong')
    }
  },[filterAssociationsByType])

  useEffect(() => {
    //console.log("itemPropsAvailableForIncites ", itemPropsAvailableForIncites)
  },[itemPropsAvailableForIncites])

  useEffect(() => {
    // console.log("newDraftAssociation ", newDraftAssociation)
  },[newDraftAssociation])

  useEffect(() => {
    //console.log("secondNewDraftAssociation ", secondNewDraftAssociation)
  },[secondNewDraftAssociation])

  useEffect(() => {
    // console.log("listAssociationResults ", listAssociationResults)
  },[listAssociationResults])

  useEffect(() => {
    if (newDraftAssociation.associationType === "strong" && newDraftAssociation.toType) {
        handleFetchOfAssociationSearchResults(newDraftAssociation.toType)
    } 
    else if (newDraftAssociation.associationType === "strong" && newDraftAssociation.fromType) {
        handleFetchOfAssociationSearchResults(newDraftAssociation.fromType)
    }
    // } else if (newDraftAssociation.associationType === "citation" && newDraftAssociation.toType) {
    //     handleFetchOfAssociationSearchResults(newDraftAssociation.toType)
    // }
    else if (newDraftAssociation.associationType === "citation" && newDraftAssociation.fromType) {
        handleFetchOfAssociationSearchResults(newDraftAssociation.fromType)
    }
  },[newDraftAssociation.toType, newDraftAssociation.fromType])

  useEffect(() => {
    if (secondNewDraftAssociation.associationType === "strong" && secondNewDraftAssociation.toType) {
        handleFetchOfAssociationSearchResults(secondNewDraftAssociation.toType)
    } else if (secondNewDraftAssociation.associationType === "citation" && secondNewDraftAssociation.fromType) {
        handleFetchOfAssociationSearchResults(secondNewDraftAssociation.fromType)
    } else if (secondNewDraftAssociation.associationType === "citation" && secondNewDraftAssociation.toType) {
        handleFetchOfAssociationSearchResults(secondNewDraftAssociation.toType)
    } else if (secondNewDraftAssociation.associationType === "strong" && secondNewDraftAssociation.fromType) {
        handleFetchOfAssociationSearchResults(secondNewDraftAssociation.fromType)
    }
  },[secondNewDraftAssociation.toType, secondNewDraftAssociation.fromType])

  useEffect(() => {
    if (!manageAssociationModalOpen) {
      // Clear out values every time the modal closes, unless...
      setSecondNewDraftAssociation({})
      setSecondaryAssociationMode(false)
      setProgressStep(0)
      setAlert(false)
      setAlertMsg("")
    }
  },[manageAssociationModalOpen])

  const handleFetchOfAssociationTypes = async () => {
    if (!loading) {
      setLoading(true)
      const result = await retrieveAllDefinitionsForId({accountId, typeId: activeItem.item.id})
      setAssociationListResults(result)
      setFilteredAssociationListResults(result)
      setLoading(false)
    }
  }

  const handleStepTwoContinue = (valueToSubmit) => {
    //console.log("handleStepTwoContinue")
    handleSetNewDraftAssociation({ newValues: { toType: valueToSubmit } })
    setProgressStep(3)
  }

  const handleStepTwoContinueForSecondary = (valueToSubmit) => {
    //console.log("handleStepTwoContinueForSecondary")
    setSecondNewDraftAssociation({ ...secondNewDraftAssociation, toType: valueToSubmit })
    setProgressStep(3)
  }

  const handleStepTwoBack = () => {
    //console.log("handleStepTwoBack")
    setProgressStep(1)
  }

  const handleStepTwoCitationContinue = (valueToSubmit) => {
    //console.log("handleStepTwoCitationContinue")
    handleSetNewDraftAssociation({ newValues: { fromType: valueToSubmit } })
    setProgressStep(3)
  }

  const handleStepTwoCitationContinueSecondary = (valueToSubmit) => {
    //console.log("handleStepTwoCitationContinueSecondary")
    setNewDraftAssociation({ ...newDraftAssociation, fromType: valueToSubmit })
    setProgressStep(3)
}

  const handleFetchOfAssociationSearchResults = async (type, propertyField = null, propertyValue = null) => {
    let results
    if (propertyField && propertyValue) {
      results = await retrieveAllForProjectAndType({ accountId, projectId: selectedProjectId, type, propertyField: propertyField, propertyValue: propertyValue })
    } else {
      results = await retrieveAllForProjectAndType({ accountId, projectId: selectedProjectId, type })
    }
    
    if (results && results.length > 0) {
        setSearchResultsForAssociation(results)
    } else {
        //console.log("No results found for type: ", type)
        setSearchResultsForAssociation([])
        setAlert(true)
        setAlertMsg("You don't currently have any of these items in your project.")
    }
  }

  const handleSetNewDraftAssociation = ({ newValues }) => {
    setNewDraftAssociation({ ...newDraftAssociation, ...newValues })
  }

  const handleAddAssociationClicked = ({ activeItem, itemPropsAvailableForIncites }) => {
    // Clear on 'Add X[type]' and clear on 'Add Association' click
    setNewDraftAssociation({})
    //console.log("add association", activeItem, itemPropsAvailableForIncites)
    // Open modal to add association
    setManageAssociationModalOpen(true)
  }

  const handleSubmitNewAssociationToDb = async (newAssociation) => {
    //console.log("handleSubmitNewAssociationToDb", newDraftAssociation)
    // Save data to associations collection as a new document
    const result = await addAssociationToFirestore({ accountId, project: selectedProjectId, association: { ...newAssociation } })
    //console.log("result of adding association", result)
    // Close the modal
    setManageAssociationModalOpen(false)
    // Refresh results
    await handleFetchOfAssociationTypes()
  }

  const handleAddingTypeAndAssociation = async ({ type, typeValues, association, comment = "" }) => {
    if (type === "feature") {
      // Add feature to feature collection
      const featureResult = await saveToFirestore({ accountId, selectedProjectId, feature: typeValues })
      //console.log("featureResult", featureResult)
      // Strip out secondaryAssociations from association object
      const { secondaryAssociations, ...associationWithoutSecondaryAssociations } = association
      const constructedAssociation = { ...associationWithoutSecondaryAssociations, comment, toId: featureResult.id }
      //console.log("constructedAssociation", constructedAssociation)
      // Save data to associations collection as a new document
      const result = await addAssociationToFirestore({ accountId, project: selectedProjectId, association: constructedAssociation })
      //console.log("result of adding association", result)
      // Loop through secondary associations and add them to the database
      if (secondaryAssociations && secondaryAssociations.length > 0) {
        for (const secondaryAssociation of secondaryAssociations) {
          const constructedSecondaryAssociation = { ...secondaryAssociation, toId: featureResult.id }
          //console.log("constructedSecondaryAssociation", constructedSecondaryAssociation)
          // Save data to associations collection as a new document
          const result = await addAssociationToFirestore({ accountId, project: selectedProjectId, association: constructedSecondaryAssociation })
          //console.log("result of adding association", result)
        }
      }
      // Refetch results
      await handleFetchOfAssociationTypes()
      // Empty out ready for another addition after the above
      setNewDraftAssociation({})
    }
    if (type === "note") {
      // Add note to note collection
      const noteResult = await saveNoteToFirestore({ accountId, selectedProjectId, note: typeValues })
    }
  }

  const handleClickOnHelperLink = () => {
    //console.log("handleClickOnHelperLink")
    if (newDraftAssociation.toType === "persona") {
        navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/personas')
    } else if (newDraftAssociation.toType === "feature") {
        navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/features')
    }
  }

  const checkStrongRelationshipDoesntAlreadyExist = ({fromId, toId, toTitle}) => {
    let result = true
    listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.map((association) => {
      // check whether both fromId and toId already exist in a single association record, even if fromId to toId, or toId is fromId
      if ((association.fromId === fromId && association.toId === toId) || (association.fromId === toId && association.toId === fromId)) {
        result = false
      }
    })
    return result
  }

  const handleStepThreeContinue = (valuesToSubmit) => {
      if (newDraftAssociation.associationType === "strong") {
          // handleSetNewDraftAssociation({ newValues: { toId: valueToSubmit } })
          // Check if a strong relationship between the fromId and the toId already exists
          const result = checkStrongRelationshipDoesntAlreadyExist({ fromId: newDraftAssociation.fromId, toId: valuesToSubmit.id })
          //console.log("result", result)
          if (!result) {
            setShowSnackbar(true)
            setSnackbarMsg("There is already an existing link between these two items. Please select another item.")
            setSnackbarSeverity("warning")
          } else {
            if (newDraftAssociation.toType === "pageset") {
              // Need the user to select a page from the pageset
              // search for all searchItem_page records in accounts/{accountId}/pagesets/{pagesetId} with a pagesetId that matches the valuesToSubmit.id
              handleFetchOfAssociationSearchResults("page", "pagesetId", valuesToSubmit.id)
              setProgressStep(10)

            } else {
              handleSubmitNewAssociationToDb({ ...newDraftAssociation, toId: valuesToSubmit.id, toTitle: valuesToSubmit.title, element: valuesToSubmit.elementSelected })
              setManageAssociationModalOpen(false)
            }
          }          
      } else if (newDraftAssociation.associationType === "citation") {
          handleSetNewDraftAssociation({ newValues: { fromId: valuesToSubmit.id } })
          setManageAssociationModalOpen(false)
      }
      
  }

  const handleStepThreeContinueSecondary = (valuesToSubmit) => {
    //console.log("handleStepThreeContinueSecondary", valuesToSubmit)
    if (newDraftAssociation.associationType === "strong") {
      setNewDraftAssociation({ ...newDraftAssociation, toId: valuesToSubmit.id, toTitle: valuesToSubmit.title })
    } else if (newDraftAssociation.associationType === "citation") {
      setNewDraftAssociation({ ...newDraftAssociation, fromId: valuesToSubmit.id, fromTitle: valuesToSubmit.title })
    }
    setProgressStep(4)
  }

  const handleStepThreeBack = (valueToSubmit) => {
      //console.log("handleStepThreeBack")
      setProgressStep(0)
  }

  const handleStepFourSubmitSecondary = async (comment) => {
    // Construct the secondary association object
    const { fromId, fromType, inReferenceTo, from, to, associationType, ...everyThingElse } = newDraftAssociation
    const citationRestructuredObj = { associationType, referenced: [ { from: { fromId: fromId ? fromId : '', fromType: fromType ? fromType : '' }, to: { toId: to[0].toId, toType: to[0].toType } } ], comment }

    // Save citation to associations collection as a new document
    const result = await addAssociationToFirestore({ accountId, project: selectedProjectId, association: citationRestructuredObj })
    //console.log("result of adding association", result)
    
    // Store secondary association to the existing strong association between the activeItem and the current opened item
    const resultOfUpdateToPrimaryStrong = await updateCitationInStrongAssociation({ accountId, associationId: newDraftAssociation.to[0].toId, association: { citations: [result.id] }})
    //console.log("result of updating primary strong association", resultOfUpdateToPrimaryStrong)

    // Refetch results
    await handleFetchOfAssociationTypes()

    // Close the modal
    setManageAssociationModalOpen(false)
  }

  const handleStepTenContinueSecondary = (valuesToSubmit) => {
    //console.log("handleStepTenContinueSecondary", valuesToSubmit)
    // TO DO when do I need this?
  }

  const handleStepTenContinue = (valuesToSubmit) => {
    if (newDraftAssociation.associationType === "strong") {
      // handleSetNewDraftAssociation({ newValues: { toId: valueToSubmit } })
      // Check if a strong relationship between the fromId and the toId already exists
      const result = checkStrongRelationshipDoesntAlreadyExist({ fromId: newDraftAssociation.fromId, toId: valuesToSubmit.id })
      //console.log("result", result)
      if (!result) {
        setShowSnackbar(true)
        setSnackbarMsg("There is already an existing link between these two items. Please select another item.")
        setSnackbarSeverity("warning")
      } else {
        handleSubmitNewAssociationToDb({ ...newDraftAssociation, toId: valuesToSubmit.id, toTitle: valuesToSubmit.title, toType: "page", element: valuesToSubmit.elementSelected })
        setManageAssociationModalOpen(false)
        setManageAssociationModalOpen(false)
      }
    }    
  }

  const handleResetOfFilteredListAssociationResults = () => {
    setAssociationIsFiltered(false)
    setFilteredAssociationListResults(listAssociationResults)
  }

  const handleUpdateListAssociationResults = (property) => {

    setAssociationIsFiltered(true)

    // Filter the listFilteredAssociationResults by the property
    const filteredResults = listAssociationResults.definitions.filter((association) => {
      return association.toType === property
    })

    setFilteredAssociationListResults({ definitions: filteredResults })
  }

  const value = {
    handleStepTenContinueSecondary,
    handleStepTenContinue,
    activeItem,
    setActiveItem,
    handleAddAssociationClicked,
    manageAssociationModalOpen,
    setManageAssociationModalOpen,
    itemPropsAvailableForIncites,
    setItemPropsAvailableForIncites,
    newDraftAssociation,
    setNewDraftAssociation,
    handleSetNewDraftAssociation,
    searchResultsForAssociation,
    progressStep,
    setProgressStep,
    handleFetchOfAssociationTypes,
    alert,
    setAlert,
    alertMsg,
    showSnackbar, 
    setShowSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMsg, 
    setSnackbarMsg,
    setAlertMsg,
    handleSubmitNewAssociationToDb,
    selectedItemInstance,
    setSelectedItemInstance,
    listAssociationResults,
    filterAssociationsByType, 
    setFilterAssocitaionsByType,
    loading,
    createDocsOpen,
    setCreateDocsOpen,
    handleAddingTypeAndAssociation,
    handleStepTwoContinue,
    handleStepTwoBack,
    handleStepTwoCitationContinue,
    handleClickOnHelperLink,
    handleStepThreeContinue,
    handleStepThreeBack,
    secondaryAssociationMode, 
    setSecondaryAssociationMode,
    secondNewDraftAssociation,
    setSecondNewDraftAssociation,
    handleStepTwoContinueForSecondary,
    handleStepTwoCitationContinueSecondary,
    handleStepThreeContinueSecondary,
    handleStepFourSubmitSecondary,
    quickAddMode,
    setQuickAddMode,
    associationIsFiltered,
    setAssociationIsFiltered,
    listFilteredAssociationResults,
    setFilteredAssociationListResults,
    handleUpdateListAssociationResults,
    handleResetOfFilteredListAssociationResults
  };

  return <ContextualPaneContext.Provider value={value}>{children}</ContextualPaneContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useContextualPane() {
  return useContext(ContextualPaneContext);
}