import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import MessagesPane from '../MessagesPane';
import ChatsPane from './ChatsPane';
import MessageInputComponent from './MessageInputComponent.js';
import ChatsPaneHeader from './ChatsPaneHeader.js';
//import { chats } from '../../data/data';
import { retrieveChatsFromFirestore, saveChatToFirestore } from '../../database/chats-db.js';
import { formatJSON } from '../../utils/utils.js';
import { useAuth } from './../../../authContext';  
import { useParams } from 'react-router-dom';

export default function MyProfile() {
  const {currentUser} = useAuth()
  const {accountId, selectedProjectId} = useParams()
  // Firestore chats
  const [chats, setChats] = React.useState(null)
  // Currently selected chat
  const [selectedChat, setSelectedChat] = React.useState(null);
  // Just the messages of the current chat - the thing that is handed down to the chatBubbles
  const [chatMessages, setChatMessages] = React.useState(null);
  // 
  const [file, setFile] = React.useState("");
  const [fetched, setFetched] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [updating, setUpdating] = React.useState(false)
  const [lastResponse, setLastResponse] = React.useState();

  const updateCurrentChat = ({content, role, json = ""}) => {
    if (chatMessages) {
      const newId = chatMessages.length + 1;
      const newIdString = newId.toString();
      setChatMessages([
          ...chatMessages,
          {
            id: newIdString,
            sender: role,
            content: content,
            json: json,
            created: new Date()
          }
        ]);
    } else {
      // or create one
      const newId = 1;
      const newIdString = newId.toString();
      setChatMessages([{
          id: newIdString,
          sender: role,
          content: content,
          json: json,
          created: new Date()
        }]);
    }
  }

  const newMessageHandler = async ({ role, content }) => {
    updateCurrentChat({content,role})
    setLoading(true)
    const response = await sendMessage(content)
    //console.log("response was ", response)
    
    setLoading(false)
    setLastResponse(response)
  }
  
  const sendMessage = async (message) => {
    //console.log("sending message:",message)
    let options = {}
    if (selectedChat && selectedChat.assistantId) {
      options = {
        method: "POST",
        body: JSON.stringify({
          message: message,
          assistantId: selectedChat.assistantId
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    } else {
      options = {
        method: "POST",
        body: JSON.stringify({
          message: message
        }),
        headers: {
          "Content-Type": "application/json"
        }
      }
    }
    
    // Conditionally add the 'file' property if it's not an empty string
    if (file !== "") {
      //console.log("File is not empty, adding...", file)
      options.body = JSON.stringify({
        ...JSON.parse(options.body), // Parse the existing body to modify it
        file: file,
      });
    }
    try {
      let path = ''
      if (file !== '') {
        path = "http://localhost:8000/assistantwf"
      } else {
        // no file uploaded
        path = "http://localhost:8000/assistant"
      }
      const response = await fetch(path, options)
      const data = await response.json()
      return data
    } catch (error) {
      console.log("error", error)
    }
  }

  React.useEffect(() => {
    if (lastResponse) {
      //console.log("response from server received ", lastResponse)
      // Check if response has JSON, and separate if neccessary
      let content = lastResponse[0].content[0].text.value
      let strippedOutJsonContent = ''
      setSelectedChat({...selectedChat, assistantId: lastResponse.assistantId})
      if (content !== '') {
        const { jsonArray, preJsonText, postJsonText } = formatJSON(content)
        strippedOutJsonContent = preJsonText + '. ' + postJsonText
        if (jsonArray.length > 0) {
          updateCurrentChat({ content: strippedOutJsonContent, role: "assistant", json: jsonArray })
        } else {
          updateCurrentChat({ content: content, role: "assistant", json: jsonArray })
        }
        
      } else {
        // In some cases, the AI returns nothing and this doesn't help
        updateCurrentChat({ content: "Sorry, I'm being rubbish right now.... Can you please try again.", role: "assistant" })
      }      
    }
  },[lastResponse])

  React.useEffect(() => {
    if (fetched) {
      //console.log("chats useEffect called after fetched")
      if (chats && chats.length > 0 && !selectedChat) {
        setSelectedChat(chats[0])
        if (chats[0].messages && chats[0].messages.length > 0) {
          setChatMessages(chats[0].messages)
        }
      }
    }
  },[chats])

  React.useEffect(() => {
    const updateChatsInFirestore = async (chatMessages) => {
      if (chatMessages && chatMessages.length > 1) {
        //console.log("update messages remotely with account id ", accountId)
          if (!updating) {
            setUpdating(true) 
            
            // set the message thread up with a title of the first prompt
            if (chatMessages && chatMessages.length === 2) {
              //console.log("set 1st record")
              const { id, chatTitle, created, updated } = await saveChatToFirestore({ 
                newMessages: chatMessages,
                selectedChat: selectedChat,
                user: currentUser,
                accountId: accountId,
                selectedProjectId: selectedProjectId
              })
              //console.log("Result of updating firestore is ", id, chatTitle, created, updated )
              setSelectedChat({
                id: id, 
                chatTitle: chatTitle, 
                created: created, 
                updated: updated 
              })
              // Refresh results
              const results = await retrieveChatsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
              setChats(results)
            } else if (chatMessages && chatMessages.length > 2) {
              await saveChatToFirestore({ 
                newMessages: chatMessages,
                selectedChat: selectedChat,
                user: currentUser,
                accountId: accountId,
                selectedProjectId: selectedProjectId
              })
              // Refresh results
              const results = await retrieveChatsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
              setChats(results)
            }
            
            setUpdating(false)  
            
          } else {
            //console.log("already updating")
          }
      } else {
        //console.log("chatMessages only has the AI intro, skipping remote update for now")
      }      
    }
    // retrieve latest document of selectedChat
    //console.log("chatMessages updated locally, attempting to update remotely ",chatMessages)
    updateChatsInFirestore(chatMessages)
    // update the selected chat to include the latest chatMessages
    
  },[chatMessages])

  React.useEffect(() => {
    // //console.log("Selected chat is now ", selectedChat)
    // retrieve latest document of selectedChat
    //console.log("selectedChat",selectedChat)
  },[selectedChat])

  React.useEffect(() => {
    //console.log("Selected project changed, refetch notes")
    const fetchData = async () => {
      try {
        const data = await retrieveChatsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId});
        return data;
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        throw error; // Rethrow the error so that the outer catch block can handle it
      }
    };
    if (selectedProjectId) {
      const getData = async () => {
        try {
          const data = await fetchData();
          // if item was deleted, then select something again
          // if item was updated, then maintain selection
          // selectedNote will be emptied on deletion of record, so use this to tell
          //console.log("selectedChat is empty, set to first chat in array", selectedChat)
          if (data.length > 0) {
            setChats(data);
            setSelectedChat(data[0])
          } else {
            setChats([]);
            setSelectedChat([])
          }
        } catch (error) {
          // Handle the error if needed
          //console.log("Error fetching chats", error)
        }
      };
      getData();
    }
  },[selectedProjectId])

  React.useEffect(() => {
    //console.log("Chats is ", chats)
    if (fetched) {
      if (chats) {
        if (chats.length === 0) {
          // there are no notes, create one
          //console.log("there are no chats, create first message")
          setChatMessages([
            {
              sender: 'assistant',
              content: ' I can help you put together strategic, thoughtful product design work faster based on smrt cookie data, uploaded files, as well as my own knowledge. Specifically I help with creating personas, features, user journeys, template lists, sitemaps and more... How can I help today?',
              created: new Date()
            }
          ])
        }
      }
    }
  },[fetched,chats])

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setFetched(true)
        const data = await retrieveChatsFromFirestore({accountId: accountId, selectedProjectId:selectedProjectId});
        return data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const getData = async () => {
        try {
          const data = await fetchData();
          if (data) {
            setChats(data);
          } else {
            //console.log("NO CHATS")
            setChats([])
          } 
        } catch (error) {
          // Handle the error if needed
          //console.log("error calling fetchData", error)
        }
    };

    if (!fetched) {
      getData();
    }
    
  }, []);

  React.useEffect(() => {
    //console.log("file", file)
  },[file])

  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          //sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          sm: '4fr 21fr',
        },
        minHeight: '100dvh',
      }}
      className="ChatMessagesPane"
    >
      <Sheet
        sx={{
          position: {
            xs: 'fixed',
            sm: 'sticky',
          },
          transform: {
            xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
            sm: 'none',
          },
          transition: 'transform 0.4s, width 0.4s',
          zIndex: 100,
          top: 52,
        }}
        md={4}
      >
        { chats &&
          <Sheet
            sx={{
              borderRight: '1px solid',
              borderColor: 'divider',
              height: 'calc(100dvh - var(--Header-height))',
              overflowY: 'auto',
              minHeight: '100dvh',
            }}
            className="ChatsPane"
            >
            <ChatsPaneHeader chats={chats} />
            { selectedChat &&
              <ChatsPane
                chats={chats}
                selectedChatId={selectedChat.id}
                setSelectedChat={setSelectedChat}
              />
            } 
          </Sheet>
        }
      </Sheet>
      <Sheet
        sx={{
          flexDirection: 'column',
          backgroundColor: '#F4F1F8', // Ivory: FFFFF0, Lavender: F4F1F8
          paddingTop: 2,
        }}
      >
        { chatMessages && chatMessages.length > 0 &&
          <MessagesPane loading={loading} setChatMessages={setChatMessages} chatMessages={chatMessages} selectedChat={selectedChat} />
        }
        <MessageInputComponent file={file} setFile={setFile} newMessageHandler={newMessageHandler} chats={chats} chatMessages={chatMessages} setChatMessages={setChatMessages} selectedChat={selectedChat} setSelectedChat={setSelectedChat} />
      </Sheet>      
    </Sheet>
  );
}