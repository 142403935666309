import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { addPropertyToAllDocuments, binNote, deleteNote, retrieveNotesFromFirestore } from '../../database/notes-db';
import { useParams } from 'react-router-dom';
import FeedbackSnackbar from '../Snackbar';
import { useNotes } from '../../contexts/notesContext';
import { usePersonas } from '../../contexts/personasContext';
import { downloadHtml } from '../../utils/downloadUtils';
import { getFileTypeFromFilePath, isManagerAndAbove } from '../../utils/utils';
import { useAuth } from '../../../authContext';
import { Divider } from '@mui/joy';
import Pins from '../Pins';
import { useDrawerRight } from '../../contexts/drawerRightContext';
import DownloadIcon from '@mui/icons-material/Download';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic'; // Select Template icon
import DrawIcon from '@mui/icons-material/Draw'; // Save Template icon
import DeleteIcon from '@mui/icons-material/Delete';
import MakePrivate from '../MakePrivate';
import MakePrivateModal from '../Modals/MakePrivate';
import ProjectModal from '../Navigations/Components/SwitchProject/ProjectModal';
import MoveNote from '../MoveNote';
import Templates from '../Selects/Templates';
import { retrieveTemplatesFromFirestore } from '../../database/saveAsTemplate-db';
import CreateTemplate from '../Modals/CreateTemplate';
import SelectTemplate from '../Modals/SelectTemplate';

export default function NoteMoreOptions({ content }) {

  const { allowEditing, fetchNoteVersions, getAndSetData, noteEditorOpen, handleSave, setNoteEditorOpen, html, handlePinNote } = useNotes()
  const { fetchPersonas, handlePersonaDeletion, personaEditorOpen, setPersonaEditorOpen, handlePinPersona, persona, setPersona } = usePersonas()
  const { currentUser } = useAuth();
  const { accountId, selectedProjectId } = useParams()
  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [makePrivateModal, setMakePrivateModal] = React.useState(false);
  const [saveTemplateModalOpen, setSaveTemplateModalOpen] = React.useState(false);
  const [selectTemplateModalOpen, setSelectTemplateModalOpen] = React.useState(false);
  const [projectModal, setProjectModal] = React.useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')
  const [ versions, setVersions ] = React.useState([]);
  const [ currentVersion, setCurrentVersion ] = React.useState();
  const { toggleDrawer, setReason } = useDrawerRight();
  const [ open, setOpen ] = React.useState(false)
  const [ templates, setTemplates ] = React.useState([])

  const fetchTemplatesData = async () => {
    const result = await retrieveTemplatesFromFirestore({ accountId, selectedProjectId, type: content.type })
    if (result && result.length > 0) {
      setTemplates(result)
    }
  }

  const handleOpenChange = (event, isOpen) => {
    setOpen(isOpen);
  };

  const handlePinNoteClick = () => {
    if (!content) return;

    console.log("handlePinNoteClick", content)

    if (content.type === "note") {
      handlePinNote({ note: content });
    } else if (content.type === "persona") {
      handlePinPersona({ persona: content, trueOrFalse: !content.pinned });
    }
  };

  const handleSaveAsTemplate = () => {
    setSaveTemplateModalOpen(true)
  }

  const handleSelectTemplate = () => {
    setSelectTemplateModalOpen(true)
  }

  const handleTemplateSelected = async ({ selectedData, type }) => {
    if (type === "note") {
      await handleSave({ 
        note: { 
          id: content.id, 
          noteTitle: content.noteTitle, 
          createdBy: content.createdBy, 
          project: content.project, 
          ...selectedData
        },
        setNewCurrentNote: true,
        tagsChanged: false,
        pendingTag: [],
        pinNote: null,
        sortByPinned: false
      })
    }
    if (type === "persona") {
      setPersona({ ...persona, data: selectedData })
    }
    // TO DO handle sitemap and journey
  }

  const handleBinItem = async () => {
    if (content.type === "note" || content.type === "upload" || content.type === "link") {
      try {
        await binNote(content.id, accountId)        
        await getAndSetData({ setNewCurrentNote: true, sortByPinned: false })
        // Close modal
        setNoteEditorOpen(false)
        // Display success message
        setSnackbarSeverity('success')
        setSnackbarMsg('Note moved to bin successfully')
        setShowSnackbar(true)
      } catch (error) {
        console.log("error moving note to bin", error)
        setSnackbarSeverity('error')
        setSnackbarMsg('Error moving note to bin')
        setShowSnackbar(true)
      }
    } else if (content.type === "persona") {
      try {
        await handlePersonaDeletion(content.id)
        // Fetch personas again
        fetchPersonas()
        // Close modal
        setPersonaEditorOpen(false)
        // Display success message
        setSnackbarSeverity('success')
        setSnackbarMsg('Persona deleted successfully')
        setShowSnackbar(true)
      } catch (error) {
        console.log("error deleting persona", error)
        setSnackbarSeverity('error')
        setSnackbarMsg('Error deleting persona')
        setShowSnackbar(true)
      }
    }
  }

  const handleDownloadHTML = () => {
    if (html) {
      downloadHtml(html)
    } else {
      setSnackbarSeverity('error')
      setSnackbarMsg('There has been a problem, please refresh the page and try again')
      setShowSnackbar(true)
    }
  }
  
  const handleDownloadFile = async () => {
    if (content.url) {
      try {
        // Get file extension from the URL or use a default
        const fileExtension = getFileTypeFromFilePath(content.url)
        const fileName = content.noteTitle || 'download';

        if ('showSaveFilePicker' in window) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: `${fileName}.${fileExtension}`,
            types: [{
              description: 'File',
              accept: {
                // Use a more specific MIME type if available, otherwise fallback to 'application/octet-stream'
                [content.mimeType || 'application/octet-stream']: [`.${fileExtension}`]
              }
            }],
          });
          const writable = await fileHandle.createWritable();
          const response = await fetch(content.url);
          await response.body.pipeTo(writable);
        } else {
          // Fallback method remains the same
          const link = document.createElement('a');
          link.href = content.url;
          link.download = `${fileName}.${fileExtension}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        console.error('Error downloading file:', error);
        setSnackbarSeverity('error');
        setSnackbarMsg('Error downloading file. Please try again.');
        setShowSnackbar(true);
      }
    } else {
      setSnackbarSeverity('error');
      setSnackbarMsg('Download URL not available');
      setShowSnackbar(true);
    }
  }

  const handleVersionHistoryClick = async () => {
    setReason('noteVersions');
    const result = await fetchNoteVersions({ accountId, noteId: content.id });
    if (result) {
      setVersions(result);
      setCurrentVersion(result[0]);
    }
    toggleDrawer();
  }

  React.useEffect(() => {
    fetchTemplatesData()
  }, [content])
  
  return (
    <>
    <Dropdown open={open} onOpenChange={handleOpenChange}>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'md' } }}
      > 
        { open ? <CloseIcon /> : <MenuIcon /> }
      </MenuButton>
      <Menu size="sm" placement="left" sx={{ zIndex: 9999 }}>
        <List>
          {(noteEditorOpen || personaEditorOpen) &&
          <>
            <ListItem>
              <ListItemButton onClick={() => handlePinNoteClick()}>
                <ListItemDecorator><Pins noteToDisplay={content} /></ListItemDecorator>
                <ListItemContent>Pin</ListItemContent>
              </ListItemButton>
            </ListItem>
            <Divider sx={{ mb: 1 }} />
          </>
          }
          {/* Lock note */}
          <MakePrivate item={content} makePrivateModal={makePrivateModal} setMakePrivateModal={setMakePrivateModal} />
          {/* Move note */}
          <MoveNote content={content} setProjectModal={setProjectModal} />
          {(isManagerAndAbove(currentUser) || content.createdBy === currentUser._delegate.uid) &&
          <ListItem>
            <ListItemButton onClick={handleBinItem}>
              <ListItemDecorator><DeleteIcon /></ListItemDecorator>
              <ListItemContent>{ content.type === 'persona' ? 'Delete' : 'Bin' } { content.type }</ListItemContent>
            </ListItemButton>
          </ListItem>
          }
          {/* Templates */}
          {(content.type === 'note' || content.type === 'persona') && allowEditing && templates.length > 0 &&
          <ListItem>
            <ListItemButton onClick={() => handleSelectTemplate()}>
              <ListItemDecorator><AutoAwesomeMosaicIcon /></ListItemDecorator>
              <ListItemContent>Use template</ListItemContent>
            </ListItemButton>
          </ListItem>
          }
          {(content.type === 'note' || content.type === 'persona') && 
          <ListItem>
            <ListItemButton onClick={() => handleSaveAsTemplate()}>
              <ListItemDecorator><DrawIcon /></ListItemDecorator>
              <ListItemContent>Save as template</ListItemContent>
            </ListItemButton>
          </ListItem>
          }
          {content.type === 'upload' && 
          <ListItem>
            <ListItemButton onClick={() => handleDownloadFile()}>
              <ListItemDecorator><DownloadIcon /></ListItemDecorator>
              <ListItemContent>Download file</ListItemContent>
            </ListItemButton>
          </ListItem>
          }
          {content.type === 'note' && 
          <ListItem>
            <ListItemButton onClick={() => handleDownloadHTML()}>
              <ListItemDecorator><DownloadIcon /></ListItemDecorator>
              <ListItemContent>Download html</ListItemContent>
            </ListItemButton>
          </ListItem>
          }
        </List>
      </Menu>
    </Dropdown>
    <CreateTemplate 
        open={saveTemplateModalOpen} 
        setOpen={setSaveTemplateModalOpen} 
        type={content.type}
        newTypeData={content}
        fetchData={fetchTemplatesData}
    />
    { templates.length > 0 &&
      <SelectTemplate 
          open={selectTemplateModalOpen} 
          setOpen={setSelectTemplateModalOpen} 
          type={content.type}
          options={templates}
          fetchData={fetchTemplatesData}
          onSelectHandler={handleTemplateSelected}
      />
    }
    <ProjectModal open={projectModal} setOpen={setProjectModal} mode="change" content={content} />
    <MakePrivateModal
        item={content}
        makePrivateModal={makePrivateModal}
        setMakePrivateModal={() => setMakePrivateModal()}
    />
    <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
    </>
  );
}
