import React from 'react';
import Modal from '@mui/joy/Modal';
import { Button, Stack, Box, ModalClose } from '@mui/joy';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';
import ListPane from '../../MyNotes/ListPane';
import NoteHeader from '../../MyNotes/NoteHeader';
import { useNotes } from '../../../contexts/notesContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from './../../../../redirects/index';

function BrowseNotes({ open, setOpen, setSubMenu }) {
  const { newSelection, setNewSelection } = useNotes();
  const navigate = useNavigate();
  const { accountId, selectedProjectId, boardId } = useParams() 
  const handleClickOpen = () => {
    // redirect to research page if not already on it
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/')
    setSubMenu(false);
    setNewSelection(false);
    setOpen(false); 
  }
  return (
    <React.Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
      <ModalOverflow>
        <ModalDialog layout="fullscreen" spacing={2}>
          <ModalClose variant="plain" sx={{ m: 1 }} onClick={() => setOpen(false)}/>
          <Box sx={{ mt: 3.5, mb: 1 }}>
            <NoteHeader mode="nav" />
          </Box>
          <ListPane mode="nav" />
          <Button disabled={!newSelection} onClick={() => handleClickOpen()} variant={ newSelection ? "solid" : "plain"} sx={{ width: '95%', position: 'fixed', bottom: 15, zIndex: 1000, right: 15 }}>
            { !newSelection ? "Make a selection" : "Open this" }
          </Button>
        </ModalDialog>
      </ModalOverflow>
      </Modal>
  </React.Fragment>
  );
}

export default BrowseNotes;