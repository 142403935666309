import * as React from 'react';
import { Grid, Modal, ModalDialog } from '@mui/joy';
import FirstStep from './FirstStep';
import Generating from './Generating';
import Generated from './Generated';
import { useProjects } from '../../../contexts/projectsContext';
import { useAiPane } from '../../../contexts/aiPaneContext';

function CreateProject({ open, setOpen }) {

  const { summariseThisTextProvider } = useAiPane()
  const [ modalStep, setModalStep ] = React.useState(0)
  const [ data, setData ] = React.useState(null)
  const [ createdData, setCreatedData ] = React.useState({})
  const [ projectLoading, setProjectLoading ] = React.useState(false)
  const [ summarisedProjectNoteLoading, setSummarisedProjectNoteLoading ] = React.useState(null)
  const [ summarisingFileLoading, setSummarisingFileLoading ] = React.useState(false)
  const [ suggestingTodosLoading, setSuggestingTodosLoading ] = React.useState(false)
  const [ suggestingPersonasLoading, setSuggestingPersonasLoading ] = React.useState(false)
  const [ suggestingUserJourneysLoading, setSuggestingUserJourneysLoading ] = React.useState(false)
  const [ suggestingFeaturesLoading, setSuggestingFeaturesLoading ] = React.useState(false)
  const [ suggestingPagesAndContentLoading, setSuggestingPagesAndContentLoading ] = React.useState(false)
  const [ suggestingNavigationsLoading, setSuggestingNavigationsLoading ] = React.useState(false)
  const [ suggestingUserFlowsLoading, setSuggestingUserFlowsLoading ] = React.useState(false)
  const { handleNewProject } = useProjects()
  
  const handleNewProjectWithGen = async () => {
    // Redirect to loading screen
    setModalStep(1)
    // Set loading states to true
    setProjectLoading(true)
    setSummarisedProjectNoteLoading(true)
    setSummarisingFileLoading(true)
    setSuggestingTodosLoading(true)
    setSuggestingPersonasLoading(true)
    setSuggestingUserJourneysLoading(true)
    setSuggestingFeaturesLoading(true)
    setSuggestingPagesAndContentLoading(true)
    setSuggestingNavigationsLoading(true)

    // Create the project collection record first so there's a project id to work with
    await handleNewProject(data.name, data.description).then(async (projectresult) => {
      setCreatedData((prevData) => ({
        ...prevData,
        ...projectresult
      }));
      setProjectLoading(false)
      
      // TO DO - Ai - handle auto summarise project from the given description, store as note content
      await summariseThisTextProvider(data.description).then((summaryText) => {
        // TO DO - use this to create a new note in the new project
        setSummarisedProjectNoteLoading(false)
        setCreatedData((prevData) => ({
          ...prevData,
          summarisedProjectNote: (prevData.summarisedProjectNote || '') + summaryText
        }));

        // TO DO - Ai - handle other generation tasks
        setSummarisingFileLoading(true)
        setSuggestingTodosLoading(true)
        setSuggestingPersonasLoading(true)
        setSuggestingUserJourneysLoading(true)
        setSuggestingFeaturesLoading(true)
        setSuggestingPagesAndContentLoading(true)
        setSuggestingNavigationsLoading(true)

        // Finally redirect to the generated screen
        setModalStep(2)
      }, (error) => {
        console.error("Error summarising project description", error)
      })

    }, (error) => {
      console.error("Error creating project", error)
    })    

  }

  React.useEffect(() => {
    if (data) {
      console.log("createdData updated: ", createdData)
    }
  },[createdData])

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog layout="fullscreen" sx={{ background: '#F4F1F8' }}>
        <Grid container spacing={3} sx={{ pb: 5, overflow: 'auto' }}>
          { modalStep === 0 && <FirstStep setData={setData} data={data} handleNewProjectWithGen={handleNewProjectWithGen} setOpen={setOpen} /> }
          { modalStep === 1 && 
            <Generating 
              projectLoading={projectLoading}
              summarisedProjectNoteLoading={summarisedProjectNoteLoading}
              summarisingFileLoading={summarisingFileLoading}
              suggestingTodosLoading={suggestingTodosLoading}
              suggestingPersonasLoading={suggestingPersonasLoading}
              suggestingUserJourneysLoading={suggestingUserJourneysLoading}
              suggestingFeaturesLoading={suggestingFeaturesLoading}
              suggestingPagesAndContentLoading={suggestingPagesAndContentLoading}
              suggestingNavigationsLoading={suggestingNavigationsLoading}
              suggestingUserFlowsLoading={suggestingUserFlowsLoading}
            /> 
          }
          { modalStep === 2 && <Generated setOpen={setOpen} data={createdData} /> }
        </Grid>
      </ModalDialog>
    </Modal>
  );
}

export default CreateProject;