import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function retrieveJourneysFromFirestore({accountId,selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/journeys`)
    .where("project", "==", selectedProjectId ? selectedProjectId : '')
    .orderBy("updated", "desc").get()

    const journeys = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      journeys.push({
        id: doc.id,
        title: data.title ? data.title : '',
        project: data.project ? data.project : '',
        description: data.description ? data.description : '',
        persona: data.persona ? data.persona : [],
        items: data.items ? data.items : [],
        columns: data.columns ? data.columns : 14,
        width: data.width ? data.width : 1200,
        columnsSpan: data.columnsSpan ? data.columnsSpan : 2,
        created: data.created ? data.created.toDate() : null,
        updated: data.updated ? data.updated.toDate() : null
      });
    });

    //console.log("Retrieved features from Firestore:", features);
    return journeys;
  } catch (error) {
    console.error("Error retrieving journeys from Firestore:", error);
    return [];
  }
}

export default async function saveToFirestore({ journey, accountId, selectedProjectId }) {  
  const id = journey && journey.id ? journey.id : null 

  console.log("saveToFirestore with id, and journey", id, journey)

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/journeys`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/journeys`).doc(id).update({
          title: journey.title,
          description: journey.description,
          project: selectedProjectId ? selectedProjectId : '',
          persona: journey.persona ? journey.persona : [],
          items: journey.items,
          columns: journey.columns ? journey.columns : 14,
          columnsSpan: journey.columnsSpan ? journey.columnsSpan : 2,
          width: journey.width ? journey.width : 1200,
          updated: new Date(),
        });
        //console.log("Feature updated in Firestore!");
      } catch(error) {
        console.error("Error saving journey to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    try {
      await db.collection(`accounts/${accountId}/journeys`).add({
        title: journey.title, 
        description: journey.description,
        project: selectedProjectId ? selectedProjectId : '',
        items: journey.items,
        persona: journey.persona ? journey.persona : [],
        columns: journey.columns ? journey.columns : 14,
        columnsSpan: journey.columnsSpan ? journey.columnsSpan : 2,
        width: journey.width ? journey.width : 1200,
        created: new Date(),
        updated: new Date(),
      });
      //console.log("New feature saved to Firestore!");
    } catch (error) {
      console.error("Error saving journey to Firestore:", error);
    }
  }
}

export async function deleteJourney({ journeyId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/journeys`).doc(journeyId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Feature deleted from Firestore with ID:", featureId);

      // Optionally, you can return the deleted Feature data if needed
      const deletedJourneyData = snapshot.data();
      return { id: journeyId, ...deletedJourneyData };
    } else {
      console.error("No document found with ID:", journeyId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting journey from Firestore:", error);
    return null;
  }
}

export { saveToFirestore }