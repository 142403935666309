import React, {forwardRef, useState, HTMLAttributes} from 'react';
import classNames from 'classnames';
import { Input } from '@mui/joy';

import {removeIcon} from './icons';
import styles from './Page.module.css';

export const Position = {
  Before: -1,
  After: 1,
};

export const Layout = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
  Grid: 'grid',
};

export const Page = forwardRef(function Page(
  {id, title, index, editingTitleId, active, clone, insertPosition, handleChangeTitleName, layout, onRemove, handlePageTitleClick, style, ...props},
  ref
) {

  const [ draftPageTitle, setDraftPageTitle ] = useState(title);

  return (
    <li
      className={classNames(
        styles.Wrapper,
        active && styles.active,
        clone && styles.clone,
        insertPosition === Position.Before && styles.insertBefore,
        insertPosition === Position.After && styles.insertAfter,
        layout === Layout.Vertical && styles.vertical
      )}
      style={style}
      ref={ref}
    >
      <button className={styles.Page} data-id={id.toString()} {...props} />
      {!active && onRemove ? (
        <button className={styles.Remove} onClick={onRemove}>
          {removeIcon}
        </button>
      ) : null}
      {index != null ? (
        editingTitleId === id ?
        <>
          <Input 
            sx={{ mt: 1 }}
            size="sm"
            placeholder="Page name" 
            value={draftPageTitle}
            onBlur={() => handleChangeTitleName({ id, draftPageTitle })}
            onChange={(e) => setDraftPageTitle(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleChangeTitleName({ id, draftPageTitle });
              }
            }}
          />
        </>
        :
        <span onDoubleClick={() => { handlePageTitleClick({ id }) }} className={styles.PageNumber}>{title}</span>
      ) : null}
    </li>
  );
});