import React from 'react'
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';

export default function CommentTextArea({ handleChange }) {
    return (
        <FormControl sx={{ mt: 2 }}>
            <FormLabel>Your comment</FormLabel>
            <Textarea
                placeholder="Describe why you've made this link..."
                minRows={3}
                onChange={handleChange}
                sx={{
                    minWidth: 300,
                }}
            />
        </FormControl>
    )
}