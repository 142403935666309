import database from '../../configs/firebaseConfig'; 
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';

const db = database.firestore(); // Access Firestore database

export async function retrieveBoardsFromFirestore({accountId,projectId}) {
  //console.log("Retrieving tagBoards from Firestore for accountId", accountId, "and projectId", projectId)
  try {
    const snapshot = await db.collection(`accounts/${accountId}/tagBoards`).where("project","==",projectId).orderBy("updated","desc").get();

    const tagBoards = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      tagBoards.push({
        id: doc.id,
        title: data.title,
      });
    });

    ////console.log("Retrieved tagBoards from Firestore:", tagBoards);
    return tagBoards;
  } catch (error) {
    console.error("Error retrieving tagBoards from Firestore:", error);
    return [];
  }
}

export async function createMissingDefaultTagBoard({user, accountId, projectId}) {

  //console.log("Attempting to create missing default tag board with user", user, "and accountId", accountId)
  try {
    const accountRef = await db.collection('accounts').doc(accountId);
    const accountRefId = accountId;

    const tagBoardCollection = await accountRef.collection('tagBoards').doc();
    const tagBoardDoc = await tagBoardCollection.set({
      title: 'Untitled tag board',
      created: new Date(),
      updated: new Date(),
      lastupdatedBy: user.uid,
      project: projectId,      
    });

    const boardGroupsCollection = await tagBoardCollection.collection('boardGroups').doc();

    await boardGroupsCollection.set({
      title: 'Untitled group',
      created: new Date(),
      updated: new Date(),
      colour: blue,
      lastupdatedBy: user.uid,
      project: projectId, 
    });

    const tagBoard = await tagBoardCollection.get();
    const tagBoardData = tagBoard.data();

    return { ...tagBoardData, id: tagBoard.id }

  } catch (error) {
    console.error("Error creating missing default tag board:", error);
  }
  
}

export async function fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId}) {
  //console.log("Attempting to fetchTagBoardWithId with boardId", boardId, "and accountId", accountId, "and projectId", projectId)
  if (boardId) {
    const tagBoard = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId).get();
    if (tagBoard.exists) {
      let boardData = tagBoard.data(); 
      // Fetch the groups also
      const boardGroups = await db.collection(`accounts/${accountId}/tagBoards/${boardId}/boardGroups`).get();
      if (!boardGroups.empty) {
        const boardGroupsData = boardGroups.docs.map(async (doc) => {
          const data = doc.data();
          // Check if there are tags in the group
          const boardTags = await db.collection(`accounts/${accountId}/tagBoards/${boardId}/boardGroups/${doc.id}/tags`).orderBy("updated","asc").get();
          if (!boardTags.empty) {
            const boardTagsData = boardTags.docs.map((doc) => {
              const data = doc.data();
              return {
                id: doc.id,
                title: data.title,
                colour: data.colour,
                created: data.created,
                updated: data.updated,
                lastupdatedBy: data.lastupdatedBy,
                references: data.references ? data.references : [],
                ...data
              };
            });
            if (boardTagsData) {
              //console.log("boardTagsData", boardTagsData)
              data.tags = boardTagsData;
            }
          }
          return {
            id: doc.id,
            title: data.title,
            colour: data.colour,
            created: data.created,
            updated: data.updated,
            lastupdatedBy: data.lastupdatedBy,
            tags: data.tags,
            references: data.references ? data.references : [],
            ...data
          };
        });
        boardData.boardGroups = await Promise.all(boardGroupsData);
        return boardData
      } else {
        return boardData
      }
    } else {
      console.error("No tagBoard found with boardId ", boardId)
      return []
    } 
  } else {
    console.error("Can't retrieve as there is no or no valid boardId");
    return
  }
}

export async function updateBoard({boardId, accountId, projectId, updatedBy, boardData}) {
  //console.log("Update board attempted with boardId", boardId, "and accountId", accountId, "and projectId", projectId, "and updatedBy", updatedBy._delegate.uid, "and boardData", boardData)
  //console.log("User is", updatedBy._delegate.uid)
  if (boardId && accountId && projectId && updatedBy && boardData) {
    try {
      const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);

      const boardSnapshot = await boardRef.get()
      
      if (boardSnapshot.exists) {
        //console.log("EXISTS")
        const remoteData = boardSnapshot.data();
    
        if (boardData.title !== remoteData.title) {
          await boardRef.update({ title: boardData.title, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
        if (boardData.colour !== remoteData.colour) {
          await boardRef.update({ colour: boardData.colour, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
      }

      return true

    } catch (error) {
      console.error("Error updating board:", error);
    }
  } else {
    console.error("Can't update board as there is missing information");
    return
  }
}

export async function deleteBoard(boardId, accountId) {
  //console.log("Attempting to deleteBoard with boardId", boardId, "and accountId", accountId)
  try {
    const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
    const boardSnapshot = await boardRef.get();

    if (boardSnapshot.exists) {
      await boardRef.delete();
      //console.log("Board deleted from Firestore");

      // Optionally, you can return the deleted note data if needed
      const deletedBoardData = boardSnapshot.data();
      return { id: boardRef.id, ...deletedBoardData };
    } else {
      console.error("No board found with ID:", boardId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting board from Firestore:", error);
    return null;
  }
}

/****************** GROUPS ********************/

export async function createGroupInBoard({boardId, accountId, projectId, updatedBy}) {
  //console.log("Attempting to createGroupInBoard with boardId", boardId, "and accountId", accountId, "and projectId", projectId, "and updatedBy", updatedBy._delegate.uid)
  if (boardId && accountId && projectId && updatedBy) {
    try {
      const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
      const boardGroupsCollection = boardRef.collection('boardGroups').doc();
      const boardGroupsDoc = await boardGroupsCollection.set({
        title: 'Untitled group',
        created: new Date(),
        updated: new Date(),
        colour: blue,
        lastupdatedBy: updatedBy._delegate.uid,
        project: projectId, 
      });
      const boardGroups = await boardGroupsCollection.get();
      const boardGroupsData = boardGroups.data();
      return { ...boardGroupsData, id: boardGroups.id }
    } catch (error) {
      console.error("Error creating group in board:", error);
    }
  } else {
    console.error("Can't create group in board as there is missing information");
    return
  }
}

export async function updateGroupInBoard({boardId, accountId, projectId, updatedBy, groupId, groupData}) {
  //console.log("Attempting to updateGroupInBoard with boardId", boardId, "and accountId", accountId, "and projectId", projectId, "and updatedBy", updatedBy._delegate.uid, "and groupId", groupId, "and groupData", groupData)
  if (boardId && accountId && projectId && updatedBy && groupId && groupData) {
    try {
      const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
      const boardGroupsCollection = await boardRef.collection('boardGroups').doc(groupId);
      const boardGroupsSnapshot = await boardGroupsCollection.get();

      if (!boardGroupsSnapshot.empty) {
        const remoteData = boardGroupsSnapshot.data();
    
        if (groupData.title !== remoteData.title) {
          await boardGroupsCollection.update({ title: groupData.title, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
        if (groupData.colour !== remoteData.colour) {
          await boardGroupsCollection.update({ colour: groupData.colour, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
      }

      return true
    } catch (error) {
      console.error("Error updating group in board:", error);
    }
  } else {
    console.error("Can't update group in board as there is missing information");
    return
  }
}

export async function deleteGroup(boardId, groupId, accountId) {
  //console.log("Attempting to deleteGroup with boardId", boardId, "and groupId", groupId, "and accountId", accountId)
  try {
    const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
    const boardGroupsCollection = await boardRef.collection('boardGroups').doc(groupId);
    const boardGroupsSnapshot = await boardGroupsCollection.get();

    if (boardGroupsSnapshot.exists) {
      await boardGroupsCollection.delete();
      //console.log("Group deleted from Firestore");

      // Optionally, you can return the deleted note data if needed
      const deletedGroupData = boardGroupsSnapshot.data();
      return { id: groupId, ...deletedGroupData };
    } else {
      console.error("No group found with ID:", groupId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting group from Firestore:", error);
    return null;
  }
}

/****************** TAGS ********************/

// Fetch all boardGroups of a given tagBoard id
export async function fetchBoardGroups({boardId, accountId}) {
  //console.log("Attempting to fetchBoardGroups with boardId", boardId, "and accountId", accountId)
  const snapshot = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId).collection('boardGroups').get();
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

// Fetch all tags of a given boardGroup id
export async function fetchTagsInBoardGroup({boardId, accountId, projectId, groupId}) {
  //console.log("Attempting to fetchTagsInBoardGroup with boardId", boardId, "and accountId", accountId, "and projectId", projectId, "and groupId", groupId)
  const snapshot = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId).collection('boardGroups').doc(groupId).collection('tags').get();
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function fetchTagWithIdInGroup({boardId, accountId, projectId, groupId, tagId}) {
  console.log("Attempting to fetchTagWithIdInGroup with boardId", boardId, "and accountId", accountId, "and projectId", projectId, "and groupId", groupId, "and tagId", tagId)
  if (boardId && accountId && projectId && groupId && tagId) {
    try {
      const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
      const boardGroupsCollection = await boardRef.collection('boardGroups').doc(groupId);
      const boardTagsCollection = await boardGroupsCollection.collection('tags').doc(tagId);
      const boardTags = await boardTagsCollection.get();
      const boardTagsData = boardTags.data();
      //console.log("boardTagsData", boardTagsData)
      return { ...boardTagsData, id: boardTags.id }
    }
    catch (error) {
      console.error("Error fetching tag with ID in group:", error);
    }
  } else {
    console.error("Can't fetch tag with ID in group as there is missing information");
    return
  }
}

export async function createTagInGroup({boardId, accountId, projectId, updatedBy, groupId, name}) {
  //console.log("Attempting to createTagInGroup with boardId", boardId, "and accountId", accountId, "and projectId", projectId, "and updatedBy", updatedBy._delegate.uid, "and groupId", groupId)
  if (boardId && accountId && projectId && updatedBy && groupId) {
    try {
      const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
      const boardGroupsCollection = await boardRef.collection('boardGroups').doc(groupId);
      const boardTagsCollection = await boardGroupsCollection.collection('tags').doc();
      const boardTagsDoc = await boardTagsCollection.set({
        title: name ? name : 'Untitled tag',
        created: new Date(),
        updated: new Date(),
        colour: blue,
        groupId: groupId,
        boardId: boardId,
        project: projectId, 
        lastupdatedBy: updatedBy._delegate.uid,
      });
      const boardTags = await boardTagsCollection.get();
      const boardTagsData = boardTags.data();
      return { ...boardTagsData, id: boardTags.id }
    } catch (error) {
      console.error("Error creating tag in group:", error);
    }
  } else {
    console.error("Can't create tag in group as there is missing information");
    return
  }
}

export async function updateTagInGroup({boardId, accountId, projectId, updatedBy, groupId, tagId, tagData, references = [], updateReferences = false}) {
  if (boardId && accountId && projectId && updatedBy && groupId && tagId && tagData) {
    try {
      const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
      const boardGroupsCollection = await boardRef.collection('boardGroups').doc(groupId);
      const boardTagsCollection = await boardGroupsCollection.collection('tags').doc(tagId);
      const boardTagSnapshot = await boardTagsCollection.get();

      if (!boardTagSnapshot.empty) {
        const remoteData = boardTagSnapshot.data();

        if (remoteData.boardId !== boardId) {
          await boardTagsCollection.update({ boardId: boardId, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
        if (remoteData.groupId !== groupId) {
          await boardTagsCollection.update({ groupId: groupId, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
        if (tagData.title !== remoteData.title) {
          await boardTagsCollection.update({ title: tagData.title, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
        if (tagData.colour !== remoteData.colour) {
          await boardTagsCollection.update({ colour: tagData.colour, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }

        if (references && updateReferences) {
          console.log("update references array with ", references)
          await boardTagsCollection.update({ references: references, updated: new Date(), lastupdatedBy: updatedBy._delegate.uid });
        }
      }

      return true
    } catch (error) {
      console.error("Error updating tag in group:", error);
    }
  } else {
    console.error("Can't update tag in group as there is missing information");
    return
  }
}

export async function deleteTag(boardId, groupId, tagId, accountId) {
  //console.log("Attempting to deleteTag with boardId", boardId, "and groupId", groupId, "and tagId", tagId, "and accountId", accountId)
  try {
    const boardRef = await db.collection(`accounts/${accountId}/tagBoards`).doc(boardId);
    const boardGroupsCollection = await boardRef.collection('boardGroups').doc(groupId);
    const boardTagsCollection = await boardGroupsCollection.collection('tags').doc(tagId);
    const boardTagsSnapshot = await boardTagsCollection.get();

    if (boardTagsSnapshot.exists) {
      await boardTagsCollection.delete();

      // Optionally, you can return the deleted note data if needed
      const deletedTagData = boardTagsSnapshot.data();
      return { id: tagId, ...deletedTagData };
    } else {
      console.error("No tag found with ID:", tagId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting tag from Firestore:", error);
    return null;
  }
}

// Check in associations collection for how many records contain a passed noteId as an attribute noteId
export async function checkIfTagIsInUse({accountId, tagId}) {
  //console.log("Attempting to checkIfTagIsInUse with accountId", accountId, "and tagId", tagId)
  try {
    const snapshot = await db.collection(`accounts/${accountId}/associations`).where("toId","==",tagId).get();
    const associations = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      associations.push({
        id: doc.id,
        noteId: data.noteId,
        tagId: data.tagId,
      });
    });
    //console.log("Retrieved associations from Firestore:", associations);
    return { associations, occurances: associations.length }
  } catch (error) {
    console.error("Error retrieving associations from Firestore:", error);
    return [];
  }
}