import { useState } from "react";
import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import Button from '@mui/joy/Button';

export default function FeedbackSnackbar({ openSetting = true, setShowSnackbar, variantSetting =  'solid', colorSetting = 'primary', message = '' }) {
  // variant possibilities: outlined, plain, soft, solid
  // color possibilities: primary, neutral, danger, success, warning
  const handleClose = (event, reason) => {
    setShowSnackbar(false);
  };
  return (
    <Snackbar
      open={openSetting}
      variant={variantSetting}
      color={colorSetting}
      onClose={handleClose}
      sx={{ zIndex: 9999 }}
      startDecorator={<PlaylistAddCheckCircleRoundedIcon />}
        endDecorator={
          <Button
            onClick={() => setShowSnackbar(false)}
            size="sm"
            variant="soft"
            color="neutral"
          >
            Dismiss
          </Button>
        }
    >
      {message && message}.
    </Snackbar>
  )
}