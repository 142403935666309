import { useState } from 'react'
import { Box, Card, Typography, Chip, CardActions, Button } from '@mui/joy';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PlanPoints from '../PlanPoints';
import Pricing from '../Pricing';
import { createSubscription, redirectToStripeCheckout } from '../../../database/plans-db';
import { useAuth } from '../../../../authContext';

function PlanCard({ plan, selected, points, inverted, pricing = null, monthly = null, name = null }) {
  const { currentUser } = useAuth()
  const [ clicked, setClicked ] = useState(false)
  const handleCreatePlan = async ({ priceId }) => {
    setClicked(true)
    await redirectToStripeCheckout()
  }
  return (
    <Card 
      size="lg" 
      variant={ inverted ? 'solid' : 'outlined' } 
      color={ inverted ? 'neutral' : 'primary' }
      invertedColors={inverted}
      sx={{ 
        border: selected ? '3px solid #724F9B' : '2px solid #E8DFF4',
        bgcolor: inverted ? '#724F9B' : 'transparent',
        '&:hover': {
          border: '3px solid',
          borderColor: '#9B7FCC',
        },
      }}
    >
      {/* CHIP */}
      <Chip size="sm" variant={ selected ? 'solid' : 'outlined' } color="neutral">
        {plan === "free" ? (selected ? "YOUR PLAN" : "BASIC PLAN") :
         plan === "paid" ? (selected ? "YOUR PLAN" : "MOST POPULAR") :
         plan === "team" ? (selected ? "YOUR PLAN" : "FOR TEAMS") : null}
      </Chip>
      {/* TITLE */}
      <Typography level="h2">{name}</Typography>
      {/* PRICE */}
      { pricing &&
      <CardActions sx={{ mt: 0, pt: 0 }}>
        <Pricing pricing={pricing} monthly={monthly} />
      </CardActions>
      }
      {/* POINTS */}
      <PlanPoints points={points} />
      {/* SWITCH BUTTON */}
      { pricing && monthly ?
        <Button
          variant={ inverted ? "outlined" : "solid" }
          color={ inverted ? "neutral" : "primary" }
          size="sm"
          loading={clicked}
          disabled={clicked}
          loadingIndicator="Loading..."
          onClick={() => handleCreatePlan({ priceId: pricing.monthly.priceId })}
          endDecorator={<KeyboardArrowRight />}
        >
          { selected ? "Manage" : "Switch" }
        </Button>
        :
        <Button
          variant={ inverted ? "outlined" : "solid" }
          color={ inverted ? "neutral" : "primary" }
          size="sm"
          loading={clicked}
          disabled={clicked}
          loadingIndicator="Loading..."
          onClick={() => handleCreatePlan({ priceId: pricing.annual.priceId })}
          endDecorator={<KeyboardArrowRight />}
        >
          { selected ? "Manage" : "Switch" }
        </Button>
      }
    </Card>
  )
}

export default PlanCard;