import React, { useState } from 'react';
import { Modal, ModalDialog, ModalClose, DialogTitle, DialogContent, CardOverflow, CardActions, Button, Stack, Input, Textarea, Grid } from '@mui/joy';
import { useJourneys } from '../../contexts/journeysContext';
import { useParams } from 'react-router-dom';
import SelectPersonaForJourney from '../Personas/SelectPersonaForJourney';
import SwitchToLinks from '../LinksComponent/components/SwitchToLinks';
import SaveDropdown from '../Buttons/SaveDropdown';
import Templates from '../Selects/Templates';
import { retrieveTemplatesFromFirestore } from '../../database/saveAsTemplate-db';
import JourneyEditorLayout from './components/Layouts';

export default function GridJourneyModal() {
  
    const [ viewPane, setViewPane ] = React.useState(0);
    const { defaultWidth, updateDescriptionOfActiveItem, columns, columnsSpan, handleApplyTemplateToJourney, initialData, setInitialData, handleCreateJourney, fetchJourneys, handleJourneyDeletion, handleUpdateCurrentJourney, open, setOpen, setJourney, activeItem, draftJourney, updateTitleOfActiveItem, columnsOffset, setColumnsOffset } = useJourneys();
    const [ journeyTitle, setJourneyTitle ] = useState("New Journey");
    const [ journeyDescription, setJourneyDescription ] = useState("");
    const { accountId } = useParams()
    const { selectedProjectId } = useParams()
    const [ data, setData ] = useState([])

    const fetchData = async () => {
      const result = await retrieveTemplatesFromFirestore({ accountId, selectedProjectId, type: "journey" })
      if (result){
          setData(result)
      }        
    }
  
    React.useEffect(() => {
        fetchData()
    },[])
  
    const handleJourneyTitleChange = (e) => {
      updateTitleOfActiveItem(e.target.value);
    }
  
    const handleJourneyDescriptionChange = (e) => {
      updateDescriptionOfActiveItem(e.target.value);
    }
  
    const title = activeItem && activeItem.item && activeItem.item.title ? activeItem.item.title : journeyTitle;
    const description = activeItem && activeItem.item && activeItem.item.description ? activeItem.item.description : journeyDescription;
  
    return (
      <>
      <Modal open={!!open}>
        <ModalDialog sx={{ backgroundColor: '#f4f1f8', p: 0 }} layout={'fullscreen'}>
          <ModalClose onClick={ () => { setOpen(!open) } }/>
          <DialogTitle sx={(theme) => ({ p: 3, borderBottom: `1px solid ${theme.palette.primary.outlinedBorder}` })}>
            <Grid container sx={{ flexGrow: 1, pr: 5 }}>
              <Grid item xs={1.2} sx={{  }}>
                <SelectPersonaForJourney />
              </Grid>
              <Grid item xs={6} sx={{ ml: 2 }}>
                <Input defaultValue={title} onChange={handleJourneyTitleChange} size="lg" placeholder="Journey title" />
                <Textarea defaultValue={description} onChange={handleJourneyDescriptionChange} placeholder="Enter a description of this journey" minRows={2} sx={{ fontSize: 14, mt: 2, backgroundColor: 'transparent', border: 'none' }} />
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent sx={{ p: 0, m: 0 }}>
            <JourneyEditorLayout viewPane={viewPane} setViewPane={setViewPane} columnsOffset={columnsOffset} setColumnsOffset={setColumnsOffset} columns={columns} columnsSpan={columnsSpan} data={initialData} setData={setInitialData} width={defaultWidth} />
          </DialogContent>
          <Stack sx={{ p: 3, pt: 0, mt: 0 }}>
            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
              <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', pt: 2 }}>
                <>
                <div style={{ flex: 1 }}>
                  <Button size="lg" variant="outlined" color="neutral" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
                    Close
                  </Button>
                  <Button size="lg" variant='outlined' color="danger" onClick={() => handleJourneyDeletion(activeItem.item)}>
                    Delete journey
                  </Button>
                </div>
                {/* <div style={{ flex: 1 }}>
                  <SwitchToLinks viewPane={viewPane} setViewPane={setViewPane} />
                </div> */}
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                  {/* <Button sx={{ alignSelf: 'flex-end' }} onClick={() => handleUpdateCurrentJourney()} size="lg" variant="solid">
                    Save
                  </Button> */}
                  <Templates type="journey" data={data} fetchData={fetchData} updateDataHandler={handleApplyTemplateToJourney} /> 
                  <Button sx={{ mr: 2 }} variant="outlined" size="lg">
                    Share
                  </Button>
                  { activeItem && activeItem.item &&
                    <SaveDropdown type="journey" fetchData={fetchData} saveHandler={handleUpdateCurrentJourney} fields={{ items: initialData, columns: activeItem.item.columns, columnsSpan: activeItem.item.columnsSpan, width: activeItem.item.width }} id={activeItem && activeItem.item && activeItem.item.id ? activeItem.item.id : null} />
                  }
                </div>
                </>
              </CardActions>
            </CardOverflow>
          </Stack>
        </ModalDialog>
      </Modal>
      </>
    )
}