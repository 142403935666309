import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, IconButton, Input, Switch, Typography, Tooltip } from '@mui/joy';
import { usePersonas } from '../../../../contexts/personasContext';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EditorMenu from '../../../EditerMenu';
import TagPicker from '../../../Tags/Editor/TagPicker';
import { useAuth } from '../../../../../authContext';   
import { updateTagInGroup } from '../../../../database/tags-db';
import AiMagicButton from '../../../Buttons/AiMagic';
import ModalEditorHeader from '../../../Layouts/ModalEditorHeader';

export default function HeaderComponent({ sidePaneOpen, setSidePaneOpen, fields, setName, editChecked, setEditChecked, setPersonaFields }) {

  const { 
    persona,
    name,
    handleUpdateCurrentPersona,
    pendingTag,
    setSelectedTags, 
    setPendingTag,
    selectedTags
  } = usePersonas()

  const { currentUser } = useAuth();

  const handleChangeTitle = (e) => {
    setName(e.target.value);
  }

  useEffect(() => {
    if (editChecked) {
      setSidePaneOpen(false);
    }
  }, [editChecked])

  const handleUpdateTags = async (tags = pendingTag) => {
    setSelectedTags(tags);
    setPendingTag(tags);
    // loop through pendingTag and update the tag in the group
    if (tags && tags.length > 0) {
      console.log('tags', tags);
      tags.forEach(async tag => {
        console.log('outside tag', tag);
        if (tag.boardId && tag.accountId && tag.projectId && tag.groupId && tag.id) {
          console.log('inside tag', tag);
          const hasMatch = (tag.references ?? []).some(item => item.id === persona.id);
          if (!hasMatch) {
            const reference = { type: 'persona', id: persona.id, summaryTitle: name }
            if (tag.references && tag.references.length >= 0) {
              tag.references.push(reference);
            } else {
              tag.references = [reference]
            }
          }
          await updateTagInGroup({ 
            boardId: tag.boardId, 
            accountId: tag.accountId, 
            projectId: tag.projectId,
            groupId: tag.groupId, 
            tagId: tag.id, 
            updatedBy: currentUser,
            tagData: { ...tag },
            references: tag.references,
            updateReferences: true
          });
        }
      });
    }
  }

  const handleSave = () => {
    handleUpdateCurrentPersona({ fields, id: persona.id, name, pendingTag });
  }

  return (
    <>
      <ModalEditorHeader 
        handleSave={handleSave}
        handleUpdateTags={handleUpdateTags}
        pendingTag={pendingTag}
        setPendingTag={setPendingTag}
        selectedTags={selectedTags}
        fields={fields}
        document={persona}
        type="persona"
        name={name}
        editChecked={editChecked}
        handleChangeTitle={handleChangeTitle}
        setSidePaneOpen={setSidePaneOpen}
        sidePaneOpen={sidePaneOpen}
      />
    </>
  );
}