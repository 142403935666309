import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export function matchProductId({ productId, allPlans }) {
  if (!productId || !allPlans) {
    return null;
  }
  return allPlans.find(plan => plan.id === productId);
}

export function matchPriceId({ priceId, allPlans }) {
  if (!priceId || !allPlans) {
    return null;
  }
  const matchingPlan = allPlans.find(plan => plan.prices.some(price => price.id === priceId));
  return matchingPlan ? matchingPlan.prices.find(price => price.id === priceId) : null;
}

export async function checkTeamMembership(accountId, userId) {
  //console.log("attempting check with user:", userId)
  try {
    const teamMemberRef = db.collection(`users`).doc(userId);
    const teamMemberDoc = await teamMemberRef.get();

    if (!teamMemberDoc.exists) {
      console.log('No such team member!');
      return false;
    }

    // Now check if the team member is part of the account
    const teamMemberData = teamMemberDoc.data()

    if (teamMemberData.accountId === accountId) {
      return true
    }
    return false    

  } catch (error) {
    console.error('Error checking team membership:', error);
    return false;
  }
}

export async function checkAccountExists(accountId) {
  try {
    const account = await db.collection(`accounts`).doc(accountId)
    const result = await account.get()
    return result.exists
    
  } catch (error) {
    console.log("error with checking account exists", error)
  }
}

export async function fetchAccountId(uid) {
  const doc = await db.collection('users').doc(uid).get();
  if (doc.exists){
    return doc.data().accountId
  } else {
    return false
  }
}

export async function fetchSelectedProjectId(uid) {
  const doc = await db.collection('users').doc(uid).get();
  if (doc.exists){
    return doc.data().selectedProject
  } else {
    return false
  }
}

export async function checkUserExistsbyEmail(email) {
  try {
    const usersRef = db.collection('users');
    const snapshot = await usersRef.where('email', '==', email).get();

    if (snapshot.empty) {
      //console.log('No matching users.');
      return false;
    }  

    // console.log('User found:', snapshot.docs[0].data());
    return { response: true, data: snapshot.docs[0].data() };
  } catch (error) {
    console.error('Error checking user by email:', error);
  }
}