import React, { forwardRef } from 'react';
import { useState } from "react";
import { Box, Button, Dropdown, IconButton, ListDivider, Menu, MenuButton, MenuItem, Tooltip, Typography } from "@mui/joy";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import TuneIcon from '@mui/icons-material/Tune';
import ToggleCheckmark from "../Buttons/ToggleCheckmark";
import { useNotes } from "../../contexts/notesContext";
import { useAuth } from "../../../authContext";
import TagPicker from "../Tags/Editor/TagPicker";

const FilterContent = forwardRef(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    {...props}
  >
    {children}
  </Box>
));

FilterContent.displayName = 'FilterContent';

function FiltersMenu() {
  const [open, setOpen] = useState(false);
  const { notes, notesFiltered, uploadsFiltered, linksFiltered, handleFilterNotes, handleFilterUploads, handleFilterLinks, handleFilterByUser, userFilters, setUserFilters,setNotesFiltered, setUploadsFiltered, setLinksFiltered, tagsFiltered, handleFilterTags, filteredTags, setFilteredTags, pendingTag, setPendingTag } = useNotes();
  const { currentUser, accountUsers } = useAuth();

  const handleItemClick = (handler) => (event) => {
    event.stopPropagation();
    handler();
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FilterContent>
        <Dropdown open={open}>
          <Tooltip variant="outlined" color="neutral" title={ open ? "Close filters" : "Open filters" } placement="right" arrow>
            <MenuButton 
              slots={{ root: IconButton }} 
              slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }} 
              sx={{ ml: 1 }}
              onClick={() => setOpen(!open)}
            >
              <TuneIcon />
            </MenuButton>
          </Tooltip>
          {/* Filters */}
          <Menu size="sm" placement="bottom-start">
            { currentUser && currentUser.plan === "team" &&
              <>
              {accountUsers && accountUsers.length > 0 && accountUsers.map((user) => (
                <MenuItem key={user.id} onClick={handleItemClick(() => handleFilterByUser(user.id))}>
                  <ToggleCheckmark checked={!userFilters[user.id]} text={user.displayName || user.email} />
                </MenuItem>
              ))}
              <ListDivider />
              </>
            }
            <MenuItem onClick={handleItemClick(handleFilterNotes)}>
              <ToggleCheckmark checked={notesFiltered} text="Notes" />
            </MenuItem>
            <MenuItem onClick={handleItemClick(handleFilterUploads)}>
              <ToggleCheckmark checked={uploadsFiltered} text="Uploads" />
            </MenuItem>
            <MenuItem onClick={handleItemClick(handleFilterLinks)}>
              <ToggleCheckmark checked={linksFiltered} text="Links" />
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button size="xs" color="neutral" variant="plain" onClick={() => { setNotesFiltered(false); setUploadsFiltered(false); setLinksFiltered(false); setUserFilters({}); }}>Reset</Button>
                <Button size="xs" color="primary" variant="solid" onClick={handleClickAway}>Done</Button>
              </Box>
            </MenuItem>
          </Menu>
        </Dropdown>
      </FilterContent>
    </ClickAwayListener>
  );
}

export default FiltersMenu;
