import React from 'react'
import { Box, Grid, Typography } from '@mui/joy'
import PersonaCard from '../PersonaCard'

export default function PersonaNav() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        direction={{ sm: 'row' }}
      >
        <Grid
          xs
          xsOffset={2} // This is for spacing
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
          <PersonaCard persona="Jane" />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
          <PersonaCard persona="Silvia" />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
          <PersonaCard persona="Jimmy" />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
          <PersonaCard persona="Jynx" />
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
          <PersonaCard persona="Cabbage" />
        </Grid>

        {/* The below are for spacing 
        See xsOffset={2} above
        */}
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
        </Grid>
        <Grid
          xs
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={180}
        >
        </Grid>
      </Grid>
    </Box>
  )
}