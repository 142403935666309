import * as React from 'react';
import { Box, Button, FormControl, FormLabel, FormHelperText, Switch, Typography, IconButton } from '@mui/joy';
import Tooltip from '@mui/joy/Tooltip';
import InputFileUpload from '../../MessagesPane/InputFileUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function SwitchControl({ defaultFiles, filesRequired, label, defaultValue, helpText }) {
  const [checked, setChecked] = React.useState(defaultValue);
  const [files, setFiles] = React.useState(defaultFiles);
  return (
    <Box>
      <Box>
        <FormControl
          orientation="horizontal"
          sx={{ width: '100%', justifyContent: 'space-between' }}
        >
          <Box sx={{ display: 'flex' }}>
            <Tooltip variant="outlined" color="neutral" title={helpText}><IconButton size="sm"><HelpOutlineIcon sx={{ opacity: .5 }} /></IconButton></Tooltip>
            <FormLabel sx={{ ml: .75 }}>{ label }</FormLabel>
          </Box>
          <Switch
            checked={checked}
            onChange={(event) => setChecked(event.target.checked)}
            color={checked ? 'success' : 'neutral'}
            variant={checked ? 'solid' : 'outlined'}
            slotProps={{
              endDecorator: {
                sx: {
                  minWidth: 24,
                },
              },
            }}
          />
        </FormControl>
      </Box>
      { checked &&
        <Box sx={{ ml: 4, mt: 1.5 }}>
          <InputFileUpload size={"sm"} setFiles={setFiles} />
        </Box>
      }
    </Box>
  );
}