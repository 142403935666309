import React, { useEffect, useState } from "react";
import { Box, Typography, IconButton, Alert } from "@mui/joy";

import InlineLoader from "../Loaders/InlineLoader";
import TranscriptEditor from "../Editor/TranscriptEditor";
import { useNotes } from "../../contexts/notesContext";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

// Import Firestore
import database from "../../../configs/firebaseConfig"
import { useParams } from "react-router-dom";
import NoteEditor from './../Modals/NoteEditor/index';
const db = database.firestore(); // Access Firestore database

function VideoTranscripts({ videoRef, content }) {

  const [transcriptionStatus, setTranscriptionStatus] = useState(content.transcriptStatus);
  const [resetTranscript, setResetTranscript] = useState(false);
  const { accountId } = useParams();
  const { noteEditorOpen, getAndSetData } = useNotes();
  const [pollingInterval, setPollingInterval] = useState(null);

  useEffect(() => {
    if (!content || !content.transcriptionId) {
      console.error('Invalid transcriptionId:', content ? content.transcriptionId : 'undefined');
      return;
    }

    console.log('content.transcriptionId ', content.transcriptionId);
    // Set up Firestore listener for real-time updates
    const unsubscribe = db.collection(`accounts/${accountId}/transcriptions`)
      .doc(content.transcriptionId)
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          setTranscriptionStatus(data.status);
          
          // If status is completed and we need to fetch data, do it here
          if (data.status === "completed" && content.editorState === undefined && content.transcriptions.length === 0) {
            getAndSetData({ setNewCurrentNote: true, sortByPinned: false });
          }
        }
      });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [content, content.transcriptionId]);

  // Helper function to convert "HH:MM:SS" to seconds
  const timeStringToSeconds = (timeString) => {
    if (typeof timeString !== 'string') {
      console.error('Invalid time string:', timeString);
      return 0;
    }
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  // Preprocess transcription data
  const preprocessTranscriptions = (transcriptions) => {
    const processedSentences = [];

    transcriptions.forEach((transcription) => {
      if (!transcription.segments || transcription.segments.length === 0) {
        return; // Skip if segments are undefined or empty
      }

      let sentence = '';
      let lastSpeaker = '';
      let lastEndTime = '';
      let sentenceStartTime = null;

      transcription.segments.forEach((segment) => {
        const currentSpeaker = segment.speaker;
        const currentStartTime = segment.startTime;
        const currentEndTime = segment.endTime;

        if (currentSpeaker !== lastSpeaker || timeStringToSeconds(currentStartTime) - timeStringToSeconds(lastEndTime) > 1) {
          if (sentence) {
            // Save the completed sentence
            processedSentences.push({ text: sentence.trim(), startTime: sentenceStartTime });
            sentence = ''; // Reset sentence
          }
          // Update the sentence start time for the new sentence
          sentenceStartTime = currentStartTime;
        }

        sentence += ` ${segment.text}`;
        lastSpeaker = currentSpeaker;
        lastEndTime = currentEndTime;
      });

      // Push the last sentence if it exists
      if (sentence) {
        processedSentences.push({ text: sentence.trim(), startTime: sentenceStartTime });
      }
    });

    return processedSentences;
  };

  const processedSentences = preprocessTranscriptions(content.transcriptions);

  const handleResetTranscript = () => {
    setResetTranscript(true);
    // Reset back to false after a short delay to allow for future resets
    setTimeout(() => setResetTranscript(false), 100);
  };

  // Add new useEffect for polling
  useEffect(() => {
    if ((transcriptionStatus === "processing" || transcriptionStatus === "pending") && content.type === "upload") {
      // Start polling every 20 seconds
      const interval = setInterval(() => {
        getAndSetData({ setNewCurrentNote: true, sortByPinned: false });
      }, 20000);
      
      setPollingInterval(interval);

      // Clean up interval when status changes or component unmounts
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    } else if (pollingInterval && (transcriptionStatus === "completed" || transcriptionStatus === "failed")) {
      // Clear interval when transcription is complete or failed
      clearInterval(pollingInterval);
      setPollingInterval(null);
    }
  }, [transcriptionStatus, content.type]);

  return (
    <Box sx={{ flex: 1, mt: 3, ml: 2, mr: 2 }}>
      {transcriptionStatus === "failed" ? (
        <Box display="flex" alignItems="center">
          <Typography sx={{ mr: 1 }} variant="h6" color="error">
            Transcription failed. Please try again.
          </Typography>
        </Box>
      ) : (transcriptionStatus === "processing" || transcriptionStatus === "pending") ? (
        <Box display="flex" alignItems="center">
          <Typography sx={{ mr: 1 }} variant="h6" color="text.secondary">
            Transcript loading...
          </Typography>
          <InlineLoader height={24} width={24} loading={true} />
        </Box>
      ) : (
        <>
        { noteEditorOpen ?
        <Typography 
          variant="h6" 
          sx={{ mb: 1.5 }} 
          endDecorator={
            <IconButton
              onClick={handleResetTranscript}
              size="sm"
            >
              <SettingsBackupRestoreIcon sx={{ mr: 1 }} /> Reset transcript
            </IconButton>
          }>
          Transcript
        </Typography>
        :
        <Typography 
          variant="h6" 
          sx={{ mb: 1.5 }} 
        >
          Transcript
        </Typography>
        }
        { !noteEditorOpen && <Alert size="sm" sx={{ mb: 1.5 }} color="warning">You can edit generated transcripts by clicking the open button.</Alert> }
        <TranscriptEditor 
          content={content}
          processedSentences={processedSentences} 
          reset={resetTranscript}
          onSentenceClick={(startTime) => {
            console.log("onSentenceClick startTime", startTime);
            const videoElement = videoRef.current;
            let passedStartTime = startTime.__startTime;
            if (videoElement) {
              const newTime = timeStringToSeconds(passedStartTime);
              console.log('Setting video currentTime to:', newTime);
              videoElement.currentTime = newTime;
              if (videoElement.paused) {
                videoElement.play().catch(error => {
                  console.error("Error attempting to play video:", error);
                });
              }
            } else {
              console.error("Video element not found");
            }
          }}
        />
        </>
      )}
    </Box>
  )
}

export default VideoTranscripts;