import React from 'react'
import { styled } from '@mui/joy/styles'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/joy/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/joy/Button'
import Typography from '@mui/joy/Typography'
import { useAuth } from '../../../authContext' 
import { useLoading } from '../../contexts/loadingContext'


export default function ConfirmDeleteTagsModal({ open, setOpen, handleDeleteTags, tags }) {
  
  const handleClose = () => {
    setOpen(false);
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
    <ModalDialog>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>By selecting yes below, <b>you will be deleting tags that are currently referenced by {tags && tags.occurances} notes.</b></DialogContent>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          setOpen(false);
        }}
      >
        <Stack spacing={2} sx={{ flex: 1 }}>
          <Button variant="solid" size="sm" color="danger" onClick={() => { setOpen(false); handleDeleteTags(); }}>Yes, that's fine</Button>
          <Button variant="solid" size="sm" color="primary" onClick={() => handleClose() }>No, not cool</Button>
        </Stack>
      </form>
    </ModalDialog>
  </Modal>
  )
}