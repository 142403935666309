import * as React from 'react';
import { Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Button from '@mui/joy/Button';
import SwitchToLinks from '../LinksComponent/components/SwitchToLinks';
import PagesModalContent from './PagesModalContent';

export default function PagesModal({ open, setOpen, handleUpdateCurrentPageSet, handlePageSetDeletion, pages, setPages }) {
  const [ viewPane, setViewPane ] = React.useState(0)
  return (
    <React.Fragment>
      <Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            
          keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={() => setOpen(false)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: 'blur(2px)' },
                    entered: { opacity: 1, backdropFilter: 'blur(2px)' },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === 'exited' ? 'hidden' : 'visible',
            }}
          >
            <ModalDialog
              layout={ 'fullscreen' }
              sx={{
                opacity: 0,
                backgroundColor: '#f4f1f8',
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogContent>
                <PagesModalContent viewPane={viewPane} setViewPane={setViewPane} pages={pages} setPages={setPages} open={open} />
              </DialogContent>
              <Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                  <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', pt: 2 }}>
                    <div style={{ flex: 1 }}>
                      { pages && pages.id &&
                      <Button size="md" variant='outlined' color="danger" onClick={() => handlePageSetDeletion({...pages})}>
                        Delete pages set
                      </Button>
                      }
                    </div>
                    {/* INSERT SWITCH
                    <div style={{ flex: 1 }}>
                      <SwitchToLinks viewPane={viewPane} setViewPane={setViewPane} />
                    </div> */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                      <Button size="md" sx={{ mr: 2 }} variant="outlined" color="neutral" onClick={() => setOpen(false)}>
                        Cancel
                      </Button>
                      <Button sx={{ alignSelf: 'flex-end' }} onClick={() => handleUpdateCurrentPageSet({...pages})} size="md" variant="solid">
                        Save
                      </Button>  
                    </div>
                  </CardActions>
                </CardOverflow>
              </Stack>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </React.Fragment>
  );
}