import { Box } from "@mui/joy";
import DockPaneHeader from "../DockPaneHeader";
import { useDockableContent } from "../../../contexts/dockableContentContext";
import LinkCard from "../../MyNotes/LinkCard";
import { useNotes } from "../../../contexts/notesContext";

function DockedLink() {

  const { dockableContent } = useDockableContent();
  const { setNewSelection } = useNotes();

  return (
    <>
      <DockPaneHeader />
      <Box
        className="dock-content"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexShrink: 0 }}
      >
        <LinkCard
          note={dockableContent}  
          noteTitle={dockableContent.noteTitle}
          description={dockableContent.description}
          url={dockableContent.url}
          handleViewLink={() => {
            // redirect to the dockableContent.url
            window.open(`https://` + dockableContent.url, '_blank');
          }}
          setNewSelection={setNewSelection}
        />
      </Box>
    </>
  );
}

export default DockedLink;