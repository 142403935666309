import React, { useEffect, useRef, useState } from 'react';
import PersonaCard from './PersonaCard';
import { Stack, Box, List, ListItem, ListItemContent } from '@mui/joy';
import { useContextualPane } from '../../contexts/contextualPane';
import { useMultiTasking } from '../../contexts/multiTaskingContext';
import Grid from '@mui/joy/Grid';

function PersonaCards({ personas, handleSetPersona }) {

  const { activeItem, setActiveItem } = useContextualPane();
  const { addPanel, panels, clearPanels } = useMultiTasking();

  const getColumnSpan = (containerWidth) => {
    if (containerWidth < 600) return 6;        // xs
    if (containerWidth < 900) return 4;         // sm
    if (containerWidth < 1200) return 3;        // md
    return 3;                                   // lg and xl
  };

  const [columnSpan, setColumnSpan] = useState(3);
  const containerRef = useRef(null);

  useEffect(() => {
    const updateColumnSpan = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setColumnSpan(getColumnSpan(width));
      }
    };

    const resizeObserver = new ResizeObserver(updateColumnSpan);
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateColumnSpan(); // Initial calculation
    }

    return () => resizeObserver.disconnect();
  }, []);

  const handlePersonaClick = (persona) => {
    // console.log("Persona clicked", persona)
    // Set an active item
    setActiveItem({
      item: persona,
      itemType: 'persona',
      mode: 'view',
    })

    handleSetPersona(persona,persona.name)
    
    // Add panel instead of opening modal
    // if panel.type === 'persona', then don't add another, remove the existing one and add the new one
    const existingPanel = panels.find(panel => panel.type === 'persona');
    if (existingPanel) {
      clearPanels();
    }
    addPanel({
      type: 'persona',
      title: `${persona.name} - ${persona.role || 'Persona'}`,
      content: persona,
      panelId: persona.id
    });
  }

  return (
    <Box ref={containerRef} sx={{ width: '100%', minHeight: '100%' }}>
      <Grid container spacing={2}>
        {personas && personas.map((persona, index) => (
          <Grid 
            key={index} 
            xs={columnSpan}
          >
            <ListItem sx={{ pt: 1.2, pb: 1.2, borderRadius: 10, bgcolor: 'transparent' }} variant="soft">
              <ListItemContent>
                <PersonaCard 
                  persona={persona}
                  handlePersonaClick={handlePersonaClick} 
                />
              </ListItemContent>
            </ListItem>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PersonaCards;
