import React from 'react';
import { Box, Typography } from '@mui/joy';
import { useParams } from 'react-router-dom';
import UsersTable from './../../UsersTable/index';
import InviteUsers from './../../InviteUsers/index';
import InvitedUsersTable from './../../InvitedUsersTable/index';
import { retrieveInvitationsForAccount } from './../../../database/invitations-db';
import { useAuth } from '../../../../authContext';
import { isManagerAndAbove } from '../../../utils/utils';

function TeamTab({allPlans}) {

  const { accountId } = useParams();
  const { currentUser } = useAuth();
  const [invitationsSent, setInvitationsSent] = React.useState(null);

  React.useEffect(() => {
    if (accountId) {
      retrieveInvitationsForAccount({ accountId }).then(invitations => setInvitationsSent(invitations));
    }
  }, [accountId]);

  return (
    <Box sx={{ mx: 5, my: 4, pb: 5 }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 4 }}>
        <Box sx={{ flex: { xs: '1 1 100%', md: '2 1 66.67%' } }}>
          <Typography level="h3" sx={{ mb: 2 }} component="h2">Team</Typography>
          <UsersTable />
          { currentUser && isManagerAndAbove(currentUser) &&
            <InvitedUsersTable invitationsSent={invitationsSent} setInvitationsSent={setInvitationsSent} />
          }
        </Box>
        { currentUser && isManagerAndAbove(currentUser) &&
        <Box sx={{ flex: { xs: '1 1 100%', md: '1 1 33.33%' } }}>
          <InviteUsers allPlans={allPlans} invitationsSent={invitationsSent} setInvitationsSent={setInvitationsSent} />
        </Box>
        }
      </Box>
    </Box>
  );
}

export default TeamTab;
