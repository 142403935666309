import database from '../../configs/firebaseConfig'; 
import { checkTeamMembership } from './utilities-db';

const db = database.firestore(); // Access Firestore database

export async function retrieveFromFirestore({accountId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/projects`).orderBy("updated","desc").get();

    const projects = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      projects.push({
        id: doc.id,
        name: data.name,
      });
    });

    // console.log("Retrieved projects from Firestore:", projects);
    return projects;
  } catch (error) {
    console.error("Error retrieving projects from Firestore:", error);
    return [];
  }
}

export default async function saveToFirestore(selectedProject,newValues,accountId) {  
  //console.log("saveToFirestore", selectedProject, newValues, accountId)
  const id = selectedProject && selectedProject.id ? selectedProject.id : null 

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/projects`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        const docRef = await db.collection(`accounts/${accountId}/projects`).doc(id).update({
          name: newValues.name, // Replace with the new title
          updated: new Date(),
        });
        //console.log("Project updated in Firestore!", docRef);
        const updatedDoc = await db.collection(`accounts/${accountId}/projects`).doc(id).get();
        const updatedDocData = updatedDoc.data()
        return { ...updatedDocData, id }
      } catch(error) {
        console.error("Error saving project to Firestore:", error);
      }
    } else {
      console.error("No project found with id ", id)
    } 
  }
}

export async function newProject({ user, newValues, accountId }) {   
  if (user && user !== undefined) {
    //console.log("New project attempted with ", user._delegate.uid, accountId)
    try {
      if (!user) {
        console.error('User not authenticated');
        return;
      }
  
      if (!accountId) {
        console.error('Account id is missing');
        return;
      }
    
      // Allow creation only if user is a team member
      const isTeamMember = await checkTeamMembership(accountId, user._delegate.uid);
    
      if (!isTeamMember) {
        console.error('User is not a team member');
        return;
      } else {
        //console.log("teamMember present, proceeding")
      }
    
      const docRef = await db.collection(`accounts/${accountId}/projects`).add({
        name: newValues.name,
        description: newValues.description,
        createdBy: user._delegate.uid,
        created: new Date(),
        updated: new Date(),
      });
  
      //console.log('New project created in Firestore!');
      const newProjectId = docRef.id;
      const record = await docRef.get();
      const snapshot = record.data();
  
      return { id: newProjectId, ...snapshot };
  
    } catch (error) {
      console.error("Could not create new project ", error)
    }
  } else {
    //console.log("User not setup yet")
  }
}

export async function deleteProject(projectId, accountId) {
  try {
    const docRef = db.collection(`accounts/${accountId}/projects`).doc(projectId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Project deleted from Firestore with ID:", projectId);

      // Optionally, you can return the deleted note data if needed
      const deletedNoteData = snapshot.data();
      return { id: projectId, ...deletedNoteData };
    } else {
      console.error("No projectId found with ID:", projectId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting project from Firestore:", error);
    return null;
  }
}
