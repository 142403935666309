import * as React from 'react';
import Box from '@mui/joy/Box';
import Drawer from '@mui/joy/Drawer';
import ButtonGroup from '@mui/joy/ButtonGroup';
import Button from '@mui/joy/Button';
import List from '@mui/joy/List';
import Divider from '@mui/joy/Divider';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import { useDrawerRight } from '../../contexts/drawerRightContext';

export default function DrawerRight({size = "lg", content, noBlur = false}) {
  
  const { drawerRightOpen, setReason, toggleDrawer } = useDrawerRight();
  
  return (
    <React.Fragment>
      <Drawer
        size={size}
        anchor='right'
        className={noBlur ? `my-custom-drawer` : ''}
        open={drawerRightOpen}
        onClose={toggleDrawer}
      >
        { content() }
      </Drawer>
    
    </React.Fragment>
  );
}