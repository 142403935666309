import React from 'react';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PublicSite from './PublicSite/index';
import PrivateSite from './PrivateSite';
import { AuthProvider } from './authContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import PublishedSites from './PublishedSites';

const App = () => {
  return (
      <AuthProvider>
        <GoogleOAuthProvider clientId="655206626559-umhg415rto0kpb841kn0ojshn3oa375c.apps.googleusercontent.com">
        <Router>
          <Routes>
            {/* Don't allow routes that arent within firestore */}
            <Route
              path="/client/:accountId/:selectedProjectId/*"
              element={<PrivateSite />}
            />
            <Route 
              path="/pub/:accountId/:selectedProjectId/*" 
              element={<PublishedSites />} 
            /> 
            <Route 
              path="/*" 
              element={<PublicSite />} 
            />
          </Routes>
        </Router>
        </GoogleOAuthProvider>
      </AuthProvider>   
  );
};

export default App;
