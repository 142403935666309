import { firebaseApp } from '../../configs/firebaseConfig';
import React, { useState, createContext, useContext } from 'react';

const app = firebaseApp()

// Create the context
const SnackbarContext = createContext();

// Create a provider component
export function SnackbarProvider({ children }) {
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const value = {
    setSnackbarSeverity,
    setSnackbarMsg,
    setShowSnackbar,
    snackbarSeverity,
    snackbarMsg,
    showSnackbar,
  };

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useSnackbar() {
  return useContext(SnackbarContext);
}
