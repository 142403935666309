import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Card, Grid, Sheet, Switch, Typography } from '@mui/joy';
import Add from '@mui/icons-material/Add';
import InputComponent from '../../InputComponent';
import TextAreaComponent from '../../TextAreaComponent';
import QuoteComponent from '../../QuoteComponent';
import BigInputComponent from '../../BigInputComponent';
import ListComponent from '../../ListComponent';
import SliderComponent from '../../SliderComponent';
import ImageComponent from '../../ImageComponent';
import AddNewComponent from '../../AddNewComponent';
import { Edit } from '@mui/icons-material/Edit';

let slotLocations = ['tl_1_1', 'tl_2_1', 'tr_1_1', 'tr_2_1', 'tr_2_2', 'tr_3_2', 'tr_4_1', 'tr_5_1', 'tr_5_2', 'tr_6_1', 'tr_6_2']

export default function PersonaEditorDefaultLayout( { emptySlots, slots, setPersonaFields, handleDeleteField, editChecked, newSlots, handleAddNewRow } ) {
  const containerRef = useRef(null);
  const [gridColumns, setGridColumns] = useState(3); // Default number of columns

  // Function to determine number of columns based on container width
  const getGridColumns = (width) => {
    if (width < 600) return 1;      // Single column for very small widths
    if (width < 900) return 2;      // Two columns for medium widths
    return 3;                       // Three columns for large widths
  };

  useEffect(() => {
    const updateLayout = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        setGridColumns(getGridColumns(width));
      }
    };

    const resizeObserver = new ResizeObserver(updateLayout);
    
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
      updateLayout(); // Initial calculation
    }

    return () => resizeObserver.disconnect();
  }, []);

  // Helper function to chunk slots into columns
  const distributeSlots = () => {
    const allSlots = slotLocations;
    const slotsPerColumn = Math.ceil(allSlots.length / gridColumns);
    
    return Array.from({ length: gridColumns }, (_, index) => {
      const start = index * slotsPerColumn;
      return allSlots.slice(start, start + slotsPerColumn);
    });
  };

  function getTypeBasedComponent(slots, location, updateHandler, deleteHandler, edit ) {
    if (slots && slots.has(location)) {
      const type = slots.get(location).type;
      const id = slots.get(location).id;
      const data = slots.get(location)    
  
      switch (type) {
        case 'text':
          return <InputComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} deleteHandler={deleteHandler} editMode={edit} />;
        case 'bigtext':
          return <BigInputComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} deleteHandler={deleteHandler} editMode={edit} />;
        case 'textarea':
          return <TextAreaComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} deleteHandler={deleteHandler} editMode={edit} />;
        case 'quote':
          return <QuoteComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} editMode={edit} />;
        case 'image':
          return <ImageComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} editMode={edit} />;
        case 'list':
          return <ListComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} deleteHandler={deleteHandler} editMode={edit} />;
        case 'slider':
          return <SliderComponent gridColumns={gridColumns} data={data} id={id} updateHandler={updateHandler} deleteHandler={deleteHandler} editMode={edit} />;
        default:
          return <AddNewComponent gridColumns={gridColumns} updateHandler={setPersonaFields} location={location} editMode={edit} />;
      }
    }
  
    return <AddNewComponent updateHandler={setPersonaFields} location={location} editMode={edit} />;
  }

  return (
    <Box ref={containerRef} sx={{ width: '100%', height: '100%' }}>
      <Grid container columns={12}>
        {distributeSlots().map((columnSlots, columnIndex) => (
          <Grid 
            key={columnIndex} 
            xs={12 / gridColumns} 
            sx={{ p: 0, pr: 0 }}
          >
            {columnSlots.map((slotLocation, slotIndex) => (
              <React.Fragment key={slotLocation}>
                {editChecked ? (
                  <Card sx={{ 
                    mt: slotIndex === 0 ? 0 : 2, 
                    mb: slotIndex === columnSlots.length - 1 ? 3 : 0,
                    border: 'none', 
                    borderBottom: slotLocation !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
                    backgroundColor: '#ffffff', 
                    borderRadius: '10px', 
                    boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
                    ml: gridColumns === 1 ? 0 : 4, 
                    mr: gridColumns === 1 ? 0 : 4, 
                  }}>
                    <Box sx={{ zIndex: 9999, '&:hover .delete-item': { display: "block" } }}>
                      {getTypeBasedComponent(slots, slotLocation, setPersonaFields, handleDeleteField, editChecked)}
                    </Box>
                  </Card>
                ) : (
                  <Box sx={{ 
                    mt: slotIndex === 0 ? 0 : 2,
                    mb: slotIndex === columnSlots.length - 1 ? 3 : 0,
                    zIndex: 9999, 
                    '&:hover .delete-item': { display: "block" },
                  }}>
                    {getTypeBasedComponent(slots, slotLocation, setPersonaFields, handleDeleteField, editChecked)}
                  </Box>
                )}
              </React.Fragment>
            ))}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}