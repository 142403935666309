import React from 'react';
import Search from '../../../Components/Search';
import { Box, Stepper, Step, StepIndicator, Textarea, Typography, Button } from '@mui/joy';
import { useContextualPane } from '../../../contexts/contextualPane';

export default function AddIncites() {

  const [ linkTitle, setLinkTitle ] = React.useState('');
  const [ value, setValue ] = React.useState('');
  const [ url, setUrl ] = React.useState('');
  const [ comment, setComment ] = React.useState('');
  const { activeItem, handleSubmitNewAssociationToDb } = useContextualPane();

  // console.log("url", url)
  // console.log("linkTitle", linkTitle)
  // console.log("value", value)
  // console.log("activeItem", activeItem)

  const handleAddIncite = () => {
    console.log("handleAddIncite", comment, linkTitle, url)
    handleSubmitNewAssociationToDb({
      associationType: "strong",
      fromId: activeItem.item.id,
      fromType: activeItem.item.type,
      toId: value.id,
      toType: value.type,
      url: url,
      title: linkTitle,
      comment: comment
    })
  }

  const handleClearIncite = () => {
    setLinkTitle('')
    setValue('')
    setUrl('')
    setComment('')
  }

  return (
    <>
      <Box sx={{ mx: 'auto', my: 5, px: 30 }}>
        <Search 
          mode="addlink"
          size="md"
          linkTitle={linkTitle} 
          setLinkTitle={setLinkTitle}
          setValue={setValue} 
          value={value} 
          url={url} 
          setUrl={setUrl} 
        />
      </Box>
      { value && value !== "" && linkTitle && linkTitle !== "" &&
      <Box sx={{ mx: 'auto', my: 5, px: 30 }}>
        <Typography level="h3" variant="h3" sx={{ mb: 3 }}>Link Summary</Typography>
        <Stepper>
          <Step
            indicator={
              <StepIndicator variant="outlined" color="primary">
                A
              </StepIndicator>
            }
          >{activeItem.item.title}</Step>
          <Step
            indicator={
              <StepIndicator variant="outlined" color="primary">
                B
              </StepIndicator>
            }
          >{ linkTitle }</Step>
        </Stepper>
        <Box sx={{ mt: 3 }}>
          <Textarea 
            placeholder="Add a comment about this link" 
            onChange={(e) => setComment(e.target.value)}
          />
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button onClick={handleAddIncite}>Add incite</Button>
          <Button variant="outlined" sx={{ ml: 2 }} onClick={handleClearIncite}>Clear</Button>
        </Box>
      </Box>
      }
    </>
  )
}