import { Button, Box, Card, Typography } from "@mui/joy";

function ConnectionCard({ data }) {
    return (
        <Card sx={{
            height: 163,
            width: '90%',
            paddingBottom: 1,
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            border: 'none',
            boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',  
        }}>
            <Typography level="body-sm">{ data && data.title && data.title }</Typography>
            <Typography level="body-sm">{ data && data.comment && data.comment }</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button variant="soft" color="neutral" size="sm">Add to insight</Button>
                <Button variant="outlined" size="sm" color="neutral" sx={{ ml: 'auto' }}>Re-generate summary</Button>
            </Box>
        </Card>
    )
}

export default ConnectionCard;