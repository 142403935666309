import React from 'react';
import { Typography, Switch, Button } from '@mui/joy';

function PreviewButton({ editChecked, setEditChecked}) {
  return (
    <Button
      variant="outlined"
      size="sm"
      sx={{ borderRadius: 12, px: 2.7, py: 2.5, fontSize: '16px' }}
      onClick={() => setEditChecked(!editChecked)}
      endDecorator={
        <Switch 
          size="sm"
          checked={editChecked} 
          sx={{
            ml: 1,
            '--Switch-thumbSize': '12px',
            '--Switch-trackWidth': '41px',
            '--Switch-trackHeight': '13px',
            '--Switch-thumbRadius': '36px',
          }}
        />
      }
    >
      { editChecked ? 'Switch to preview' : 'Switch to edit' }
    </Button>
  )
}

export default PreviewButton;