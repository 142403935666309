import { TextNode } from "lexical";

export class HighlightNode extends TextNode {
  __className;
  __text;
  __id; // id for retrieving data from highlights collection
  __tags;

  static getType() {
    return "highlight";
  }

  static getId(node) {
    return node.__id;
  }

  static clone(node) {
    return new HighlightNode(node.__className, node.__text, node.__id, node.__tags, node.__key);
  }

  constructor(className, text, id, tags, key) {
    super(text, key);
    this.__className = className;
    this.__text = text;
    this.__id = id;
    this.__tags = tags;
  }

  setHighlight(className, text, id, tags, key) {
    // getWritable() creates a clone of the node
    // if needed, to ensure we don't try and mutate
    // a stale version of this node.
    const self = this.getWritable();
    self.__className = className;
    self.setTextContent(text); // Use the TextNode method to set text
    self.__id = id;
    self.__tags = tags;
    self.__key = key;
  }

  setHighlightTags(id, tags, key) {
    // getWritable() creates a clone of the node
    // if needed, to ensure we don't try and mutate
    // a stale version of this node.
    const self = this.getWritable();
    self.__id = id;
    self.__tags = tags;
    self.__key = key;
  }

  getHighlight() {
    const self = this.getLatest();
    return {
      className: self.__className,
      text: self.__text,
      id: self.__id,
      tags: self.__tags,
      key: self.__key
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.className = this.__className;
    return dom;
  }

  exportJSON() {
    const json = {
      ...super.exportJSON(),
      className: this.getClassName(),
      type: 'highlight',
      text: this.__text,
      id: this.__id,
      tags: this.__tags,
      version: 1
    };
    return json;
  }

  static importJSON(node) {
    // console.log("importJSON node", node);
    const highlightNode = $createHighlightNode("highlight", node.text, node.id, node.tags);
    // await highlightNode.fetchDataFromFirestore(); // Fetch data from Firestore
    // console.log("importJSON node", node);
    return highlightNode;
  }

  getClassName() {
    const self = this.getLatest();
    return self.__className;
  }
}

export function $isHighlightNode(node) {
  return node instanceof HighlightNode;
}

export function $createHighlightNode(className, highlightText, id = null, tags = []) {
  return new HighlightNode(className, highlightText, id, tags).setMode("segmented");
}