import { Card, CardContent, Box, Link, Typography } from "@mui/joy";
import LinkIcon from '@mui/icons-material/Link';
import { useNotes } from "../../contexts/notesContext"

function LinkCard({ selected = false, setNewSelection, mode = "default", handleViewLink, note, noteTitle = "", description = "", url = "" }) {

  const { loadNoteContent } = useNotes();

  const handleClick = async (e) => {
    e.preventDefault();
    
    // First load the full note content
    const fullNote = await loadNoteContent(note.id);
    
    if (mode === 'nav') {
      setNewSelection(true)
    }
    
    // Pass the combined note data to handleViewLink
    handleViewLink({ ...note, ...fullNote })
  };

  return (
    <Card className={ selected ? 'selected note-item' : 'note-item' }
    sx={{
      flexDirection: 'column',
      alignItems: 'initial',
      gap: 1,
      marginBottom: 1.85,
      paddingTop: .5,
      paddingBottom: 1,
      paddingLeft: 1.5,
      paddingRight: 1.5,
      backgroundColor: '#fbf8ff',
      boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
      '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' },
    }}
    variant="soft"
    orientation="vertical">
      <CardContent>
        <Box sx={{ flex: 1 }}>
          <Link
            component="a"
            href={`https://${url}`}
            target="_blank"
            rel="noreferrer"
            title={description}
            overlay
            underline="none"
            onClick={handleClick}
            sx={{ color: 'text.tertiary' }}
          >
            <Typography startDecorator={<LinkIcon fontSize="sm" />} level="title-sm">
              {noteTitle}
            </Typography>
          </Link>
        </Box>
      </CardContent>
    </Card>
  )
}

export default LinkCard;
