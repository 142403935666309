import React from 'react';
import { Box, FormControl, FormLabel, Input, IconButton, Typography } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

export default function BigInputComponent({ gridColumns, data, id, updateHandler, deleteHandler, editMode = true }) {

  const [ editing, setEditing ] = React.useState(false);
  const [ newTitle, setNewTitle ] = React.useState('');

  const handleClickOfEdit = () => {
    if (data.cantDelete) return;
    setNewTitle(data.field_title);
    setEditing(true);
  }

  return (
    editMode ?
    <FormControl>
      <FormLabel sx={{ flex: 1, width: "100%", flexDirection: "row", justifyContent: "space-between" }} onClick={() => { handleClickOfEdit() }}>
        { data && data.field_title && 
          <>
          <Box flex={1}>
            { data.field_title } { data.cantDelete && ' (required)' }
          </Box>
          { data.cantDelete ? <Box sx={{ minHeight: 32 }}></Box> :
            <Box
              flex={1}
              sx={{
                minHeight: 32,
                justifyContent: "end",
                display: "flex",
              }}
            >
              <IconButton onClick={() => deleteHandler({ idToDelete: id })} className='delete-item' sx={{ display: "none" }} size="sm">
                <CloseIcon />
              </IconButton>
            </Box> 
          }
          </>
        }
      </FormLabel>
      
      <Input 
        onBlur={(e) => updateHandler(prevState => {
          const updatedArray = prevState.map(item => {
            if (item.id === id) {
              return { ...item, field_value: e.target.value };
            }
            return item;
          });
        
          return updatedArray;
        })}
        defaultValue={data && data.field_value ? data.field_value : ''} 
      size="lg" />
    </FormControl>
    :
    data.field_value !== '' &&
    <Box
      sx={{ 
        borderBottom: data.block_location !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
        ml: gridColumns === 1 ? 0 : 4, 
        mr: gridColumns === 1 ? 0 : 4, 
        pb: 2.5
      }}
    >
      <Typography level="h1" fontSize={25}>
        { data.field_value }
      </Typography>
    </Box>
  )
}