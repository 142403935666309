import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import ModalOverflow from '@mui/joy/ModalOverflow';
import DialogTitle from '@mui/joy/DialogTitle';
import NewProjectModal from './NewProjectModal';
import Avatar from '@mui/joy/Avatar';
import List from '@mui/joy/List';
import Box from '@mui/joy/Box';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Typography from '@mui/joy/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import { getCombinedCharacters } from '../../../../utils/utils';
import { IconButton, Input, ListItem } from '@mui/joy';
import { retrieveFromFirestore } from '../../../../database/projects-db';
import { useParams } from 'react-router-dom';
import { useProjects } from '../../../../contexts/projectsContext';
import { useNotes } from '../../../../contexts/notesContext';
import { usePersonas } from '../../../../contexts/personasContext';
import CreateProject from '../../../Modals/CreateProject';

export default function ProjectModal({ mode = "switch", open, setOpen, content }) {

  const { accountProjects, setSelectedProject, setAccountProjects, handleDeleteProject, handleNewProject, handleSwitchSelectedProject } = useProjects()
  const { handleUpdateNoteProject } = useNotes()
  const { handleUpdatePersonaProject } = usePersonas()
  const [ newProjectModal, setNewProjectModal ] = React.useState(false)
  const [ projectSearch, setProjectSearch ] = React.useState('')
  const { accountId } = useParams()

  const handleProjectSearch = (event) => {
    //console.log("Searching for project: ", event.target.value)
    setProjectSearch(event.target.value)
  }

  const handleChangeProjectOfNote = (project) => {
    console.log("Changing project of note to: ", project)
    setOpen(false)
    if (content.type === "note" || content.type === "upload" || content.type === "link") {
      handleUpdateNoteProject({ newProjectId: project.id, content: content })
    } else if (content.type === "persona") {
      handleUpdatePersonaProject({ newProjectId: project.id, content: content })
    }
  }

  const filteredProjects = accountProjects.filter(project => project.name.toLowerCase().includes(projectSearch.toLowerCase()));

  React.useEffect(() => {
    //console.log("project array", projects)
  },[projectSearch])

  React.useEffect(() => {
    //console.log("project array", projects)
  },[])

  return (
    <React.Fragment>
      <Modal className="projectModalContainer" open={open} onClose={() => setOpen(false)}>
        <ModalOverflow>
          { mode === "switch" ?
          <ModalDialog>
            <ModalClose />
            <DialogTitle>Projects</DialogTitle>
            <Input placeholder="Search projects" onChange={handleProjectSearch} />
            { accountProjects && accountProjects.length > 0 &&
            <List
              aria-label="Personal info"
              size="md"
              sx={{ '--ListItemDecorator-size': '72px' }}
            >
            { filteredProjects.map((project, index) => (             
              <ListItem key={project.id + index}>
                <ListItemButton onClick={(e) => handleSwitchSelectedProject(project) } key={project.id}>
                  <ListItemDecorator>
                    <Avatar size="lg" sx={{ '--Avatar-size': '32px' }} color="primary">
                      { getCombinedCharacters(project.name) }
                    </Avatar>
                  </ListItemDecorator>
                  <div>
                    <Typography fontSize="md">{project.name}</Typography>
                  </div>
                </ListItemButton>
                <ListItemDecorator style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <IconButton size="sm" variant="plain" color="neutral" onClick={() => handleDeleteProject(project.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemDecorator>
              </ListItem>
              ))}
            </List>
            }
            <Box sx={{ background: 'rgba(255, 255, 255, 1)', py: 2, px: 2, position: 'sticky', bottom: -25, borderRadius: 20 }}>
              <Button onClick={(e) => setNewProjectModal(true)}>Create new project</Button>
            </Box>
            {/* <NewProjectModal open={newProjectModal} setOpen={setNewProjectModal} handleNewProject={handleNewProject} /> */}
            {/* TO DO Insert new project modal flow here */}
            <CreateProject open={newProjectModal} setOpen={setNewProjectModal} />
          </ModalDialog>
          :
          <ModalDialog>
            <ModalClose />
            <DialogTitle>Move { content.type } to another project</DialogTitle>
            <Input placeholder="Search and choose project" onChange={handleProjectSearch} />
            { accountProjects && accountProjects.length > 0 &&
            <List
              aria-label="Personal info"
              size="md"
              sx={{ '--ListItemDecorator-size': '72px' }}
            >
            { filteredProjects.map((project, index) => (             
              <ListItem key={project.id + index}>
                <ListItemButton onClick={(e) => handleChangeProjectOfNote(project) } key={project.id}>
                  <ListItemDecorator>
                    <Avatar size="lg" sx={{ '--Avatar-size': '32px' }} color="primary">
                      { getCombinedCharacters(project.name) }
                    </Avatar>
                  </ListItemDecorator>
                  <div>
                    <Typography fontSize="md">{project.name}</Typography>
                  </div>
                </ListItemButton>
              </ListItem>
              ))}
            </List>
            }
          </ModalDialog>
          }
        </ModalOverflow>
      </Modal>
    </React.Fragment>
  );
}