import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function retrievePersonasFromFirestore({accountId, selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/personas`)
    .where("project", "==", selectedProjectId)
    .get();

    const personas = [];
    snapshot.forEach((doc) => {
      const data = doc.data();

      if (data && data.values) {
        personas.push({
          id: doc.id,
          type: "persona",
          data: data.values || [],
          tags: data.tags || [],
          name: data.name || "",
          created: data.created || new Date(),
          updated: data.updated || new Date(),
          project: data.project,
          pinned: data.pinned ?? false
        });
      }
    });

    return personas;
  } catch (error) {
    console.error("Error retrieving personas from Firestore:", error);
    return [];
  }
}

export async function getPersona({ personaId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/personas`).doc(personaId);
    const snapshot = await docRef.get();
    if (snapshot.exists) {
      const personaData = snapshot.data();
      return { id: personaId, data: personaData.values, tags: personaData.tags, name: personaData.name, created: personaData.created, updated: personaData.updated, project: personaData.project, pinned: personaData.pinned ? personaData.pinned : false };
    } else {
      console.error("No document found with ID:", personaId);
      return null;
    }
  } catch (error) {
    console.error("Error retrieving persona from Firestore:", error);
    return null;
  }
}

export default async function saveToFirestore({ id, persona, name = "", accountId, selectedProjectId, pinPersona = false, pendingTag = [] }) {  
  console.log("saveToFirestore", id, persona, name, pinPersona, pendingTag)
  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/personas`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/personas`).doc(id).update({
          values: [...persona],
          name: name,
          updated: new Date(),
          project: selectedProjectId,
          pinned: pinPersona,
          tags: pendingTag,
          type: "persona"
        });
        //console.log("Persona updated in Firestore!");
        return { id: id, data: persona, name: name, updated: new Date(), project: selectedProjectId, pinned: pinPersona ? pinPersona : false };
      } catch(error) {
        console.error("Error saving persona to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    try {
      console.log("About to save:", {
        values: persona,
        name,
        created: new Date(),
        updated: new Date(),
        project: selectedProjectId,
        pinned: pinPersona,
        tags: pendingTag,
        type: "persona"
      });
      const newDocRef = await db.collection(`accounts/${accountId}/personas`).add({
        values: [...persona],
        name: name,
        created: new Date(),
        updated: new Date(),
        project: selectedProjectId,
        pinned: pinPersona,
        type: "persona"
      });
      //console.log("New persona saved to Firestore!");
      return { id: newDocRef.id, data: persona, name: name, created: new Date(), updated: new Date(), project: selectedProjectId, pinned: pinPersona };
    } catch (error) {
      console.error("Error saving persona to Firestore:", error);
      console.error("Error details:", {
        name: error.name,
        message: error.message,
        code: error.code,
        stack: error.stack
      });
    }
  }
}

export async function deletePersona({ personaId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/personas`).doc(personaId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Persona deleted from Firestore with ID:", personaId);

      // Optionally, you can return the deleted note data if needed
      const deletedPersonaData = snapshot.data();
      return { id: personaId, ...deletedPersonaData };
    } else {
      console.error("No document found with ID:", personaId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting persona from Firestore:", error);
    return null;
  }
}

export { saveToFirestore }