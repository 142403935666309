import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function getHighlight({ highlightId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/highlights`).doc(highlightId);
    const snapshot = await docRef.get();
    if (snapshot.exists) {
      return { id: snapshot.id, ...snapshot.data() };
    } else {
      console.error("No document found with ID:", highlightId);
      return null;
    }
  } catch (error) {
    console.error("Error getting highlight from Firestore:", error);
    return null;
  }
}

export default async function saveHighlightToFirestore({ highlight, researchId, accountId, selectedProjectId }) {  
  if (highlight && highlight.id) {
    const existingDoc = await db.collection(`accounts/${accountId}/highlights`).doc(highlight.id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/highlights`).doc(highlight.id).update({
          updated: new Date(),
          text: highlight.text,
          tags: highlight.tags,
          project: selectedProjectId,
          researchId: researchId,
          ...highlight,
        });
        return { ...highlight, updated: new Date() }; // Return the updated highlight
      } catch(error) {
        console.error("Error saving highlight to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", highlight.id)
    } 
  } else {
    try {
      const docRef = await db.collection(`accounts/${accountId}/highlights`).add({
        created: new Date(),
        updated: new Date(),
        project: selectedProjectId,
        researchId: researchId,
        ...highlight
      });
      const createdHighlight = { ...highlight, id: docRef.id, created: new Date(), updated: new Date() };
      return createdHighlight; // Return the created highlight
    } catch (error) {
      console.error("Error saving highlight to Firestore:", error);
    }
  }
}

export async function deleteHighlight({ highlightId, accountId }) {
  try {

    const docRef = db.collection(`accounts/${accountId}/highlights`).doc(highlightId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();

      // Optionally, you can return the deleted note data if needed
      const deletedHighlightData = snapshot.data();
      return { id: highlightId, ...deletedHighlightData };
    } else {
      console.error("No document found with ID:", highlightId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting highlight from Firestore:", error);
    return null;
  }
}

export { saveHighlightToFirestore }