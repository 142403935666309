import React from 'react';
import { useContextualPane } from '../../../../../contexts/contextualPane';
import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import ResultListings from '../SearchAssociationsList/SearchForAssociations/ResultListings';
import Box from '@mui/joy/Box';

export default function SelectPageToAssociate() {

  const { 
    newDraftAssociation, 
    searchResultsForAssociation, 
    setProgressStep, 
    alert, 
    alertMsg, 
    setAlert, 
    setAlertMsg, 
    handleSetNewDraftAssociation, 
    activeItem,
    handleClickOnHelperLink,
    secondaryAssociationMode,
    handleStepThreeContinue,
    handleStepThreeBack,
    secondNewDraftAssociation,
    setSecondNewDraftAssociation,
    handleStepTwoContinueForSecondary,
    handleStepTwoCitationContinueSecondary,
    handleStepThreeContinueSecondary,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    snackbarMsg,
    setSnackbarSeverity,
    setSnackbarMsg,
    handleStepTenContinueSecondary,
    handleStepTenContinue
  } = useContextualPane();

  const [ refineSearch, setRefineSearch ] = React.useState('')
  const [ selectedValue, setSelectedValue ] = React.useState('');

  function handleChange(id, title) {
    //console.log('id', id)
    //console.log('title', title)
    setSelectedValue({ id: id, title: title, elementSelected: activeItem.elementSelected });
  }

  const filteredResults = searchResultsForAssociation.filter(result => result.title.toLowerCase().includes(refineSearch.toLowerCase()));
  
  return (
      <>
      { filteredResults && filteredResults.length > 0 && (
            <>
            <FormControl>
                {/* Result listings */}
                <ResultListings filteredResults={filteredResults} handleChange={handleChange} />
            </FormControl>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                { secondaryAssociationMode 
                ?
                    <>
                    <Button onClick={() => handleStepThreeBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                        Back
                    </Button>
                    <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepTenContinueSecondary(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                        Continue
                    </Button>
                    </>
                :
                    <>
                    <Button onClick={() => handleStepThreeBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                        Back
                    </Button>
                    <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepTenContinue(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                        { newDraftAssociation.toType === "pageset" ? 'Select' : 'Create' }
                    </Button>
                    </>
                }
            </Box>
            </>
        )}
      </>
  )
}