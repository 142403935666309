import React from 'react';
import { Card, Link, Typography } from '@mui/joy';
import { TimeAgo } from '../../../../utils/utils';

export default function PageSetCards({ pages, handlePagesClick }) {
  return (
    <Card 
      variant="soft" 
      size="sm" 
      className="note-item"
      sx={{ 
        minHeight: 100, 
        backgroundColor: '#fbf8ff',
          '&:hover': { 
            boxShadow: 'md', 
            backgroundColor: '#fff', 
            borderColor: 'neutral.outlinedHoverBorder' 
          }, 
        marginBottom: 1,
        paddingTop: .5,
        paddingBottom: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
      }}
    >
      <Link variant="plain" overlay to={`/pages/${pages.id}`} onClick={() => handlePagesClick(pages)}>
        {pages && pages.title && pages.title}
      </Link>
      {pages.updated &&
        <Typography level="body-xs" sx={{ color: 'grey' }}>Last updated <TimeAgo timestamp={pages.updated} /></Typography>
      }
    </Card>
  );
}