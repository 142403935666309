import React from 'react';
import { Box, Typography, Card, Link } from '@mui/joy';
import { BASE_PRIVATEAPP_URL } from '../../../../../../redirects';
import { useNavigate, useParams } from 'react-router-dom';

export default function InciteCardForLists({ citation }) {

  const navigate = useNavigate();
  const { accountId, selectedProjectId, boardId } = useParams() 

  const handleRefClick = ({ id, project }) => {
    //console.log("attempting: ", BASE_PRIVATEAPP_URL + accountId + '/' + project + '?noteId=' + id)
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + project + '?noteId=' + id);
  }

  return (
    <Card orientation="horizontal" size="sm" sx={{ borderColor: '#f4f1f8', mb: 1 }} variant="outlined">
      <Box sx={{ mx: 1 }}>
        <Typography level="title-md" sx={{ mb: .7 }}>{ citation && citation.comment }</Typography>
        { citation && citation.referenced && citation.referenced.length > 0 && citation.referenced.map((ref, index) => (
          <>{ ref.from.fromId !== "" && <Typography level="body-sm">Referenced from <Link variant="plain" color="primary" onClick={() => handleRefClick({ id: ref.from.fromId, project: ref.referenceData.project } )}>{ ref.from.fromType === "note" ? ref.referenceData.noteTitle : ref.referenceData.title } ({ ref.from.fromType })</Link></Typography>}</>
        ))}
      </Box>
    </Card>
  )
}