import { Box, Typography } from '@mui/joy';

function TieredPricing({ currency, price, text }) {
    return (
        <Box>
            <Typography level="title-lg">{currency === 'gbp' && '£'}{price && price === 0 || null ? '0' : price}</Typography>
            <Typography level="body-sm">{text}</Typography>
        </Box>
    )
}

function FlatPricing({ currency, price, text }) {
    return (
        <Box>
            <Typography level="title-lg">{currency === 'gbp' && '£'}{price && price === 0 || price === undefined ? '0' : price}</Typography>
            <Typography level="body-sm">{text}</Typography>
        </Box>
    )
}

function Pricing({ pricing, monthly }) {
    return (
        monthly 
            ?
              <>
                { pricing.monthlyFlatAmountData ?
                  pricing.monthlyFlatAmountData.map((item, index) => (
                    <>
                      {item.price_type === 'flat' && item.up_to === 1 ?
                        <>
                          <TieredPricing currency={pricing.currency} price={item.amount} text={'includes 2 seats'} />
                        </>
                      :
                        <>
                          <TieredPricing currency={pricing.currency} price={item.amount_per_unit} text={'per seat thereafter'} />
                        </>
                      }
                    </>
                  ))
                :
                  <>
                    <FlatPricing currency={pricing.currency} price={pricing.monthlyCost} text={'per month'} />
                  </>
                }
              </>
            :
            <>
            { pricing.annualFlatAmountData ?
              pricing.annualFlatAmountData.map((item, index) => (
                <>
                  {item.price_type === 'flat' && item.up_to === 1 ?
                    <>
                      <TieredPricing currency={pricing.currency} price={item.amount} text={'includes 2 seats'} />
                    </>
                  :
                    <>
                      <TieredPricing currency={pricing.currency} price={item.amount_per_unit} text={'per seat thereafter'} />
                    </>
                  }
                </>
              ))
            :
              <>
                <FlatPricing currency={pricing.currency} price={pricing.annualCost} text={'per year'} />
              </>
            }
          </>
    )
}

export default Pricing;