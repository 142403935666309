import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function retrieveToDosFromFirestore({accountId, selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/todos`)
        .where("project", "==", selectedProjectId)
        .orderBy("order", "asc")
        .get();

    const todos = [];
    snapshot.forEach((doc) => {
      const data = doc.data();

      // console.log("data for todos ", data)

      if (data) {
        todos.push({ ...data, id: doc.id, dueBy: data.dueBy.toDate(), created: data.created.toDate(), updated: data.updated.toDate(), project: data.project });
      }
    });

    return todos;
  } catch (error) {
    console.error("Error retrieving todos from Firestore:", error);
    return [];
  }
}

export default async function saveToDoToFirestore({ todo, accountId, selectedProjectId }) {  
  if (todo && todo.id) {
    const existingDoc = await db.collection(`accounts/${accountId}/todos`).doc(todo.id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/todos`).doc(todo.id).update({
          updated: new Date(),
          dueBy: new Date(todo.dueBy),
          project: selectedProjectId,
          ...todo,
        });
      } catch(error) {
        console.error("Error saving todo to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", todo.id)
    } 
  } else {
    try {
      await db.collection(`accounts/${accountId}/todos`).add({
        created: new Date(),
        updated: new Date(),
        dueBy: new Date(todo.dueBy),
        project: selectedProjectId,
        ...todo
      });
    } catch (error) {
      console.error("Error saving todo to Firestore:", error);
    }
  }
}

export async function deleteToDo({ todoId, accountId }) {
  console.log("Deleting todo with ID:", todoId);
  try {
    const docRef = db.collection(`accounts/${accountId}/todos`).doc(todoId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();

      // Optionally, you can return the deleted note data if needed
      const deletedToDoData = snapshot.data();
      return { id: todoId, ...deletedToDoData };
    } else {
      console.error("No document found with ID:", todoId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting todo from Firestore:", error);
    return null;
  }
}

const BATCH_SIZE = 500; // Firestore's limit

export const saveMultipleToDosToFirestore = async ({ todos, accountId, selectedProjectId }) => {
  
  // Split todos into chunks of BATCH_SIZE
  const chunks = [];
  for (let i = 0; i < todos.length; i += BATCH_SIZE) {
    chunks.push(todos.slice(i, i + BATCH_SIZE));
  }

  try {
    // Process each chunk in a separate batch
    const batchPromises = chunks.map(chunk => {
      const batch = db.batch();
      
      chunk.forEach(todo => {
        const todoRef = todo.id 
          ? db.collection(`accounts/${accountId}/todos`).doc(todo.id)
          : db.collection(`accounts/${accountId}/todos`).doc();
        batch.set(todoRef, todo, { merge: true });
      });

      return batch.commit();
    });

    // Wait for all batches to complete
    await Promise.all(batchPromises);
  } catch (error) {
    console.error('Error in saveMultipleToDosToFirestore:', error);
    throw error;
  }
};

export { saveToDoToFirestore }