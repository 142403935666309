import { Avatar, Box, Tooltip } from '@mui/joy';
import { getCombinedCharacters } from '../../utils/utils';

function CreatedBy({ user }) {
    return (
      user &&
        <Box sx={{ mt: 1, mx: 1.5, mr: 2 }}>
          <Tooltip variant="outlined" color="neutral" title={user.displayName && user.displayName !== "" ? `Created by ${user.displayName}` : user.email && user.email !== "" ? `Created by ${user.email}` : ""}>
            { user.photoURL && user.photoURL !== "" ? <Avatar size="sm" src={user.photoURL} /> :
            user.displayName && user.displayName !== "" ? (
                <Avatar size="sm">
                    {getCombinedCharacters(user.displayName)}
                </Avatar>
            ):
            user.email && user.email !== "" && (
                <Avatar size="sm">
                    {getCombinedCharacters(user.email)}
                </Avatar>
            )}
          </Tooltip>
        </Box>
    )
}

export default CreatedBy;