import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/joy';
import VersionHistoryEditor from '../Notes/VersionHistoryEditor';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { flattenTree, removeChildrenOf, getProjection, setProperty } from '../../SitemapTree/utilities';
import { SortableTreeItem } from '../../SitemapTree/components';
import { SortableTree } from '../../SitemapTree';

export default function PreviewNavigation({ selected, setSelected }) {

  const [activeId, setActiveId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [overId, setOverId] = useState(null);
  const indentationWidth = 50;
  const indicator = false;

  const flattenedItems = useMemo(() => {
    if (selected && selected.items && selected.items.length > 0) {
      const flattenedTree = flattenTree(selected.items);
      const collapsedItems = flattenedTree.reduce(
        (acc, {children, collapsed, id}) =>
          collapsed && children.length ? [...acc, id] : acc,
        []
      );

      return removeChildrenOf(
        flattenedTree,
        activeId ? [activeId, ...collapsedItems] : collapsedItems
      );
    }
  }, [selected.items]);

  function handleCollapse(id) {
    setSelected((sitemap) =>
      setProperty(sitemap.items, id, 'collapsed', (value) => {
        return !value;
      })
    );
  }

  const projected =
    activeId && overId
      ? getProjection(
          flattenedItems,
          activeId,
          overId,
          offsetLeft,
          indentationWidth
        )
      : null;

  return (
    <>
    <Box sx={{ mb: 2 }}>
      <Typography level="h5" sx={{ fontWeight: 800 }} startDecorator={<ArrowBackIosIcon sx={{ '&:hover': { cursor: 'pointer' } }} fontSize="10" onClick={() => setSelected(null)} />} >{ selected.title === "" ? "Untitle navigation" : selected.title }</Typography>
    </Box>
    <Box sx={{ overflow: 'auto', maxheight: '10vh', ml: 0, mr: 0 }}>
      <SortableTree showAddBtn={false} sitemap={selected}>
        {flattenedItems.map(({id, children, label, collapsed, depth}) => (
          <SortableTreeItem
            key={id}
            id={id}
            value={label}
            depth={id === activeId && projected ? projected.depth : depth}
            indentationWidth={indentationWidth}
            indicator={false}
            collapsed={Boolean(collapsed && children.length)}
            disableInteraction={true}
          />
        ))}
      </SortableTree>
    </Box>
    </>
  );
}