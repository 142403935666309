import { Chip, Badge, Box, FormControl, FormLabel, Switch, Typography } from '@mui/joy';
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function TagContextChip({ tag }) {
    return (
        <Chip size="sm" sx={{ color: tag.colour[500], backgroundColor: tag.colour[200], border: 'none' }}>
            { tag.boardGroupsTitle } <ArrowForwardIcon fontSize="9" /> { tag.boardsGroupsTagsTitle }
        </Chip>
    )
}

export default function TagSwitch({ tag, textColourStrength = 500, selected, onChange }) {

    return (
        <>
        { tag && tag.colour &&
            <>
            <Box 
                sx={{ 
                    px: 1.25, 
                    py: .75, 
                    color: '#fff', 
                    borderRadius: 6, 
                    fontWeight: 600, 
                    backgroundColor: selected ? tag.colour[textColourStrength] : tag.colour[200], 
                    minWidth: '100%'
                }}>
                    <Box 
                        sx={{ 
                            display: 'flex',
                            flexDirection: 'row',
                            flex: 1,
                        }}>
                        <FormControl
                            orientation="horizontal"
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <FormLabel>
                                <Typography 
                                    sx={{ 
                                        color: selected ? '#fff' : tag.colour[600], 
                                        fontWeight: 'bold',
                                        pl: 1 
                                    }} 
                                    level="body-xs"
                                >
                                    { tag.title }
                                </Typography>
                            </FormLabel>
                            <Switch
                                checked={selected}
                                onChange={(event) => {
                                    console.log('Switch onChange', event.target.checked);
                                    if (onChange) {
                                        onChange(event);
                                    }
                                }}
                                slotProps={{
                                    thumb: {
                                    sx: {
                                        bgcolor: selected ? tag.colour[textColourStrength] : tag.colour[400], // Thumb background color
                                        border: 'none',
                                        boxShadow: 'none',
                                    },
                                    },
                                    track: {
                                    sx: {
                                        bgcolor: selected ? tag.colour[200] : tag.colour[500], // Track background color
                                        border: 'none',
                                        boxShadow: 'none',
                                    },
                                    },

                                }}
                            />
                        </FormControl>
                    </Box>
            </Box>
            </>
        }
        </>
    )
}
