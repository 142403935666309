import * as React from 'react';
import { CssVarsProvider } from '@mui/joy/styles';

import Box from '@mui/joy/Box';
import CssBaseline from '@mui/joy/CssBaseline';

import framesxTheme from '../../theme';
import HeroLeft01 from '../../Blocks/HeroLeft01';
import Header from '../../Components/Header';

export default function Home() {
  return (
      <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
            backgroundColor: '#F4F1F8'
          }}
        >
        <Header />
        <HeroLeft01 />
      </Box>
  );
}