import React, { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getRoot } from 'lexical';
import { useAiPane } from '../../../contexts/aiPaneContext';
import { useNotes } from '../../../contexts/notesContext';
import {$generateHtmlFromNodes} from '@lexical/html';
import { debounce } from 'lodash'; // Import debounce from lodash

function CurrentContentPlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();
  const { setEditorContent } = useAiPane();
  const { setHtml } = useNotes();

  useEffect(() => {

    const debounceSetHtml = debounce((htmlString) => {
      setHtml(htmlString);
    }, 2000); // Adjust the delay as needed

    const debounceSetEditorContent = debounce((textContent) => {
      setEditorContent(textContent); // Update the global variable
    }, 2000); // Adjust the delay as needed

    const unsubscribe = editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const root = $getRoot();
        const textContent = root.getTextContent();

        debounceSetEditorContent(textContent); // Debounce the update so as to not affect typing performance        
        
        const htmlString = $generateHtmlFromNodes(editor, null);
        debounceSetHtml(htmlString); // Debounce the update so as to not affect typing performance
      });
    });

    return () => {
      unsubscribe();
    };
  }, [editor, onChange]);

  return null;
}

export default CurrentContentPlugin;