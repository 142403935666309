import * as React from 'react';
import Dropdown from '@mui/joy/Dropdown';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import MoreVert from '@mui/icons-material/MoreVert';
import FeedbackSnackbar from '../Snackbar';
import { useNotes } from '../../contexts/notesContext';

export default function NoteMoreBinOptions({ noteToDisplay }) {

  const { handleDeleteNote } = useNotes()
  const { handleRestoreNote } = useNotes()
  const { showSnackbar, setShowSnackbar } = useNotes()
  const { snackbarSeverity } = useNotes()
  const { snackbarMsg } = useNotes()

  const [ open, setOpen ] = React.useState(false)

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);

  return (
    <Dropdown onOpenChange={handleOpenChange} open={open}>
      <MenuButton
        slots={{ root: IconButton }}
        slotProps={{ root: { variant: 'outlined', color: 'neutral' } }}
      >
        <MoreVert />
      </MenuButton>
      <Menu placement="left">
        <MenuItem onClick={() => handleRestoreNote({noteId: noteToDisplay.id})}>Restore</MenuItem>
        <MenuItem onClick={() => handleDeleteNote({noteId: noteToDisplay.id})}>Delete permantely</MenuItem>
      </Menu>
      <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
    </Dropdown>
  )
}