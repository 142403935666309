import React from "react";
import { Sheet, Stack } from "@mui/joy";
import JourneysListCards from "../../Components/JourneyMap/components/JourneyListCards";
import { useJourneys } from "../../contexts/journeysContext";
import DefinitionBanner from "../../Components/Banners/DefinitionBanner";
import GridJourneyModal from "../../Components/JourneyMap/GridJourneyModal";
import { getConfig } from "../../database/configs-db";

export default function JourneysPage() {
  const [banner, setBanner] = React.useState('')
  const { journeys, handleSetJourney, handleCreateJourney } = useJourneys();

  React.useEffect(() => {
    getConfig().then(config => setBanner(config.journeysImage))
  }, [])

  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        gridTemplateColumns: {
          xs: '1fr',
          //sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          sm: '1fr',
        },
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pb={1.5}
      >
        <DefinitionBanner 
          title="Journeys" 
          // text="A feature, in the context of software development, refers to a distinct, individual functionality or characteristic that a software product or system offers to its users. Features are specific components or capabilities that contribute to the overall functionality and utility of the software. Each feature typically serves a particular purpose, addressing a specific user need or requirement." 
          btnTxt="Create Journey"
          handleCreate={handleCreateJourney} 
          srcImage={banner}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pt={0}
        pb={1.5}
      >
        { journeys && journeys.length > 0 &&
          <JourneysListCards handleSetJourney={handleSetJourney} journeys={journeys} />
        }
        <GridJourneyModal />
      </Stack>
      {/* <JourneyMap vertical={false} items={items} /> */}
      {/* <JourneyMap minimal={true} vertical={false} items={newItems} /> */}
      {/* <MultiAxisDragAndDrop /> */}      
      {/* <GridJourney items={items} />       */}
    </Sheet>
  )
}