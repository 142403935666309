// CustomSentenceNode.js
import { useState, useEffect } from 'react';
import { DecoratorNode, $getNodeByKey, $getSelection } from 'lexical';
import { CLICK_COMMAND, COMMAND_PRIORITY_LOW } from 'lexical';
import React from 'react';
import { Box, IconButton, Typography, Textarea, Button } from '@mui/joy';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useNotes } from '../../../contexts/notesContext';

export class CustomSentenceNode extends DecoratorNode {
  constructor(text, startTime, key) {
    super(key);
    this.__text = text;
    this.__startTime = startTime;
  }

  static getType() {
    return 'custom-sentence';
  }

  static clone(node) {
    return new CustomSentenceNode(
      node.__text,
      node.__startTime,
      node.getKey()
    );
  }

  createDOM() {
    const div = document.createElement('div');
    div.setAttribute('data-sentence-node', 'true');
    return div;
  }

  updateDOM() {
    return false;
  }

  decorate() {
    return (
      <CustomSentenceNodeComponent
        text={this.__text}
        startTime={this.__startTime}
        nodeKey={this.getKey()}
      />
    );
  }

  exportJSON() {
    return {
      type: 'custom-sentence',
      version: 1,
      text: this.__text,
      startTime: this.__startTime,
    };
  }

  setText(text) {
    const writable = this.getWritable();
    writable.__text = text;
    return writable;
  }

  getText() {
    return this.__text;
  }

  static importJSON(serializedNode) {
    const node = $createCustomSentenceNode(
      serializedNode.text,
      serializedNode.startTime
    );
    return node;
  }
}

// Create a custom plugin to handle the click events
export function CustomSentenceClickPlugin({ onSentenceClick }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!onSentenceClick) return;    

    return editor.registerCommand(
      CLICK_COMMAND,
      (payload) => {
        console.log("Click command received with payload:", payload);
        const selection = $getSelection();
        if (selection) {
          const node = selection.getNodes()[0];
          if (node instanceof CustomSentenceNode) {
            onSentenceClick({ __startTime: payload.startTime });
            return true;
          }
        }
        return false;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor, onSentenceClick]);

  return null;
}

function CustomSentenceNodeComponent({ text, startTime, nodeKey }) {
  const [editor] = useLexicalComposerContext();
  const [localText, setLocalText] = useState(text);
  const { noteEditorOpen } = useNotes();

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    setLocalText(newValue);
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (node) {
        node.setText(newValue);
      }
    });
  };

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    editor.update(() => {
      const node = $getNodeByKey(nodeKey);
      if (node) {
        node.selectNext();
        editor.dispatchCommand(CLICK_COMMAND, { startTime });
      }
    });
  };

  return (
    <Box sx={{ mb: 2, flex: 1 }}>
      <div
        role="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            handleClick(e);
          }
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          color: '#000',
          cursor: 'pointer',
          padding: '4px 8px 4px 0px',
          borderRadius: '4px',
          marginRight: '8px',
        }}
      >
        <PlayArrowIcon style={{ marginRight: '8px' }} />
        <span style={{ fontWeight: 'bold' }}>{startTime}</span>
      </div>
      <Textarea 
        sx={{ 
          '&.MuiTextarea-root': {
            m: 0,
            color: '#000',
            background: '#e0e0e0',
            border: 'none',
            py: 2,
            px: 2,
            borderRadius: 8,
          },
          '& .MuiTextarea-textarea': {
            color: '#000',
            background: '#e0e0e0',
          }
        }} 
        readOnly={noteEditorOpen ? false : true}
        value={localText}
        onChange={handleTextChange}
      />
    </Box>
  );
}

export function $createCustomSentenceNode(text, startTime) {
  return new CustomSentenceNode(text, startTime);
}