/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import ArrowForward from '@mui/icons-material/ArrowForward';
import TwoSidedLayout from '../Components/Layouts/TwoSidedLayout';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../authContext'; 
import { BASE_PRIVATEAPP_URL } from './../../redirects/index';

export default function HeroLeft01() {
  const { currentUser } = useAuth()
  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentUser) {
      // Redirect to app home page if user still has a session
      if (currentUser.accountId) {
        navigate(BASE_PRIVATEAPP_URL + currentUser.accountId)
      }
    }
  },[currentUser])

  return (
    <TwoSidedLayout>
      <Typography color="primary" variant="solid" fontSize="lg" fontWeight="lg">
         Powered by AI
      </Typography>
      <Typography
        level="h1"
        fontWeight="xl"
        fontSize="clamp(1.875rem, 1.3636rem + 2.1818vw, 3rem)"
      >
        The Experience Modelling App. 
      </Typography>
      <Typography fontSize="lg" textColor="text.secondary" lineHeight="lg">
        Powerful <span className='highlight'>notes</span>, <span className='highlight'>experience documentation</span> and an <span className="highlight">insights repository</span>. smrt cookie is a must-have tool for digital product creators to analyse research, explore, refine product ideas and <span className="highlight">connect thinking effortlessly</span>.
      </Typography>
      <Link to='/sign-up'>
      <Button size="lg" endDecorator={<ArrowForward fontSize="xl" />}>
        Get Started
      </Button>
      </Link>
      <Typography>
        Already a member? <Link fontWeight="lg" to="/login">Sign in</Link>
      </Typography>
    </TwoSidedLayout>
  );
}
