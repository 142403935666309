import { useState } from 'react';
import { Box, Modal, ModalDialog, DialogTitle, Stack, FormControl, FormLabel, Input, Button, Textarea } from '@mui/joy';
import Search from '../../Search';

function CreateConnectionModal({ 
  source,
  setSource,
  sourceTitle,
  setSourceTitle,
  sourceType,
  setSourceType,
  target,
  setTarget,
  targetTitle,
  setTargetTitle,
  targetType,
  setTargetType,
  setComment,
  comment,
  createConnectionModal, 
  setCreateConnectionModal, 
  handleCreateConnection 
}) {

  return (
    <Modal open={createConnectionModal} onClose={() => setCreateConnectionModal(false)}>
      <ModalDialog size="md" sx={{ width: 600 }}>
        <DialogTitle>Create Connection</DialogTitle>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            setCreateConnectionModal(false);
          }}
        >
          <Stack spacing={2}>
            <FormControl>
              <FormLabel>Source</FormLabel>
              {/* <Input defaultValue={source} onChange={(e) => setSource(e.target.value)} size="sm" autoFocus required /> */}
              <Search mode="addlink" setValue={setSource} setLinkTitle={setSourceTitle} />
            </FormControl>
            <FormControl>
              <FormLabel>Target</FormLabel>
              <Search mode="addlink" setValue={setTarget} setLinkTitle={setTargetTitle} />
              {/* <Input defaultValue={target} onChange={(e) => setTarget(e.target.value)} size="sm" required /> */}
            </FormControl>
            <FormControl>
              <FormLabel>Comment (optional)</FormLabel>
              <Textarea defaultValue={comment} onChange={(e) => setComment(e.target.value)} size="sm" minRows={3} />
            </FormControl>
            <Box>
              <Button onClick={handleCreateConnection} type="submit">Create</Button>
            </Box>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  )
}

export default CreateConnectionModal;