import React, { useState, useEffect } from 'react';
import { Box, Typography, Stack, Card, Chip, Input, FormControl, FormLabel, Modal, ModalDialog, ModalClose, DialogContent, Button } from '@mui/joy';
import AspectRatio from '@mui/joy/AspectRatio';
import CardOverflow from '@mui/joy/CardOverflow';
import CardContent from '@mui/joy/CardContent';
import { definitionTypes } from '../../variables';
import AddReactionIcon from '@mui/icons-material/AddReaction'; // feature
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'; // Carousel forward
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Carousel back
import CreateTypeFromInstance from '../DrawerRight/IncitesForTypeInstance/CreateTypeFromInstance';
import FeatureDetailCardView from '../Features/FeatureDetailCardView';
import NoteDetailCardView from '../MyNotes/NoteDetailCardView';
import InciteCardForLists from '../DrawerRight/IncitesForTypeInstance/InciteResults/InciteCardForLists';
import { useParams } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useContextualPane } from '../../contexts/contextualPane';
import ManageAssociationModal from '../DrawerRight/IncitesForTypeInstance/ManageAssociationModal';
import PageSetCards from '../Pages/Components/PageSetCards';
import LinkedPageSetsCards from '../Pages/Components/LinkedPageSetsCards';

export default function LinksComponent({ open }) {

  const { 
    setCreateDocsOpen, 
    setNewDraftAssociation, 
    listAssociationResults, 
    handleAddingTypeAndAssociation, 
    setManageAssociationModalOpen, 
    activeItem, 
    itemPropsAvailableForIncites,
    setSecondaryAssociationMode,
    setSecondNewDraftAssociation,
    setProgressStep,
    quickAddMode,
    setQuickAddMode,
    setSelectedItemInstance,
    selectedItemInstance,
    setItemPropsAvailableForIncites
  } = useContextualPane()

  const [ quickAddModalOpenState, setQuickAddModalOpenState ] = React.useState(false)
  
  const [ loading, setLoading ] = React.useState(false)
  const [ groupedAndSorted, setGroupedAndSorted ] = React.useState({})
  const { accountId } = useParams()

  const [ selectedTypeToFilterby, setSelectedTypeToFilterBy ] = useState('features')
  const [ inputValue, setInputValue ] = React.useState('')

  useEffect(() => {
    if (!open) {
      setSelectedItemInstance({})
      setSelectedTypeToFilterBy('features')
    }
  },[open])

  useEffect(() => {
    if (activeItem) {
      setSelectedItemInstance({ item: activeItem.item, type: activeItem.itemType })
    }
    if (activeItem && activeItem.itemType === "persona") {
      const { name, description, role, image, additionalFields } = { ...activeItem.item };
      setItemPropsAvailableForIncites({ name, description, role, image, additionalFields })
    }
    if (activeItem && activeItem.itemType === "sitemap") {
      const { title, items, description } = { ...activeItem.item };
      setItemPropsAvailableForIncites({ title, description, items })
    }
    
    // TO DO: Set other types here
  },[activeItem])

  useEffect(() => {
    // Create a sorted array of listAssociationResults.definitions grouped by toType
    if (listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.length > 0) {
      const groupedAndSortedDraft = listAssociationResults.definitions.reduce((acc, curr) => {
        // Group by 'toType'
        if (!acc[curr.toType]) {
          acc[curr.toType] = [];
        }
        acc[curr.toType].push(curr);
      
        return acc;
      }, {});
      for (let key in groupedAndSortedDraft) {
        groupedAndSortedDraft[key].sort((a, b) => {
          if (a.referenceData.title < b.referenceData.title) {
            return -1;
          }
          if (a.referenceData.title > b.referenceData.title) {
            return 1;
          }
          return 0;
        });
      }
      //console.log("groupedAndSorted", groupedAndSorted)
      if (groupedAndSortedDraft[selectedTypeToFilterby.slice(0, -1)]) {
        if (groupedAndSortedDraft[selectedTypeToFilterby.slice(0, -1)][0]) {
          setGroupedAndSorted(groupedAndSortedDraft)
          setSelectedItemInstance({ ...selectedItemInstance, item: groupedAndSortedDraft[selectedTypeToFilterby.slice(0, -1)][0], type: selectedTypeToFilterby.slice(0, -1), id: groupedAndSortedDraft[selectedTypeToFilterby.slice(0, -1)][0].id })
        } else {
          setSelectedItemInstance({})
        }        
      } else {
        setSelectedItemInstance({})
      }
    }
    
  },[listAssociationResults?.definitions, selectedTypeToFilterby])

  const handleClickOfFeature = (association) => {
    //console.log("Click of feature", association)
    setSelectedItemInstance({ 
      item: { ...association }
    })
  }

  const handleAddCitationInline = () => {
    //console.log("handleAddCitationInline")
    setSecondaryAssociationMode(true)
    setNewDraftAssociation({
      from: [{
        fromType: '',
        fromId: '',
      }],
      to: [{
        toType: 'strong',
        toId: selectedItemInstance.id,
      }],
      associationType: 'citation',
    })
    setProgressStep(4)
    setManageAssociationModalOpen(true)
  }

  const handleNewItemAndAssociate = (type) => {
    if (type === "page") {
      // as pagesets are required to be selected before pages, even though pages are the thing that is being attributed
      type = "pageset"
    }
    handleAddingTypeAndAssociation({ 
      type: type, 
      typeValues: { title: inputValue }, 
      association: {
        associationType: 'strong',
        fromId: activeItem.item.id,
        fromType: activeItem.itemType,
        toType: type,
      }, 
    });
    // Clear on 'Add X[type]' and clear on 'Add Association' click
    setNewDraftAssociation({})
    setInputValue("");  
  }

  const handleChangeOfInput = (event) => {
    //console.log("handleChange", event.target.value)
    setInputValue(event.target.value);
  }

  const handleAssociateExistingItem = (type) => {
    if (type === "page") {
      // as pagesets are required to be selected before pages, even though pages are the thing that is being attributed
      type = "pageset"
    }
    console.log("Associate new", type)
    
    setNewDraftAssociation({
      toType: type
    })
    // Open modal to add association
    setManageAssociationModalOpen(true)
  }

  return (
    <Card sx={{ minHeight: "68vh", pb: 3 }}>
      <Stack direction="row" alignItems="center">
        <Typography level="title-md" sx={{ mr: 2 }}>Linked documents</Typography>
        { definitionTypes && definitionTypes.map((type) => (
          <Chip key={type.name} variant={selectedTypeToFilterby === type.name ? "solid" : "outlined"} size="lg" startDecorator={type.icon}
            onClick={() => setSelectedTypeToFilterBy(type.name)}
            sx={{ 
              mr: 1, 
              py: 0.5, 
              borderColor: '#f4f1f8',
              cursor: 'pointer',
                '&:hover': {
                  cursor: 'pointer',
                }, }}>
            {type.label}
          </Chip>
        ))}
      </Stack>

      { groupedAndSorted && groupedAndSorted[selectedTypeToFilterby.slice(0, -1)] && groupedAndSorted[selectedTypeToFilterby.slice(0, -1)].length > 0 ?
        <Stack direction="row">
        <Stack direction="column" sx={{ p: 0, borderRadius: 9, minHeight: "60vh", position: 'relative', width: "30%", borderColor: 'divider', mt: 3 }} spacing={1}>
          <Box sx={{ pb: 0 }}>
            {/* LIST ALL EXISTING ASSOCIATIONS FOR THE SELECTED TYPE */}
            {

              groupedAndSorted && groupedAndSorted[selectedTypeToFilterby.slice(0, -1)] && groupedAndSorted[selectedTypeToFilterby.slice(0, -1)].length > 0 &&
              groupedAndSorted[selectedTypeToFilterby.slice(0, -1)].map((association) => (
                  <Card 
                    className={ selectedItemInstance.id === association.id ? 'selected note-item' : 'note-item' }
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'initial',
                      gap: 1,
                      marginBottom: 1,
                      paddingTop: .5,
                      paddingBottom: 1,
                      paddingLeft: 1.5,
                      paddingRight: 1.5,
                      backgroundColor: '#f4f1f8',
                      cursor: 'pointer',
                      '&:hover': { cursor: 'pointer', boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' },
                    }}
                    key={association.id}
                    variant="soft"
                    orientation="vertical"
                    onClick={() => handleClickOfFeature(association)}
                  >
                    <CardContent>
                      <Stack direction="column" spacing={1}>
                        <Box sx={{ flex: 1 }}>
                          <Typography level="body-sm">{ association.referenceData.title || association.referenceData.noteTitle }</Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
              ))
            }
          </Box>
        </Stack>

        <Stack direction="column" sx={{ mt: 3, width: '100%', ml: 2, height: '94%', position: 'relative' }}>
          { selectedItemInstance && selectedItemInstance.item && (
            <>
            { selectedItemInstance.item.toType === 'feature' && (
              <FeatureDetailCardView feature={selectedItemInstance.item} handleAddCitationInline={handleAddCitationInline} setSelectedItemInstance={setSelectedItemInstance} />
            )}
            { selectedItemInstance.item.toType === 'note' && (
              <NoteDetailCardView note={selectedItemInstance.item} handleAddCitationInline={handleAddCitationInline} setSelectedItemInstance={setSelectedItemInstance} />
            )}
            { selectedItemInstance.item.toType === 'page' && (
              // <NoteDetailCardView note={selectedItemInstance.item} handleAddCitationInline={handleAddCitationInline} setSelectedItemInstance={setSelectedItemInstance} />
              <LinkedPageSetsCards pageSet={selectedItemInstance.item} handleAddCitationInline={handleAddCitationInline} setSelectedItemInstance={setSelectedItemInstance} />
            )}
            { selectedItemInstance.item.toType === 'user-journey' && (
              <Box sx={{ 
                backgroundColor: '#f4f1f8', 
                borderRadius: 12, 
                p: 1, 
                px: 5,
                width: '100%', 
                position: 'absolute', 
                bottom: 0, 
                left: 0,
              }} className="container-for-strong-citations">
                <Slider
                  {...{
                    dots: false,
                    infinite: false,
                    speed: 250,
                    swipeToSlide: true,
                    slidesToShow: 1.5,
                    centerMode: false,
                    slidesToScroll: 1,
                    nextArrow: <ArrowForwardIcon />,
                    prevArrow: <ArrowBackIcon />
                  }}
                  className="relative-citations-slider"
                >
                  <InciteCardForLists />
                  <InciteCardForLists />
                </Slider>
              </Box>
            )}
            </>
          )}
        </Stack>
      </Stack>
      :  
      <Stack direction="row">
        <Card sx={{ py: 2, mt: 3 }}>
          {/* TO DO Content to example about linked documents and citations */}
          <Typography sx={{ pb: 0, mb: 0 }} level="h3">There are no linked documents yet.</Typography>
          <Typography level="body-md" sx={{ mt: 0, mb: 0 }}>Select a document type above, and click on 'Link' or 'Create' below to get start linking other documents to this one.</Typography>
        </Card>
      </Stack>
      }
      
      <Stack direction="row" sx={{ position: 'sticky', bottom: 0, left: 0, width: 1, zIndex: 9999 }}>
        <Stack direction="column" spacing={1} sx={{ minWidth: '100%', display: { xs: 'flex', } }}>
          <Card sx={{ p: 2, background: '#fff' }}>
            { selectedTypeToFilterby !== 'pages' &&
              <FormControl>
                <FormLabel sx={{  }}>Add new {selectedTypeToFilterby.slice(0, -1)} and autolink</FormLabel>
                <Input value={inputValue} onChange={handleChangeOfInput} placeholder={`New ${selectedTypeToFilterby.slice(0, -1)} title`} sx={{ mb: 0, pb: 0 }} />
              </FormControl>
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button size="sm" variant="outlined" startDecorator={<AddReactionIcon />} onClick={() => handleAssociateExistingItem(selectedTypeToFilterby.slice(0, -1))}>
                Link {selectedTypeToFilterby.slice(0, -1)}
              </Button>
              { selectedTypeToFilterby !== 'pages' &&
                <Button size="sm" sx={{ ml: 'auto' }} onClick={() => handleNewItemAndAssociate(selectedTypeToFilterby.slice(0, -1))} variant="outlined" startDecorator={<AddReactionIcon />}>
                  Create new
                </Button>
              }
            <Modal size="md" open={quickAddModalOpenState}>
              <ModalDialog sx={{ backgroundColor: '#F4F1F8' }}>
                <ModalClose variant="soft" onClick={() => setQuickAddModalOpenState(false)} sx={{ zIndex: 9999 }} />
                <DialogContent>
                  <CreateTypeFromInstance modalMode={true} />
                </DialogContent>
              </ModalDialog>
            </Modal>
            </Box>
          </Card>
        </Stack>
      </Stack>
      <ManageAssociationModal />
    </Card>
  );
}