import React, { useState, createContext, useContext } from 'react';

// Create the context
const LoadingContext = createContext();

// Create a provider component
export function LoadingProvider({ children }) {
  const [running, setRunning] = useState(false);

  const value = {
    running,
    setRunning
  };

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useLoading() {
  return useContext(LoadingContext);
}