import {
  closestCorners,
  getFirstCollision,
  KeyboardCode,
  KeyboardCoordinateGetter,
  DroppableContainer,
} from '@dnd-kit/core';

import {getProjection} from './utilities';

const directions = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
];

const horizontal = [KeyboardCode.Left, KeyboardCode.Right];

export const sortableTreeKeyboardCoordinates = (
  context,
  indicator,
  indentationWidth
) => (event, {
  currentCoordinates,
  context: {
    active,
    over,
    collisionRect,
    droppableRects,
    droppableContainers
  }
}) => {
  if (directions.includes(event.code)) {
    if (!active || !collisionRect) {
      return;
    }

    event.preventDefault();

    const {
      current: {
        items,
        offset
      }
    } = context;

    if (horizontal.includes(event.code) && over?.id) {
      const {
        depth,
        maxDepth,
        minDepth
      } = getProjection(
        items,
        active.id,
        over.id,
        offset,
        indentationWidth
      );

      switch (event.code) {
        case KeyboardCode.Left:
          if (depth > minDepth) {
            return {
              ...currentCoordinates,
              x: currentCoordinates.x - indentationWidth
            };
          }
          break;
        case KeyboardCode.Right:
          if (depth < maxDepth) {
            return {
              ...currentCoordinates,
              x: currentCoordinates.x + indentationWidth
            };
          }
          break;
        default:
          break;
      }

      return undefined;
    }

    const containers = [];

    droppableContainers.forEach(container => {
      if (container?.disabled || container.id === over?.id) {
        return;
      }

      const rect = droppableRects.get(container.id);

      if (!rect) {
        return;
      }

      switch (event.code) {
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            containers.push(container);
          }
          break;
        case KeyboardCode.Up:
          if (collisionRect.top > rect.top) {
            containers.push(container);
          }
          break;
        default:
          break;
      }
    });
  }

  return undefined;
};