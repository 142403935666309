import React from 'react';
import { Box, Card, Typography, Divider, FormLabel, FormControl, Input, IconButton, CardOverflow, CardActions, Button, AspectRatio, Stack } from '@mui/joy';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../../configs/firebaseConfig';
import { v4 } from 'uuid';
import ReactLoading from 'react-loading';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import { useAuth } from '../../../../authContext';
import AccountAvatar from './../../Navigations/Account/AccountAvatar';
import UploadIconButton from '../../FileUploadButton/UploadIconButton';

function SettingsTab({ showSnackbar, setShowSnackbar, snackbarSeverity, setSnackbarSeverity, snackbarMsg, setSnackbarMsg }) {

  const { currentUser, updateProfile } = useAuth()
  const [ image, setImage ] = React.useState(currentUser._delegate.photoURL)
  const [ imageUpload, setImageUpload ] = React.useState(null)
  const [ imageUploading, setImageUploading ] = React.useState(false)
  const [ name, setName ] = React.useState('')
  const [ email, setEmail ] = React.useState('')

  const handleChangeName = (e) => {
    setName(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async () => {
    //console.log("name", name)
    //console.log("email", email)
    //console.log("image", image)
    // Check email is a valid email
    if (email !== "" && !email.includes('@')) {
      setSnackbarSeverity('danger')
      setSnackbarMsg('Please enter a valid email address')
      setShowSnackbar(true)
      return
    }
    const result = await updateProfile({ name: name, email: email, image: image })
    //console.log("result of updating profile", result)
    if (result) {
      setSnackbarSeverity('success')
      setSnackbarMsg('Profile updated successfully')
      setShowSnackbar(true)
    } else {
      setSnackbarSeverity('danger')
      setSnackbarMsg('Error updating profile')
      setShowSnackbar(true)
    }
  }

  const handleImageUpload = (file) => {
    //console.log("Triggered handleImageUpload", file)
    setImageUpload(file)
  }

  const setDefaultValues = () => {
    if (email === "") {
      setEmail(currentUser._delegate.email)
    } 
    if (name === "" && currentUser._delegate && currentUser._delegate.displayName) {
      setName(currentUser._delegate.displayName)
    }
    if (name === "" && currentUser._delegate && currentUser._delegate.photoURL) {
      setImage(currentUser._delegate.photoURL)
    }
  }

  React.useEffect(() => {

    const uploadImage = async (imageUpload) => {

        //console.log("imageUpload", imageUpload)
        const imageRef = ref(storage, `users/` + currentUser._delegate.uid + `/profileImages/` + v4() )
        try {
          await uploadBytes(imageRef, imageUpload).then((result) => {
            //console.log("image uploaded", result)
            const downloadUrl = getDownloadURL(result.ref).then((imageUrl) => {
              //console.log("downloadUrl", imageUrl)
              setImage(imageUrl)
            })        
          })
          setImageUploading(false)
        } catch (error) {
          //console.log("error uploading image", error)
          setImageUploading(false)
        }
    }

    if (imageUpload !== null) {
      if (imageUpload && imageUpload.name) {
        setImageUploading(true)
        uploadImage(imageUpload)
      }
    }
  },[imageUpload])

  React.useEffect(() => {
    setDefaultValues()
  }, [])


  return (
    <Stack
      spacing={4}
      sx={{
        display: 'flex',
        maxWidth: '800px',
        mx: 'auto',
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 },
      }}
    >
      {/* Insert Edit Account Module (if account owner) here */}
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Personal info</Typography>
          <Typography level="body-sm">
            If you want to change your profile information, make the edits below and hit 'Save' below.
          </Typography>
        </Box>
        <Divider />
        <Stack
          direction="row"
          spacing={3}
          sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
        >
          <Stack direction="column" spacing={1}>
            <AspectRatio
              ratio="1"
              maxHeight={200}
              sx={{ flex: 1, alignItems: 'center', justifyContent: 'center', minWidth: 120, borderRadius: '100%' }}
            >
              <Box className={ imageUploading && 'imageLoading' } sx={{ position: 'relative' }}>
                { imageUploading && <ReactLoading className="spinnyLoader" type={'spin'} /> }
                <AccountAvatar sx={{ position: "relative", zIndex: "1"}} size="120px" src={image} />
              </Box>
            </AspectRatio>
            {/* Insert Icon Upload Btn here */}
            <UploadIconButton onChangeHandler={ handleImageUpload } />
          </Stack>
          <Stack spacing={2} sx={{ flexGrow: 1 }}>
            <Stack spacing={1}>
              <FormLabel>Name</FormLabel>
              <FormControl
                sx={{ display: { sm: 'flex-column', md: 'flex-row' }, gap: 2 }}
              >
                <Input onChange={(e) => handleChangeName(e)} size="sm" placeholder="Type your name here" defaultValue={ currentUser && currentUser._delegate.displayName ? currentUser._delegate.displayName : '' } />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormLabel>Email</FormLabel>
                <Input
                  size="sm"
                  type="email"
                  startDecorator={<EmailRoundedIcon />}
                  placeholder="email"
                  onChange={(e) => handleChangeEmail(e)}
                  defaultValue={ currentUser._delegate.email }
                  sx={{ flexGrow: 1 }}
                />
              </FormControl>
            </Stack>
            {/* TO DO Implement Role */}
            {/* <Stack direction="row" spacing={2}>
              <FormControl sx={{ flexGrow: 1 }}>
                <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Input size="sm" defaultValue="UI Developer" />
                </FormControl>
              </FormControl>
            </Stack> */}
          </Stack>
        </Stack>
        <Stack
          direction="column"
          spacing={2}
          sx={{ display: { xs: 'flex', md: 'none' }, my: 1 }}
        >
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={1}>
              <AspectRatio
                ratio="1"
                maxHeight={108}
                sx={{ flex: 1, minWidth: 108, borderRadius: '100%' }}
              >
                <AccountAvatar size="120px" src={image} />
              </AspectRatio>
              <IconButton
                aria-label="upload new picture"
                size="sm"
                variant="outlined"
                color="neutral"
                sx={{
                  bgcolor: 'background.body',
                  position: 'absolute',
                  zIndex: 2,
                  borderRadius: '50%',
                  left: 85,
                  top: 180,
                  boxShadow: 'sm',
                }}
              >
                <EditRoundedIcon />
              </IconButton>
            </Stack>
            <Stack spacing={1} sx={{ flexGrow: 1 }}>
              <FormLabel>Name</FormLabel>
              <FormControl
                sx={{
                  display: {
                    sm: 'flex-column',
                    md: 'flex-row',
                  },
                  gap: 2,
                }}
              >
                <Input size="sm" placeholder="Name" onChange={(e) => handleChangeName(e)} defaultValue={ currentUser && currentUser._delegate.displayName ? currentUser._delegate.displayName : '' } />
              </FormControl>
            </Stack>
          </Stack>
          <FormControl sx={{ flexGrow: 1 }}>
            <FormLabel>Email</FormLabel>
            <Input
              size="sm"
              type="email"
              startDecorator={<EmailRoundedIcon />}
              placeholder="email"
              onChange={(e) => handleChangeEmail(e)}
              defaultValue={ currentUser._delegate.email }
              sx={{ flexGrow: 1 }}
            />
          </FormControl>
          {/* TO DO IMPLEMENT ROLE */}
          {/* <FormControl>
            <FormLabel>Role</FormLabel>
            <Input size="sm" defaultValue="UI Developer" />
          </FormControl> */}
        </Stack>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button onClick={handleSubmit} size="sm" variant="solid">
              Save
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}

export default SettingsTab;