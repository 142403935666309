import React from 'react';
import { Stack, Typography, Card, Box, ButtonGroup, Button, Link } from '@mui/joy';
import InciteCardForLists from '../../../DrawerRight/IncitesForTypeInstance/InciteResults/InciteCardForLists';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../../../redirects';

import { deleteAssociationFromFirestore } from '../../../../database/associations-db';
import { useContextualPane } from '../../../../contexts/contextualPane';
import EditLinkDropdown from '../../../Dropdowns/EditLink';

export default function LinkedPageSetsCards({ pageSet, handleAddCitationInline, setSelectedItemInstance }) {

    const navigate = useNavigate();
    const { accountId, selectedProjectId } = useParams();
    const { handleFetchOfAssociationTypes } = useContextualPane();

    const handleClickLink = (id) => {
        navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '?noteId=' + id);
    }

    const handleDeleteLink = async (association) => {
        //console.log("handleDeleteLink with association id ", association.id)
        await deleteAssociationFromFirestore({ accountId, associationId: association.id })
        setSelectedItemInstance({})
        await handleFetchOfAssociationTypes()
    }

    return (
        <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#f4f1f8', border: 'none', minHeight: 200 }}>
            <Stack spacing={2}>
                {pageSet && pageSet.referenceData && pageSet.referenceData.title &&
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h4" sx={{ mb: 0, pb: 0, ml: 0, pl: 0 }} component="h2">
                        <Link sx={{ pl: 0, pr: 0 }} color="primary" variant="plain" onClick={() => handleClickLink(pageSet.toId)}>{pageSet.referenceData.title}</Link>
                    </Typography>
                    <EditLinkDropdown item={pageSet} />
                </Box>
                }                
            </Stack>
            <Stack spacing={2} sx={{ mt: 2 }}>
                <Box sx={{ ml: 4 }}>
                    { 
                        pageSet.citationsData && 
                        pageSet.citationsData.length > 0 && (
                            <Typography level="body-md" sx={{ mt: 3, mb: 1 }}>References</Typography>
                        ) &&
                        pageSet.citationsData.map((citation) => (
                        citation && citation.id &&
                        <InciteCardForLists citation={citation} />
                        ))
                    }
                    <ButtonGroup sx={{ mt: 2, justifyContent: 'flex-end' }}>
                        <Button size="sm" onClick={() => handleAddCitationInline()} sx={{ mr: 1 }} variant="outlined">
                        Add Incite
                        </Button>
                        <Button size="sm" variant="outlined">
                        Link Incite
                        </Button>
                    </ButtonGroup>
                </Box>
            </Stack>
        </Card>
    )
}