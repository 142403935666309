import * as React from 'react';
import { Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Input from '@mui/joy/Input';
import FeatureModalContent from './FeatureModalContent';
import Stack from '@mui/joy/Stack';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Button from '@mui/joy/Button';

export default function FeatureModal({open, setOpen, handleUpdateCurrentFeature, handleFeatureDeletion, feature}) {

  const [featureFields, setFeatureFields] = React.useState(feature ? feature : {})

  React.useEffect(() => {
    if (feature) {
      setFeatureFields(feature)
    }
  },[feature])

  return (
    <React.Fragment>
      <Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={() => setOpen(false)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: 'blur(2px)' },
                    entered: { opacity: 1, backdropFilter: 'blur(2px)' },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === 'exited' ? 'hidden' : 'visible',
            }}
          >
            <ModalDialog
              sx={{
                width: '60%',
                opacity: 0,
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogContent>
                <FeatureModalContent featureFields={featureFields} setFeatureFields={setFeatureFields} />
              </DialogContent>
              <Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                  <CardActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                    <Button size="sm" variant='outlined' color="danger" onClick={() => handleFeatureDeletion(featureFields)}>
                      Delete feature
                    </Button>
                    <div>
                    <Button size="sm" variant="outlined" color="neutral" onClick={() => setOpen(false)} sx={{ mr: 2 }}>
                      Cancel
                    </Button>
                    <Button sx={{ alignSelf: 'flex-end' }} onClick={() => handleUpdateCurrentFeature(featureFields)} size="sm" variant="solid">
                      Save
                    </Button>  
                    </div>
                  </CardActions>
                </CardOverflow>
              </Stack>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </React.Fragment>
  );
}