import * as React from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Tooltip from '@mui/joy/Tooltip';
import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';
import DialogTitle from '@mui/joy/DialogTitle';
import List from '@mui/joy/List';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import MobileNav from '../Navigations/Header/Navigation';
import SwitchProject from '../Navigations/Components/SwitchProject';
import Search from '../Search';
import AccountNavigation from '../Navigations/Account';
import DoneIcon from '@mui/icons-material/Done';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/joy/Badge';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import { IncitefulLogo } from './../../../PublicSite/Components/IncitefulLogo/index';
import { useMultiTasking } from '../../contexts/multiTaskingContext';
import { useTodos } from '../../contexts/todoContext';

export default function FullWidthHeader() {
  const [open, setOpen] = React.useState(false);
  const [value,setValue] = React.useState();
  const navigate = useNavigate();
  const { accountId, selectedProjectId, boardId } = useParams() 
  const { addPanel, panels, clearPanels } = useMultiTasking();
  const location = useLocation();
  const { fetchToDos, overdueCount } = useTodos();

  const handleNavigateToDos = () => {
    const todosPath = `${BASE_PRIVATEAPP_URL}${accountId}/${selectedProjectId}/todos`;
    if (location.pathname !== todosPath) {
      // If already on the todos page, do nothing, otherwise check if panel is already open then add the panel
      const panelAlreadyOpen = panels.find(panel => panel.type === 'todos');
      if (!panelAlreadyOpen) {
        addPanel({
          type: 'todos',
          content: null,
          title: 'To Dos',
        });
      } else {
        // If panel is already open, close it
        clearPanels();
      }
    }
  }

  React.useEffect(() => {
    if (accountId && selectedProjectId) {
      fetchToDos({ accountId, selectedProjectId })
    }
  }, [accountId, selectedProjectId])

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        px: 2,
        pl: 1.8,
        py: 1,
        backgroundColor: '#F4F1F8', // Ivory: FFFFF0, Lavender: F4F1F8
        borderBottom: '1px solid',
        borderColor: '#E5E7EB',
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{ display: { xs: 'none', sm: 'flex' } }}
      >
        {/* TO DO: Account name */}
        {/* <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          <Typography level="title-lg">smart cookie</Typography>
        </Box> */}

        {/* Insert switch project */}
        <List
          size="sm"
          sx={{
            minWidth: '215px',
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          <SwitchProject />
        </List>

        {/* Insert search */}
        <Search value={value} setValue={setValue} />
        
      </Stack>
      <Box sx={{ display: { xs: 'inline-flex', sm: 'none' } }}>
        <IconButton variant="plain" color="neutral" onClick={() => setOpen(true)}>
          <MenuRoundedIcon />
        </IconButton>
        <Drawer
          sx={{ display: { xs: 'inline-flex', sm: 'none' } }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <ModalClose />
          <DialogTitle><IncitefulLogo /></DialogTitle>
          <Box sx={{ px: 1 }}>
            {/* Mobile menu */}
            <MobileNav closeSidebar={setOpen} />
          </Box>
        </Drawer>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        
        {/* <Tooltip title="View saved items" variant="outlined">
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            component="a"
            href="/blog/first-look-at-joy/"
            sx={{ alignSelf: 'center' }}
          >
            <BookRoundedIcon />
          </IconButton>
        </Tooltip> */}
        {/* <ColorSchemeToggle /> */}

        {/* Publish site */}
        {overdueCount > 0 ? (
          <Tooltip variant="outlined" color="neutral" title={ overdueCount + " Overdue To Dos" }>
            <Badge
              badgeContent={overdueCount}
              color="danger"
              size="sm"
              sx={{ 
                '& .MuiBadge-badge': {
                  fontSize: '0.75rem',
                  minWidth: '20px',
                  height: '20px'
                }
              }}
            >
              <IconButton onClick={handleNavigateToDos}>
                <DoneIcon />
              </IconButton>
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip variant="outlined" color="neutral" title="To Dos">
            <IconButton onClick={handleNavigateToDos}>
              <DoneIcon />
            </IconButton>
          </Tooltip>
        )}

        <Tooltip variant="outlined" color="neutral" title="Notifications">
          <IconButton
            size="sm"
            variant="plain"
            color="neutral"
            sx={{ mr: 1.5, alignSelf: 'center' }}
          >
            <NotificationsIcon />
          </IconButton>
        </Tooltip>

        {/*  Insert account navigation */}
        <Tooltip title="Account &amp; Help" variant="outlined">
          <div>
            <AccountNavigation />
          </div>
        </Tooltip>
      </Box>
    </Box>
  );
}