import React, {CSSProperties} from 'react';
import {AnimateLayoutChanges, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

import {TreeItem, Props as TreeItemProps} from './TreeItem';
import {iOS} from '../../utilities';
import { useContextualPane } from '../../../../contexts/contextualPane';

const animateLayoutChanges = ({isSorting, wasDragging}) =>
  isSorting || wasDragging ? false : true;

export function SortableTreeItem({id, handleClick, editingId, depth, editLabel, onRemove, newValue, handleEditValue, mode, handleChangeValue, handleSubmit, ...props}) {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  });
  const { listAssociationResults } = useContextualPane();
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const checkIfAssociationHasMatchingElement = ({ associationElement, id }) => {
    if (associationElement === id) {
      return true;
    } else {
      return false;
    }
  }

  const [ hasLink, setHasLink ] = React.useState({ hasLink: false, howMany: 0 });

  React.useEffect(() => {

    let resultFound = false;
    let pendingAmountFound = 0;

    if (listAssociationResults && listAssociationResults.definitions && listAssociationResults.definitions.length > 0) {
      listAssociationResults.definitions.map((association) => {
        const result = checkIfAssociationHasMatchingElement({ associationElement: association.element, id })
        if (result) {
          // If so, set a flag and increment the value of pendingAmountFound
          resultFound = true;
          pendingAmountFound++;
        }
      })
      if (resultFound) {
        setHasLink({ hasLink: true, howMany: pendingAmountFound });
      } else {
        setHasLink({ hasLink: false, howMany: 0 });
      }
    }

  }, [listAssociationResults]);
  

  return (
    <TreeItem
      ref={setDraggableNodeRef}
      id={id}
      wrapperRef={setDroppableNodeRef}
      style={style}
      handleClick={handleClick}
      depth={depth}
      mode={mode}
      ghost={isDragging}
      hasLink={hasLink}
      disableSelection={iOS}
      disableInteraction={isSorting}
      editLabel={editLabel}
      newValue={newValue}
      handleEditValue={handleEditValue}
      handleChangeValue={handleChangeValue}
      onRemove={onRemove}
      editingId={editingId}
      handleSubmit={handleSubmit}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      {...props}
    />
  );
}