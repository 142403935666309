import { Card, Link, Typography } from "@mui/joy"
import { TimeAgo } from "../../../../utils/utils";

export default function JourneyCard({journey,handleJourneyClick}) {
  return (
    <Card 
      variant="soft" 
      size="sm" 
      className="note-item"
      sx={{ 
        minHeight: 100, 
        backgroundColor: '#fbf8ff',
          '&:hover': { 
            boxShadow: 'md', 
            backgroundColor: '#fff', 
            borderColor: 'neutral.outlinedHoverBorder' 
          }, 
        marginBottom: 1,
        paddingTop: .5,
        paddingBottom: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
      }}
    >
      <Link variant="plain" overlay to={`/navigation/${journey.id}`} onClick={() => handleJourneyClick(journey)}>
        {journey && journey.title && journey.title}
      </Link>
      {/*  TO DO handle when no timestamp yet */}
      {/* <Typography level="body-xs" sx={{ color: 'grey' }}>Last updated <TimeAgo timestamp={journey.updated} /></Typography> */}
      {/* <IncitesForTypeInstance item={persona} itemType="persona" /> */}
      {/* <IncitesForTypeInstance item={sitemap} itemType="sitemap" /> */}
    </Card>
  )
}