import * as React from 'react';
import { Box, Grid, Card, Typography, Link } from '@mui/joy';
import SimpleLinkCard from '../../../SimpleLinkCard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DoneIcon from '@mui/icons-material/Done';
import PersonIcon from '@mui/icons-material/Person'; // personas
import GolfCourseIcon from '@mui/icons-material/GolfCourse'; // user journeys
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'; // features
import AutoStoriesIcon from '@mui/icons-material/AutoStories'; // pages and content
import SchemaIcon from '@mui/icons-material/Schema'; // navigations
import ForkRightIcon from '@mui/icons-material/ForkRight'; // user flows
import { useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from './../../../../../redirects/index';
import { useProjects } from '../../../../contexts/projectsContext';

function Generated({ data, setOpen }) {

  const { accountId } = useParams();
  const { handleSwitchSelectedProject } = useProjects();

  const handleClick = (pathname) => {
    handleSwitchSelectedProject(data.id, pathname)
    setOpen(false)
  }

  return (
    data &&
    <Grid lgOffset={3} item xs={6}>
      {/* Heading */}
      <Box sx={{ mt: 5, justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" color="text.secondary" sx={{ color: '#724F9B', textAlign: 'center', fontSize: '2.5rem', mt: 1, ml: 1 }}>Hey Presto! Project Created</Typography>
        <Box sx={{ mt: 2, ml: 2 }}><DoneIcon sx={{ mt: .5, color: '#724F9B', fontSize: "2.5rem" }} /></Box>
      </Box>
      {/* Loading Content */}
      <Box sx={{ mt: 5, p: 2, pt: 4, pb: 5, backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>
        <Typography level="title-md" sx={{ mb: 5 }}><Link onClick={() => handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + data.id)} variant="plain">[Project name] created!</Link> Where to first?</Typography>
        <Box sx={{ mt: 2, pb: 2, px: 4 }}>
          <Grid spacing={6} container>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id} text='View summarised files' icon={<AssignmentIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id + '/todos'} text='View to dos' icon={<DoneIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id + '/personas'} text='View personas' icon={<PersonIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id + '/journeys'} text='View user journeys' icon={<GolfCourseIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id + '/features'} text='View features' icon={<RocketLaunchIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id + '/pages'} text='View pages and content' icon={<AutoStoriesIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            <Grid xs={6}>
              <SimpleLinkCard handleClick={handleClick} link={BASE_PRIVATEAPP_URL + accountId + '/' + data.id + '/ia'} text='View navigations' icon={<SchemaIcon sx={{ color: '#724F9B' }} />} />
            </Grid>
            {/* <Grid xs={6}>
              <SimpleLinkCard text='View user flows' icon={<ForkRightIcon sx={{ color: '#724F9B' }} />} />
            </Grid>               */}
          </Grid>        
        </Box>
      </Box>
    </Grid>
  )
}

export default Generated;