import React, { useState, useEffect, createContext, useContext } from 'react';
import { useContextualPane } from './contextualPane';

// Create the context
const BrowseTypesContext = createContext();

// Create a provider component
export function BrowseTypesProvider({ children }) {

  const { listAssociationResults, listAssociationResultsForType } = useContextualPane();

  const [ selected, setSelected ] = useState(null); // used for 1st tab Browse Documents tab
  const [ index, setIndex ] = React.useState(0); // used for controlling which of the tabs should be seen
  const [ currentSelectedType, setCurrentSelectedType ] = useState("notes"); // used for 1st tab Browse Documents tab
  const [ linkData, setLinkData ] = useState({}); // used for 1st tab Browse Documents tab
  const [ selectedIdAndValue, setSelectedIdAndValue ] = useState({}); // used for 1st tab Browse Documents tab

  const value = {
    selected,
    setSelected,
    index,
    setIndex,
    currentSelectedType,
    setCurrentSelectedType,
    linkData,
    setLinkData,
    selectedIdAndValue, 
    setSelectedIdAndValue
  };

  return <BrowseTypesContext.Provider value={value}>{children}</BrowseTypesContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useBrowseTypes() {
  return useContext(BrowseTypesContext);
}