import React, { useEffect, useState } from 'react' 
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import Tooltip from '@mui/joy/Tooltip';
import { googleLogout } from '@react-oauth/google';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import ListDivider from '@mui/joy/ListDivider';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import AccountAvatar from './AccountAvatar';
import { BASE_PRIVATEAPP_URL } from './../../../../redirects/index';
import StyleIcon from '@mui/icons-material/Style';
import GroupsIcon from '@mui/icons-material/Groups';
import { Chip } from '@mui/joy';
import { useAuth } from '../../../../authContext';
import { isAdminAndAbove, isSuperAdmin } from '../../../utils/utils';

export default function AccountNavigation() {

  const navigate = useNavigate();
  const { currentUser, logout } = useAuth();
  const { accountId, selectedProjectId } = useParams()

  const [ open, setOpen ] = React.useState(false)

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);

  const handleLogout = async () => {
    //console.log("auth", auth)
    // if logged in state is via google sign in currentUser object has select_by: 'btn'
    if (currentUser.select_by === 'btn') {
      googleLogout()
    } else {
      await logout()
    }    
    // else must be logged in with email/password
    //console.log("attempting logout")
    navigate('/')
  }

  const handleClick = (path) => {
    //console.log("handling click", path)
    // Redirect the user to the desired URL
    navigate(path);
  };

  return (
    <>
      { currentUser &&
      <Dropdown onOpenChange={handleOpenChange} open={open}>
        <MenuButton
          variant="plain"
          size="sm"
          sx={{ 
              maxWidth: '32px', 
              maxHeight: '32px', 
              borderRadius: '9999999px', 
              mt: currentUser && currentUser._delegate && currentUser._delegate.photoURL ? 1 : 0,
              top: 0,
              border: 
                '2px solid transparent', 
                ':hover': {
                  border: '2px solid #000' // Change this to the color and size of the border you want
                }
          }}
        >
          <AccountAvatar />
        </MenuButton>
        <Menu
          placement="bottom-end"
          size="sm"
          sx={{
            zIndex: '99999',
            p: 1,
            gap: 1,
            '--ListItem-radius': 'var(--joy-radius-sm)',
          }}
        >
          <MenuItem onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account') }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* INSERT account avatar here */}
                <AccountAvatar />
                <Box sx={{ ml: 1.5 }}>
                  <Typography level="title-sm" textColor="text.primary">
                    { currentUser._delegate.displayName && currentUser._delegate.displayName }
                  </Typography>
                  <Typography level="body-xs" textColor="text.tertiary">
                    { currentUser._delegate.email && currentUser._delegate.email }
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <ListDivider />
            <MenuItem>
              <HelpRoundedIcon />
              Help
            </MenuItem>
            { currentUser && currentUser.plan === 'team' && (
            <MenuItem onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=team') }}>
              <GroupsIcon />
              Team
            </MenuItem>
            )}
            <MenuItem onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=settings') }}>
              <SettingsRoundedIcon />
              Settings
            </MenuItem>
            {/* If user is admin, then make the Manage Plan link visible, otherwise just show the text */}
            { currentUser && isAdminAndAbove(currentUser) ? (
              <MenuItem onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=plan') }}>
                <StyleIcon />
                Manage Plan { currentUser.plan && currentUser.planLabel && <Chip size="sm" variant="soft" color="neutral" startIcon={<SettingsRoundedIcon />}>{currentUser.planLabel}</Chip> }
              </MenuItem>
            ):(
              <MenuItem>
                <StyleIcon />
                Manage Plan { currentUser.plan && currentUser.planLabel && <Chip size="sm" variant="soft" color="neutral" startIcon={<SettingsRoundedIcon />}>{currentUser.planLabel}</Chip> }
              </MenuItem>
            )}
            { currentUser && isSuperAdmin(currentUser) && (
              <MenuItem onClick={() => { handleClick(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=super') }}>
                Super admin
              </MenuItem>
            )}
            <ListDivider />
            {/*  TO DO Implement real link here */}
            <MenuItem component="a" href="#">
              Learn about smrt cookie
              <OpenInNewRoundedIcon />
            </MenuItem>
            {/*  TO DO Implement real link here */}
            <MenuItem
              component="a"
              href="#"
            >
              Help &amp; Support
              <OpenInNewRoundedIcon />
            </MenuItem>
            <ListDivider />
            <MenuItem onClick={handleLogout}>
              <LogoutRoundedIcon />
              Log out
            </MenuItem>
          </Menu>
        </Dropdown>
        }
      </>
  )
}