import { useEffect, useState } from "react";
import Sheet from '@mui/joy/Sheet';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import DefinitionBanner from "../../Components/Banners/DefinitionBanner"
import FeedbackSnackbar from "../../Components/Snackbar";
import { deleteFeature, retrieveFeaturesFromFirestore, saveToFirestore } from "../../database/features-db";
import FeaturesListCards from './../../Components/Features/index';
import FeatureModal from "../../Components/Features/FeatureModal";
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { getConfig } from "../../database/configs-db";

export default function FeaturesList() {

  const { accountId, selectedProjectId } = useParams()
  const [feature, setFeature] = useState({})
  const [features, setFeatures] = useState()
  const [open, setOpen] = useState(false);
  const [banner, setBanner] = useState('')

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = useState('')

  const handleCreateFeature = () => {
    setFeature({})
    setOpen(true)
  }

  const handleShowSnackbar = ({ reason, message }) => {
    if (reason === 'success') {
      setSnackbarSeverity('success')
      setSnackbarMsg(message)
      setShowSnackbar(true)
    }
  }

  const handleUpdateCurrentFeature = async (values) => {
    // handles remote save
    const response = await saveToFirestore({ feature: values, accountId: accountId, selectedProjectId: selectedProjectId })
    ////console.log("response from saving persona", response)
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Feature updated'})
    const newFeatures = await retrieveFeaturesFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newFeatures) {
      setFeatures(newFeatures)
    }
  }

  const handleFeatureDeletion = async (values) => {
    // handles remote deletion
    const response = await deleteFeature({ featureId: values.id, accountId: accountId })
    ////console.log("response from saving feature", response)
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Feature deleted'})
    const newFeature = await retrieveFeaturesFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newFeature) {
      setFeatures(newFeature)
    }
  }

  const handleSetFeature = (value) => {
    setFeature(value)
    setOpen(true)
  }

  useEffect(() => {
    const fetchFeatures = async () => {
      const results = await retrieveFeaturesFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
      // //console.log("personas fetched", results)
      if (results.length > 0) { setFeatures(results)} 
    }
    fetchFeatures()
    getConfig().then(config => setBanner(config.featuresImage))
  },[])

  useEffect(() => {
    ////console.log("Persona set ", persona)
  },[feature])

  useEffect(() => {
    //console.log("Selected project changed, refetch personas")
    const fetchData = async () => {
      try {
        const data = await retrieveFeaturesFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId});
        return data;
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        throw error; // Rethrow the error so that the outer catch block can handle it
      }
    };
    if (selectedProjectId) {
      const getData = async () => {
        try {
          const data = await fetchData();
          // if item was deleted, then select something again
          // if item was updated, then maintain selection
          // selectedNote will be emptied on deletion of record, so use this to tell
          if (data.length > 0) {
            setFeatures(data);
          } else {
            setFeatures([]);
          }
        } catch (error) {
          // Handle the error if needed
          //console.log("Error fetching features", error)
        }
      };
      getData();
    }
  },[selectedProjectId])

  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        gridTemplateColumns: {
          xs: '1fr',
          //sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          sm: '1fr',
        },
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pb={1.5}
        backgroundColor="#f4f1f8"
      >
        <DefinitionBanner 
          title="Features" 
          // text="A feature, in the context of software development, refers to a distinct, individual functionality or characteristic that a software product or system offers to its users. Features are specific components or capabilities that contribute to the overall functionality and utility of the software. Each feature typically serves a particular purpose, addressing a specific user need or requirement." 
          btnTxt="Create Feature"
          handleCreate={handleCreateFeature} 
          srcImage={banner}
        />
      </Stack>
      <Stack sx={{ backgroundColor: '#f4f1f8', flex: 1, width: '100%', px: { xs: 1, lg: 2, xl: 35 }, pb: 3 } }>
        <Box sx={{ mt: 3, mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box className="feature-filters" sx={{ display: 'flex', flexGrow: 1 }}>
            <Typography component="label">
              Feature filters... (GenAi/Import/Export/Graph Visualisation)
            </Typography>
          </Box>
          <Button startDecorator={<AddIcon />} size="sm" variant="solid" color="primary">
            Add
          </Button>
        </Box>
        <Box>
          <FeaturesListCards handleSetFeature={handleSetFeature} features={features} />
        </Box>
      </Stack>
      <FeatureModal open={open} setOpen={setOpen} handleFeatureDeletion={handleFeatureDeletion} handleUpdateCurrentFeature={handleUpdateCurrentFeature} feature={feature} />
      <FeedbackSnackbar openSetting={showSnackbar} setShowSnackbar={setShowSnackbar} message={snackbarMsg} colorSetting={snackbarSeverity} />
    </Sheet>
  )
}