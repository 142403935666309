import { v4 } from 'uuid';

export const pagesData = [    
    {
    id: v4(),
    title: 'Page Set 1',
    description: 'This is a description of page set.',
    pages: [
      {
        id: v4(),
        title: 'Page 1.1',
        description: 'This is a description of page 1.1.',
      },
      {
        id: v4(),
        title: 'Page 1.2',
        description: 'This is a description of page 1.2.',
      },
      {
        id: v4(),
        title: 'Page 1.3',
        description: 'This is a description of page 1.3.',
      },
      {
        id: v4(),
        title: 'Page 2.1',
        description: 'This is a description of page 2.1.',
      }
    ]
  },
]