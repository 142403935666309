import React from 'react';
import ReactDOM from 'react-dom';
import { Link, Textarea, Card, CardContent, CardOverflow, Box, Typography, Input, Button, IconButton } from '@mui/joy';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Remove } from '../../../SitemapTree/components/Item';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EmojiInlinePicker from '../../../EmojiInlinePicker';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import Search from '../../../Search';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { set } from 'date-fns';

const satisfactionColours = {
  not: '#BC4748 ',
  somewhatnot: '#E98E4D',
  neutral: '#EFD45C',
  somewhat: '#A0D663',
  satisfied: '#6FB57A',
}

export default function JourneyStepCard({ layout = "default", illustrationOnly = false, setDragMode, handleRemove, updateComponent, satisfaction, title, url, linkTitle, description, componentIndex, colIndex, rowIndex, ...props }) {

  const [ editingTitle, setEditingTitle ] = React.useState(false);
  const [ draftTitle, setDraftTitle ] = React.useState(title);
  const [ overReaction, setOverReaction ] = React.useState(false);
  const [ editingDescription, setEditingDescription ] = React.useState(false);
  const [ draftDescription, setDescription ] = React.useState(description);
  const [ draftLinkTitle, setDraftLinkTitle ] = React.useState(linkTitle);
  const [ draftUrl, setDraftUrl ] = React.useState(url);  
  
  const [ editingLink, setEditingLink ] = React.useState(false);
  const [ value, setValue ] = React.useState('');
  const navigate = useNavigate();

  const handleTitleChange = (value) => {
    setDraftTitle(value);
  }

  const handleSaveTitleName = () => {
    setEditingTitle(false);
    console.log('save title name');
    updateComponent({ componentIndex, colIndex, rowIndex, data: { title: draftTitle } });
  }

  const handleReactionClick = (data, e) => {
    console.log('reaction clicked', data, e);
    updateComponent({ componentIndex, colIndex, rowIndex, data: { satisfaction: data.unified } });
    setOverReaction(false);
  }

  const handleDescriptionChange = (value) => {
    setDescription(value);
  }

  const handleSaveDescription = () => {
    setEditingDescription(false);
    console.log('save description');
    updateComponent({ componentIndex, colIndex, rowIndex, data: { description: draftDescription } });
  }

  const handleAddDescription = () => {
    console.log('add description')
    setEditingDescription(true);
  }

  const handleAddLink = () => {
    console.log('add link')
    setEditingLink(true);
  }

  const handleOnClickOfJourneyLink = (url) => {
    console.log('handleOnClickOfJourneyLink', url);
    navigate(url);
  }

  const handleAddLinkTojourneyStep = () => {
    console.log('handleAddLinkTojourneyStep', linkTitle, url);
    updateComponent({ componentIndex, colIndex, rowIndex, data: { link: draftUrl, linkTitle: draftLinkTitle } });
    setEditingLink(false);
  }

  const handleRemoveLink = () => {
    console.log('handleRemoveLink');
    setDraftLinkTitle('');
    setDraftUrl('');
    updateComponent({ componentIndex, colIndex, rowIndex, data: { link: '', linkTitle: '' } });
    setEditingLink(false);
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    setDraggableNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id, data: { dragMode: 'item' }});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    illustrationOnly ? 
    <>
      <Card
        className="journey-step-card"
        data-resizable
        sx={{
          textAlign: 'left',
          alignItems: 'center',
          m: 1,
          overflow: 'auto',
          borderLeft: `4px solid #A0D663`,
          '--icon-size': '100px',
        }}
      >
        <CardOverflow variant="soft" color="neutral">
          <CardContent sx={{ maxWidth: '40ch', whiteSpace: 'normal', position: 'relative' }}>
            <Box display="flex" sx={{ mb: 1 }}>
              <Box>
                <SentimentSatisfiedIcon />
              </Box>
              <Box className="boardTitleEle" sx={{ flex: 1, overflow: 'auto' }}>
                <Typography level="title-sm" sx={{ ml: 1, pr: 3 }}>
                  Experience title
                </Typography>
              </Box>
            </Box>
            <Box sx={{ ml: 3 }}>
              <Typography level="body-sm">
                Experience description...
              </Typography>
            </Box>
          </CardContent>
        </CardOverflow>
      </Card>
    </>
    :
    <>
      { overReaction && 
        <Box sx={{ position: "absolute", zIndex: 9999, top: 0, left: 55 }} onMouseLeave={() => setOverReaction(false)}>
          <EmojiInlinePicker 
            skinTonesDisabled={true} 
            defaultEmojis={["not","somewhatnot","neutral","somewhat","satisfied"]} //"1f604","1f642","1f611","1f615","2639-fe0f"
            width={100} 
            customEmojis={[
              {
                names: ['Satisfied'],
                imgUrl: '/images/emoji/customEmoji/satisfied/satisfied.png',
                id: 'satisfied'
              },
              {
                names: ['Somewhat Satisfied'],
                imgUrl: '/images/emoji/customEmoji/somewhat/somewhat.png',
                id: 'somewhat'
              },
              {
                names: ['Neutral'],
                imgUrl: '/images/emoji/customEmoji/neutral/neutral.png',
                id: 'neutral'
              },
              {
                names: ['Somewhat Not Satisfied'],
                imgUrl: '/images/emoji/customEmoji/somewhatnot/somewhatnot.png',
                id: 'somewhatnot'
              },
              {
                names: ['Not Satisfied'],
                imgUrl: '/images/emoji/customEmoji/not/not.png',
                id: 'not'
              }
            ]}
            handleReaction={handleReactionClick} 
          />
        </Box>
      }
      <Card
        ref={setNodeRef} style={style} {...attributes}
        className="journey-step-card"
        data-resizable
        sx={{
          textAlign: 'left',
          alignItems: 'center',
          m: 1,
          overflow: 'auto',
          borderLeft: `4px solid ${satisfactionColours[satisfaction]}`,
          '--icon-size': '100px',
        }}
      >
        <CardOverflow variant="soft" color="neutral">
          <CardContent className="journey-step-card-content" sx={{ maxWidth: '40ch', whiteSpace: 'normal', position: 'relative' }}>
            { layout === "default" &&
            <Box className="remove-button" sx={{ position: 'absolute', zIndex: 9998, top: 5, right: -10, width: 30, height: 30 }}>
              { layout === "default" && <Remove id={props.id} sx={{ zIndex: 9997, width: '100%', height: '100%' }} onClick={() => handleRemove({ componentIndex, colIndex, rowIndex })} /> }
            </Box>
            }
            <Box display="flex" sx={{ mb: 1 }}>
              { layout === "default" ?
              <Box sx={{ '&:hover': { cursor: 'pointer' } }} onClick={() => setOverReaction(true)}>
                {(() => {
                  switch (satisfaction) {
                    case "not":
                      return <SentimentVeryDissatisfiedIcon />;
                    case "somewhatnot":
                      return <SentimentDissatisfiedIcon />;
                    case "neutral":
                      return <SentimentNeutralIcon />;
                    case "somewhat":
                      return <SentimentSatisfiedIcon />;
                    case "satisfied":
                      return <SentimentVerySatisfiedIcon />;
                    default:
                      return satisfaction && <Emoji unified={satisfaction} size="24" />;
                  }
                })()}
              </Box>
              :
              <Box>
                {(() => {
                  switch (satisfaction) {
                    case "not":
                      return <SentimentVeryDissatisfiedIcon />;
                    case "somewhatnot":
                      return <SentimentDissatisfiedIcon />;
                    case "neutral":
                      return <SentimentNeutralIcon />;
                    case "somewhat":
                      return <SentimentSatisfiedIcon />;
                    case "satisfied":
                      return <SentimentVerySatisfiedIcon />;
                    default:
                      return satisfaction && <Emoji unified={satisfaction} size="24" />;
                  }
                })()}
              </Box>
              }
              <Box className="boardTitleEle" sx={{ flex: 1, overflow: 'auto' }}>
                {editingTitle ?
                  <Input
                    autoFocus={true}
                    onChange={(e) => handleTitleChange(e.target.value)}
                    onBlur={() => setEditingTitle(false)}
                    value={draftTitle}
                    defaultValue={title}
                    size="sm"
                    sx={{ flexGrow: 1 }}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleSaveTitleName();
                      } else if (e.keyCode === 27) {
                        setEditingTitle(false);
                      }
                    }}
                  />
                : 
                  layout === "default" ?
                  <Typography level="title-sm" onClick={() => setEditingTitle(true)} sx={{ ml: 1, pr: 3, cursor: 'pointer' }}>
                    {title}
                  </Typography>
                  :
                  <Typography level="title-sm" sx={{ ml: 1, pr: 3 }}>
                    {title}
                  </Typography>
                }
              </Box>
            </Box>
            <Box sx={{ ml: 3 }}>
            {editingDescription ?
              <Textarea 
                autoFocus={true} 
                onChange={(e) => handleDescriptionChange(e.target.value)} 
                onBlur={(e) => {
                  handleSaveDescription();
                }}
                value={draftDescription}
                sx={{ fontSize: '.8em', flexGrow: 1 }}
                minRows={2} />
              :
              layout === "default" ? 
                <Typography level="body-sm" onClick={() => setEditingDescription(!editingDescription)} sx={{ ml: 1, pr: 3, cursor: 'pointer' }}>
                  {description && description }
                </Typography>
              :
                <Typography level="body-sm" sx={{ ml: 1, pr: 3 }}>
                  {description && description }
                </Typography>
              }
            </Box>
            <Box className="card-link" sx={{ ml: 3 }}>
              {editingLink ? 
                // <Input 
                //   autoFocus={true} 
                //   onChange={(e) => setLink(e.target.value)} 
                //   onBlur={() => setEditingLink(false)} 
                //   value={link} 
                //   sx={{ fontSize: '.8em', flexGrow: 1 }} 
                // />
                <>
                  <Box sx={{ mt: 1 }}>
                    <Search size="sm" linkTitle={draftLinkTitle} setLinkTitle={setDraftLinkTitle} mode="addlink" value={value} setValue={setValue} url={draftUrl} setUrl={setDraftUrl} /> 
                  </Box>
                  { draftLinkTitle && draftLinkTitle !== "" &&
                  <Card size="sm" sx={{ mt: .6, p: 0, pl: 0.6 }}>
                    <Box flex={2} sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                      <Box flex={2} sx={{ display: 'flex' }}>
                        <Link sx={{ fontSize: 14 }} variant='plain' color="neutral" onClick={() => handleOnClickOfJourneyLink(draftUrl) }>
                          { draftLinkTitle && draftLinkTitle !== "" && draftLinkTitle }
                        </Link>
                      </Box>
                      <Box flex={1} sx={{ display: 'flex', justifyContent: 'end', m: .6 }}>
                        <Button size="sm" onClick={() => handleAddLinkTojourneyStep()}>Add</Button>
                      </Box>
                    </Box>
                  </Card>
                  }
                </>
              :
                layout === "default" ? 
                draftUrl && draftUrl !== "" && 
                  <Card size="sm" sx={{ mt: .6, p: 0, pl: 0.6 }}>
                    <Box flex={2} sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                      <Box flex={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Link sx={{ fontSize: 14 }} variant='plain' color="neutral" onClick={() => handleOnClickOfJourneyLink(draftUrl) }>{ draftLinkTitle && draftLinkTitle !== "" && draftLinkTitle }</Link>
                        <IconButton size="sm" onClick={() => handleRemoveLink()}><CloseIcon fontSize='5' /></IconButton>
                      </Box>
                    </Box>
                  </Card>
                :
                draftUrl && draftUrl !== "" && 
                  <Typography level="body-sm" sx={{ ml: 1, pr: 3 }}>
                    <Card size="sm" sx={{ mt: .6, p: 0, pl: 0.6 }}>
                      <Box flex={2} sx={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>
                        <Box flex={2} sx={{ display: 'flex' }}>
                          <Link sx={{ fontSize: 14 }} variant='plain' color="neutral" onClick={() => handleOnClickOfJourneyLink(draftUrl) }>{ draftLinkTitle && draftLinkTitle !== "" && draftLinkTitle }</Link>
                        </Box>
                      </Box>
                    </Card>
                  </Typography>
              }
            </Box>
            <Box className="card-actions" sx={{ ml: 4 }}>
              {/* Add Description */}
              { layout === "default" && !description && <Link className="card-actions" level="body-sm" variant="plain" onClick={handleAddDescription}>Add description</Link> }
              {/* TO DO add link option */}
              { layout === "default" && !url && <Link className="card-actions" level="body-sm" variant="plain" onClick={handleAddLink}>Add link</Link> }
              {/* TO DO add option to make a quote */}
              {/* TO DO add option to delete a description */}
            </Box>
            { layout === "default" &&
              <Box className="grab-card" sx={{ position: 'absolute', bottom: 10, left: 0, cursor: 'grab' }} ref={setDraggableNodeRef} {...listeners}>
                <DragIndicatorIcon fontSize="small" />
              </Box>
            }
          </CardContent>
        </CardOverflow>
      </Card>
    </>
  )
}