import * as React from 'react';
import Typography from '@mui/joy/Typography';
import Card from '@mui/joy/Card';
import Button from '@mui/joy/Button';
import { useNotes } from '../../contexts/notesContext';

export default function NoNotePresent() {
  const { handleCreateNewNote, binPage } = useNotes();

  return (
    binPage ?
    <Card sx={{ m: 3 }}>
        <Typography level="title-lg">
          Nice and clean... The bin is empty
        </Typography>
      </Card>
      :
      <Card sx={{ m: 3 }}>
        <Typography level="title-lg">
          There aren't any notes at the moment.
        </Typography>
        <Typography level="body-md">
          <Button onClick={handleCreateNewNote}>Create note</Button>
        </Typography>
      </Card>
  )
}