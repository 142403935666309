import * as React from 'react';
import Snackbar, { SnackbarOrigin } from '@mui/joy/Snackbar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import { useLoading } from '../../contexts/loadingContext';

export default function Loading() {

    const { running } = useLoading()

    const [state, setState] = React.useState({
        open: running,
        vertical: 'bottom',
        horizontal: 'right',
    });

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const { vertical, horizontal, open } = state;

    React.useEffect(() => {
        if (running) {
            setState({ ...state, open: true });
        } else {
            setState({ ...state, open: false });
        }
    },[running])

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            key={vertical + horizontal}
            onClose={(event, reason) => {
                if (reason === 'clickaway') {
                    return;
                } else {
                    handleClose()
                }
            }}
        >
            <CircularProgress size="sm" /> Loading
        </Snackbar>
    )
}