import React from 'react';
import { Box, Typography } from '@mui/joy';
import VersionHistoryEditor from '../Notes/VersionHistoryEditor';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function PreviewNote({ selected, setSelected }) {
  return (
    <>
    <Box sx={{ mb: 2 }}>
      <Typography level="h5" sx={{ fontWeight: 800 }} startDecorator={<ArrowBackIosIcon sx={{ '&:hover': { cursor: 'pointer' } }} fontSize="10" onClick={() => setSelected(null)} />} >{ selected.noteTitle === "" ? "Untitle note" : selected.noteTitle }</Typography>
    </Box>
    <Box flex={1} display="flex" sx={{ overflow: 'auto', ml: 0, mr: 0, flexGrow: 1 }}>
      <VersionHistoryEditor editorState={selected.editorState} />
    </Box>
    </>
  );
}