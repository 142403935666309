import { ProjectsProvider } from "../../../PrivateSite/contexts/projectsContext";
import Header from "../../Components/Header";
import NavigationTile from "../../Components/NavigationTile";
import PersonaNav from "../../Components/PersonaNav";

export default function Home() {
  return (
    <ProjectsProvider>
      <Header />
      <PersonaNav />
      {/* Journeys */}
      <NavigationTile title="Journeys" description="Journeys description" link="/journeys" />
      {/* Features */}
      <NavigationTile title="Features" description="Features description" link="/features" />
      {/* Navigations */}
      <NavigationTile title="Navigations" description="Navigations description" link="/navigations" />
      {/* Pages */}
      <NavigationTile title="Pages" description="Pages description" link="/pages" />
      {/* Published Notes 1 aka "Research Notes" */}
      <NavigationTile title="Research Notes" description="Research Notes description" link="/published-notes-1" />
      {/* Published Notes 1 aka "Experience Themes" */}
      <NavigationTile title="Experience Themes" description="Experience Themes description" link="/published-notes-2" />
    </ProjectsProvider>
  )
}