export function downloadHtml(html) {
  // Use the html passed and present the user with a save dialogue box
  // Create a Blob from the HTML string
  const blob = new Blob([html], { type: 'text/html' });

  // Create a link element
  const link = document.createElement('a');

  // Set the download attribute with a default file name
  link.download = 'download.html';

  // Create a URL for the Blob and set it as the href attribute
  link.href = window.URL.createObjectURL(blob);

  // Append the link to the body (required for Firefox)
  document.body.appendChild(link);

  // Programmatically click the link to trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
}