import React, { useEffect, useState } from 'react'
import { useContextualPane } from '../../../../../../contexts/contextualPane'
import InfoIcon from '@mui/icons-material/Info';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/joy/Box';
import Alert from '@mui/joy/Alert';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import { useParams, useNavigate } from 'react-router-dom';
import Link from '@mui/joy/Link';
import FormControl from '@mui/joy/FormControl';
import Radio from '@mui/joy/Radio';
import RadioGroup from '@mui/joy/RadioGroup';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import ResultListings from './ResultListings';
import FeedbackSnackbar from '../../../../../Snackbar';
import Search from '../../../../../Search';

export default function SearchForAssocitations() {

    const { newDraftAssociation, 
        searchResultsForAssociation, 
        setProgressStep, 
        alert, 
        alertMsg, 
        setAlert, 
        setAlertMsg, 
        handleSetNewDraftAssociation, 
        activeItem,
        handleClickOnHelperLink,
        secondaryAssociationMode,
        handleStepThreeContinue,
        handleStepThreeBack,
        secondNewDraftAssociation,
        setSecondNewDraftAssociation,
        handleStepTwoContinueForSecondary,
        handleStepTwoCitationContinueSecondary,
        handleStepThreeContinueSecondary,
        showSnackbar,
        setShowSnackbar,
        snackbarSeverity,
        snackbarMsg,
        setSnackbarSeverity,
        setSnackbarMsg
    } = useContextualPane();

    const [ refineSearch, setRefineSearch ] = React.useState('')
    const [ selectedValue, setSelectedValue ] = useState('');
    const { accountId, selectedProjectId } = useParams()
    const navigate = useNavigate();
    const [value,setValue] = React.useState();

    const handleRefineSearch = (event) => {
        setRefineSearch(event.target.value)
    }

    function handleChange(id, title) {
        //console.log('id', id)
        //console.log('title', title)
        setSelectedValue({ id: id, title: title, elementSelected: activeItem.elementSelected });
    }

    const filteredResults = searchResultsForAssociation.filter(result => result.title.toLowerCase().includes(refineSearch.toLowerCase()));

    return (
        <>
        { filteredResults && filteredResults.length > 0 ? (
            <>
            <Input placeholder="Search here..." onChange={handleRefineSearch} sx={{ my: 3 }} />
            <FormControl>
                {/* Result listings */}
                <ResultListings filteredResults={filteredResults} handleChange={handleChange} />
            </FormControl>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                { secondaryAssociationMode 
                ?
                    <>
                    <Button onClick={() => handleStepThreeBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                        Back
                    </Button>
                    <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepThreeContinueSecondary(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                        Continue
                    </Button>
                    </>
                :
                    <>
                    <Button onClick={() => handleStepThreeBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                        Back
                    </Button>
                    <Button disabled={selectedValue === "" ? true : false } onClick={() => handleStepThreeContinue(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                        { newDraftAssociation.toType === "pageset" ? 'Select' : 'Create' }
                    </Button>
                    </>
                }
            </Box>
            </>
        ) : alert && alertMsg && (
            <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
                <Alert
                    key="issues"
                    sx={{ alignItems: 'flex-start' }}
                    startDecorator={<InfoIcon />}
                    variant="soft"
                    color="neutral"
                    >
                    <div>
                        <Typography level="body-md" color="neutral">
                            {alertMsg}. Would you like to <Link onClick={() => handleClickOnHelperLink()}
                            >create one?</Link>
                        </Typography>
                    </div>
                </Alert>
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                    <Button onClick={() => handleStepThreeBack(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                        Back
                    </Button>
                </Box>
            </Box>
        )}
        <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
        </>
    )
}