import * as React from 'react';
import { Alert, Button, Box, Input, Typography, Stack, Card, IconButton } from '@mui/joy';

import { SortableTree } from '../SitemapTree';
import LinksComponent from '../LinksComponent';
import Info from './components/Info';
import InlineTypesViewBtn from '../DrawerRight/IncitesForTypeInstance/InlineTypesViewBtn';
import BrowseTypes from '../DrawerRight/BrowseTypes';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import TriggerContextualMenu from '../Buttons/TriggerContextualMenu';
import SitemapLayout from './components/Layouts';
import DrawerRight from '../DrawerRight';
import { useDrawerRight } from '../../contexts/drawerRightContext';

export default function SitemapModalContent({ sitemap, setSitemap, open, viewPane, setViewPane }) {

  const [ openContext, setOpenContext ] = React.useState(false)
  const { drawerRightOpen, setDrawerRightOpen } = useDrawerRight()

  return (
    <Box sx={{ flex: 1, display: 'flex', width: '99%', px: 1, mb: 3 }}>
      <Box sx={{ flex: 2 }}>
        <Typography variant="h2" level="h1" sx={{ mt: 1, mb: 4, mr: 2 }}>
        <>
          <Input 
            size="lg"
            value={sitemap.title}  
            placeholder="Navigation name" 
            onChange={ (e) => setSitemap({ ...sitemap, title: e.target.value }) } 
          />
        </>
        </Typography>
        <Stack direction="row">
          { sitemap && sitemap.id ?
          <>
          { viewPane === 0 &&
          <Stack direction="column" sx={{ flex: 1, display: 'flex', justifyContent: 'center', minWidth: "34%", mr: 3 }}>
            <Card>
              <Info />
              <SitemapLayout layout="default" data={sitemap} setData={setSitemap} />
            </Card>
          </Stack>
          }
          { viewPane === 1 &&
          <Stack direction="column" spacing={1} sx={{ flex: 1, display: 'flex', justifyContent: 'center', mr: 3, minWidth: "63%", minHeight: "68vh", display: { xs: 'flex', } }}>
            <LinksComponent open={open} />
          </Stack>
          }
          </>
          :
          <>
          <Stack direction="column" sx={{ minWidth: '100vh', mr: 3 }}>
            <Card>
              <Info />
              <SitemapLayout layout="default" data={sitemap} setData={setSitemap} />
            </Card>
          </Stack>
          </>
          }
        </Stack>
      </Box>

      <DrawerRight content={() => <BrowseTypes setViewPane={setViewPane} />} />

      <Box>
        <TriggerContextualMenu bottom={120} right={15} open={drawerRightOpen} setOpen={setDrawerRightOpen} />
      </Box>
    </Box>
  );
}