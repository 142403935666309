import React from "react";
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import DefinitionBanner from "../../Components/Banners/DefinitionBanner"
import FeedbackSnackbar from "../../Components/Snackbar";
import SitemapModal from "../../Components/Sitemaps/SitemapModal";
import SitemapsListCards from '../../Components/Sitemaps/index';
import { useNavigations } from '../../contexts/navigationsContext';
import { getConfig } from '../../database/configs-db';

export default function Navigation() {
  const [banner, setBanner] = React.useState('')

  React.useEffect(() => {
    getConfig().then(config => setBanner(config.navigationImage))
  }, [])

  const { 
    sitemaps, 
    handleCreateSitemap, 
    handleSetSitemap, 
    handleSitemapDeletion, 
    handleUpdateCurrentSitemap, 
    setSitemap, 
    activeItem, 
    setOpen, 
    open, 
    setShowSnackbar, 
    showSnackbar, 
    snackbarSeverity, 
    snackbarMsg 
  } = useNavigations()

  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        gridTemplateColumns: {
          xs: '1fr',
          //sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          sm: '1fr',
        },
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pb={1.5}
      >
        <DefinitionBanner 
          title="Navigation" 
          // text="A feature, in the context of software development, refers to a distinct, individual functionality or characteristic that a software product or system offers to its users. Features are specific components or capabilities that contribute to the overall functionality and utility of the software. Each feature typically serves a particular purpose, addressing a specific user need or requirement." 
          btnTxt="Create Navigation"
          handleCreate={handleCreateSitemap} 
          srcImage={banner}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pt={0}
        pb={1.5}
      >
        <SitemapsListCards handleSetSitemap={handleSetSitemap} sitemaps={sitemaps} />
        {/* TO DO work out if this is pane is of use <InlineTypesViewBtn /> */}
        {/* TO DO work out if this is pane is of use <DrawerRight size={"sm"} noBlur={true} content={() => (<ViewNotes />)} /> */}
      </Stack>
      { activeItem && activeItem.item && activeItem.item &&
        <SitemapModal open={open} setOpen={setOpen} handleSitemapDeletion={handleSitemapDeletion} handleUpdateCurrentSitemap={handleUpdateCurrentSitemap} sitemap={activeItem.item} setSitemap={setSitemap} />
      }
      <FeedbackSnackbar openSetting={showSnackbar} setShowSnackbar={setShowSnackbar} message={snackbarMsg} colorSetting={snackbarSeverity} />
    </Sheet>
  )
}