import { Box } from "@mui/joy";
import Editor from "../../Editor";
import DockPaneHeader from "../DockPaneHeader";

function DockedNote() {
  return (
    <>
      <DockPaneHeader />
      <Box
        className="dock-content"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2, flexShrink: 0 }}
      >
        <Editor editable={false} context="dock" />
      </Box>
    </>
  );
}

export default DockedNote;