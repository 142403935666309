import { Card, Link, Typography } from "@mui/joy"
import { TimeAgo } from "../../utils/utils";

export default function SitemapCards({sitemap,handleSitemapClick}) {
  return (
    <Card 
      variant="soft" 
      size="sm" 
      className="note-item"
      sx={{ 
        minHeight: 100, 
        backgroundColor: '#fbf8ff',
          '&:hover': { 
            boxShadow: 'md', 
            backgroundColor: '#fff', 
            borderColor: 'neutral.outlinedHoverBorder' 
          }, 
        marginBottom: 1,
        paddingTop: .5,
        paddingBottom: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
      }}
    >
      <Link variant="plain" overlay to={`/navigation/${sitemap.id}`} onClick={() => handleSitemapClick(sitemap)}>
        {sitemap && sitemap.title && sitemap.title}
      </Link>
      <Typography level="body-xs" sx={{ color: 'grey' }}>Last updated <TimeAgo timestamp={sitemap.updated} /></Typography>
      {/* <IncitesForTypeInstance item={persona} itemType="persona" /> */}
      {/* <IncitesForTypeInstance item={sitemap} itemType="sitemap" /> */}
    </Card>
  )
}