import React from 'react'
import Box from '@mui/joy/Box'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import ListItemButton from '@mui/joy/ListItemButton'
import ListItemContent from '@mui/joy/ListItemContent'
import ListItemDecorator from '@mui/joy/ListItemDecorator'
import { BASE_PRIVATEAPP_URL } from '../../../../../redirects'
import { useAuth } from '../../../../../authContext'  
import { useParams } from 'react-router-dom'

export default function TagBoards({ boards, handleRedirect }) {
  const { accountId, selectedProjectId } = useParams()

  return (
    <List
      aria-labelledby="nav-list-tags"
      size="sm"
      sx={{
        '--ListItemDecorator-size': '32px',
      }}
    >
      { boards && boards.length > 0 && boards.map((board) => (
        <ListItem key={board.id}>
          <ListItemButton onClick={() => handleRedirect(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/tags/b/' + board.id)}>
            <ListItemContent>{board.title}</ListItemContent>
          </ListItemButton>
        </ListItem>
      )) }
    </List>
  )
}