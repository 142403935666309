import * as React from 'react';
import { Box, Link, Card, FormControl, FormLabel, Input, Select, Option, Button, FormHelperText, Stack, Typography, Textarea } from '@mui/joy';
import { retrievePlansForUser } from '../../database/plans-db';
import { useAuth } from '../../../authContext';
import { getCreatedBy } from '../../database/accounts-db';
import { useParams, useNavigate } from 'react-router-dom';
import { matchPriceId, matchProductId } from '../../database/utilities-db';
import { retrieveActiveUsersForAccount, retrieveUserByEmail } from '../../database/users-db';
import { retrieveInvitationsForAccount } from '../../database/invitations-db';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import FeedbackSnackbar from '../Snackbar';
import { v4 as uuidv4 } from 'uuid';

export default function InviteUsers({ allPlans, invitationsSent, setInvitationsSent }) {

  const { currentUser } = useAuth();
  const { accountId, selectedProjectId } = useParams();
  const navigate = useNavigate();
  const [plans, setPlans] = React.useState(null);
  const [planDetails, setPlanDetails] = React.useState(null);
  const [createdBy, setCreatedBy] = React.useState(null);
  const [quantity, setQuantity] = React.useState(0);
  const [activeUsers, setActiveUsers] = React.useState(0);
  const [error, setError] = React.useState('');

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('info');

  React.useEffect(() => {
    getCreatedBy(accountId).then(setCreatedBy);
    retrieveActiveUsersForAccount({ accountId }).then(users => setActiveUsers(users.length));
  }, [accountId]);

  React.useEffect(() => {
    if (createdBy) {
      const fetchPlans = async () => {
        try {
          console.log("fetching plans for user", createdBy)
          const fetchedPlans = await retrievePlansForUser({ userId: createdBy });
          console.log("fetched plans", fetchedPlans)
          if (fetchedPlans.length > 0) {
            setPlans(fetchedPlans);
          }
        } catch (error) {
          console.error("Error retrieving plans:", error);
          // Optionally, you can set an error state here to display to the user
          // setError("Failed to retrieve plans. Please try again later.");
        }
      };
      fetchPlans();
    }
  }, [createdBy]);

  React.useEffect(() => {
    if (plans && plans.length > 0) {
      setQuantity(plans[0].quantity);
      // loop through plans and get the price
      const planTemp = [];
      plans.forEach((plan) => {
        const planDetailItem = matchProductId({ productId: plan.product.id, allPlans });
        planTemp.push(planDetailItem);        
      });
      setPlanDetails(planTemp);
    }
  }, [plans]);

  const [data, setData] = React.useState({
    email: '',
    status: 'initial',
    role: 'contributor',
  });

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(data);
    console.log(currentUser);

    // First check if the email is already in the database
    const existingUser = await retrieveUserByEmail({ email: data.email });
    if (existingUser.length > 0) {
      setSnackbarMessage("User already exists, you can only invite new users to join the team");
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    try {
      const response = await fetch('/api/invitations/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          accountId: accountId,
          role: data.role,
          message: data.message,
          invitedBy: currentUser._delegate.email,
          projectId: selectedProjectId,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Invitation created with token:", result.token);

        // Display success message to user
        setSnackbarMessage("Invitation sent successfully");
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Retrieve the updated invitations
        retrieveInvitationsForAccount({ accountId }).then(invitations => setInvitationsSent(invitations));

        // Reset data to initial state
        setData({
          email: '',
          status: 'initial',
          role: 'contributor',
        });
      } else {
        const responseJson = await response.json()
        console.log("issues", responseJson)
        handleError(responseJson)
      }
      
    } catch (error) {
      console.error("error logging: ", error);
      handleError(error);
    }
  };

  const handleError = (errorResponse) => {
    console.log("errorResponse", errorResponse);
    let errorMessage = 'There was an error on our side, please try again.';

    if (typeof errorResponse === 'object' && errorResponse !== null) {
      switch(errorResponse.error) {
        case 'email-already-in-use':
          errorMessage = 'There is already an account with this email. You cannot sign up with this email as only 1 account per email is allowed at this time.';
          break;
        case 'invitation-already-exists':
          errorMessage = 'An invitation for this email is already pending.';
          break;
        case 'invalid-email':
          errorMessage = 'The email was invalid. Please retry entering your email address.';
          break;
      }
      // If there's a message property, use it
      if (errorResponse.message) {
        errorMessage = errorResponse.message;
      }
    } else if (typeof errorResponse === 'string') {
      errorMessage = errorResponse;
    }

    setError(errorMessage);
    setData(prevData => ({
      ...prevData,
      status: 'failure'
    }));
    setSnackbarOpen(true);
    setSnackbarSeverity('error');
    setSnackbarMessage(errorMessage);
  };

  return (
    <Stack
      spacing={4}
      sx={{
        display: 'flex',
        maxWidth: '800px',
        mx: 'auto',
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 },
      }}
    >
      <Typography level="h3" component="h2">Invite Users</Typography>
      <Typography level="body-sm">
        {invitationsSent !== null && quantity !== null && activeUsers !== null && (
          <>
            <b>
              The account has {quantity ? quantity + 1 : 0} seats, and{' '}
              {Math.max(0, (quantity ? quantity + 1 : 0) - activeUsers - (Array.isArray(invitationsSent) ? invitationsSent.length : 0))} available
            </b>
            .{' '}
            {Array.isArray(invitationsSent) && invitationsSent.length > 0 && (
              <b>
                &nbsp;You have {invitationsSent.length} pending{' '}
                {invitationsSent.length === 1 ? 'invitation' : 'invitations'}.
              </b>
            )}
            &nbsp;If you want to purchase more seats, please {currentUser.role !== 'admin' ? 'contact the admin (in the team table)' : <>click 'manage' on the<Link sx={{ flex: 'inline' }} variant="plain" color="primary" onClick={() => { navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=plan') }}>plans & billing tab</Link></>}
          </>
        )}
      </Typography>
      { invitationsSent && activeUsers && quantity && 
      (quantity + 1) - activeUsers - invitationsSent.length > 0 &&
        <Card>
          <form onSubmit={handleSubmit} id="submitInvite">
          <FormControl>
            <FormControl sx={{ mb: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Email Address</FormLabel>
              <Input
                sx={{ '--Input-decoratorChildHeight': '45px', flexGrow: 1 }}
                placeholder="Invitee email address"
                type="email"
                required
                value={data.email}
                onChange={(event) =>
                  setData({ ...data, email: event.target.value })
                }
                error={data.status === 'failure'}
              />
            </FormControl>
            <FormControl sx={{ mb: 2 }}>
              <FormLabel sx={{ mb: 1 }}>Role</FormLabel>
              <Select
                value={data.role}
                size="sm"
                onChange={(event, newValue) =>
                  setData({ ...data, role: newValue })
                }
              >
                <Option value="contributor">Contributor (create projects, documents, etc.)</Option>
                <Option value="manager">Manager (more rights inc. invitation rights)</Option>
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 0 }}>
              <FormLabel sx={{ mb: 1 }}>Message</FormLabel>
              <Textarea
                sx={{ '--Input-decoratorChildHeight': '45px' }}
                placeholder="Optional message"
                multiline="true"
                minRows={4}
                value={data.message}
                onChange={(event) =>
                  setData({ ...data, message: event.target.value })
                }
              />
            </FormControl>
            <Box>
              <Button
                variant="solid"
                color="primary"
                loading={data.status === 'loading'}
                type="submit"
                size="sm"
                sx={{ mt: 2 }}
              >
                Send invite
              </Button>
            </Box>
            {data.status === 'failure' && (
              <FormHelperText
                sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
              >
                Oops! something went wrong, please try again later.
              </FormHelperText>
            )}
            {data.status === 'sent' && (
              <FormHelperText
                sx={(theme) => ({ color: theme.vars.palette.primary[400] })}
              >
                You are all set!
              </FormHelperText>
            )}
          </FormControl>
        </form>
        <FeedbackSnackbar openSetting={snackbarOpen} setShowSnackbar={setSnackbarOpen} message={snackbarMessage} severity={snackbarSeverity} />
        </Card>
      }
    </Stack>
  );
}
