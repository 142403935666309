import { useState } from "react";
import { Button, Box, Card, CardActions, Input, Typography } from "@mui/joy"
import { useParams, useNavigate } from "react-router-dom";
import FeedbackSnackbar from './../../../../Snackbar/index';
import Slider from "react-slick";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/system';

const CustomArrow = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: '-30px',
  transform: 'translateY(-50%)',
  zIndex: 1,
  cursor: 'pointer',
  '& svg': {
    fontSize: '2rem',
    color: theme.vars.palette.primary.main,
  },
}));

const CustomNextArrow = styled(CustomArrow)({
  right: '0px',
});

const CustomPrevArrow = styled(CustomArrow)({
  left: '330px',
});

function PersonasResult({ personas, index }) {
  const { accountId, selectedProjectId } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const navigate = useNavigate();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    nextArrow: <CustomNextArrow><ArrowForwardIcon style={{ fontSize: '1.5rem' }} /></CustomNextArrow>,
    prevArrow: <CustomPrevArrow><ArrowBackIcon style={{ fontSize: '1.5rem' }} /></CustomPrevArrow>,
  };

  const handleUsePersona = (persona) => {
    console.log(persona)
  }

  return (
    <>
      <Slider {...settings}>
        {personas.map((persona, index) => (
          <div key={index}>
            <Card
              color="neutral"
              variant="plain"
              sx={{
                p: 1,
                mb: 1,
                boxShadow: 'md',
                backgroundColor: 'var(--joy-palette-background-card-surface)',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                height: 'calc(100vh - 100px)', // Adjust this value as needed
                overflow: 'auto'
              }}
            >
              <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2 }}>
                <Typography level="h6" sx={{ mb: 1 }}><b>Name:</b><br/> {persona.name}</Typography>
                <Typography level="h6" sx={{ mb: 1 }}><b>Description:</b><br/> {persona.description}</Typography>
                <Typography level="h6" sx={{ mb: 1 }}><b>Quote:</b><br/> {persona.quote}</Typography>
                <Typography level="h6" sx={{ mb: 1 }}><b>Goals:</b>
                  <ul>
                    {persona.goals.map((goal, index) => (
                      <li key={index}>{goal}</li>
                    ))}
                  </ul>
                </Typography>
                <Typography level="h6" sx={{ mb: 1 }}><b>Pain Points:</b>
                  <ul>
                    {persona.painPoints.map((painPoint, index) => (
                      <li key={index}>{painPoint}</li>
                    ))}
                  </ul>
                </Typography>
                <Typography level="h6" sx={{ mb: 1 }}><b>Needs:</b>
                  <ul>
                    {persona.needs.map((need, index) => (
                      <li key={index}>{need}</li>
                    ))}
                  </ul>
                </Typography>
                <Typography level="h6" sx={{ mb: 1 }}><b>Values:</b>
                  <ul>
                    {persona.values.map((value, index) => (
                      <li key={index}>{value}</li>
                    ))}
                  </ul>
                </Typography>
              </Box>
              <CardActions sx={{ 
                position: 'sticky',
                bottom: 30,
                left: 0,
                right: 0,
                zIndex: 2,
                backgroundColor: 'var(--joy-palette-background-card-surface)',
                borderTop: '1px solid var(--joy-palette-divider)',
                py: 1
              }}>
                <Button size="sm" onClick={() => handleUsePersona(persona)} variant="solid" color="primary">Use Persona</Button>
              </CardActions>
            </Card>
          </div>
        ))}
      </Slider>
      <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
    </>
  )
}

export default PersonasResult
