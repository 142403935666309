import React, {useEffect, useState} from 'react';
import {
  closestCenter,
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  useDndContext,
  MeasuringStrategy,
  DropAnimation,
  defaultDropAnimationSideEffects,
} from '@dnd-kit/core';
import {
  arrayMove,
  useSortable,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {CSS, isKeyboardEvent} from '@dnd-kit/utilities';
import classNames from 'classnames';
import {v4} from 'uuid';

import { createRange } from '../../../SitemapTree/utilities';

import {Page, Layout, Position} from './Page';
import styles from './Pages.module.css';
import style from './Page.module.css';
import pageStyles from './Page.module.css';

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
};

const dropAnimation = {
  keyframes({transform}) {
    return [
      {transform: CSS.Transform.toString(transform.initial)},
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
  sideEffects: defaultDropAnimationSideEffects({
    className: {
      active: pageStyles.active,
    },
  }),
};

export function Pages({layout, pages, setPages}) {
  const [activeId, setActiveId] = useState(null);
  const [items, setItems] = useState(pages.pages ? pages.pages : []);
  const [editingTitleId, setEditingTitleId] = useState(null);
  const activeIndex = activeId ? items.findIndex(item => item.id === activeId) : -1;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {coordinateGetter: sortableKeyboardCoordinates})
  );

  const handleAddPageToSet = () => {
    const newPage = {
      id: v4(),
      title: "New Page",
      description: "",
    }
    setItems((items) => [...items, newPage]);
    // TO DO Set focus on page title once mechanism exists
  }

  const handlePageTitleClick = ({ id }) => {
    setEditingTitleId(id);
  }

  function updateTitle({ items, id, draftPageTitle }) {
    if (draftPageTitle !== "") {
      const newItems = items.map(item => {
        if (item.id === id) {
          return {
            ...item,
            title: draftPageTitle,
          };
        }
        return item;
      });
      console.log("newItems", newItems)

      setPages({ ...pages, pages: newItems }); // items will get refreshed from updating the activeItem see pagesContext handleSetActiveItemForPages
    }
  }

  const handleChangeTitleName = ({ id, draftPageTitle }) => {
    console.log("handleChangeTitleName: ", id, draftPageTitle)
    // updateTitle({ id, items, draftPageTitle });
    const updatedItems = updateTitle({ items, id, draftPageTitle });
    setEditingTitleId(null);
  }

  useEffect(() => {
    if (pages && pages.pages) {
      setItems( pages.pages );
    }    
  }, [pages]);

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
    >
      <SortableContext items={items}>
        <ul className={classNames(styles.Pages, styles[layout])}>
          {items.map((item, index) => (
            <SortablePage
              id={item.id}
              title={item.title}
              index={item.id}
              editingTitleId={editingTitleId}
              handlePageTitleClick={handlePageTitleClick}
              key={item.id}
              layout={layout}
              handleChangeTitleName={handleChangeTitleName}
              activeIndex={activeIndex}
              onRemove={() =>
                setItems((items) => items.filter((itemId) => itemId.id !== item.id))
              }
            />
          ))}
          <li style={style} className={classNames(
              style.Wrapper,
              layout === Layout.Vertical && style.vertical
          )} onClick={handleAddPageToSet}>
            <button className={`${style.Page} ${style.AddPage}`}>
              Add Page
            </button>
          </li>
        </ul>
      </SortableContext>
      <DragOverlay dropAnimation={dropAnimation}>
        {activeId ? (
          <PageOverlay id={activeId} layout={layout} items={items} />
        ) : null}
      </DragOverlay>
    </DndContext>
  );

  function handleDragStart({active}) {
    setActiveId(active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleDragEnd({over}) {
    console.log("over", over)
    if (over) {
      const overIndex = items.findIndex(item => item.id === over.id);

      if (activeIndex !== overIndex) {
        console.log("move item", activeIndex, overIndex)
        const newIndex = overIndex;

        // setItems((items) => arrayMove(items, activeIndex, newIndex));
        setPages({ ...pages, pages: arrayMove(items, activeIndex, newIndex) });
      } else {
        console.log("no move item")
      }
    }

    setActiveId(null);
  }
}

function PageOverlay({
  id,
  items,
  ...props
}) {
  const {activatorEvent, over} = useDndContext();
  const isKeyboardSorting = isKeyboardEvent(activatorEvent);
  const activeIndex = items.indexOf(id);
  const overIndex = over?.id ? items.indexOf(over?.id) : -1;

  return (
    <Page
      id={id}
      {...props}
      clone
      insertPosition={
        isKeyboardSorting && overIndex !== activeIndex
          ? overIndex > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
    />
  );
}

function SortablePage({
  id,
  activeIndex,
  ...props
}) {
  const {
    attributes,
    listeners,
    index,
    isDragging,
    isSorting,
    handleChangeTitleName,
    over,
    setNodeRef,
    transform,
    editingTitleId,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges: always,
  });

  return (
    <Page
      ref={setNodeRef}
      id={id}
      active={isDragging}
      style={{
        transition,
        transform: isSorting ? undefined : CSS.Translate.toString(transform),
      }}
      handleChangeTitleName={handleChangeTitleName}
      editingTitleId={editingTitleId}
      insertPosition={
        over?.id === id
          ? index > activeIndex
            ? Position.After
            : Position.Before
          : undefined
      }
      {...props}
      {...attributes}
      {...listeners}
    />
  );
}

function always() {
  return true;
}