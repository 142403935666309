import React from 'react';
import { Button, ButtonGroup } from '@mui/joy';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import CloseIcon from '@mui/icons-material/Close';
import BoltIcon from '@mui/icons-material/Bolt'; // incite

export default function TriggerContextualMenu({ open, setOpen, bottom = 110, right = 50 }) {
  return (
    <>
      <ButtonGroup sx={{ backgroundColor: '#f4f1f8', position: 'fixed', right: right, bottom: bottom, zIndex: 9999 }} >
        <Button
          variant="outlined" 
          onClick={ () => setOpen(!open)}   
          sx={{ mr: 1 }}      
          endDecorator={ !open ? <TravelExploreIcon /> : <CloseIcon />}>{ !open ? 'Browse' : 'Collapse Inline' }
        </Button>
        <Button
          variant='outlined'
          onClick={ () => setOpen(!open)}
          endDecorator={ !open ? <BoltIcon /> : <CloseIcon />}>{ !open ? 'Incites' : 'Collapse Incites' }
        </Button>
      </ButtonGroup>
    </>
  );
}