import { useState } from 'react';
import { ListItem, ListItemButton, ListItemDecorator, ListItemContent } from '@mui/joy';
import AdjustIcon from '@mui/icons-material/Adjust';

function MoveNote({ content, setProjectModal }) {

  const handleClick = (e) => {
    e.stopPropagation();
    setProjectModal(true);
  }

  return (
    <>
    <ListItem>
      <ListItemButton onClick={handleClick}>
        <ListItemDecorator>
          <AdjustIcon />
        </ListItemDecorator>
        <ListItemContent>Move { content.type }</ListItemContent>
      </ListItemButton>
    </ListItem>
    </>
  );
}

export default MoveNote;