import React from 'react';
import { Box, Button } from '@mui/joy';

export default function AddRowBtn({chooseNewRowType}) {
  return (
    <>
    <Box display="flex" alignItems="center" justifyContent="left" sx={(theme) => ({ minHeight: 70, pl: 1 })}>
      <Button sx={{ flex: 1 }} onClick={() => chooseNewRowType()} variant="plain" color="neutral"><span className="addrow-icon">&nbsp;</span>Add</Button>
    </Box>
    </>
  )
}