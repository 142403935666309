import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import ToDoCard from './ToDoCard';
import zIndex from '@mui/material/styles/zIndex';

function SortableToDoItem({ mode = "", todo, handleUpdateToDo, pendingValues, setPendingValues, handleDeleteToDo, handleDragEnd, handleMoveToTop}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: todo.id});
  const [ overDragicon, setOverDragicon ] = React.useState(false);
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: 9999,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ToDoCard 
        mode={mode}
        todo={todo} 
        handleUpdateToDo={handleUpdateToDo} 
        pendingValues={pendingValues} 
        setPendingValues={setPendingValues} 
        handleDeleteToDo={handleDeleteToDo} 
        overDragicon={overDragicon}
        attributes={attributes}
        listeners={listeners}
        setOverDragicon={setOverDragicon}
        handleDragEnd={handleDragEnd}
        handleMoveToTop={handleMoveToTop}
      />
    </div>
  );
}

export default SortableToDoItem;