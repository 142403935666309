import { useState, useCallback } from 'react';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { useResizeObserver } from '@wojtekmaj/react-hooks';
import { Button, Box } from '@mui/joy';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const resizeObserverOptions = {};

const maxWidth = 800;

function Pdf({content, mode = "view"}){
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [containerRef, setContainerRef] = useState(null);
  const [containerWidth, setContainerWidth] = useState(0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const onResize = useCallback((entries) => {
    const [entry] = entries;

    if (entry) {
      setContainerWidth(entry.contentRect.width);
    }
  }, []);

  useResizeObserver(containerRef, resizeObserverOptions, onResize);

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => Math.min(Math.max(prevPageNumber + offset, 1), numPages));
  };

  return (
    <div ref={setContainerRef}>
      <Document file={content.url} onLoadSuccess={onDocumentLoadSuccess}>
        <Page
          pageNumber={pageNumber}
          width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
        <Box 
          sx={{ 
            borderRadius: '7px', 
            boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.1)',
            backgroundColor: '#000',
            p: 2, 
            mx: 'auto', 
            my: 2, 
            position: mode !== 'view' ? 'fixed' : 'sticky', 
            width: { xs: '100%', xl: '40%' },
            bottom: 10, 
            left: mode === 'edit' ? '10%' : 'auto',
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            color: mode === 'edit' ? '#fff' : 'inherit'
          }}>
          <Button color="neutral" sx={{
            color: '#fff', 
            backgroundColor: '#000', 
            '&:hover': {
              backgroundColor: '#fff',
              color: '#000',
            }
          }} size="sm" variant="outlined" onClick={() => changePage(-1)} disabled={pageNumber <= 1}>
            Previous
          </Button>
          <Box sx={{ px: 2 }}>Page {pageNumber} of {numPages}</Box>
          <Button color="neutral" sx={{
            color: '#fff', 
            backgroundColor: '#000', 
            '&:hover': {
              backgroundColor: '#fff',
              color: '#000',
            }
          }} size="sm" variant="outlined" onClick={() => changePage(1)} disabled={pageNumber >= numPages}>
            Next
          </Button>
        </Box>
      </Document>
    </div>
  );
}

export default Pdf;
