import React from 'react';
import { RadioGroup, Radio, List, ListItem, ListItemDecorator } from '@mui/joy';
import LabelIcon from '@mui/icons-material/Label'; // tag
import RouteIcon from '@mui/icons-material/Route'; // user journey
import WebIcon from '@mui/icons-material/Web'; // content
import LinkIcon from '@mui/icons-material/Link'; // link
import SignpostIcon from '@mui/icons-material/Signpost'; // user flow
import ArticleIcon from '@mui/icons-material/Article'; // templates
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // sitemap
import AddReactionIcon from '@mui/icons-material/AddReaction'; // feature
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'; // notes
import HighlightIcon from '@mui/icons-material/Highlight'; // highlight

export default function CitationOptions({handleChange}) {
    return (
        <RadioGroup onChange={handleChange} sx={{ m: 0, p: 0 }} name="select-definition-type-group">
            <List
                sx={{
                '--List-gap': '0.5rem',
                '--ListItem-paddingY': '1rem',
                '--ListItem-radius': '8px',
                '--ListItemDecorator-size': '32px',
                overflow: 'hidden',
                }}
            >
                <ListItem key={`linkToNoteOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><AssignmentRoundedIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'note'}
                        variant='outlined'
                        label="Link to note"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`linkOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><LinkIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'link'}
                        variant='outlined'
                        label="External link"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`tagOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><LabelIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'tag'}
                        variant='outlined'
                        label="Link to tag"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                {/* TO DO: Can also add tagGroups and tagBoards here */}
                <ListItem key={`highlightOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><HighlightIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'highlight'}
                        variant='outlined'
                        label="Link to highlight"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`userJourneyOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><RouteIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'userJourney'}
                        variant='outlined'
                        label="Link to user journey"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`userFlowOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><SignpostIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'userFlow'}
                        variant='outlined'
                        label="Link to user flow"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`linkToTagOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><WebIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'template'}
                        variant='outlined'
                        label="Link to template"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`linkToSitemapOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><AccountTreeIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'sitemap'}
                        variant='outlined'
                        label="Link to sitemap"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`contentOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><ArticleIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'content'}
                        variant='outlined'
                        label="Link to content"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
                <ListItem key={`featureOption`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><AddReactionIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value={'feature'}
                        variant='outlined'
                        label="Link to feature"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>
            </List>
        </RadioGroup>
    )
}