import * as React from 'react';
import { Alert, Box, Input, Typography, Stack, Card, IconButton } from '@mui/joy';

import LinksComponent from '../LinksComponent';
import BrowseTypes from '../DrawerRight/BrowseTypes';
import { Pages } from './Components/Pages/pages';
import DrawerRight from '../DrawerRight';
import { useDrawerRight } from '../../contexts/drawerRightContext';
import TriggerContextualMenu from '../Buttons/TriggerContextualMenu';

export default function PagesModalContent({ pages, setPages, open, viewPane, setViewPane }) {

  const { drawerRightOpen, setDrawerRightOpen } = useDrawerRight()

  return (
    <Box sx={{ flex: 1, display: 'flex', width: '99%', px: 1, mb: 3 }}>
      <Box sx={{ flex: 2 }}>
        <Typography variant="h2" level="h1" sx={{ mt: 1, mb: 4, mr: 2 }}>
        <>
          <Input 
            size="lg"
            value={pages.title}  
            placeholder="Page set name" 
            onChange={ (e) => setPages({ ...pages, title: e.target.value }) } 
          />
        </>
        </Typography>
        <Stack direction="row">
          { pages &&
          <>
          { viewPane === 0 &&
          <Stack direction="column" sx={{ flex: 1, display: 'flex', justifyContent: 'center', minWidth: "34%", mr: 3 }}>
            <Card>
              {/* <SortableTree removable indicator collapsible sitemap={sitemap} setSitemap={setSitemap} /> */}
              <Pages layout={"grid"} pages={pages} setPages={setPages} />
            </Card>
          </Stack>
          }
          { viewPane === 1 &&
          <Stack direction="column" spacing={1} sx={{ flex: 1, display: 'flex', justifyContent: 'center', minWidth: "63%", minHeight: "68vh", display: { xs: 'flex', } }}>
            <LinksComponent open={open} />
          </Stack>
          }
          </>
          }
        </Stack>
      </Box>
  
      <DrawerRight content={() => <BrowseTypes setViewPane={setViewPane} />} />

      <Box>
        <TriggerContextualMenu bottom={120} right={15} open={drawerRightOpen} setOpen={setDrawerRightOpen} />
      </Box>

    </Box>
  );
}