import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import InlineLoader from '../../Loaders/InlineLoader';

export default function AiMagicButton({ loading= null }) {
    return (
        <>
        { loading ? <InlineLoader width={24} height={24} loading={true} /> :
          <>
            <AutoAwesomeIcon sx={{ 
              fill: 'url(#gradient1)'
            }} />
            <svg width="0" height="0" style={{ position: 'absolute' }}>
              <defs>
                <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%" y2="100%">
                  <stop offset="22%" style={{ stopColor: 'rgba(135,14,184,1)', stopOpacity: 1 }} />
                  <stop offset="72%" style={{ stopColor: 'rgba(252,106,79,1)', stopOpacity: 1 }} />
                  <stop offset="100%" style={{ stopColor: 'rgba(114,79,155,1)', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              </svg>
            </>
          }
        </>
    )
}
