import React, { useState, useEffect, version } from 'react';
import HistoryIcon from '@mui/icons-material/History';
import { Button, Stack, Tooltip, Typography } from '@mui/joy';
import IconButton from '@mui/joy/IconButton';
import Box from '@mui/joy/Box';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import { Drawer } from '@mui/material';
import DrawerRight from '../index';
import { useDrawerRight } from '../../../contexts/drawerRightContext';
import { useNotes } from '../../../contexts/notesContext';
import { useParams } from 'react-router-dom';
import { TimeAgo } from '../../../utils/utils';
import Grid from '@mui/material/Grid';
import VersionHistoryEditor from './VersionHistoryEditor';
import DeleteIcon from '@mui/icons-material/Delete';

export default function VersionHistory() {

  const { fetchNoteVersions, handleNewSelectedNote, deleteVersionOfNote, selectedNote } = useNotes();
  const [ versions, setVersions ] = useState([]);
  const [ currentVersion, setCurrentVersion ] = useState();
  const { toggleDrawer, setReason, setDrawerRightOpen } = useDrawerRight();

  const { accountId } = useParams()

  const handleVersionHistoryClick = async () => {
    console.log("handleVersionHistoryClick")
    setReason('noteVersions');
    const result = await fetchNoteVersions({ accountId, noteId: selectedNote.id });
    if (result) {
      setVersions(result);
      setCurrentVersion(result[0]);
    }
    toggleDrawer();
  }

  const handleCurrentVersion = (version) => {
    setCurrentVersion(version);
  }

  const handleRestoreThisVersion = (versionToUpdate) => {
    console.log("Restore this version", versionToUpdate)
    // Set current note to new note and save
    // Remove the id as the current id should be preserved
    const { id, type, ...versionWithoutId } = versionToUpdate;
    handleNewSelectedNote({ id: selectedNote.id, ...versionWithoutId }, false);
    toggleDrawer();
  }

  const handleDeleteVersion = async (versionId) => {
    //console.log("Delete this version", versionId)
    const result = await deleteVersionOfNote({ accountId, noteId: selectedNote.id, versionId })
    const newVersions = await fetchNoteVersions({ accountId, noteId: selectedNote.id });
    if (newVersions) {
      setVersions(newVersions);
      setCurrentVersion(newVersions[0]);
    }
  }

  const versionsContent = () => (
    <Grid container sx={{ display: 'flex' }}>
      { versions && versions.length > 0 ?
      <>
      <Grid item xs={3}>
        <Grid container sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          <Grid item style={{ flex: '1 0 auto' }}>
            <Box
              role="presentation"
              sx={{ flexGrow: 1, overflow: 'auto', p: 2, bgcolor: 'background.level1', borderRadius: '0 0 40px 0'}}
            >
              {/* TO DO: Versions below */}
              <Typography variant="h3" component="div" sx={{ fontWeight: '600', ml: 1, mb: 1 }}>Select a version below</Typography>
              <List sx={{ fontSize: 14 }} key={version.id}>
              { versions.map((version) => (
                <ListItem key={`listitem-` + version.id}>
                  <ListItemButton onClick={() => handleCurrentVersion(version)} selected={currentVersion && currentVersion.id === version.id}>
                    <TimeAgo timestamp={version.timestamp} />
                  </ListItemButton>
                  <IconButton size="sm" onClick={() => handleDeleteVersion(version.id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItem>
              ))}
              </List>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={9} >
        <Box
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            minHeight: '100vh', 
            position: 'sticky',
            p: 2
          }}
        >
          { currentVersion && currentVersion.editorState &&
            <Box>
              <VersionHistoryEditor editorState={ currentVersion.editorState } />
            </Box>
          }
          <Box sx={{ 
            position: 'sticky',
            display: 'flex',
            bottom: 5,
            ml: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            p: 1,
            width: '70%',
            boxShadow: 'sm',
            borderRadius: '10px',
          }}>
            <Button variant="solid" onClick={() => handleRestoreThisVersion(currentVersion)}>Restore this version</Button>
          </Box>
        </Box>
      </Grid>
      </>
      :
      <Box sx={{ m: 3}}>
        <Typography>There are no versions available.</Typography>
      </Box>
      }
    </Grid>
  );

  return (
    <>
    <Tooltip variant="outlined" color="neutral" title="View note history" arrow>        
      <IconButton size="sm" variant="plain" color="neutral" onClick={() => handleVersionHistoryClick()}>
        <HistoryIcon />
      </IconButton>
    </Tooltip>
    <DrawerRight content={versionsContent} />
    </>
  )
}