import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography, Grid, Button, Modal, Sheet, ModalClose, Stack, FormControl,  FormLabel, FormHelperText, Radio } from '@mui/joy';
import { Resizable } from "re-resizable";
import { useJourneys } from '../../../../../contexts/journeysContext';
import { useDroppable } from '@dnd-kit/core';
import { closestCenter } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext } from '@dnd-kit/sortable';
import { rectSortingStrategy } from '@dnd-kit/sortable';
import { useSensor, useSensors } from '@dnd-kit/core';
import { PointerSensor } from '@dnd-kit/core';
import { KeyboardSensor } from '@dnd-kit/core';
import { verticalListSortingStrategy } from '@dnd-kit/sortable';
import { horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import AddRowBtn from '../../AddRowBtn';
import AddColumnBtn from '../../AddColumnBtn';
import TableHeader from '../../../TableHeader';
import AddElement from '../../../AddElement';
import ColumnDropZone from '../../../ColumnDropZone';
import JourneyStepCard from '../../JourneyStepCard/JourneyStepCard';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import WidthFullIcon from '@mui/icons-material/WidthFull';
import WidthNormalIcon from '@mui/icons-material/WidthNormal';
import { DndContext } from '@dnd-kit/core';
import JourneyLinks from '../../JourneyLinks/index';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PreviewTableHeader from '../../PreviewTableHeader';

export default function PreviewJourneyGrid({ columnsOffset, setColumnsOffset, initialData, columnsSpan = 2, layout, columns, defaultWidth }) {

  console.log("defaultWidth", defaultWidth)

  return (
    <>
      <Box sx={{ width: defaultWidth, height: '100vh', overflowX: 'hidden', overflowY: 'auto' }}>  
        <Grid container columns={{ md: columns }} sx={{ width: defaultWidth, p: 5 }}>
          {/* Table Headers */}
          <Grid item xs={columnsSpan}></Grid>
          {/* Top Headers */}
          {initialData && initialData.length > 0 && initialData.filter(row => row && row.type && row.type === 'rowHeaders').map((row, rowIndex) => (
              row.children.map((column, colIndex) => (
              <Grid item xs={column.columnsSpan ? column.columnsSpan : columnsSpan} key={`grid_${column.id}`}>
                  <PreviewTableHeader id={row.id} row={row} rowIndex={rowIndex} colIndex={colIndex} title={column.title} initialData={initialData} />
              </Grid>
              // <Box className="resize-col" sx={(theme) => ({ position: 'absolute', backgroundColor: `${theme.palette.primary.outlinedBorder}`, mr: 1, right: 0, top: 10, bottom: 10, width: '2px', cursor: 'col-resize' })} />
              ))
          ))}
          <Grid item xs={columnsSpan}>
              {/* <AddColumnBtn handleAddColumn={handleAddColumn} /> */}
          </Grid>

          {/* Rows */}
          { initialData && initialData.length > 0 && initialData.filter(row => row && row.type && row.type === 'rowHeader').map((row, rowIndex) => {
              //console.log("NEW ROW: ", row)
              return (
              <>
                  <Grid item xs={columnsSpan} sx={(theme) => ({  })}>
                      <PreviewTableHeader id={row.id} row={row} rowIndex={rowIndex} title={row.title} initialData={initialData} />
                  </Grid>
                  {
                  row.children && row.children.map((rowColumn, colIndex) => (
                      //console.log("NEW COLUMN: ", rowColumn),
                      <Grid sx={{ pb: 5 }} item xs={columnsSpan} key={rowColumn.id}>
                      { rowColumn && rowColumn.children && rowColumn.children.length > 0 && rowColumn.children.map((component, componentIndex) => {
                          //console.log("NEW COMPONENT: ", component)
                          return (
                          <>
                          <Box sx={{ position: 'relative' }}>
                              {/* TO DO Add other types of card based on the row types */}
                              { component.type === "journeyStepCard" &&
                              <JourneyStepCard 
                                  key={component.id}
                                  id={component.id}
                                  satisfaction={component.satisfaction} 
                                  title={component.title} 
                                  layout={layout}
                                  description={component.description} 
                                  colIndex={colIndex}
                                  rowIndex={rowIndex}
                                  componentIndex={componentIndex}
                              />
                              }
                              { component.type === "journeyLinkCard" &&
                              <p>TO DO journey link card</p>
                              }
                          </Box>
                          </>
                          )                              
                      })}
                      <Box sx={{ position: 'relative' }}>
                          <></>
                      </Box>
                      </Grid>
                  ))
                  }
                  <Grid item xs={columnsSpan}>
                  &nbsp;
                  </Grid>
              </>
              );
          })}

          {/* Add Row Button */}
          <Grid item xs={columnsSpan}>
              <></>{/* <AddRowBtn chooseNewRowType={chooseNewRowType} /> */}
          </Grid>
        </Grid>
      </Box>
    </>
  )

}