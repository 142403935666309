import * as React from 'react';
import { useState, useRef } from 'react';
import PersonaModalContent from './PersonaModalContent';
import { useContextualPane } from '../../../contexts/contextualPane';
import { useParams } from 'react-router-dom';
import { retrieveTemplatesFromFirestore } from '../../../database/saveAsTemplate-db';
import { usePersonas } from '../../../contexts/personasContext';
import ModalEditorFrame from '../../Layouts/ModalEditorFrame';

export default function PersonaModal({open, name, setName, setOpen, persona}) {
  const modalRef = useRef(null);
  const { activeItem } = useContextualPane();
  const { editChecked, setEditChecked } = usePersonas();
  const [ personaFields, setPersonaFields ] = React.useState(persona && persona.data ? persona.data : []);
  const [ viewPane, setViewPane ] = React.useState(0);
  const [ data, setData ] = useState([]);
  const { accountId, selectedProjectId } = useParams();

  const fetchData = async () => {
    const result = await retrieveTemplatesFromFirestore({ accountId, selectedProjectId, type: "persona" });
    if (result) {
      setData(result);
    }        
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    if (persona) {
      setPersonaFields(persona.data);
    }
  }, [persona]);

  return (
    <ModalEditorFrame ref={modalRef} setOpen={setOpen} open={open}>
      {persona && (
        <PersonaModalContent 
          name={name} 
          setName={setName} 
          editChecked={editChecked} 
          setEditChecked={setEditChecked} 
          editMode={activeItem.mode} 
          viewPane={viewPane} 
          setViewPane={setViewPane} 
          personaFields={personaFields} 
          setPersonaFields={setPersonaFields} 
        />
      )}
    </ModalEditorFrame>
  );
}