import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$wrapNodeInElement, mergeRegister} from '@lexical/utils';
import {$setBlocksType} from '@lexical/selection';
import {
  $createParagraphNode,
  $createRangeSelection,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  createCommand,
  DRAGOVER_COMMAND,
  DRAGSTART_COMMAND,
  DROP_COMMAND,
  LexicalCommand,
  LexicalEditor,
  ParagraphNode,
} from 'lexical';
import {useEffect, useRef, useState, createContext, useContext} from 'react';
import * as React from 'react';
import {CAN_USE_DOM} from '../shared/canUseDom'
import { base64ToBlob } from '../../../utils/utils';

import { storage } from "../../../../configs/firebaseConfig";
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage"
import { v4 } from 'uuid'

import {
  $createImageNode,
  $isImageNode,
  ImageNode,
  ImagePayload,
} from '../nodes/ImageNode/ImageNode'
import { AspectRatio, Button, Skeleton } from '@mui/joy';

import InputFileUpload from '../../FileUploadButton';
import { Input } from '@mui/joy';
import { useAuth } from './../../../../authContext';  
import { addImageToNote } from '../../../database/notes-db';
import { useParams } from 'react-router-dom';
import { set } from 'date-fns';

const UploadContext = createContext();

const getDOMSelection = (targetWindow) => CAN_USE_DOM ? (targetWindow || window).getSelection() : null;

export const INSERT_IMAGE_COMMAND = createCommand('INSERT_IMAGE_COMMAND');
export const UPLOAD_IMAGE_COMMAND = createCommand('UPLOAD_IMAGE_COMMAND');

// Create a provider component
export function UploadProvider({ children }) {
  const [uploading, setUploading] = useState(false);

  return (
    <UploadContext.Provider value={{ uploading, setUploading }}>
      {children}
    </UploadContext.Provider>
  );
}

export function useUpload() {
  return useContext(UploadContext);
}

export function InsertImageUriDialogBody({ onClick }) {
  const [src, setSrc] = useState('');
  const [altText, setAltText] = useState('');

  const isDisabled = src === '';

  return (
    <>
      <Input 
        label="Image URL" 
        onChange={setSrc}
        placeholder="i.e. https://source.unsplash.com/random"
        value={src}
      />
      <Input 
        label="Alt Text"
        onChange={setAltText}
        placeholder="Random unsplash image"
        value={altText}
      />
      <div>
        <Button
          data-test-id="image-modal-confirm-btn"
          disabled={isDisabled}
          onClick={() => onClick({altText, src})}>
          Confirm
        </Button>
      </div>
    </>
  );
}

export function InsertImageUploadedDialogBody({ onClick }) {
  const [src, setSrc] = useState('');
  const [altText, setAltText] = useState('');

  const isDisabled = src === '';

  const loadImage = (files) => {
    const reader = new FileReader();
    reader.onload = function () {
      if (typeof reader.result === 'string') {
        setSrc(reader.result);
      }
      return '';
    };
    if (files !== null) {
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
      <InputFileUpload
        label="Image Upload"
        onChange={loadImage}
        accept="image/*"
        data-test-id="image-modal-file-upload"
      />
      <Input onChange={setAltText} placeholder="Alt text" value={altText} />
      <div>
        <Button
          disabled={isDisabled}
          onClick={() => onClick({altText, src})}>
          Confirm
        </Button>
      </div>
    </>
  );
}

export function InsertImageUploadingFeedback() {

  const { uploading } = useUpload();

  return (
    <AspectRatio ration="21/9">
      <Skeleton loading={uploading} variant="rect" width="100px" height="100px" animation="wave" />
    </AspectRatio>
  )
}

export function InsertImageDialog({ activeEditor, onClose }) {
  const [mode, setMode] = useState(null);
  const hasModifier = useRef(false);

  useEffect(() => {
    hasModifier.current = false;
    const handler = (e) => {
      hasModifier.current = e.altKey;
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [activeEditor]);

  const onClick = (payload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
    onClose();
  };

  return (
    <>
      {!mode && (
          <>
          <Button
            onClick={() =>
              onClick(
                hasModifier.current
                  ? {
                      altText:
                        'Daylight fir trees forest glacier green high ice landscape',
                      src: '',
                    }
                  : {
                      altText: 'Yellow flower in tilt shift lens',
                      src: '',
                    },
              )
            }>
            Sample
          </Button>
          <Button
            onClick={() => setMode('url')}>
            URL
          </Button>
          <Button
            onClick={() => setMode('file')}>
            File
          </Button></>
      )}
      {mode === 'url' && <InsertImageUriDialogBody onClick={onClick} />}
      {mode === 'file' && <InsertImageUploadedDialogBody onClick={onClick} />}
    </>
  );
}

export default function ImagesPluginModified({ captionsEnabled, noteToDisplay }) {
  const [editor] = useLexicalComposerContext();
  const [imageUpload,setImageUpload] = useState(null);
  const { currentUser } = useAuth();
  const { accountId} = useParams();
  const { uploading, setUploading } = useUpload();

  useEffect(() => {
    if (imageUpload && imageUpload !== null) {
      try {  
        // Split the base64 string into data and actual base64 parts
        const parts = imageUpload.split(';base64,');
        const mimeType = parts[0].split(':')[1];
        const imageBase64 = parts[1];
  
        // Convert base64 to Blob
        const blob = base64ToBlob(imageBase64, mimeType);
  
        const imageRef = ref(storage, `users/` + currentUser._delegate.uid + `/notes/` + noteToDisplay.id + `/uploads/` + v4() )

        uploadBytes(imageRef, blob).then((result) => {
          //console.log("image uploaded", result)
          const downloadUrl = getDownloadURL(result.ref).then((imageUrl) => {
            //console.log("Inserting image from firestore with downloadUrl", imageUrl)
            addImageToNote(noteToDisplay.id, accountId, currentUser, imageUrl)
            editor.dispatchCommand(INSERT_IMAGE_COMMAND,{ src: imageUrl, altText: '' })
          })
        })
      } catch (error) {
        //console.log("error uploading file", error)
      }        
    }
  },[imageUpload])

  useEffect(() => {
    //console.log("Uploading", uploading)
  },[uploading])

  useEffect(() => {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error('ImagesPlugin: ImageNode not registered on editor');
    }

    return mergeRegister(
      editor.registerCommand(
        INSERT_IMAGE_COMMAND,
        (payload) => {
          // { altText, src }
          //console.log("INSERT_IMAGE_COMMAND with payload", payload)
          setImageUpload(payload.src)
          const imageNode = $createImageNode(payload);
          //console.log("imageNode", imageNode) 
          $insertNodes([imageNode]);
          if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
            $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
          }
          setUploading(false)
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        UPLOAD_IMAGE_COMMAND,
        (payload) => {
          //console.log("UPLOAD_IMAGE_COMMAND with payload", payload)
          setUploading(true)
          setImageUpload(payload.src)
          return true;
        },
        COMMAND_PRIORITY_EDITOR,
      ),
      editor.registerCommand(
        DRAGSTART_COMMAND,
        (event) => {
          return onDragStart(event);
        },
        COMMAND_PRIORITY_HIGH,
      ),
      editor.registerCommand(
        DRAGOVER_COMMAND,
        (event) => {
          return onDragover(event);
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        DROP_COMMAND,
        (event) => {
          return onDrop(event, editor);
        },
        COMMAND_PRIORITY_HIGH,
      ),
    );
  }, [captionsEnabled, editor]);

  const values = {
    uploading,
    setUploading,
  }

  return null;
}

const TRANSPARENT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
const img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;

function onDragStart(event) {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const dataTransfer = event.dataTransfer;
  if (!dataTransfer) {
    return false;
  }
  dataTransfer.setData('text/plain', '_');
  dataTransfer.setDragImage(img, 0, 0);
  dataTransfer.setData(
    'application/x-lexical-drag',
    JSON.stringify({
      data: {
        altText: node.__altText,
        caption: node.__caption,
        height: node.__height,
        key: node.getKey(),
        maxWidth: node.__maxWidth,
        showCaption: node.__showCaption,
        src: node.__src,
        width: node.__width,
      },
      type: 'image',
    }),
  );

  return true;
}

function onDragover(event) {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  if (!canDropImage(event)) {
    event.preventDefault();
  }
  return true;
}

function onDrop(event, editor) {
  const node = getImageNodeInSelection();
  if (!node) {
    return false;
  }
  const data = getDragImageData(event);
  if (!data) {
    return false;
  }
  event.preventDefault();
  if (canDropImage(event)) {
    const range = getDragSelection(event);
    node.remove();
    const rangeSelection = $createRangeSelection();
    if (range !== null && range !== undefined) {
      rangeSelection.applyDOMRange(range);
    }
    $setSelection(rangeSelection);
    editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
  }
  return true;
}

function getImageNodeInSelection() {
  const selection = $getSelection();
  if (!$isNodeSelection(selection)) {
    return null;
  }
  const nodes = selection.getNodes();
  const node = nodes[0];
  return $isImageNode(node) ? node : null;
}

function getDragImageData(event) {
  const dragData = event.dataTransfer?.getData('application/x-lexical-drag');
  if (!dragData) {
    return null;
  }
  const {type, data} = JSON.parse(dragData);
  if (type !== 'image') {
    return null;
  }

  return data;
}

function canDropImage(event) {
  const target = event.target;
  return !!(
    target &&
    target instanceof HTMLElement &&
    !target.closest('code, span.editor-image') &&
    target.parentElement &&
    target.parentElement.closest('div.ContentEditable__root')
  );
}

function getDragSelection(event) {
  let range;
  const target = event.target;
  const targetWindow =
    target == null
      ? null
      : target.nodeType === 9
      ? (target).defaultView
      : (target).ownerDocument.defaultView;
  const domSelection = getDOMSelection(targetWindow);
  if (document.caretRangeFromPoint) {
    range = document.caretRangeFromPoint(event.clientX, event.clientY);
  } else if (event.rangeParent && domSelection !== null) {
    domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
    range = domSelection.getRangeAt(0);
  } else {
    throw Error(`Cannot get the selection when dragging`);
  }

  return range;
}