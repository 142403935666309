import { Stack, Typography } from "@mui/joy";
import BrowseTag from "../../Components/BrowseTag";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchTagWithIdInGroup } from "../../database/tags-db";
import TagChip from "../../Components/Tags/TagChip";

export default function BrowseTagPage() {
    const { accountId, selectedProjectId, boardId, tagId, groupId } = useParams();
    const [ tag, setTag ] = useState(null);

    const fetchTagData = async () => {
        const tag = await fetchTagWithIdInGroup({ tagId, groupId, boardId, accountId, projectId: selectedProjectId });
        setTag(tag);
    }

    useEffect(() => {
        if (tag === null && accountId && selectedProjectId && boardId && groupId && tagId) {

            const handleFetchTags = async () => {
                const results = await fetchTagData()
            }

            // fetch tag
            const result = handleFetchTags();
        }
    }, [accountId, selectedProjectId, groupId, boardId, tagId]);

    useEffect(() => {
        console.log('Tag data is:', tag);
    }, [tag]);

    return (
        <>
            <Stack sx={{ backgroundColor: '#f4f1f8', flexGrow: 1, p: 2 }}> 
                { tag &&
                <Typography fontSize={{ xs: 'md', md: 'lg' }} component="h1" fontWeight="lg">Explore <TagChip tag={tag} /></Typography>
                }
                { tag &&
                    <BrowseTag tag={tag} />
                }
            </Stack>
        </>    
    )
}