import { useEffect } from 'react';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import { useNotes } from '../../../contexts/notesContext';

function OnChangePlugin({ onChange }) {
  const [editor] = useLexicalComposerContext();
  const { setEditor } = useNotes();
  
  useEffect(() => {
    return editor.registerUpdateListener(({editorState}) => {
      // Debounce the onChange callback to prevent rapid updates
      const timeoutId = setTimeout(() => {
        onChange(editorState);
      }, 100);
      
      return () => clearTimeout(timeoutId);
    });
  }, [editor, onChange]);

  useEffect(() => {
    setEditor(editor);
  }, [editor, setEditor]);
}

export default OnChangePlugin