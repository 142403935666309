import React, {useEffect, useState, useRef, useCallback} from 'react';
import { $getSelection, $isRangeSelection, $isLineBreakNode } from 'lexical';
import {$findMatchingParent, mergeRegister} from '@lexical/utils';
import { getSelectedNode } from '../../../../utils/utils';
import { sanitizeUrl } from '../../../../utils/url';
import { setFloatingElemPositionForLinkEditor } from '../../../../utils/setFloatingElemPositionForLinkEditor';
import { COMMAND_PRIORITY_LOW, KEY_ESCAPE_COMMAND, SELECTION_CHANGE_COMMAND } from 'lexical';
import { Link, useNavigate } from 'react-router-dom';
import {
  BaseSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_HIGH,
  LexicalEditor,
} from 'lexical';
import {
  $createLinkNode,
  $isAutoLinkNode,
  $isLinkNode,
  TOGGLE_LINK_COMMAND,
} from '@lexical/link';
import Search from '../../../Search';
import { Box, Button, Input, List, ListItem, Tabs, TabList, Tab, TabPanel } from '@mui/joy';
import { tabClasses } from '@mui/joy/Tab';
import { set } from 'date-fns';

export default function FloatingSmartLinkEditor({
    editor,
    isLink,
    setIsLink,
    anchorElem,
    isLinkEditMode,
    setIsLinkEditMode,
  }) {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const [value,setValue] = useState();
    const [linkUrl, setLinkUrl] = useState('');
    const [editedLinkUrl, setEditedLinkUrl] = useState('https://');
    const [linkTitle, setLinkTitle] = useState('');
    const [externalLinkUrl, setExternalLinkUrl] = useState('https://');
    const [lastSelection, setLastSelection] = useState(
      null,
    );
  
    const updateLinkEditor = useCallback(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = getSelectedNode(selection);
        const linkParent = $findMatchingParent(node, $isLinkNode);
  
        if (linkParent) {
          setLinkUrl(linkParent.getURL());
          setLinkTitle(linkParent.getTitle());
        } else if ($isLinkNode(node)) {
          setLinkTitle(linkParent.getTitle());
          setLinkUrl(node.getURL());
        } else {
          setLinkUrl('');
        }
        if (isLinkEditMode) {
          setEditedLinkUrl(linkUrl);
        }
      }
      const editorElem = editorRef.current;
      const nativeSelection = window.getSelection();
      const activeElement = document.activeElement;
  
      if (editorElem === null) {
        return;
      }
  
      const rootElement = editor.getRootElement();
  
      if (
        selection !== null &&
        nativeSelection !== null &&
        rootElement !== null &&
        rootElement.contains(nativeSelection.anchorNode) &&
        editor.isEditable()
      ) {
        const domRect =
          nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
        if (domRect) {
          domRect.y += 40;
          setFloatingElemPositionForLinkEditor(domRect, editorElem, anchorElem);
        }
        setLastSelection(selection);
      } else if (!activeElement || activeElement.className !== 'link-input') {
        if (rootElement !== null) {
          setFloatingElemPositionForLinkEditor(null, editorElem, anchorElem);
        }
        setLastSelection(null);
        setIsLinkEditMode(false);
        setLinkUrl('');
      }
  
      return true;
    }, [anchorElem, editor, setIsLinkEditMode, isLinkEditMode, linkUrl]);
  
    useEffect(() => {
      const scrollerElem = anchorElem.parentElement;
  
      const update = () => {
        editor.getEditorState().read(() => {
          updateLinkEditor();
        });
      };
  
      window.addEventListener('resize', update);
  
      if (scrollerElem) {
        scrollerElem.addEventListener('scroll', update);
      }
  
      return () => {
        window.removeEventListener('resize', update);
  
        if (scrollerElem) {
          scrollerElem.removeEventListener('scroll', update);
        }
      };
    }, [anchorElem.parentElement, editor, updateLinkEditor]);
  
    useEffect(() => {
      return mergeRegister(
        editor.registerUpdateListener(({editorState}) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),
  
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          COMMAND_PRIORITY_LOW,
        ),
        editor.registerCommand(
          KEY_ESCAPE_COMMAND,
          () => {
            if (isLink) {
              setIsLink(false);
              return true;
            }
            return false;
          },
          COMMAND_PRIORITY_HIGH,
        ),
      );
    }, [editor, updateLinkEditor, setIsLink, isLink]);
  
    useEffect(() => {
      editor.getEditorState().read(() => {
        updateLinkEditor();
      });
    }, [editor, updateLinkEditor]);
  
    useEffect(() => {
      if (isLinkEditMode && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isLinkEditMode, isLink]);
  
    const monitorInputInteraction = (
      event,
    ) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        handleLinkSubmission();
      } else if (event.key === 'Escape') {
        event.preventDefault();
        setIsLinkEditMode(false);
      }
    };
  
    const handleLinkSubmission = () => {
      if (lastSelection !== null) {
        if (linkUrl !== '') {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
            url: sanitizeUrl(editedLinkUrl),
            title: linkTitle !== '' ? linkTitle : sanitizeUrl(editedLinkUrl),
          });
          editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection)) {
              const parent = getSelectedNode(selection).getParent();
              if ($isAutoLinkNode(parent)) {
                const linkNode = $createLinkNode(parent.getURL(), {
                  rel: parent.__rel,
                  target: parent.__target,
                  title: parent.__title,
                });
                parent.replace(linkNode, true);
              }
            }
          });
        }
        setEditedLinkUrl('https://');
        setIsLinkEditMode(false);
      }
    };

    useEffect(() => {
      if (externalLinkUrl.length > 0 && externalLinkUrl !== 'https://') {
        setEditedLinkUrl(externalLinkUrl);
        setLinkTitle(externalLinkUrl);
      }
    }, [externalLinkUrl]);
      
    return (
      <div ref={editorRef} className="link-editor">
        {!isLink ? null : isLinkEditMode ? (
          <>
            <Box sx={{ flexGrow: 1, p: .75}}>
              <Tabs aria-label="tabs" defaultValue={0} sx={{ bgcolor: 'transparent' }}>
                <TabList
                  disableUnderline
                  sx={{
                    p: 0.5,
                    gap: 0.5,
                    borderRadius: 'xl',
                    bgcolor: 'background.level1',
                    [`& .${tabClasses.root}[aria-selected="true"]`]: {
                      boxShadow: 'sm',
                      bgcolor: 'background.surface',
                    },
                  }}
                >
                  <Tab sx={{ fontSize: 14 }} disableIndicator xs={{ backgroundColor: 'none' }}>Internal link</Tab>
                  <Tab sx={{ fontSize: 14 }} disableIndicator>External link</Tab>
                </TabList>
                <TabPanel value={0} sx={{ px: .75, pb: 1, m: 0 }}>
                  <Box sx={{ mb: 1 }}>
                    <Search size="md" linkTitle={linkTitle} setLinkTitle={setLinkTitle} value={value} setValue={setValue} url={editedLinkUrl} setUrl={setEditedLinkUrl} mode="addlink" />
                  </Box>
                  <Button size="sm" sx={{ mt: .5 }} onClick={handleLinkSubmission}>Save</Button>
                  <Button variant='outlined' size="sm" sx={{ ml: 1, mt: .5 }} onClick={() => setIsLinkEditMode(false)}>Cancel</Button>
                </TabPanel>
                <TabPanel value={1} sx={{ px: .75, m: 0 }}>
                  <Box sx={{ mb: 1 }}>
                    <Input size="md" value={externalLinkUrl} onChange={(e) => setExternalLinkUrl(e.target.value)} />     
                  </Box>
                  <Button size="sm" sx={{ mt: .5 }} onClick={handleLinkSubmission}>Save</Button>
                  <Button variant='outlined' size="sm" sx={{ ml: 1, mt: .5 }} onClick={() => setIsLinkEditMode(false)}>Cancel</Button>           
                </TabPanel>
              </Tabs>
            </Box>
          </>
          // <>
          //   <input
          //     ref={inputRef}
          //     className="link-input"
          //     value={editedLinkUrl}
          //     onChange={(event) => {
          //       setEditedLinkUrl(event.target.value);
          //     }}
          //     onKeyDown={(event) => {
          //       monitorInputInteraction(event);
          //     }}
          //   />
          //   <div>
          //     <div
          //       className="link-cancel"
          //       role="button"
          //       tabIndex={0}
          //       onMouseDown={(event) => event.preventDefault()}
          //       onClick={() => {
          //         setIsLinkEditMode(false);
          //       }}
          //     />
  
          //     <div
          //       className="link-confirm"
          //       role="button"
          //       tabIndex={0}
          //       onMouseDown={(event) => event.preventDefault()}
          //       onClick={handleLinkSubmission}
          //     />
          //   </div>
          // </>
        ) : (
          <div className="link-view">
            {
              !linkUrl.startsWith('/client/') ?
              (
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                  {linkUrl}
                </a>
              ) : (
                <Link to={linkUrl}>
                  {linkTitle !== "" ? linkTitle : linkUrl}
                </Link>
              )
            }
            <div
              className="link-edit"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                setEditedLinkUrl(linkUrl);
                setIsLinkEditMode(true);
              }}
            />
            <div
              className="link-trash"
              role="button"
              tabIndex={0}
              onMouseDown={(event) => event.preventDefault()}
              onClick={() => {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
              }}
            />
          </div>
        )}
      </div>
    );
}