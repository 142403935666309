import React from 'react';
import { Link, Box, Button, Card, FormControl, Stack, Typography } from '@mui/joy';
import Launch from '@mui/icons-material/Launch';
import { useNotes } from '../../../contexts/notesContext';

function ExistingLinkCard({ noteTitle, defaultUrl, defaultDescription }) {
  const { binPage, updateLinkModal, setUpdateLinkModal } = useNotes();
  const handleUpdateLink = () => {
    console.log("handleUpdateLink")
    setUpdateLinkModal(true)
  }
  return (
    <Stack spacing={2}>
      <Card>
        <Box id="title" sx={{ mt: 3, mr: 2, ml: 2, mb: 2 }} tabIndex={1}>
          <Link startDecorator={<Launch />} target="_blank" variant="plain" href={defaultUrl}>
            <Typography variant="h6" color="text.secondary" sx={{ fontSize: '2.25rem', mt: 1, ml: 1 }}>
              {noteTitle}
            </Typography>
          </Link>
          <Typography>{defaultDescription}</Typography>
          { !updateLinkModal && !binPage && <Button sx={{ mt: 2 }} size="sm" onClick={() => handleUpdateLink()}>Update</Button> }
        </Box>
      </Card>
    </Stack>
  )
}

export default ExistingLinkCard;