import React, { useState } from 'react';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Stack from '@mui/joy/Stack';
import Add from '@mui/icons-material/Add';
import { saveAsTemplateToFirestore } from '../../../database/saveAsTemplate-db';
import { useParams } from 'react-router-dom';
import { el } from 'date-fns/locale';

export default function CreateTemplate({ open, setOpen, type, newTypeData, fetchData }) {

    const [ title, setTitle ] = useState(`New ` + type + ` template`)
    const { accountId, selectedProjectId } = useParams()

    return (
        <React.Fragment>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog fullscreen>
                    <DialogTitle>Create new {type} template</DialogTitle>
                    <DialogContent>Let's name the {type} below.</DialogContent>
                    <form
                        onSubmit={async (event) => {
                            event.preventDefault();
                            let result;
                            if (type === "persona") {
                                console.log("CreateTemplate newTypeData", newTypeData)
                                result = await saveAsTemplateToFirestore({ type: type, fields: [...newTypeData.data] , accountId, selectedProjectId, title });
                            } else if (type === "journey") {
                                result = await saveAsTemplateToFirestore({ type: type, fields: { width: newTypeData.width , columns: newTypeData.columns, columnsSpan: newTypeData.columnsSpan, items: [...newTypeData.items] }, accountId, selectedProjectId, title });
                            }
                            if (type === "note") {
                                result = await saveAsTemplateToFirestore({ type: type, fields: { ...newTypeData }, accountId, selectedProjectId, title });
                            }
                            console.log("result of creating template", result)
                            fetchData()
                            setOpen(false);
                        }}
                    >
                        <Stack spacing={2}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Input onChange={(e) => setTitle(e.target.value)} defaultValue={`New ` + type + ` template`} autoFocus required />
                            </FormControl>
                            <Button type="submit">Create</Button>
                        </Stack>
                    </form>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    );
}