import { useEffect, useState } from 'react';
import { Box, Modal, ModalDialog, Stack, FormControl, FormLabel, Input, Button, Textarea } from '@mui/joy';
import HttpIcon from '@mui/icons-material/Http';
import { updateLink } from '../../../database/notes-db';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../authContext';  
import { useNotes } from '../../../contexts/notesContext';
import ExistingLinkCard from './ExistingLinkCard';

function ViewUpdateLink({ content }) {
  const { getAndSetData, handleDeleteNote, updateLinkModal, setUpdateLinkModal } = useNotes();
  const [linkTitle, setLinkTitle] = useState();
  const [viewMode, setViewMode] = useState(true);
  const [linkAddress, setLinkAddress] = useState();
  const [description, setDescription] = useState();
  const { accountId, selectedProjectId } = useParams()
  const { currentUser } = useAuth()

  const handleUpdateLink = async () => {
    setViewMode(false);
    console.log('linkAddress', linkAddress);
    console.log('description', description);
    const user = currentUser;
    const resultOfUpdateLink = await updateLink({ type: content.type, linkTitle, linkAddress, description, accountId, user, selectedProjectId, id: content.id, tags: content.tags, pinned: content.pinned });
    getAndSetData({ setNewCurrentNote: true, sortByPinned: false });
    handleCloseLinkModal();
    setViewMode(true);
    console.log('resultOfUpdateLink ', resultOfUpdateLink)
  }

  const handleDeleteLink = async () => {
    setViewMode(false);
    handleDeleteNote({ noteId: content.id });
  }

  const handleEditLink = () => {
    setViewMode(!viewMode);
  }

  const handleCloseLinkModal = () => {
    console.log('close link modal')
    setUpdateLinkModal(false);
  }

  useEffect(() => {
    if (content.noteTitle) {
      setLinkTitle(content.noteTitle);
    }
    if (content.url) {
      setLinkAddress(content.url);
    }
    if (content.description) {
      setDescription(content.description);
    }
  }, [content])

  return (
    <Modal open={updateLinkModal} onClose={() => setUpdateLinkModal(false)}>
      <ModalDialog size="md" sx={{ width: 600 }}>
        { viewMode ?
          <>
          <ExistingLinkCard 
            noteTitle={content.noteTitle} 
            defaultUrl={content.url} 
            defaultDescription={content.description} 
          />
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleEditLink} type="submit">Edit</Button>
            <Button variant="plain" onClick={handleCloseLinkModal}>Close</Button>
          </Box>
          </>
        :
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>Link Address</FormLabel>
            <Input defaultValue={content.url} onChange={(e) => setLinkAddress(e.target.value)} size="sm" startDecorator={<HttpIcon />} autoFocus required />
          </FormControl>
          <FormControl>
            <FormLabel>Link Title</FormLabel>
            <Input defaultValue={content.noteTitle} onChange={(e) => setLinkTitle(e.target.value)} size="sm" required />
          </FormControl>
          <FormControl>
            <FormLabel>Description (optional)</FormLabel>
            <Textarea defaultValue={content.description} onChange={(e) => setDescription(e.target.value)} size="sm" minRows={3} />
          </FormControl>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={handleUpdateLink} type="submit">Update</Button>
            <Button onClick={handleDeleteLink} variant="outlined" color="danger">Delete</Button>
            
            <Button variant="plain" onClick={handleEditLink}>Cancel</Button>
          </Box>
        </Stack>
        }
      </ModalDialog>
    </Modal>
  )

}

export default ViewUpdateLink;