import React, { createContext, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const MultiTaskingContext = createContext();

export function MultiTaskingProvider({ children }) {
  const [panels, setPanels] = useState([]);
  // Panel structure: { id: string, type: string, content: object, title: string }
  
  const addPanel = (panel) => {
    console.log("Adding panel:", panel);
    setPanels(prev => [...prev, {
      ...panel,
      id: panel.id || uuidv4(),
      title: panel.title || panel.id,
      panelId: uuidv4()
    }]);
  };

  const removePanel = (panelId) => {
    console.log("Removing panel with id:", panelId);
    console.log("Panels before removal:", panels);
    setPanels(prev => prev.filter(p => p.panelId !== panelId));
    console.log("Panels after removal:", panels);
  };

  const clearPanels = () => {
    setPanels([]);
  };

  const updatePanelContent = (panelId, newContent) => {
    setPanels(prev => prev.map(panel => 
      panel.panelId === panelId ? { ...panel, content: newContent } : panel
    ));
  };

  return (
    <MultiTaskingContext.Provider value={{
      panels,
      addPanel,
      removePanel,
      clearPanels,
      updatePanelContent
    }}>
      {children}
    </MultiTaskingContext.Provider>
  );
}

export const useMultiTasking = () => useContext(MultiTaskingContext);
