import IconButton from '@mui/joy/IconButton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { styled } from '@mui/joy';

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

export default function UploadIconButton({onChangeHandler, label, onChange, accept, dataTestId }) {
  return (
    <IconButton
      aria-label="upload new picture"
      size="sm"
      variant="outlined"
      color="neutral"
      sx={{
        bgcolor: 'background.body',
        position: 'absolute',
        zIndex: 2,
        borderRadius: '50%',
        left: 100,
        top: 170,
        boxShadow: 'sm',
      }}
      component="label"
      role={undefined}
      tabIndex={-1}
    >
      <EditRoundedIcon />
      <VisuallyHiddenInput type="file" onChange={(event => {onChangeHandler(event.target.files[0])})} />
    </IconButton>
  )
}