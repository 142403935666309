import { useState } from "react";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { IconButton, Tooltip, ListItemButton, ListItemDecorator, ListItemContent, ListItem } from '@mui/joy';
import { useNotes } from "../../contexts/notesContext";
import { useAuth } from "../../../authContext";

export default function MakePrivate({ item, makePrivateModal, setMakePrivateModal }) {

    const { handleMakeNotePublic } = useNotes();
    const { currentUser } = useAuth();

    const handleMakePrivate = (e) => {
        console.log("handleMakePrivate", item)
        e.stopPropagation();
        setMakePrivateModal(true);
    }

    const handleUnPrivate = (e) => {
        console.log("handleUnPrivate", item)
        e.stopPropagation();
        // handleUnPrivate(item);
        handleMakeNotePublic({ noteId:item.id });
    }

    return (
        <>
        { currentUser._delegate.uid === item.createdBy &&
        <ListItem>
            { item.private ?
                <ListItemButton onClick={(e) => handleUnPrivate(e)}>            
                    <ListItemDecorator><LockIcon /></ListItemDecorator>
                    <ListItemContent>Unlock { item.type }</ListItemContent>
                </ListItemButton>
            :
                <ListItemButton onClick={(e) => handleMakePrivate(e)}>
                    <ListItemDecorator><LockOpenIcon /></ListItemDecorator>
                    <ListItemContent>Mark as private</ListItemContent>
                </ListItemButton>
            }
        </ListItem>
        }
        </>
    )
}