import PlanCard from '../PlanCard';
import CookieIcon from '@mui/icons-material/Cookie';
import StorageIcon from '@mui/icons-material/Storage';
import SchoolIcon from '@mui/icons-material/School';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DevicesIcon from '@mui/icons-material/Devices';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';

const points = [
    { title: '1 project', icon: <CookieIcon /> },
    { title:'250mb storage', icon: <StorageIcon /> },
    { title:'Research repository', icon: <SchoolIcon /> },
    { title:'Research tagging and analysis', icon: <LocalOfferIcon /> },
    { title:'Experience documentation', icon: <MenuBookIcon /> },
    { title:'Multi device', icon: <DevicesIcon /> },
    { title:'AI Magic', icon: <AutoFixNormalIcon /> }
];

function Free({ name, pricing, selected, monthly }) {
    return (
      <PlanCard plan="free" selected={selected} points={points} pricing={pricing} monthly={monthly} name={name} />
    )
}

export default Free;