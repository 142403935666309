import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function startVideoTranscription({ userId, accountId, fileName, fileUrl, noteId }) {
  try {
    // Create a new transcription request document
    const transcriptionRef = await db.collection(`accounts/${accountId}/transcriptions`).add({
      userId,
      fileName,
      fileUrl,
      noteId,
      status: 'pending',
      created: new Date(),
      updated: new Date(),
      error: null,
      progress: 0
    });

    return { id: transcriptionRef.id, status: 'pending' };
  } catch (error) {
    console.error("Error starting transcription:", error);
    throw error;
  }
}

export async function getTranscriptionStatus(accountId, transcriptionId) {
  try {
    const doc = await db.collection(`accounts/${accountId}/transcriptions`).doc(transcriptionId).get();
    if (doc.exists) {
      return doc.data().status; // Return only the status field
    } else {
      throw new Error("Transcription document does not exist");
    }
  } catch (error) {
    console.error("Error getting transcription status:", error);
    throw error;
  }
}