import PlanCard from '../PlanCard';
import CookieIcon from '@mui/icons-material/Cookie';
import StorageIcon from '@mui/icons-material/Storage';
import SchoolIcon from '@mui/icons-material/School';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DevicesIcon from '@mui/icons-material/Devices';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import PublishIcon from '@mui/icons-material/Publish';
import PeopleIcon from '@mui/icons-material/People';
import CommentIcon from '@mui/icons-material/Comment';

const points = [
    { title:'Unlimited projects', icon: <CookieIcon /> },
    { title:'Unlimited storage', icon: <StorageIcon /> },
    { title:'Research repository', icon: <SchoolIcon /> },
    { title:'Research tagging and analysis', icon: <LocalOfferIcon /> },
    { title:'Experience documentation', icon: <MenuBookIcon /> },
    { title:'Publish & Share', icon: <PublishIcon /> },
    { title:'Multi device', icon: <DevicesIcon /> },
    { title:'Invite team members', icon: <PeopleIcon /> },
    { title:'Commenting and feedback', icon: <CommentIcon /> },
    { title:'AI Magic', icon: <AutoFixNormalIcon /> }
];

function Team({ name, selected, pricing, monthly }) {
    return (
      <PlanCard plan="team" selected={selected} points={points} pricing={pricing} monthly={monthly} name={name} />
    )
}

export default Team;