import React from "react"
import { Box, Tooltip, Card, CardContent, IconButton, Grid, Typography, Dropdown, MenuButton, Menu, MenuItem } from "@mui/joy"
import TagPicker from "../Tags/Editor/TagPicker"
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FeedbackSnackbar from './../Snackbar/index';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import TagChip from "../Tags/TagChip";
import MosCow from "../Tags/MoSCoW";
import BoltIcon from '@mui/icons-material/Bolt';

export default function FeatureCards({feature,handleFeatureClick}) {
  const [open, setOpen] = React.useState(false);
  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);

  const handleDeleteFeature = async () => {
    console.log("Deleting feature")
    // TO DO delete feature
  }

  const handleDockFeature = async () => {
    console.log("Docking feature")
    // TO DO dock feature
  }

  return (
    <Card 
      sx={{
        flexDirection: 'column',
        alignItems: 'initial',
        gap: 1,
        marginBottom: 1.85,
        pt: { xs: 2, lg: 4 },
        pb: { xs: 4, lg: 4 },
        px: 3,
        backgroundColor: '#fff',
        boxShadow: '0px 2px 10px rgba(3,3,3,0.1)',
        '&:hover': { boxShadow: 'md', backgroundColor: '#fff', borderColor: 'neutral.outlinedHoverBorder' },
      }}
      variant="soft"
      orientation="vertical"
    >
      <CardContent className="show-on-hover-parent">
        <Grid container spacing={2}>
          <Grid item xs={12} xl={9} >
            <CardContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', pl: { xs: 2, lg: 2, xl: 0 }, pt: .5, fontSize: 14, lineHeight: 2, pr: 2 }}>
              <Box className="show-on-hover">
                <Dropdown onOpenChange={handleOpenChange} open={open}>
                  <MenuButton
                    slots={{ root: IconButton }}
                    slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
                    tabIndex={-1}
                  >
                    <IconButton size="sm">
                      <MoreVertIcon />
                    </IconButton>
                  </MenuButton>
                  <Menu placement="left" sx={{ zIndex: 9999 }}>
                    <MenuItem onClick={handleDockFeature}>Dock feature</MenuItem>
                    <MenuItem onClick={handleDeleteFeature}>Delete</MenuItem>
                  </Menu>
                  <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
                </Dropdown>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                { feature && feature.priority &&
                  <CardContent sx={{ textAlign: 'right', pl: { xs: 2, lg: 2, xl: 0 }, pt: .5, fontSize: 14, lineHeight: 2, pr: 2 }}>
                    <Typography
                      sx={{ fontSize: 14, lineHeight: 2 }}
                    >
                      <MosCow priority={feature.priority} />
                    </Typography>
                  </CardContent>
                }
                <Typography onClick={() => handleFeatureClick(feature)} sx={{ mt: .5, fontSize: 14, lineHeight: 2, '&:hover': { cursor: 'pointer' } }}>
                  {feature && feature.title && feature.title}
                </Typography>
              </Box>
            </CardContent>
          </Grid>
          <Grid item xs={6} xl={3}>
            <Box className="show-on-hover" sx={{ textAlign: 'right' }}>
              <Tooltip variant="outlined" color="neutral" title="Explore relationships">
                <IconButton size="sm" sx={{ mr: 1, pr: 1, pl: 1, color: 'neutral' }}>
                  <BoltIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </CardContent>      
    </Card>
  )
}