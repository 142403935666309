import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function retrieveFeaturesFromFirestore({accountId,selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/features`)
    .where("project", "==", selectedProjectId ? selectedProjectId : '')
    .orderBy("updated", "desc").get()

    const features = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      features.push({
        id: doc.id,
        title: data.title ? data.title : '',
        project: data.project ? data.project : '',
        description: data.description ? data.description : '',
        additionalFields: data.additionalFields ? data.additionalFields : {},
        priority: data.priority ? data.priority : '',
        tags: data.tags ? data.tags : [],
        created: data.created ? data.created.toDate() : null,
        updated: data.updated ? data.updated.toDate() : null
      });
    });

    //console.log("Retrieved features from Firestore:", features);
    return features;
  } catch (error) {
    console.error("Error retrieving features from Firestore:", error);
    return [];
  }
}

export default async function saveToFirestore({ feature, accountId, selectedProjectId }) {  
  const id = feature && feature.id ? feature.id : null 

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/features`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/features`).doc(id).update({
          title: feature.title,
          description: feature.description,
          project: selectedProjectId ? selectedProjectId : '',
          additionalFields: feature.additionalFields,
          updated: new Date(),
          priority: feature.priority ? feature.priority : '',
          category: feature.category ? feature.category : '',
          status: feature.status ? feature.status : '',
          dueDate: feature.dueDate ? feature.dueDate : new Date(),
          tags: feature.tags ? feature.tags : []
        });
        //console.log("Feature updated in Firestore!");
      } catch(error) {
        console.error("Error saving feature to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    try {
      const docRef = await db.collection(`accounts/${accountId}/features`).add({
        title: feature.title, 
        description: feature.description ? feature.description : '',
        project: selectedProjectId ? selectedProjectId : '',
        additionalFields: feature.additionalFields ? feature.additionalFields : {},
        priority: feature.priority ? feature.priority : '',
        category: feature.category ? feature.category : '',
        status: feature.status ? feature.status : '',
        dueDate: feature.dueDate ? feature.dueDate : new Date(),
        tags: feature.tags ? feature.tags : [],
        created: new Date(),
        updated: new Date(),
      });

      const docSnap = await docRef.get();
      const newDoc = { id: docRef.id, ...docSnap.data() };

      return newDoc;
      //console.log("New feature saved to Firestore!");
    } catch (error) {
      console.error("Error saving feature to Firestore:", error);
    }
  }
}

export async function deleteFeature({ featureId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/features`).doc(featureId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Feature deleted from Firestore with ID:", featureId);

      // Optionally, you can return the deleted Feature data if needed
      const deletedPersonaData = snapshot.data();
      return { id: featureId, ...deletedPersonaData };
    } else {
      console.error("No document found with ID:", featureId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting Feature from Firestore:", error);
    return null;
  }
}

export { saveToFirestore }