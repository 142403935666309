import React from "react";
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Grid from '@mui/joy/Grid';
import DefinitionBanner from "../../Components/Banners/DefinitionBanner"
import FeedbackSnackbar from "../../Components/Snackbar";
import SitemapsListCards from '../../Components/Sitemaps/index';
import { usePages } from '../../contexts/pagesContext';
import PagesModal from '../../Components/Pages/PagesModal';
import PageSetCards from '../../Components/Pages/Components/PageSetCards';
import { getConfig } from '../../database/configs-db';

export default function PagesPage() {
  const [banner, setBanner] = React.useState('')

  React.useEffect(() => {
    getConfig().then(config => setBanner(config.pagesImage))
  }, [])

  const {
    pages,
    activeItem,
    handleSetActiveItemForPages,
    setPages,
    handleCreatePagesSet,
    handleSetPages,
    handleUpdateCurrentPageSet,
    handlePageSetDeletion,
    open,
    setOpen,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMsg,
    setSnackbarMsg
  } = usePages()

  const handlePagesClick = (pages) => {
    setOpen(true)
    handleSetPages(pages)
  }

  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        gridTemplateColumns: {
          xs: '1fr',
          //sm: 'minmax(min-content, min(30%, 400px)) 1fr',
          sm: '1fr',
        },
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pb={1.5}
      >
        <DefinitionBanner 
          title="Pages &amp; Content" 
          // text="A feature, in the context of software development, refers to a distinct, individual functionality or characteristic that a software product or system offers to its users. Features are specific components or capabilities that contribute to the overall functionality and utility of the software. Each feature typically serves a particular purpose, addressing a specific user need or requirement." 
          btnTxt="Create Page Set"
          handleCreate={handleCreatePagesSet} 
          srcImage={banner}
        />
      </Stack>
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pt={0}
        pb={1.5}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ flexGrow: 1, backgroundColor: '#f4f1f8', borderRadius: 7 }}
        >
          { pages && pages.length > 0 && pages.map((pageSet, index) => (
            <Grid key={index} xs={2} sm={4} md={4}>
              <PageSetCards pages={pageSet} handlePagesClick={handlePagesClick} />
            </Grid>
          ))}
          {/* TO DO work out if this is pane is of use <InlineTypesViewBtn /> */}
          {/* TO DO work out if this is pane is of use <DrawerRight size={"sm"} noBlur={true} content={() => (<ViewNotes />)} /> */}
        </Grid>
      </Stack>
      { activeItem && activeItem.item && 
        <PagesModal open={open} setOpen={setOpen} handlePageSetDeletion={handlePageSetDeletion} handleUpdateCurrentPageSet={handleUpdateCurrentPageSet} pages={activeItem.item} setPages={handleSetActiveItemForPages} />
      }
      <FeedbackSnackbar openSetting={showSnackbar} setShowSnackbar={setShowSnackbar} message={snackbarMsg} colorSetting={snackbarSeverity} />
    </Sheet>
  )
}