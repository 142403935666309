import * as React from 'react';
import { styled } from '@mui/joy/styles';
import Sheet from '@mui/joy/Sheet';
import Grid from '@mui/joy/Grid';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import EditBoardTitleDropdown from './EditBoardTitleDropdown';
import Box from '@mui/joy/Box';
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import { set } from 'date-fns';
import Button from '@mui/joy/Button';
import Add from '@mui/icons-material/Add';
import EditTagDropdown from './EditTagDropdown';
import { useParams } from 'react-router-dom';
import { createGroupInBoard, createTagInGroup, deleteGroup, deleteTag, fetchTagBoardAndGroupsAndTagsWithId, updateBoard, updateGroupInBoard, updateTagInGroup, deleteBoard } from '../../database/tags-db';
import { useAuth } from '../../../authContext'; 
import { useBoards } from '../../contexts/boardsContext';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import Dropdown from '@mui/joy/Dropdown'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import TagChip from './TagChip';
import { useLoading } from '../../contexts/loadingContext';
import { checkIfTagIsInUse } from '../../database/tags-db';
import ConfirmDeleteTagsModal from './ConfirmDeleteTagsModal';

const backgroundColourStrength = 50
const hoverColourStrength = 100
const textColourStrength = 500

const Item = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.background.level1 : '#fff',
  ...theme.typography['body-sm'],
  padding: theme.spacing(1),
  textAlign: 'center',
  borderRadius: 4,
  color: theme.vars.palette.text.secondary,
}));

const initialBoard = {
  title: 'Untitled tag board',
  created: new Date(),
  updated: new Date(),
  lastupdatedBy: 'User',
  boardGroups: [
    {
      id: 1,
      title: 'Untitled Group',
      created: new Date(),
      updated: new Date(),
      colour: blue,
      lastupdatedBy: 'User',
      tags: [
        {
          id: 1,
          title: 'Features',
          colour: purple,
          references: [
            {
              title: 'Note',
              content: 'Note content'
            }
          ]
        },
        {
          id: 1,
          title: 'Ideas',
          colour: lime,
          references: [
            {
              title: 'Note',
              content: 'Note content'
            }
          ]
        }
      ]
    },
    {
      id: 2,
      title: 'Store Observations',
      created: new Date(),
      updated: new Date(),
      colour: red,
      lastupdatedBy: 'User',
      tags: [
        {
          id: 2,
          title: 'Behaviour',
          colour: blue,
          references: [
            {
              title: 'Note',
              content: 'Note content'
            }
          ]
        }
      ]
    }
  ],
}

export default function TagBoard() {

  const [board, setBoard] = React.useState([])
  const { boards, setBoards, handleFetchBoards, handleFetchBoardsGroupsTags } = useBoards();
  const { running, setRunning } = useLoading()
  
  // Board States
  const [newTitle, setNewTitle] = React.useState('')
  const [editingBoardTitle, setEditingBoardTitle] = React.useState(false)

  // Board Group States
  const [editingBoardGroup, setEditingBoardGroup] = React.useState(false)
  const [currentlyEditingGroup, setCurrentlyEditingGroup] = React.useState()
  const [editingGroupTitle,setEditingGroupTitle] = React.useState(false)
  const [newGroupTitle, setNewGroupTitle] = React.useState('')
  const [newGroupColour, setNewGroupColour] = React.useState()

  // Tag States
  const [newTagTitle, setNewTagTitle] = React.useState('')

  const { accountId, selectedProjectId, boardId } = useParams()
  const { currentUser } = useAuth()

  const [ open, setOpen ] = React.useState(false)

  // Delete tags confirmation modal
  const [tagIdForDeletion, setTagIdForDeletion] = React.useState()
  const [tagGroupIdForTagDeletion, setTagGroupIdForTagDeletion] = React.useState()
  
  const [tagsForDeletionOccurances, setTagsForDeletionOccurances] = React.useState()
  const [openModal, setOpenModal] = React.useState(false)  

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);

  // ********** BOARD ********** //

  const handleEditBoardName = () => {
    //console.log("handleEditBoardName")
    setEditingBoardTitle(true)
  }

  const handleCloseBoardNameEdit = () => {
    //console.log("handleCloseBoardNameEdit")
    setEditingBoardTitle(false)
  }

  const handleSaveBoardName = async () => {
    //console.log("handleSaveBoardName")
    setEditingBoardTitle(false)
    // Save to DB
    await updateBoard({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser, boardData: {title: newTitle}})
    // Retrieve board again to keep values fresh
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    // And update the boards context to update the sideNavigation...
    handleFetchBoards()
  }

  const handleBoardTitleChange = (newTitle) => {
    setNewTitle(newTitle)
  }

  const fetchBoard = async () => {
    try {
      const data = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
      return data;
    } catch (error) {
      console.error('Error fetching tagBoard from Firestore:', error);
      throw error; // Rethrow the error so that the outer catch block can handle it
    }
  };

  const getData = async () => {
    try {
      setRunning(true)
      
      const data = await fetchBoard();
      if (data.length === 0) {
        //console.log("No tagBoard found")
        // TO DO: Handle this somehow?
        setBoard([])
      } else {
        setBoard(data)
      }
      setRunning(false)
    } catch (error) {
      console.error('Error fetching tagBoard from Firestore:', error);
      setRunning(false)
    }
  }

  React.useEffect(() => {
    // on load, retrieve board based on tagBoard param and setBoard
    if (boardId && running === false) {
      getData()
    }
  }, [])

  React.useEffect(() => {
    //console.log("boardId or selectedProjectId changed, refetch", boardId, selectedProjectId)
    if (boardId && running === false) {
      getData()
    }
  }, [boardId,selectedProjectId])

  // ********** GROUPS ********** //

  const handleCreateNewGroup = async () => {
    //console.log("handleCreateNewGroup");
    // Create new board in DB
    const newBoardGroup = await createGroupInBoard({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser})
    if (newBoardGroup) {
      setBoard(prevBoard => ({
        ...prevBoard,
        boardGroups: [...prevBoard.boardGroups, newBoardGroup]
      }));
  
      // Set default state to changing label
      setCurrentlyEditingGroup(newBoardGroup.id)
      setEditingGroupTitle(true)
      setEditingBoardGroup(true)
      setEditingGroupTitle(true)
    }
  };

  const handleChangeGroupTitle = (id) => {
    //console.log("handleChangeGroupTitle", id)
    // The below two states are used to create a context in which to edit varios aspects of the board group
    setEditingBoardGroup(true)
    setCurrentlyEditingGroup(id)
    setEditingGroupTitle(true)
  }

  const handleSaveGroupTitleName = async () => {
    //console.log("handleSaveGroupTitleName")
    setEditingGroupTitle(false)
    // Save to DB using newGroupTitle and currentlyEditingGroup
    await updateGroupInBoard({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser, groupId: currentlyEditingGroup, groupData: {title: newGroupTitle}})
    // Retrieve board again to keep values fresh
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    setEditingBoardGroup(false)
    setEditingGroupTitle(false)
  }

  const handleGroupTitleChange = (text) => {
    //console.log("handleGroupTitleChange", text)
    setNewGroupTitle(text)
  }

  const handleGroupColourChange = async ({ color, groupId }) => {
    //console.log("handleGroupColourChange", color, "with id ", groupId)
    // setNewGroupColour(color)
    // Save to DB using newGroupTitle and currentlyEditingGroup
    await updateGroupInBoard({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser, groupId: currentlyEditingGroup, groupData: {colour: color}})
    // Retrieve board again to keep values fresh
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
  }

  const handleDeleteGroup = async (groupId) => {
    //console.log("handleDeleteGroup", groupId)
    // TO DO: Create a modal to confirm they are okay with this and the implications
    // TO DO: The logic here should be if there are tags within the group, the tag cannot be deleted
    
    await deleteGroup(boardId, groupId, accountId)
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
  }

  React.useEffect(() => {
    //console.log("currentlyEditingGroup changed", currentlyEditingGroup)
  }, [currentlyEditingGroup])

  // ********** TAGS ********** //

  const handleCreateNewTag = async (groupId) => {
    //console.log("handleCreateNewTag", groupId)
    // Create tag against group
    await createTagInGroup({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser, groupId})
    // Refetch board
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    handleFetchBoards()
    // handleFetchBoardsGroupsTags()
  }

  const handleTagTitleChange = (text) => {
    //console.log("handleTagTitleChange", text)
    setNewTagTitle(text)
  }

  const handleSaveTagTitle = async (tag, groupId) => {
    //console.log("handleSaveTagTitle")
    // Save to DB
    await updateTagInGroup({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser, groupId, tagId: tag.id, tagData: {title: newTagTitle}})
    // Refetch board
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    handleFetchBoards()

    // handleFetchBoardsGroupsTags()
  }

  const handleTagColourChange = async ({ color, tagId }) => {
    //console.log("handleTagColourChange", color, "with id ", tagId, "with groupId", currentlyEditingGroup)
    // Save to DB
    await updateTagInGroup({boardId, accountId, projectId: selectedProjectId, updatedBy: currentUser, groupId:currentlyEditingGroup, tagId, tagData: {colour: color}})
    // Refetch board
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    handleFetchBoards()
    // handleFetchBoardsGroupsTags()
  }

  const handleDeleteTagAfterConfirmation = async (tag, groupId) => {
    //console.log("handleDeleteTagAfterConfirmation", tag, groupId)
    //console.log("tagGroupIdForTagDeletion", tagGroupIdForTagDeletion)
    //console.log("tagIdForDeletion", tagIdForDeletion)

    // Delete tag from DB
    if (tagGroupIdForTagDeletion && tagIdForDeletion) {
      await deleteTag(boardId, tagGroupIdForTagDeletion, tagIdForDeletion, accountId)
    } else if (tag && groupId) {
      await deleteTag(boardId, groupId, tag.id, accountId)
    } else {
      console.error("Error deleting tag, no tag or groupId provided")
      return
    }
    // Refresh results
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    handleFetchBoards()
    // handleFetchBoardsGroupsTags()
  }

  const handleOpenDeletionConfirmationModal = (tag,noteOccurances,groupId) => {
    setTagGroupIdForTagDeletion(groupId)
    setTagIdForDeletion(tag.id)
    setTagsForDeletionOccurances(noteOccurances)
    setOpenModal(true)
  }


  const handleDeleteTag = async (tag, groupId) => {
    //console.log('handleDeleteTag requested', tag, 'with groupId', groupId)    
    const noteOccurances = await checkIfTagIsInUse({accountId, projectId: selectedProjectId, tagId: tag.id})
    // TO DO: Create a modal to confirm they are okay with this and the implications
    //console.log('noteOccurances', noteOccurances)
    if (noteOccurances && noteOccurances.associations && noteOccurances.associations.length > 0) {
      // First confirm the user wants to delete the tag considering all of the places it is cited    
      handleOpenDeletionConfirmationModal(tag, noteOccurances, groupId)
    } else {
      // Just delete as its not being referenced anywhere
      handleDeleteTagAfterConfirmation(tag, groupId)
    }
  }

  const handleShowTagBoardOptions = () => {
    //console.log("handleShowTagBoardOptions")
  }

  const handleDeleteBoard = async () => {
    //console.log("handleDeleteBoard with boardId", boardId)
    await deleteBoard(boardId, accountId)
    // Refetch boards
    await handleFetchBoards()
    // Grab first item in new boards array and set as board
    const newBoardId = boards[boards.length - 1].id
    
    //console.log("Attempting to set new board Id to ", newBoardId)
    const newBoard = await fetchTagBoardAndGroupsAndTagsWithId({boardId: newBoardId, accountId, projectId: selectedProjectId})
    if (newBoard) {
      setBoard(newBoard)
    }
    // And update the boards context to update the sideNavigation...
    handleFetchBoards()
  }

  return (
    // Ivory: FFFFF0, Lavender: F4F1F8
    <Stack sx={{ flexGrow: 1, p: 2 }}> 
      <Typography
        fontSize={{ xs: 'md', md: 'lg' }}
        component="h1"
        fontWeight="lg"
        endDecorator={
          editingBoardTitle ? 
          <>
            <IconButton aria-label="Rename Board" size="sm" onClick={handleSaveBoardName}>
              <DoneIcon fontSize='md' />
            </IconButton>
            <IconButton aria-label="Rename Board" size="sm" onClick={handleCloseBoardNameEdit}>
              <CloseIcon fontSize='md' />
            </IconButton>
          </>
          :
          <>
          <IconButton aria-label="Rename Board" size="sm" onClick={handleEditBoardName}>
            <EditIcon fontSize='md' />
          </IconButton>
          <Dropdown onOpenChange={handleOpenChange} open={open}>
            <MenuButton
              variant="plain"
              size="sm"
              sx={{
                maxWidth: '32px',
                maxHeight: '32px',
                borderRadius: '9999999px',
              }}
            >
              <IconButton
                component="span"
                variant="plain"
                color="neutral"
                size="sm"
              >
                <MoreVertRoundedIcon />
              </IconButton>
            </MenuButton>
            <Menu
              placement="bottom-end"
              size="sm"
              sx={{
                zIndex: '99999',
                p: 1,
                gap: 1,
                '--ListItem-radius': 'var(--joy-radius-sm)',
              }}
            >
              <MenuItem sx={{ color: 'danger' }} onClick={() => handleDeleteBoard()}>
                <DeleteRoundedIcon />
                Delete board
              </MenuItem>
            </Menu>
          </Dropdown>
          </>
        }
        sx={{ mr: 'auto', mb: 3 }}
      >
        { editingBoardTitle 
          ?
            <Input
              autoFocus={true}
              onChange={(e) => handleBoardTitleChange(e.target.value)}
              defaultValue={board.title}
              size="lg"
              sx={{ flexGrow: 1 }}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSaveBoardName();
                } else if (e.keyCode === 27) {
                  setEditingBoardTitle(false);
                }
              }}
            />
          : 
            board.title
        }
        
      </Typography>


      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        
        { board && board.boardGroups && board.boardGroups.map((boardGroup, index) => (            
          <Grid key={`boardGroup` + index} xs={2}>
            <Box sx={{ flexGrow: 1, height: '100%', p: 1.5, borderRadius: 7, pt: 1, backgroundColor: boardGroup.colour[backgroundColourStrength] }}>
              <Box sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}>
                <Box className="boardTitleEle" sx={{ flex: 1, overflow: 'auto' }}>
                  {editingBoardGroup && boardGroup.id === currentlyEditingGroup && editingGroupTitle ?
                    <Input
                      autoFocus={true}
                      onChange={(e) => handleGroupTitleChange(e.target.value)}
                      defaultValue={boardGroup.title}
                      size="sm"
                      sx={{ flexGrow: 1 }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleSaveGroupTitleName();
                        } else if (e.keyCode === 27) {
                          setEditingGroupTitle(false);
                        }
                      }}
                    />
                  : 
                    <Typography onClick={() => handleChangeGroupTitle(boardGroup.id)} level="title-sm" sx={{ cursor: 'pointer', fontWeight: 'bold', color: boardGroup.colour[textColourStrength] }}>
                      { boardGroup.title }
                    </Typography>
                  }
                </Box>
                <Box className="boardTitleEleOptions">
                  <EditBoardTitleDropdown setCurrentlyEditingGroup={setCurrentlyEditingGroup} handleDeleteGroup={handleDeleteGroup} groupId={boardGroup.id} handleGroupColourChange={handleGroupColourChange} colour={boardGroup.colour} />
                </Box>
              </Box>
              { boardGroup.tags && boardGroup.tags.map((tag,i) => (
                console.log('tag', tag),
                <Box key={`groupTags` + i} 
                  sx={{ 
                    backgroundColor: 'white', 
                    p: 1, 
                    pb: 1, 
                    mt: 1, 
                    borderRadius: 5, 
                    display: 'flex', 
                    textAlign: 'left', 
                    alignItems: 'center',
                    ':hover': {
                      '& .tagEditMenu': {
                        visibility: 'visible',
                      },
                    },
                    '& .tagEditMenu': {
                      visibility: 'hidden',
                    },
                  }}>
                  <Box sx={{ flex: 1 }}>
                    {/* <Typography level="title-sm" sx={{ fontWeight: 'bold', color: tag.colour[textColourStrength] }}>{tag.title}</Typography> */}
                    <TagChip tag={tag} textColourStrength={textColourStrength} />
                  </Box>
                  <Box className="tagEditMenu">
                    <EditTagDropdown 
                      setCurrentlyEditingGroup={setCurrentlyEditingGroup} 
                      handleDeleteTag={handleDeleteTag} 
                      handleTagColourChange={handleTagColourChange} 
                      handleSaveTagTitle={handleSaveTagTitle} 
                      handleTagTitleChange={handleTagTitleChange} 
                      tag={tag} 
                      groupId={boardGroup.id} 
                    />
                  </Box>
                </Box>
              ))}
              <Box sx={{ mt: 1 }}>
                <Button variant="plain" size="sm"
                  sx={{ 
                    m: 0, 
                    color: boardGroup.colour[textColourStrength],
                    ':hover': {
                      backgroundColor: boardGroup.colour[hoverColourStrength],
                    },
                  }} 
                  startDecorator={<Add />}
                  onClick={() => handleCreateNewTag(boardGroup.id) }
                >
                  New tag
                </Button>
              </Box>
            </Box>
          </Grid>
        ))}

        <Grid xs={2}>
          <Box sx={{ flexGrow: 1, p: 1.5, borderRadius: 7, pt: 2, pb: 2, backgroundColor: grey[backgroundColourStrength] }}>
            <Box sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}>
              <Box sx={{ flex: 1 }}>
                <Typography level="title-sm" onClick={handleCreateNewGroup} sx={{ cursor: 'pointer', fontWeight: 'bold', color: grey[600] }}>Create New Group</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={2}>
          <Box sx={{ flexGrow: 1, height: '100%', p: 1.5, borderRadius: 7, backgroundColor: blue[50] }}>
            <Box sx={{ display: 'flex', textAlign: 'left', alignItems: 'center' }}>
              <Box sx={{ flex: 1 }}>
                <Typography level="title-sm" sx={{ fontWeight: 'bold', color: blue[500] }}>[Board Group]</Typography>
              </Box>
              <EditBoardTitleDropdown />
            </Box>
          </Box>
        </Grid> */}

        {/* <Grid xs={2} sx={{ backgroundColor: 'red' }}>
          <Item>xs=4</Item>
        </Grid>
        <Grid xs={2} sx={{ backgroundColor: 'green' }}>
          <Item>xs=4</Item>
        </Grid>
        <Grid xs={2} sx={{ backgroundColor: 'purple' }}>
          <Item>xs=8</Item>
        </Grid>
        <Grid xs={2} sx={{ backgroundColor: 'blue' }}>
          <Item>xs=8</Item>
        </Grid>
        <Grid xs={2} sx={{ backgroundColor: 'yellow' }}>
          <Item>xs=8</Item>
        </Grid> */}
      </Grid>
      <ConfirmDeleteTagsModal 
        open={openModal} 
        setOpen={setOpenModal} 
        handleDeleteTags={handleDeleteTagAfterConfirmation} 
        tags={tagsForDeletionOccurances}
      />
    </Stack>
  )
}