import {$isAutoLinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';
import { $isHighlightNode } from './nodes/Highlight';
import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$findMatchingParent, mergeRegister} from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_LOW,
  GridSelection,
  LexicalEditor,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
  $setSelection, $createRangeSelection, $createNodeSelection,
  KEY_BACKSPACE_COMMAND, KEY_DELETE_COMMAND
} from 'lexical';
import {useCallback, useEffect, useRef, useState} from 'react';
import * as React from 'react';
import {createPortal} from 'react-dom';
import { saveHighlightToFirestore, getHighlight, deleteHighlight } from '../../../database/highlights-db';
import { updateTagInGroup, fetchTagWithIdInGroup } from '../../../database/tags-db';
import { useNotes } from '../../../contexts/notesContext';
import { useParams } from 'react-router-dom';

import LinkPreview from './ui/LinkPreview';
import { getSelectedNode, sanitizeUrl, setFloatingElemPosition } from './EditorUtils';
import { Box, Button, Typography } from '@mui/joy';
import TagPicker from './../../Tags/Editor/TagPicker';
import { useAuth } from './../../../../authContext';  

function FloatingHighlightEditor({ editor, anchorElem, noteToDisplay }) {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const [text, setText] = useState('');
  const [lastSelection, setLastSelection] = useState(null);
  const { accountId, selectedProjectId } = useParams();
  const { currentUser } = useAuth();
  // Tags
  const [differenceTags, setDifferenceTags] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [pendingTag, setPendingTag] = React.useState([]);

  const checkDifferenceInTags = () => {
    // handle when a tag is removed
    let diffArr = [];
    selectedTags.map(item => {
      if (!pendingTag.includes(item)) {
        if (item !== undefined) {
          diffArr.push({ tag: item, status: "removed" });
        }
      }
    })
    
    if (diffArr && diffArr.length > 0) {
      setDifferenceTags(diffArr);
    } 
  }

  const handleUpdateTags = (updatedTags = pendingTag) => {
    checkDifferenceInTags();
    setSelectedTags(updatedTags);
    // TO DO not saving the newly added tag to the highlight (it shows and then on reclick it disappears)
    
    editor.update(() => {
      const selection = $getSelection();
      
      // Add null check for selection
      if (!selection) {
        console.warn('No selection found when updating tags');
        return;
      }
      
      const node = getSelectedNode(selection);
      // Add null check for node
      if (!node) {
        console.warn('No node found when updating tags');
        return;
      }
      
      const parent = node.getParent();

      if ($isRangeSelection(selection)) {
        const targetNode = $isHighlightNode(parent) ? parent : ($isHighlightNode(node) ? node : null);
        
        if (targetNode) {
          // Update tag references
          updatedTags.forEach(async tag => {
            const hasMatch = (tag.references ?? []).some(item => item.id === targetNode.__id);

            if (!hasMatch) {
              const reference = { 
                type: 'highlight', 
                id: targetNode.__id, 
                summaryTitle: targetNode.__text 
              };
              
              if (tag.references && tag.references.length >= 0) {
                tag.references.push(reference);
              } else {
                tag.references = [reference];
              }
            }

            await updateTagInGroup({ 
              boardId: tag.boardId, 
              accountId: tag.accountId, 
              projectId: tag.projectId, 
              updatedBy: currentUser, 
              groupId: tag.groupId, 
              tagId: tag.tagId, 
              updateReferences: true,
              tagData: {
                title: tag.title,
                colour: tag.colour,
              },
              references: tag.references
            });
          });

          // Update highlight in Firestore
          saveHighlightToFirestore({ 
            highlight: { 
              id: targetNode.__id,
              text: targetNode.__text, 
              tags: updatedTags 
            },
            researchId: noteToDisplay.id,
            accountId,
            selectedProjectId
          });
          
          // Update highlight node
          node.getWritable().setHighlightTags(targetNode.__id, updatedTags, targetNode.__key);
        } else {
          // Add null check before accessing node methods
          if (node && node.getWritable) {
            node.getWritable().setHighlightTags(node.__id, [], node.__key);
          }
        }
      }
    });
  }

  const fetchSelectedTags = async (nodeTags) => {
    let freshDataTags = [];
  
    await Promise.all(nodeTags.map(async (tag) => {
      if (tag) {
        const result = await fetchTagWithIdInGroup({ accountId, projectId: selectedProjectId, tagId: tag.id, boardId: tag.boardId, groupId: tag.groupId });
        if (result) {
          // console.log("result of fetchTagWithIdInGroup", result);
          freshDataTags.push({ 
            accountId, 
            projectId: selectedProjectId, 
            boardId: tag.boardId, 
            groupId: tag.groupId, 
            tagId: tag.id, 
            id: tag.id,
            key: tag.id, 
            tagData: { title: result.title, colour: result.colour }, 
            title: result.title,
            colour: result.colour,
            lastupdatedBy: result.lastupdatedBy,
            references: result.references,
            updated: result.updated        
          });
        }
      } else {
        console.log("missing data to fetch latest data for tag", tag)
      }
    }));

    return freshDataTags;
  };

  const handleHighlightDeletionFromDb = async (highlightId) => {
    // get the latest data of this highlight using highlightId
    const highlight = await getHighlight({ highlightId, accountId });
    // delete each tag reference using highlight.tags
    highlight.tags.forEach(async tag => {
      const result = await updateTagInGroup({
        boardId : tag.boardId,
        accountId : tag.accountId,
        projectId : tag.projectId,
        updateReferences: true,
        updatedBy: currentUser,
        groupId: tag.groupId,
        tagId: tag.tagId,
        tagData: {
          title: tag.title,
          colour: tag.colour,
        },
        references: tag.references.filter(item => item.id !== highlightId)
      });
    });

    const result = await deleteHighlight({ highlightId, accountId });
    return result;
  }

  const handleDeleteHighlight = () => {
    // Delete the highlight using deleteHighlight
    editor.update(() => {
      const selection = $getSelection();
      const node = getSelectedNode(selection);
      const parent = node.getParent();

      if ($isRangeSelection(selection)) {
        if ($isHighlightNode(parent)) {
          console.log("parent is highlight node")
          // update the collection first
          const result = handleHighlightDeletionFromDb(parent.__id);
          if (result) {
            console.log("deleting node")
            // delete the node
            parent.remove();
          }          
        } else if ($isHighlightNode(node)) {
          console.log("node is highlight node")
          // update the collection first
          const result = handleHighlightDeletionFromDb(node.__id);
          if (result) {
            console.log("deleting node")
            // delete the node
            node.remove();
          }
        } else {
          console.log("could not delete node")
        }
      }
    });    
  }

  const updateLinkEditor = useCallback(async () => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isHighlightNode(parent)) {
        setText(parent.__text);
        const tagResults = await fetchSelectedTags(parent.__tags);
        setSelectedTags(tagResults);
      } else if ($isHighlightNode(node)) {
        setText(node.__text);
        const tagResults = await fetchSelectedTags(node.__tags);
        setSelectedTags(tagResults);
      } else {
        setText('');
        setSelectedTags([]);
      }
    }
    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }
        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      setFloatingElemPosition(rect, editorElem, anchorElem);
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      if (rootElement !== null) {
        setFloatingElemPosition(null, editorElem, anchorElem);
      }
      setLastSelection(null);
      setText('');
    }

    return true;
  }, [anchorElem, editor]);

  useEffect(() => {
    if (differenceTags && differenceTags.length > 0) {
      // diff for purposes of tag removal
      editor.update(() => {
        const selection = $getSelection();
        const node = getSelectedNode(selection);
        const parent = node.getParent();
  
        if ($isRangeSelection(selection)) {
          if ($isHighlightNode(parent)) {
            
            differenceTags.forEach(async tag => {
              const result = await updateTagInGroup({ 
                boardId : tag.tag.boardId, 
                accountId : tag.tag.accountId, 
                projectId : tag.tag.projectId, 
                updatedBy: currentUser, 
                groupId: tag.tag.groupId, 
                tagId: tag.tag.tagId, 
                updateReferences: true,
                tagData: {
                  title: tag.tag.title,
                  colour: tag.tag.colour,
                },
                references: tag.tag.references.filter(item => item.id !== parent.__id)
              });
            });
          } else if ($isHighlightNode(node)) {
            differenceTags.forEach(async tag => {

              const newFilteredArray = tag.tag.references.filter(item => item.id != node.__id);

              await updateTagInGroup({ 
                boardId : tag.tag.boardId, 
                accountId : tag.tag.accountId, 
                projectId : tag.tag.projectId, 
                updatedBy: currentUser, 
                groupId: tag.tag.groupId, 
                tagId: tag.tag.tagId, 
                updateReferences: true,
                tagData: {
                  title: tag.tag.title,
                  colour: tag.tag.colour,
                },
                references: newFilteredArray
              });
            });
          }
        }
      });
    }
  }, [differenceTags]);

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement;

    const update = () => {
      editor.getEditorState().read(() => {
        updateLinkEditor();
      });
    };

    window.addEventListener('resize', update);

    if (scrollerElem) {
      scrollerElem.addEventListener('scroll', update);
    }

    return () => {
      window.removeEventListener('resize', update);

      if (scrollerElem) {
        scrollerElem.removeEventListener('scroll', update);
      }
    };
  }, [anchorElem.parentElement, editor, updateLinkEditor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({editorState}) => {
        editorState.read(() => {
          updateLinkEditor();
        });
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateLinkEditor();
          return true;
        },
        COMMAND_PRIORITY_LOW,
      ),

      // Prevent deletion of HighlightNode by intercepting delete commands
      editor.registerCommand(
        KEY_BACKSPACE_COMMAND,
        (event) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const nodes = selection.getNodes();
            for (const node of nodes) {
              if ($isHighlightNode(node)) {
                console.log("Preventing deletion... backspace on highlight node")
                // Prevent deletion of HighlightNode
                event.preventDefault();
                return true; // Returning true to signal that the event has been handled
              }
            }
          }
          return false; // Allow default behavior for other nodes
        },
        COMMAND_PRIORITY_CRITICAL
      ),

      editor.registerCommand(
        KEY_DELETE_COMMAND,
        (event) => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const nodes = selection.getNodes();
            for (const node of nodes) {
              if ($isHighlightNode(node)) {
                console.log("Preventing deletion... backspace on highlight node")
                // Prevent deletion of HighlightNode
                event.preventDefault();
                return true; // Returning true to signal that the event has been handled
              }
            }
          }
          return false; // Allow default behavior for other nodes
        },
        COMMAND_PRIORITY_CRITICAL
      ),

    );
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  return (
    <div ref={editorRef} className="link-editor">
      <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ pt: 3, px: 3 }}>
          <Typography level="body-sm" color="text-secondary">
          { text }
          </Typography>
        </Box>
        <Box sx={{ pt: 1, pb: 1, px: 3, ml: -1, mt: 1, mb: 1.75 }}>
          <TagPicker 
            handleUpdateTags={handleUpdateTags} 
            setPendingTag={setPendingTag}
            pendingTag={pendingTag}
            selectedTags={selectedTags}
            noteToDisplay={noteToDisplay}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'end', flex: 1, px: 3, pt: 1.5, pb: 1.5, borderTop: 'solid 1px lightgray' }}>
          {/* <Button variant='solid' size="sm" color="primary" onClick={() => closeHighlightBox() }>
            Done
          </Button> */}
          <Button variant="outlined" size="sm" color="danger" onClick={() => handleDeleteHighlight()}>
            Remove highlight
          </Button>
        </Box>
      </Box>
      {/* <div className="link-input">
        {text}
        <div
          className="link-edit"
          role="button"
          tabIndex={0}
          onMouseDown={(event) => event.preventDefault()}
          onClick={() => {
            setEditMode(true);
          }}
        />
      </div> */}
      {/* <LinkPreview url={linkUrl} /> */}
    </div>
  );
}

function useFloatingHighlightEditorToolbar( editor, anchorElem, noteToDisplay) {
  const [activeEditor, setActiveEditor] = useState(editor);
  const [isHighlight, setIsHighlight] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const highlightParent = $findMatchingParent(node, $isHighlightNode);

      if (highlightParent != null) {
        setIsHighlight(true)
      } else {
        setIsHighlight(false)
      }
    }
  }, []);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL,
    );
  }, [editor, updateToolbar]);

  return isHighlight
    ? createPortal(
        <FloatingHighlightEditor editor={activeEditor} anchorElem={anchorElem} noteToDisplay={noteToDisplay} />,
        anchorElem,
      )
    : null;
}

export default function FloatingHighlightEditorPlugin({ anchorElem, noteToDisplay }) {
  const [editor] = useLexicalComposerContext();
  return useFloatingHighlightEditorToolbar(editor, anchorElem, noteToDisplay);
}
