import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListDivider from '@mui/joy/ListDivider';
import MoreVert from '@mui/icons-material/MoreVert';
import Edit from '@mui/icons-material/Edit';
import DeleteForever from '@mui/icons-material/DeleteForever';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';
import { deleteAssociationFromFirestore } from '../../database/associations-db';
import { useNavigate, useParams } from 'react-router-dom';
import { useContextualPane } from '../../contexts/contextualPane';

export default function EditLinkDropdown({item}) {

  const { accountId, selectedProjectId } = useParams();
  const { handleFetchOfAssociationTypes, setSelectedItemInstance } = useContextualPane();

    const handleDeleteLink = async (association) => {
        //console.log("handleDeleteLink with association id ", association.id)
        await deleteAssociationFromFirestore({ accountId, associationId: association.id })
        setSelectedItemInstance({})
        await handleFetchOfAssociationTypes()
    }

    return (
      <Dropdown>
          <MenuButton
              slots={{ root: IconButton }}
              slotProps={{ root: { variant: 'plain', color: 'neutral' } }}
          >
              <MoreVert />
          </MenuButton>
          <Menu placement="bottom-end" sx={{ zIndex: 9999 }}>
              <MenuItem variant="soft" color="danger" onClick={() => handleDeleteLink(item)}>
                  <ListItemDecorator sx={{ color: 'inherit' }}>
                      <DeleteForever />
                  </ListItemDecorator>{' '}
                  Delete link
              </MenuItem>
          </Menu>
      </Dropdown>  
    )
}