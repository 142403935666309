import * as React from 'react';
import { useNotes } from '../../contexts/notesContext';
import NoteLayout from './LayoutTypes/Note';
import UploadLayout from './LayoutTypes/Upload';
import LinkLayout from './LayoutTypes/Link';
import NoteEditor from '../Modals/NoteEditor';
import UploadEditor from '../Modals/UploadEditor';

export default function NotesPane({showSidePane, note}) {
  const { selectedNote } = useNotes();
  const noteToDisplay = note || selectedNote;

  return (
    <>
    { noteToDisplay?.type === 'note' && <NoteEditor content={noteToDisplay} /> }
    { noteToDisplay?.type === 'upload' && <UploadEditor content={noteToDisplay} /> }

    { noteToDisplay.type === "note" ? 
        <NoteLayout showSidePane={showSidePane} content={noteToDisplay} /> :
      noteToDisplay.type === "upload" ? 
        <UploadLayout showSidePane={showSidePane} content={noteToDisplay} /> :
        noteToDisplay.type === "link" ? 
          <LinkLayout showSidePane={showSidePane} content={noteToDisplay} /> :
        <></>
    }
    </>
  );
}
