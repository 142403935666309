import { useState } from "react";
import { Card, Link, CardContent, Box, Typography } from "@mui/joy"
import { useNotes } from "../../contexts/notesContext";
import LinkIcon from '@mui/icons-material/Link';
import ViewUpdateLink from "../Modals/ViewUpdateLink";
import LinkCard from "./LinkCard";

function LinkItem({ id, note, mode = "default", noteTitle, description, url, handleViewLink }) {

  const { setNewSelection } = useNotes();
  
  const selected = note.id === id;

  return (
    <LinkCard 
      selected={selected} 
      note={note} 
      noteTitle={noteTitle}
      description={description}
      url={url}
      mode={mode}
      setNewSelection={setNewSelection} 
      handleViewLink={handleViewLink}
    />
  )
}

export default LinkItem;