import React, { useCallback, useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { COMMAND_PRIORITY_EDITOR } from 'lexical';
import { $convertFromMarkdownString } from '@lexical/markdown';
import { PLAYGROUND_TRANSFORMERS } from './MarkdownTransformers';
import { Button } from '@mui/joy';
import { useAiPane } from '../../../contexts/aiPaneContext';

export const INSERT_GENAI_MARKDOWN = 'INSERT_GENAI_MARKDOWN';

const text = `## Summary: The author had an argument with their partner after going out with a friend. They felt their partner wasn't being open or vulnerable, creating a communication barrier. The author expressed frustration with this, while their partner felt misunderstood and needed space. After apologizing, the author attempted to reconcile, but their partner still wants to be alone and seems emotionally distant. The author feels ignored and unsure of the future of their relationship.`;

function InsertTextPlugin() {
  const [editor] = useLexicalComposerContext();
  const { result, insertText, setInsertText } = useAiPane();

  useEffect(() => {
    // Register the command
    return editor.registerCommand(
      INSERT_GENAI_MARKDOWN,
      (payload) => {
        editor.update(() => {
          const { markdownContent } = payload;
          console.log("markdownContent", markdownContent);

          $convertFromMarkdownString(markdownContent, PLAYGROUND_TRANSFORMERS);

        });
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  useEffect(() => {
    if (insertText) {
      editor.dispatchCommand(INSERT_GENAI_MARKDOWN, { markdownContent: result });
      setInsertText(false);
    }
  }, [insertText]);

  return null;
}

export function useInsertText() {
  const [editor] = useLexicalComposerContext();

  const insertText = useCallback((markdownContent) => {
    editor.dispatchCommand(INSERT_GENAI_MARKDOWN, { markdownContent });
  }, [editor]);

  return insertText;
}

export default InsertTextPlugin;
