import React from 'react';
import { FormControl, Input, Box, IconButton, FormLabel, Textarea, Typography } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

export default function TextAreaComponent({ gridColumns, data, id, updateHandler, deleteHandler, editMode = true }) {

  const [ editing, setEditing ] = React.useState(false);
  const [ newTitle, setNewTitle ] = React.useState('');

  return (
    editMode ?
    <FormControl>
      <FormLabel sx={{ flex: 1, width: "100%", flexDirection: "row", justifyContent: "space-between" }} onClick={() => { setNewTitle(data.field_title); setEditing(true) }}>
        { data && data.field_title &&
          <>
          <Box flex={1}>
            { data.field_title }
          </Box>
          <Box
            flex={1}
            sx={{
              minHeight: 32,
              justifyContent: "end",
              display: "flex",
            }}
          >
            <IconButton onClick={() => deleteHandler({ idToDelete: id })} className='delete-item' sx={{ display: "none" }} size="sm">
              <CloseIcon />
            </IconButton>
          </Box> 
          </>
          }
      </FormLabel>
      {/* <FormHelperText sx={{ ml: 0 }}>
        Write a few words that will be shown alongside the persona to give an idea of who they are
      </FormHelperText> */}
      <Textarea
        size="sm"
        minRows={4}
        onBlur={(e) => updateHandler(prevState => {
          const updatedArray = prevState.map(item => {
            if (item.id === id) {
              return { ...item, field_value: e.target.value };
            }
            return item;
          });
        
          return updatedArray;
        })}
        defaultValue={data && data.field_value ? data.field_value : ''}
      />
    </FormControl>
    :
    data.field_value !== '' &&
    <Box
      sx={{ 
        borderBottom: data.block_location !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
        ml: gridColumns === 1 ? 0 : 4, 
        mr: gridColumns === 1 ? 0 : 4, 
        pb: 2.5
      }}
    >
      <Typography level="body-md" color="neutral">
        { data.field_title }:
      </Typography>
      <Typography level="body-sm" sx={{ fontWeight: 'bold' }}>
        { data.field_value }
      </Typography>
    </Box>
  )
}