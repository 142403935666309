import React from 'react';
import { RadioGroup, Radio } from '@mui/joy';
import { useContextualPane } from '../../../../../../../contexts/contextualPane';

export default function ResultListings({ filteredResults, handleChange }){
    return (
        <RadioGroup defaultValue="medium" name="radio-buttons-group">
            {filteredResults.map((result, index) => (
                <Radio
                    key={result.id}
                    value={result.id}
                    label={result.title}
                    size="sm"
                    onChange={() => handleChange(result.id, result.title)}
                />
            ))}
        </RadioGroup>
    )
}