import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import { Avatar, AvatarGroup, Box, Button, Link } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useJourneys } from '../../contexts/journeysContext';
import { usePersonas } from '../../contexts/personasContext';
import { useContextualPane } from '../../contexts/contextualPane';
import SelectPersonaSelect from './SelectPersonaSelect';
import { set } from 'date-fns';

export default function SelectPersonaForJourney() {

  const [ mode, setMode ] = React.useState('edit')
  const [ personaData, setPersonaData ] = React.useState([])
  const { personas } = usePersonas()
  const { activeItem, setActiveItem } = useContextualPane()

  // console.log("activeItem", activeItem)

  React.useEffect(() => {
    if (personas && personas.length > 0 && activeItem.item && activeItem.item.persona && activeItem.item.persona.length > 0) {
      const personaData = activeItem.item.persona.map(id => {
        const persona = personas.find(persona => persona.id === id);
        console.log("missing persona?", persona)
        if (persona && persona.data && persona !== "undefined") {
          const name = persona.data.find(data => data.field_title === 'Name').field_value;
          const role = persona.data.find(data => data.field_title === 'Role').field_value;
          const image = persona.data.find(data => data.type === 'image').field_value;
          return {
            id: persona.id,
            name,
            role,
            image
          };
        } else {
          setPersonaData([])
          setActiveItem({ ...activeItem, item: { ...activeItem.item, persona: [] } })
        }
      });
      setPersonaData(personaData)
    }
  },[activeItem,personas])

  React.useEffect(() => {
    if (personaData.length > 0) {
      setMode('view')
    }
  },[personaData])

  const handleChangePersona = () => {
    setMode('edit')
  }

  return (
    mode === 'view' && activeItem.item.persona && activeItem.item.persona.length > 0 ?      
      <Box className="journey-persona">
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 1, display: 'flex' }}>
            <Box sx={{ mr: 1 }}>
              { personaData && personaData.length === 1 && personaData.map((persona, personaIndex) => (
                persona && persona.name && persona.image &&
                  <Avatar key={`persona_` + personaIndex} size="lg" alt={persona.name} src={persona.image} />
              ))}
              { personaData && personaData.length > 1 && 
                <AvatarGroup>
                  { personaData.map((persona, personaIndex) => (
                    <Avatar key={`personaItem_` + personaIndex} size="lg" alt={persona.name} src={persona.image} />
                  ))}
                </AvatarGroup>
              }
            </Box>
            <Box sx={{ ml: 0 }}>
            { personaData && personaData.length === 1 && personaData.map((persona, index) => (
              <Box key={`personaDetails_` + index}>
                { persona && persona.role && persona.name &&
                  <>
                    <Typography level="title-md">{persona.role}</Typography>
                    <Typography level="body-sm">{persona.name}</Typography>
                  </>
                }
              </Box>
            ))}
            { personaData && personaData.length > 1 && 
              <Typography level="body-sm">
              { personaData.map((persona, index) => (
                personaData.length - 1 === index 
                  ? persona.role + '.' 
                  : persona.role + ' and '  
              ))}
              </Typography>
            }
            </Box>
          </Box>   
        </Box>
        
        <Box sx={{ mt: 0 }} className="switchPersona">
            <Typography level="body-xs">
              <Link onClick={handleChangePersona} variant="plain" color="primary">Change</Link>
            </Typography>
          </Box>     
      </Box>
    :
      <SelectPersonaSelect setMode={setMode} personas={personas} />
  );
}