import React, { useState } from 'react';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import { saveAsTemplateToFirestore, deleteTemplateFromFirestore } from '../../../database/saveAsTemplate-db';
import { useParams } from 'react-router-dom';
import PersonaEditorDefaultLayout from '../../Personas/components/Layouts/Default';
import PersonaEditorLayout from '../../Personas/components/Layouts';
import JourneyEditorLayout from '../../JourneyMap/components/Layouts';
import SitemapLayout from '../../Sitemaps/components/Layouts';
import ModalEditorFrame from '../../Layouts/ModalEditorFrame';
import ModalWithPreview from '../../Layouts/ModalWithPreview';
import SelectTemplateMenu from '../../SelectTemplate';
import PreviewNoteForTemplate from '../../Editor/PreviewNoteForTemplate';

export default function SelectTemplate({ type, fetchData, open, setOpen, options, onSelectHandler }) {

    const { accountId, selectedProjectId } = useParams() 
    const [ selectedValue, setSelectedValue ] = useState(options[0].id)
    const [ selectedData, setSelectedData ] = useState(options[0].data)

    const handleDeleteTemplate = async () => {
        const templateId = selectedValue
        const response = await deleteTemplateFromFirestore({ accountId, selectedProjectId, templateId })
        fetchData()
        setSelectedValue(options[0].id)
        setSelectedData(options[0].data)
    }

    return (
        <ModalEditorFrame open={open} setOpen={() => setOpen(!open)}>
            <ModalWithPreview
                menuWidth="20%"
                SideContent={
                    <SelectTemplateMenu 
                        type={type} 
                        open={open}
                        setOpen={setOpen}
                        options={options} 
                        onSelectHandler={onSelectHandler} 
                        selectedValue={selectedValue} 
                        setSelectedValue={setSelectedValue} 
                        selectedData={selectedData}
                        setSelectedData={setSelectedData}
                    />
                }
                MainContent={                
                    <Box sx={{ p: 6, pt: 0, mr: 5, mt: 2 }}>
                        <Box sx={{ border: '1px solid #ccc', borderRadius: 10, overflow: 'auto' }}>
                            { type === "persona" &&
                                <Box sx={{ p: 3 }}>
                                    <PersonaEditorLayout personaFields={selectedData} editChecked={false} />
                                </Box>
                            }
                            { type === "journey" &&
                                <JourneyEditorLayout data={selectedData.items} layout="preview" width={selectedData.width} columns={selectedData.columns} columnsSpan={selectedData.columnsSpan} />
                            }
                            { type === "sitemap" &&
                                <Box sx={{ p: 3 }}>
                                    <SitemapLayout layout="preview" data={selectedData} />
                                </Box>
                            }
                            { type === "note" &&
                                <PreviewNoteForTemplate data={selectedData} layout="preview" />
                            }
                        </Box>
                        <Box flex={1} sx={{ mt: 2, p: 1, borderRadius: 8, backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between' }}>
                            <Button color="danger" onClick={() => handleDeleteTemplate()} variant="plain">Delete template</Button>
                        </Box>
                    </Box>
                }
            />
        </ModalEditorFrame>
    );
}