import { useAuth } from "../../../authContext";
import Firebase from "../../../configs/firebaseConfig";
import { Button } from "@mui/joy";

function TestApi() {

    const { getIdToken, currentUser } = useAuth()

    async function acceptInvitation(invitationId, accountId) {
        // Get the user's Firebase ID token
        const idToken = await Firebase.auth().currentUser.getIdToken();
      
        try {
          const response = await fetch('http://localhost:3000/api/invitations/accept', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}`
            },
            body: JSON.stringify({ invitationId, accountId })
          });
    
          if (response.status === 404) {
            throw new Error('API endpoint not found. Please check if the server route is set up correctly.');
          }

          if (!response.ok) {
            const errorBody = await response.text();
            throw new Error(`Failed to accept invitation: ${response.status} ${response.statusText}. ${errorBody}`);
          }
      
          const data = await response.json();
          console.log(data.message);
          // Handle successful invitation acceptance (e.g., update UI, navigate to a new page)
        } catch (error) {
          console.error('Error accepting invitation:', error.message);
          // Handle error (e.g., show error message to user)
          // You might want to add some UI feedback here
        }
    }

    
    const callApi = async () => {
        try {
            const token = await getIdToken();
            // console.log("Token obtained:", token ? "Yes" : "No");
            
            if (token) {
                // console.log("Token (first 20 chars):", token.substring(0, 20) + "...");
                
                // Decode and log non-sensitive parts of the token
                const [header, payload] = token.split('.').slice(0, 2).map(part => JSON.parse(atob(part)));
                // console.log("Token header:", header);
                // console.log("Token payload (partial):", {
                //     iss: payload.iss,
                //     aud: payload.aud,
                //     exp: new Date(payload.exp * 1000).toISOString(),
                //     iat: new Date(payload.iat * 1000).toISOString()
                // });

                const response = await fetch('/api/hello', {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                });
                
                console.log("Response status:", response.status);
                console.log("Response headers:", JSON.stringify(Object.fromEntries(response.headers)));
                
                if (!response.ok) {
                    const errorBody = await response.text();
                    console.error("Error response body:", errorBody);
                    throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`);
                }
                
                const data = await response.text();
                console.log("Data from token response:", data);
            } else {
                console.log('User not authenticated');
            }
        } catch (error) {
            console.error("Error with callApi:", error.message);
        }
    } 
    
    return (
        <Button onClick={() => acceptInvitation("8HYh8M5xj7Wvj9mF5YJ0", "jPWXv2E2Q2rj3KAEQUIZ")}>
            Accept Invitation
        </Button>
    );
}

export default TestApi;
