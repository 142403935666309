import { useEffect, useCallback } from 'react';
import Button from '@mui/joy/Button';
import { Box } from '@mui/joy';
import { useParams } from 'react-router-dom';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import saveNoteToFirestore from '../../../database/notes-db'
import { SAVE_COMMAND } from './KeyUtilities';
import {
  mergeRegister
} from '@lexical/utils'
import { COMMAND_PRIORITY_LOW } from 'lexical';
import { useNotes } from '../../../contexts/notesContext';

function OpenButton({noteToDisplay}) {

  const { passedPasswordCheck, handleOpenNoteInEditor } = useNotes();

  return (
    noteToDisplay && noteToDisplay.private ?
      passedPasswordCheck ?
        <Box sx={{ padding: 1, pr: 0 }}>
          <Button onClick={() => handleOpenNoteInEditor(noteToDisplay)} size="sm" sx={{ mr: .7 }}>Open</Button>
        </Box>
        :
      <></>
      :
      <Box sx={{ padding: 1, pr: 0 }}>
        <Button onClick={() => handleOpenNoteInEditor(noteToDisplay)} size="sm" sx={{ mr: .7 }}>Open</Button>
      </Box>      
  )
}

export default OpenButton