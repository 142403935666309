import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $createCustomSentenceNode } from '../../CustomSentenceNode';
import { $getRoot } from 'lexical';
import { useNotes } from "../../../../../contexts/notesContext";

export function LoadTranscriptPlugin({ processedSentences, reset }) {
  const [editor] = useLexicalComposerContext();
  const [hasInitialized, setHasInitialized] = useState(false);
  const { selectedNote, setSelectedNote } = useNotes()

  useEffect(() => {
    if (reset) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
      });
      setSelectedNote(prev => ({
        ...prev,
        editorState: undefined
      }));
      setHasInitialized(false);
    }
  }, [reset, editor]);

  console.log("reset", reset)
  console.log("selectedNote", selectedNote)
  console.log("hasInitialized", hasInitialized)

  useEffect(() => {
    if (!editor || !processedSentences || hasInitialized) return;

    if (selectedNote?.editorState) {
      editor.setEditorState(editor.parseEditorState(selectedNote.editorState));
      setHasInitialized(true);
      return;
    }

    const updateEditor = () => {
      editor.update(() => {
        const root = $getRoot();
        const existingNodes = root.getChildren();
        
        if (existingNodes.length === 0 || existingNodes.length !== processedSentences.length) {
          root.clear();
          processedSentences.forEach((sentence) => {
            if (!sentence || !sentence.text || !sentence.startTime) return;
            const sentenceNode = $createCustomSentenceNode(sentence.text, sentence.startTime);
            root.append(sentenceNode);
          });
          setHasInitialized(true);
        }
      });
    };

    updateEditor();

    return () => {
      setHasInitialized(false);
    };
  }, [processedSentences, editor, hasInitialized, selectedNote?.editorState]);

  return null;
}