import React from 'react'
import { IconButton } from '@mui/joy';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookIcon from '@mui/icons-material/Book';
import Tooltip from '@mui/joy/Tooltip';
import { useNotes } from '../../contexts/notesContext';
import { useParams } from 'react-router-dom';
import FeedbackSnackbar from '../Snackbar';
import { useSnackbar } from '../../contexts/snackbarContext';

export default function Pins({noteToDisplay}) {

  const { accountId } = useParams() 
  const { handlePinNote } = useNotes();
  const { snackbarSeverity, snackbarMsg, showSnackbar, setSnackbarSeverity, setSnackbarMsg, setShowSnackbar } = useSnackbar();

  const handlePinNoteClick = async () => {
    if (noteToDisplay) {
      try {
        await handlePinNote({ note: noteToDisplay, trueOrFalse: !noteToDisplay.pinned });
        setSnackbarSeverity('success');
        if (noteToDisplay.pinned) {
          setSnackbarMsg('Unpinned successfully');
        } else {
          setSnackbarMsg('Pinned successfully');
        }
        setShowSnackbar(true);
      } catch (error) {
        setSnackbarSeverity('error');
        setSnackbarMsg('Error pinning');
        setShowSnackbar(true);
      }

    }
  }

  return (
    <>
      <Tooltip title={ noteToDisplay.pinned ? `${noteToDisplay.type} pinned` : `Pin ${noteToDisplay.type}` } variant="outlined" arrow>
        <IconButton size="sm" onClick={handlePinNoteClick} variant="plain">
          { noteToDisplay && noteToDisplay.pinned ? <BookmarkAddedIcon /> : <BookIcon /> }
        </IconButton>
      </Tooltip>
      <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
    </>
  )
}
