import React, { useEffect } from 'react';
import { FormControl, FormLabel, Radio, RadioGroup, List, ListItem, ListItemDecorator, Button, Typography, Box } from '@mui/joy';
import AspectRatio from '@mui/joy/AspectRatio';
import TextFieldsIcon from '@mui/icons-material/TextFields'; // Any text field
import ArticleIcon from '@mui/icons-material/Article'; // templates // pages
import ImageIcon from '@mui/icons-material/Image';
import { useContextualPane } from '../../../../contexts/contextualPane';
import { returnTenWordSummaryFromString } from '../../../../utils/utils';

export default function FilterAndCreateIncite() {

    const { activeItem, setNewDraftAssociation, newDraftAssociation, setProgressStep, setActiveItem, setManageAssociationModalOpen, itemPropsAvailableForIncites } = useContextualPane();

    const handleChange = (e) => {
        setActiveItem({ ...activeItem, elementSelected: e.target.value })
    }

    const handleExit = () => {
        //console.log("handleExit")
        setManageAssociationModalOpen(false)
    }

    const handleStepZeroContinue = (selectedValue) => {
        if (activeItem.toType !== "" || !activeItem.toType) {
            setProgressStep(3)
        } else {
            setProgressStep(2)
        }
    }

    useEffect(() => {
        setNewDraftAssociation({ ...newDraftAssociation, associationType: 'strong', fromType: activeItem.itemType, fromId: activeItem.item.id })
    },[])


    return (
        <FormControl>
            <RadioGroup onChange={handleChange} sx={{ m: 0, p: 0 }} defaultValue="Entire document" name="select-element-to-associate-group">
            <List
                sx={{
                minWidth: 240,
                '--List-gap': '0.5rem',
                '--ListItem-paddingY': '1rem',
                '--ListItem-radius': '8px',
                '--ListItemDecorator-size': '32px',
                }}
            >
                <ListItem key={`${activeItem.item.id}_firstItem`} variant="outlined" sx={{ boxShadow: 'sm' }}>
                    <ListItemDecorator><ArticleIcon /></ListItemDecorator>
                    <Radio
                        overlay
                        value="Entire document"
                        variant='outlined'
                        label="Entire document"
                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                        slotProps={{
                        action: ({ checked }) => ({
                            sx: (theme) => ({
                            ...(checked && {
                                inset: -1,
                                border: '2px solid',
                                borderColor: theme.vars.palette.primary[500],
                            }),
                            }),
                        }),
                        }}
                    />
                </ListItem>

                { Object.keys(itemPropsAvailableForIncites).map((key, index) => {
                    const value = itemPropsAvailableForIncites[key];

                    // Check if value is not null, not an empty array, and not an empty string
                    if (value !== null && value !== '') {
                        if (key === 'additionalFields') {
                            return (
                                Object.keys(value).map((field, index) => (   
                                    <>
                                    <ListItem variant="outlined" key={`_sub_${index}`} sx={{ boxShadow: 'sm' }}>
                                        <ListItemDecorator>{ key === 'image' ? <ImageIcon /> : <TextFieldsIcon /> }</ListItemDecorator>
                                        <Radio
                                            overlay
                                            value={field}
                                            className="select-element-to-associate-radio"
                                            variant='outlined'
                                            label={ `${field}: ${returnTenWordSummaryFromString(itemPropsAvailableForIncites[key][field])}` }
                                            sx={{ textTransform: 'capitalize', flexGrow: 1, flexDirection: 'row-reverse' }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.primary[500],
                                                    }),
                                                    }),
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                    </>
                                ))
                            )
                        } 
                        else if (key === 'items') {
                            return (
                                Object.keys(value).map((field, index) => (
                                    <>
                                    <ListItem variant="outlined" key={`_sub_${index}`} sx={{ boxShadow: 'sm' }}>
                                        <ListItemDecorator><ArticleIcon /></ListItemDecorator>
                                        <Radio
                                            overlay
                                            value={value[field].id} // TO DO: this is the ID of the item that is selected, will need to create a filter to get the selected item at a later point
                                            className="select-element-to-associate-radio"
                                            variant='outlined'
                                            label={ `${value[field].label}` }
                                            sx={{ textTransform: 'capitalize', flexGrow: 1, flexDirection: 'row-reverse' }}
                                            slotProps={{
                                                action: ({ checked }) => ({
                                                    sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.primary[500],
                                                    }),
                                                    }),
                                                }),
                                            }}
                                        />
                                    </ListItem>
                                    { value[field].children && value[field].children.length > 0 &&
                                        Object.keys(value[field].children).map((child, index) => (
                                            <ListItem variant="outlined" key={`_sub_${index}`} sx={{ boxShadow: 'sm' }}>
                                                <ListItemDecorator><ArticleIcon /></ListItemDecorator>
                                                <Radio
                                                    overlay
                                                    value={value[field].children[child].id} // TO DO: this is the ID of the item that is selected, will need to create a filter to get the selected item at a later point
                                                    className="select-element-to-associate-radio"
                                                    variant='outlined'
                                                    label={ `${value[field].children[child].label}` }
                                                    sx={{ textTransform: 'capitalize', flexGrow: 1, flexDirection: 'row-reverse' }}
                                                    slotProps={{
                                                        action: ({ checked }) => ({
                                                            sx: (theme) => ({
                                                            ...(checked && {
                                                                inset: -1,
                                                                border: '2px solid',
                                                                borderColor: theme.vars.palette.primary[500],
                                                            }),
                                                            }),
                                                        }),
                                                    }}
                                                />
                                            </ListItem>
                                        ))                                        
                                    }
                                    </>
                                ))
                            )                
                        }
                        else {
                            return (
                            <>
                                <ListItem variant="outlined" key={`${key}_${index}`} sx={{ boxShadow: 'sm' }}>
                                    <ListItemDecorator>{ key === 'image' ? <ImageIcon /> : <TextFieldsIcon /> }</ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={key}
                                        className="select-element-to-associate-radio"
                                        variant='outlined'
                                        label={ key !== 'image' 
                                        ?
                                            `${key}: ${returnTenWordSummaryFromString(itemPropsAvailableForIncites[key])}` 
                                        : (
                                            <>
                                            <AspectRatio minHeight="120px" maxHeight="200px">
                                                <img src={itemPropsAvailableForIncites[key]} srcSet={itemPropsAvailableForIncites[key]} loading="lazy" alt="" />
                                            </AspectRatio>
                                            <Box sx={{ pt: 1 }}>
                                                <Typography >{key}</Typography>
                                            </Box>
                                            </>
                                        )
                                        }
                                        sx={{ textTransform: 'capitalize', flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                        action: ({ checked }) => ({
                                            sx: (theme) => ({
                                        ...(checked && {
                                            inset: -1,
                                            border: '2px solid',
                                            borderColor: theme.vars.palette.primary[500],
                                        }),
                                        }),
                                    }),
                                    }}
                                />
                            </ListItem>
                            </>
                            )
                        }
                    }
                })}
            </List>
            </RadioGroup>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
                <Button onClick={() => handleExit()} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="neutral">
                    Cancel
                </Button>
                <Button onClick={() => handleStepZeroContinue()} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                    Continue
                </Button>
            </Box>
        </FormControl>
    )
}