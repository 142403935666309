import React, { useState, createContext, useContext } from 'react';
import { useAuth } from '../../authContext'; 
import { useNotes } from './notesContext';
import { useLoading } from './loadingContext';
import { useBoards } from './boardsContext';
import { useProjects } from './projectsContext';
import { useParams } from 'react-router-dom';
import { retrieveAllTags } from '../database/search-db';
import { createTagInGroup } from '../database/tags-db';
import { create } from '@mui/material/styles/createTransitions';
import { ref } from 'firebase/storage';

// Create the context
const TagsContext = createContext();

// Create a provider component
export function TagsProvider({ children }) {

    const { selectedProject } = useProjects()
    
    const [tags, setTags] = React.useState([]);
    const { currentUser } = useAuth()
    const { accountId, selectedProjectId } = useParams();
    const { boards, boardsGroupsTags, handleFetchBoardsGroupsTags } = useBoards()
    const { running } = useLoading()
    const handleFetchTags = async () => {
        //console.log("handleFetchTags")
        const results = await handleFetchBoardsGroupsTags()
        //console.log("tags retrieved ", results)
    }

    const handleCreateTagInGroup = async ({ name, boardId, groupId }) => {
        const result = await createTagInGroup({ boardId, accountId, projectId: selectedProjectId, groupId, name, updatedBy: currentUser })
        //console.log("createTagInGroup result", result)
        return result
    }

    const handleAddNewTag = async ({ name, boardId, groupId }) => {
        //console.log("handleAddNewTag", name, boardId, groupId)
        // Add a new tag to the board group
        const newTag = await handleCreateTagInGroup({ name, boardId, groupId })
        // TO DO: Create an association record within /accountId/associations
        // with the following data: { tagSrc: 'tag_Note', tagSrcId: 'noteId', tagId: 'tagId', created: timestamp, updated: timestamp, associatedBy: 'userId' }
        // Update the tags
        await handleFetchTags()
        // If boards.length is equal to 1, just use the id of boards[0].id and add to the first group
        // If boards.length is greater than 1, use the boardId and groupId selection from the user
        return newTag
    }

    const parseTagsFromBoardsGroupsTags = () => {
        // Parse through all tags and create a normalised list for use in the tags picker
        const tags = []
        if (boardsGroupsTags && boardsGroupsTags.length > 0) {
            boardsGroupsTags.forEach(board => {
                if (board.boardGroups && board.boardGroups.length > 0) {
                    board.boardGroups.forEach(group => {
                        if (group.tags && group.tags.length > 0) {
                            group.tags.forEach(tag => {
                                // Add the tag, boardsGroupsTags.title, and board.boardGroups.title to the tags array
                                tags.push({
                                    boardsGroupsTagsTitle: board.title,
                                    boardGroupsTitle: group.title,
                                    key: tag.id,
                                    id: tag.id,
                                    boardId: board.id,
                                    accountId: accountId,
                                    projectId: selectedProjectId,
                                    referenceId: tag.referenceId ? tag.referenceId : '',
                                    groupId: group.id,
                                    tagId: tag.id,
                                    tagData: {
                                        name: tag.title,
                                        color: tag.colour,
                                    },
                                    references: tag.references ? tag.references : [],
                                    ...tag
                                })
                            })
                        }
                    })
                }
            })
        }
        return tags
    }

    React.useEffect(() => {
        // onLoad fetch tags
        if (!running) {
            handleFetchTags()
        }
    },[])

    React.useEffect(() => {
        if (!running) {
            handleFetchTags()
        }
    },[selectedProject])

    React.useEffect(() => {
        // Parse through all tags and create a normalised list for use in the tags picker
        const newTags = parseTagsFromBoardsGroupsTags()
        // Update tags on the picker and elsewhere 
        setTags(newTags)
    },[boardsGroupsTags])

    // console.log("tags", tags)
  const value = {
    tags,
    setTags,
    handleAddNewTag,
    handleFetchTags
  };

  return <TagsContext.Provider value={value}>{children}</TagsContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useTags() {
  return useContext(TagsContext);
}
