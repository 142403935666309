import React from 'react';
import { Box, Input, Typography } from '@mui/joy';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import EditIcon from '@mui/icons-material/Edit';

export default function PreviewTableHeader({ id, row, rowIndex, title, initialData, ...props }) {
  return (
    row 
    ?
      <>
        <Box display="flex" alignItems="center" sx={{ '&:hover .title-icon-edit': {
          display: 'block',
        }}}>        
        <>
        <Typography 
            level="title-sm" 
            sx={{ ml: 1, pr: 3, cursor: 'pointer', fontWeight: 800 }} 
        >
            {title}
        </Typography>
        </>
      </Box>
      <Box>
        <></>{/* <Remove onClick={() => handleRemoveRow({ rowIndex: rowIndex + 1 })} /> */}
      </Box>
      </>
    :
      <>
      <Box position="relative" display="flex" alignItems="center" sx={(theme) => ({ minHeight: '60px', '&:hover .title-icon-edit': {
          display: 'flex', 
        },  })} >
        <Typography 
        level="title-sm" 
        sx={{ ml: 1, pr: 3, cursor: 'pointer', fontWeight: 800 }} 
        >
            {title}
        </Typography>
        {/* <Box className="resize-col" sx={(theme) => ({ position: 'absolute', backgroundColor: `${theme.palette.primary.outlinedBorder}`, mr: 1, right: 0, top: 10, bottom: 10, width: '2px', cursor: 'col-resize' })} /> */}
      </Box>
      </>
  )
}