import { useEffect, useState } from "react";
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import Grid from '@mui/joy/Grid';
import Box from '@mui/joy/Box';
import DefinitionBanner from "../../Components/Banners/DefinitionBanner"
import PersonaCards from "../../Components/Personas"
import PersonaModal from "../../Components/Modals/PersonaModal";
import FeedbackSnackbar from "../../Components/Snackbar";
import OpenAssociationsBtn from "../../Components/DrawerRight/IncitesForTypeInstance/OpenAssociationsBtn";
import { usePersonas } from "../../contexts/personasContext";
import { getConfig } from "../../database/configs-db";

function PersonasList() {
  const [banner, setBanner] = useState('')
  const { 
    persona,
    personas,
    name, setName,
    personaEditorOpen,
    setPersonaEditorOpen,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    snackbarMsg,
    handleCreatePersona,
    handleSetPersona,
    handleUpdateCurrentPersona,
    handlePersonaDeletion,
    pendingTag 
  } = usePersonas()

  useEffect(() => {
    getConfig().then(config => setBanner(config.personasImage))
  }, [])
  
  return (
    <Sheet
      sx={{
        width: '100%',
        height: '100vh',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        alignItems="start"
        justifyContent="start"
        p={2}
        pb={1.5}
        flexShrink={0}
      >
        <DefinitionBanner 
          title="Personas" 
          btnTxt="Create Persona"
          handleCreate={handleCreatePersona} 
          srcImage={banner}
        />
      </Stack>
      <Box 
        sx={{ 
          flexGrow: 1,
          overflow: 'auto',
          px: 2,
          pb: 8
        }}
      >
        <Grid 
          container 
          spacing={2}
          sx={{
            p: { xl: 3 },
            pr: { xl: 1 },
            pt: { xs: 0, xl: 0 },
            mt: { xs: 0, xl: 0 }
          }}
          xs={12}
        >
          <PersonaCards handleSetPersona={handleSetPersona} personas={personas} />
        </Grid>
      </Box>
      <PersonaModal 
        tags={pendingTag} 
        name={name} 
        setName={setName} 
        open={personaEditorOpen} 
        setOpen={setPersonaEditorOpen} 
        handlePersonaDeletion={handlePersonaDeletion} 
        handleUpdateCurrentPersona={handleUpdateCurrentPersona} 
        persona={persona} 
      />
      <FeedbackSnackbar 
        openSetting={showSnackbar} 
        setShowSnackbar={setShowSnackbar} 
        message={snackbarMsg} 
        colorSetting={snackbarSeverity} 
      />
      {/* <OpenAssociationsBtn /> */}
    </Sheet>
  )
}

export default PersonasList