import React, { useState } from 'react';
import { Link, Box, Button, Card, IconButton, Select, Option, Input, Textarea } from "@mui/joy"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { saveToFirestore } from "../../../../../database/features-db";
import { useParams, useNavigate } from "react-router-dom";
import FeedbackSnackbar from './../../../../Snackbar/index';
import { BASE_PRIVATEAPP_URL } from './../../../../../../redirects/index';

function FeaturesResults({ result, setResult }) {
  const { accountId, selectedProjectId } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const navigate = useNavigate();

  const deleteFeature = (index) => {
    // Delete item from result array based on index
    const updatedResult = result.filter((_, i) => i !== index);
    setResult(updatedResult);
  }

  const handleClickLink = (id) => {
      // TO DO - need to handle a passed in feature
      navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/features/');
  }

  const addFeature = async (feature,index) => {
    // save to firestore
    try {
      await saveToFirestore({
        feature: {
          title: feature.title,
          description: feature.description,
          priority: feature.priority,
          category: feature.category,
          status: feature.status,
          dueDate: feature.dueDate,
          tags: feature.tags
        }, 
        accountId, 
        selectedProjectId
      })
      // on success of adding to firestore, show toaster and delete from result
      setSnackbarMsg(<span>
        <Link size="sm" sx={{ fontSize: 14, textDecoration: 'underline', color: "var(--joy-palette-common-white)" }} onClick={() => handleClickLink()}>Feature added</Link> successfully
      </span>)
      setSnackbarSeverity("success")
      setShowSnackbar(true)
      deleteFeature(index)
    } catch (error) {
      console.error("Error saving feature to Firestore:", error);
    }    
  }

  return (
    result && result.length > 0 && result.map((feature, index) => (
      <React.Fragment key={index}>
        <Card color="neutral" variant="plain" sx={{ p: 3, pr: 2, mb: 1, boxShadow: 'md', backgroundColor: 'var(--joy-palette-background-card-surface)' }}>
          <Box sx={{ display: 'flex', mb: 1.5, alignItems: 'center' }}>
            {/* <Typography level="h6">{feature.featureTitle}</Typography> */}
            <Input sx={{ width: '85%' }} variant="plain" color="neutral" size="md" value={feature.title} />
            <IconButton onClick={() => { deleteFeature(index)}} variant="plain" color="neutral" size="sm" sx={{ ml: 'auto' }}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Textarea size="md" sx={{ mb: 1.5 }} color="neutral" variant="plain" defaultValue={feature.description} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button onClick={() => { addFeature(feature,index) }} variant="plain" color="primary">
              <Box sx={{ mt: .5, mr: 1.25, alignItems: "center" }}>
                <AddIcon />
              </Box> Add                
            </Button>
            <Select defaultValue={feature.priority} variant="plain" sx={{ mt: .5, ml: 'auto' }}>
              <Option value="4">Must</Option>
              <Option value="3">Should</Option>
              <Option value="2">Could</Option>
              <Option value="1">Won't</Option>
            </Select>
          </Box>
        </Card>
        <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
      </React.Fragment>
    ))
  )
}

export default FeaturesResults