import database from '../../configs/firebaseConfig';

const db = database.firestore(); // Access Firestore database

export async function getConfig() {
    const configDoc = await db.collection('config').doc('global').get();
    return configDoc.data();
}

export async function updateConfig({ updates }) {
    const configDoc = await db.collection('config').doc('global').update(updates);
    return configDoc;
}
