import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../authContext'; 
import { useNavigate } from 'react-router-dom';
import { CssVarsProvider, useColorScheme } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Checkbox from '@mui/joy/Checkbox';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import BadgeRoundedIcon from '@mui/icons-material/BadgeRounded';
import GoogleIcon from './GoogleIcon'
import { useParams } from 'react-router-dom';
import Header from '../../Components/Header';
import { jwtDecode } from 'jwt-decode';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import { fetchAccountId, fetchSelectedProjectId } from '../../../PrivateSite/database/utilities-db';
import Alert from '@mui/joy/Alert';
import { set } from 'date-fns';
import { Link } from '@mui/joy';
import { getConfig } from '../../../PrivateSite/database/configs-db';

export default function Login() {
  const { accountId, selectedProjectId } = useParams()
  const { login, setCurrentUser } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [banner, setBanner] = useState('')

  const handleLogin = async (e) => {
    //console.log("handle login")
    e.preventDefault();

    try {
      const result = await login(email, password);
      if (result) {
        const userDetails = await fetchAccountId(result.user.uid)
        if (userDetails) {
          const selectedProjectDetails = await fetchSelectedProjectId(result.user.uid)
          //console.log("selectedProjectDetails", selectedProjectDetails)
          if (selectedProjectDetails) { 
            navigate(BASE_PRIVATEAPP_URL + userDetails + '/' + selectedProjectDetails)
          } else {
            //console.log("No selected project for user" + result.user.uid)
            setError('There has been an issue logging in, please contact us citing your email address.');
          }
        } else {
          //console.log("No matching account for user" + result.user.uid)
        }
      }
      
    } catch (error) {
      setError('Failed to log in. Check your email and password.');
      console.error(error.message);

      if (error.code == 'auth/invalid-login-credentials') {
        setError('The login credentials were incorrect. Please try again.');
      } else {
        setError('There was an error our side, please try again.');
      }
      console.error(error.code);
    }
  };

  React.useEffect(() => {
    getConfig().then(config => setBanner(config.loginImage))
  }, [])

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: '#F4F1F8',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Header />
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography level="h3">Sign in</Typography>
                <Typography level="body-sm">
                  New to smrt cookie?{' '}
                  <Link href="/sign-up" variant='plain' color="neutral">
                    Sign up
                  </Link>
                </Typography>
              </Stack>

              {/* <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
                onClick={() => loginWithGoogle()}
              >
                Continue with Google
              </Button> */}

            </Stack>

            { error !== '' &&
              <Alert variant='soft' color="warning">
                {error}
              </Alert>
            }

            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={handleLogin}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </FormControl>
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Link variant="plain" sx={{ fontSize: '0.8rem' }} color="neutral" href="/forgot-password">
                      Forgot your password?
                    </Link>
                  </Box>
                  <Button type="submit" fullWidth>
                    Sign in
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © smrt cookie {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',


          backgroundColor: 'background.level1', // Ivory: FFFFF0, Lavender: F4F1F8
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            `url(${banner})`,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              `url(${banner})`,
          },
        })}
      />
    </CssVarsProvider>
  );
}