import React, { useEffect } from "react";
import TagPicker from "../Tags/Editor/TagPicker";
import { Box } from "@mui/joy";

function FilterToDosByTag({ toDos, setFilteredToDos, pendingTag, setPendingTag, selectedTags, setSelectedTags }) {
  
  const handleUpdateTags = async (tags = pendingTag) => {
    console.log('tags', tags);
    setSelectedTags(tags);
  }

  useEffect(() => {
    if (selectedTags.length > 0) {
      console.log('selectedTags', selectedTags);
      // Filter todos by selectedTags comparing the id field
      const filteredToDos = toDos.filter(todo => 
        todo.tags.some(tag => selectedTags.some(selectedTag => selectedTag.id === tag.id))
      );
      console.log('filteredToDos', filteredToDos);
      if (filteredToDos.length > 0) {
        setFilteredToDos(filteredToDos);
      } else if (filteredToDos.length === 0) {
        setFilteredToDos([]);
      }
    } else {
      setFilteredToDos([]);
    }
  }, [selectedTags, toDos]);

  return (
    <Box sx={{ mt: -.9 }}>
      <TagPicker 
        handleUpdateTags={handleUpdateTags} 
        setPendingTag={setPendingTag}
        pendingTag={pendingTag}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
    </Box>
  );
}

export default FilterToDosByTag;