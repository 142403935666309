import React from 'react'
import { useNotes } from '../../contexts/notesContext'
import { IconButton, Input } from '@mui/joy'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { matchSorter } from 'match-sorter';
import CloseRounded from '@mui/icons-material/CloseRounded';
import { searchUsingText } from '../../database/search-db';
import { getNote } from '../../database/notes-db';
import { useParams } from 'react-router-dom';

export default function InlineSearch() {
  const { notes, loadNoteContent, setSearchFilteredNotes, setFilteredNotes } = useNotes()
  const [ search, setSearch ] = React.useState('');
  const { accountId } = useParams();

  const handleSearch = async (event) => {
    
    const searchText = event.target.value;
    setSearch(searchText);

    if (searchText.trim()) {
      if (searchText !== " ") {
        const searchResults = await searchUsingText({
          accountId: accountId,
          text: searchText,
          type: 'searchItem_note'
        });
        
        if (searchResults?.length > 0) {
          setFilteredNotes(true)
          // Ensure noteId exists and is a string before proceeding
          const validSearchResults = searchResults.filter(result => 
            result && typeof result.noteId === 'string'
          );
          
          // Fetch full note content for each valid search result
          const notesPromises = validSearchResults.map(async result => {
            try {
              return await getNote({ 
                noteId: result.noteId, 
                accountId: accountId 
              });
            } catch (error) {
              console.error(`Error loading note ${result.noteId}:`, error);
              return null;
            }
          });
          
          const matchingNotes = (await Promise.all(notesPromises))
            .filter(note => note !== null);
          
          if (matchingNotes?.length > 0) {
            setSearchFilteredNotes(matchingNotes || []);
          } else {
            setSearchFilteredNotes([]);
            handleClearSearch();
            setFilteredNotes(false)
          }
        }
      } else {
        setFilteredNotes(false)
      }
    } else {
      setSearchFilteredNotes([]);
      setFilteredNotes(false)
    }
  };

  const handleClearSearch = () => {
    setFilteredNotes(false);
    setSearch('');
  }

  //console.log("searchFilteredNotes", searchFilteredNotes)
  
  return (
    <Input
      size="sm"
      variant="plain"
      startDecorator={<SearchRoundedIcon />}
      placeholder="Search"
      aria-label="Search"
      onChange={handleSearch}
      value={search}
      endDecorator={
        search !== "" && (
          <IconButton variant="plain" color="neutral" onClick={() => handleClearSearch() }>
            <CloseRounded />
          </IconButton>
        )
      }
    />
  )
}