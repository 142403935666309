export const palette = {
  primary: {
    300: '#7451A2',
    400: '#3E2B57',
    500: '#d8cbe6',
    700: '#5B4080',
    solidBg: '#7451A2',
    solidBorder: '#9669D1',
    solidHoverBg: '#5B4080',
    solidHoverBorder: '#9669D1',
    solidActiveBg: '#3E2B57',
    solidActiveBorder: '#3E2B57',
    solidDisabledBg: '#7451A2',
    solidDisabledBorder: '#7451A2',
    outlinedBorder: '#d8cbe6',
    outlinedHoverBg: '#F4F1F8',
    outlinedHoverBorder: '#5B4080',
    outlinedColor: '#7451A2',
    outlinedActiveBg: 'var(--joy-palette-secondary-900)',
    outlineActiveBorder: '#7451A2',
    softColor: '#fff',
    softBg: '#75519f',
    softBorder: '#7451A2',
    softHoverBg: '#7451A2',
    softHoverBorder: '#7451A2',
    softActiveBg: '#7451A2',
    softActiveBorder: '#7451A2',
    softDisabledBg: '#7451A2',
    softDisabledBorder: '#7451A2',
    plainColor: '#7451A2',
    plainHoverBg: 'transparent',
    plainActiveBg: 'var(--joy-palette-primary-500)',
    outlinedColor: '#7451A2',
  },
  secondary: {
    solidBg: '#7451A2',
    solidActiveBg: '#7451A2',
    outlinedBorder: '#7451A2',
    outlinedColor: 'var(--joy-palette-secondary-600)',
    outlinedActiveBg: 'var(--joy-palette-secondary-900)',
    softColor: 'var(--joy-palette-secondary-500)',
    softBg: '#7451A2',
    softActiveBg: 'var(--joy-palette-secondary-800)',
    plainColor: 'var(--joy-palette-secondary-500)',
    plainActiveBg: 'var(--joy-palette-secondary-900)',
  },
  neutral: {
    solidBg: '#7451A2',
    solidBorder: '#7451A2',
    solidHoverBg: '#7451A2',
    solidHoverBorder: '#565e64',
    solidActiveBg: '#565e64',
    solidActiveBorder: '#51585e',
    solidDisabledBg: '#6c757d',
    solidDisabledBorder: '#6c757d',
    // btn-light
    softColor: '#000',
    softBg: '#f8f9fa',
    softBorder: '#f8f9fa',
    softHoverBg: '#f9fafb',
    softHoverBorder: '#f9fafb',
    softActiveBg: '#f9fafb',
    softActiveBorder: '#f9fafb',
    softDisabledBg: '#f8f9fa',
    softDisabledBorder: '#f8f9fa',
  },
  success: {
    solidBg: '#198754',
    solidBorder: '#198754',
    solidHoverBg: '#157347',
    solidHoverBorder: '#146c43',
    solidActiveBg: '#146c43',
    solidActiveBorder: '#13653f',
    solidDisabledBg: '#198754',
    solidDisabledBorder: '#198754',
  },
  danger: {
    solidBg: '#dc3545',
    solidBorder: '#dc3545',
    solidHoverBg: '#bb2d3b',
    solidHoverBorder: '#b02a37',
    solidActiveBg: '#b02a37',
    solidActiveBorder: '#a52834',
    solidDisabledBg: '#dc3545',
    solidDisabledBorder: '#dc3545',
  },
  warning: {
    solidColor: '#000',
    solidBg: '#ffc107',
    solidBorder: '#ffc107',
    solidHoverBg: '#ffca2c',
    solidHoverBorder: '#ffc720',
    solidActiveBg: '#ffcd39',
    solidActiveBorder: '#ffc720',
    solidDisabledBg: '#ffc107',
    solidDisabledBorder: '#ffc107',
  },
  info: {
    solidColor: '#000',
    solidBg: '#0dcaf0',
    solidBorder: '#0dcaf0',
    solidHoverBg: '#31d2f2',
    solidHoverBorder: '#25cff2',
    solidActiveBg: '#3dd5f3',
    solidActiveBorder: '#25cff2',
    solidDisabledBg: '#0dcaf0',
    solidDisabledBorder: '#0dcaf0',
  },
  background: {
    card: {
      surface: '#fff'
    }
  },
  components: {
    JoyInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#fff',
        }
      }
    },
    // This does not work as there is another JoyButton in the PrivateSite theme
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '0px',
          ...(ownerState.variant === 'solid' && {
            // Styles for solid variant
          }),
          ...(ownerState.color === 'primary' && {
            // Styles for primary color
          }),
          ...(ownerState.size === 'sm' && {
            // Styles for small size
          }),
        }),
      }
    }
  }
};