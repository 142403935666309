import * as React from 'react';
import { Avatar, Box, Link, Table, Typography, Button } from '@mui/joy';
import { useAuth } from '../../../authContext';
import { retrieveUsersForAccount } from '../../database/users-db';
import { useParams } from 'react-router-dom';
import AccountAvatar from '../Navigations/Account/AccountAvatar';
import { isManagerAndAbove } from '../../utils/utils';

export default function UsersTable() {
  const { currentUser } = useAuth();
  const { accountId } = useParams();
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    const fetchUsers = async () => {
      const users = await retrieveUsersForAccount({ accountId });
      setUsers(users);
      console.log(users);
    };

    fetchUsers();
  }, [accountId]);

  return (
    <Table
      aria-label="table with ellipsis texts"
      noWrap
      sx={{ '--TableCell-headBackground': '#fff',
        '--Table-headerUnderlineThickness': '1px',
        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
        '--TableCell-paddingY': '12px',
        '--TableCell-paddingX': '6px',
        width: '100%',
        pb: 5 }}
    >
      <thead>
        <tr>
          <th style={{ width: '40%' }}>User</th>
          <th>
            Role
          </th>
        </tr>
      </thead>
      <tbody>
        {users && users.length > 0 && users.map((user) => (
          <tr key={user.id}>
          <td>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
              {(user && isManagerAndAbove(user)) ? (
                user.photoURL && user.photoURL !== '' && (
                  <Link>
                    <AccountAvatar size="40px" src={user.photoURL} />
                  </Link>
                )
              ) : (
                user.photoURL && user.photoURL !== '' && (
                  <AccountAvatar size="40px" src={user.photoURL} />
                )
              )}
              <Box sx={{ minWidth: 0 }}>
                {(user && isManagerAndAbove(user)) ? (
                  <Link variant="plain" color="primary">
                    <Typography noWrap sx={{ fontWeight: 'lg' }}>
                      {user.displayName}
                    </Typography>
                  </Link>
                ):(
                  <Typography noWrap sx={{ fontWeight: 'lg' }}>
                      {user.displayName}
                  </Typography>
                )}
                <Typography noWrap level="body-sm">
                  {user.email}
                </Typography>
              </Box>
            </Box>
          </td>
          <td>
            {user.role}
          </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
