import PlanCard from '../PlanCard';
import CookieIcon from '@mui/icons-material/Cookie';
import StorageIcon from '@mui/icons-material/Storage';
import SchoolIcon from '@mui/icons-material/School';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DevicesIcon from '@mui/icons-material/Devices';
import AutoFixNormalIcon from '@mui/icons-material/AutoFixNormal';
import PublishIcon from '@mui/icons-material/Publish';

const points = [
    { title:'Unlimited projects', icon: <CookieIcon /> },
    { title:'Unlimited storage', icon: <StorageIcon /> },
    { title:'Research repository', icon: <SchoolIcon /> },
    { title:'Research tagging and analysis', icon: <LocalOfferIcon /> },
    { title:'Experience documentation', icon: <MenuBookIcon /> },
    { title:'Publish', icon: <PublishIcon /> },
    { title:'Multi device', icon: <DevicesIcon /> },
    { title:'AI Magic', icon: <AutoFixNormalIcon /> }
];

function Paid({ name, selected, pricing, monthly }) {
    return (
      <PlanCard plan="paid" selected={selected} points={points} inverted={true} pricing={pricing} monthly={monthly} name={name} />
    )
}

export default Paid;