import React from 'react';
import { Table, Typography, Chip, Link, Box } from '@mui/joy';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AutorenewRoundedIcon from '@mui/icons-material/AutorenewRounded';
import BlockIcon from '@mui/icons-material/Block';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { retrievePlansForUser, redirectToStripeCheckout } from '../../database/plans-db';
import { useAuth } from '../../../authContext';
import { matchProductId, matchPriceId } from '../../database/utilities-db';
import { isAdminAndAbove } from '../../utils/utils';

function SubscriptionsTable({ allPlans }) {
  const { currentUser } = useAuth();
  const [plans, setPlans] = React.useState(null);
  const [ linkLoading, setLinkLoading ] = React.useState(false);

  React.useEffect(() => {
    retrievePlansForUser({ userId: currentUser._delegate.uid }).then(setPlans);
  }, [currentUser]);

  const handleNavigateToLink = () => {
    setLinkLoading(true);
    redirectToStripeCheckout();
  }

  return (
    <Table
      aria-labelledby="tableTitle"
      stickyHeader
      hoverRow
      sx={{
        '--TableCell-headBackground': '#fff',
        '--Table-headerUnderlineThickness': '1px',
        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
        '--TableCell-paddingY': '12px',
        '--TableCell-paddingX': '6px',
        width: '100%',
        pb: 5
      }}
    >
      <thead>
        <tr>
          <th style={{ width: 140, padding: '12px 6px' }}>Plan</th>
          <th style={{ width: 140, padding: '12px 6px' }}>Cost</th>
          <th style={{ width: 140, padding: '12px 6px' }}>Started</th>
          <th style={{ width: 140, padding: '12px 6px' }}>Renewing</th>
          <th style={{ width: 140, padding: '12px 6px' }}>Status</th>
          {currentUser && isAdminAndAbove(currentUser) && (
            <th style={{ width: 140, padding: '12px 6px' }}></th>
          )}
        </tr>
      </thead>
      <tbody>
        {plans && plans.length > 0 && [...plans].map((row) => (
          <tr key={row.id}>
            <td>
              <Typography level="body-xs">
                {row.items && row.items.length > 0 && allPlans && row.items.map((item, index) => (
                  <div key={index}>
                    <Typography level="body-sm">{matchProductId({ productId: item.plan.product, allPlans })?.name}</Typography>
                  </div>
                ))}
              </Typography>
            </td>
            <td>
              <Typography level="body-sm">
                {row.items && row.items.length > 0 && allPlans && row.items.map((item, index) => {
                  const price = matchPriceId({ priceId: item.plan.id, allPlans });
                  return (
                    <div key={index}>
                      {price && price.currency ? (
                        price.tiers && price.tiers.length > 0 ? (
                          (() => {
                            const quantity = item.quantity +1 || 2;

                            const baseAmount = price.tiers[0].flat_amount / 100;
                            const perSeatCost = price.tiers[1].unit_amount / 100;
                            const includedSeats = 2;
                            const extraSeats = Math.max(0, quantity - includedSeats);
                            const totalCost = baseAmount + (extraSeats * perSeatCost);

                            return (
                              <Typography level="body-sm">
                                {`${item.quantity + 1} seats: `}
                                <br />
                                {`${totalCost.toFixed(2)} ${price.currency.toUpperCase()} / ${price.interval}`}
                                <br />
                                <Typography level="body-sm">
                                  {`(${baseAmount.toFixed(2)} base for ${includedSeats} seats + `}<br />
                                  {`${perSeatCost.toFixed(2)} per additional seat)`}
                                </Typography>
                              </Typography>
                            );
                          })()
                        ) : (
                          `${price.unit_amount / 100} ${price.currency.toUpperCase()} / ${price.interval}`
                        )
                      ) : 'N/A'}
                    </div>
                  );
                })}
              </Typography>
            </td>
            <td>
              <Typography level="body-sm">
                {new Date(row.current_period_start.seconds * 1000).toLocaleDateString()}
              </Typography>
            </td>
            <td>
              <Typography level="body-sm">
                {
                  row.cancel_at_period_end
                    ? new Date(row.current_period_end.seconds * 1000) < new Date()
                      ? "Plan cancelled on " + new Date(row.current_period_end.seconds * 1000).toLocaleDateString()
                      : "Plan cancelling on " + new Date(row.current_period_end.seconds * 1000).toLocaleDateString()
                    : new Date(row.current_period_end.seconds * 1000).toLocaleDateString()
                }
              </Typography>
            </td>
            <td>
              <Typography level="body-sm">
                <Chip
                  variant="soft"
                size="md"
                startDecorator={
                  {
                    active: <CheckRoundedIcon />,
                    past_due: <AutorenewRoundedIcon />,
                    unpaid: <BlockIcon />,
                    canceled: <BlockIcon />,
                    incomplete: <AutorenewRoundedIcon />,
                    incomplete_expired: <BlockIcon />,
                    trialing: <AutorenewRoundedIcon />,
                    paused: <PauseCircleOutlineIcon />,
                  }[row.status] || <HelpOutlineIcon />
                }
                color={
                  {
                    active: 'success',
                    past_due: 'warning',
                    unpaid: 'danger',
                    canceled: 'neutral',
                    incomplete: 'warning',
                    incomplete_expired: 'danger',
                    trialing: 'info',
                    paused: 'neutral',
                  }[row.status] || 'neutral'
                }
              >
                  {row.status}
                </Chip>
              </Typography>
            </td>
            {currentUser && isAdminAndAbove(currentUser) && (
              <td>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                  <Link
                    disabled={linkLoading}
                    variant="plain"
                    color="primary"
                    onClick={() => handleNavigateToLink()}
                    level="body-sm"
                    component="button"
                    loading={linkLoading}
                  >
                    {linkLoading ? "Redirecting..." : "Manage"}
                  </Link>
                </Box>
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export default SubscriptionsTable;
