import * as React from 'react';
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { styled } from '@mui/joy/styles';
import Button from '@mui/joy/Button';
import MenuList from '@mui/joy/MenuList';
import MenuItem from '@mui/joy/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Popup = styled(Popper)({
  zIndex: 1000,
});

export default function SwitchViewTypeMenu({ currentSelectedType, setCurrentSelectedType }) {
  const buttonRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = (type) => {
    setCurrentSelectedType(type)
    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      setOpen(false);
    } else if (event.key === 'Escape') {
      buttonRef.current.focus();
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        ref={buttonRef}
        id="composition-button"
        aria-controls={'composition-menu'}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        color="neutral"
        startDecorator={<KeyboardArrowDownIcon />}
        onClick={() => {
          setOpen(!open);
        }}
      >
        Browse { currentSelectedType === "notes" && "Notes" }{ currentSelectedType === "navigations" && "Navigations" }
      </Button>
      <Popup
        role={undefined}
        id="composition-menu"
        open={open}
        anchorEl={buttonRef.current}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
      >
        <ClickAwayListener
          onClickAway={(event) => {
            if (event.target !== buttonRef.current) {
              handleClose();
            }
          }}
        >
          <MenuList
            variant="outlined"
            onKeyDown={handleListKeyDown}
            sx={{ boxShadow: 'md' }}
          >
            <MenuItem onClick={() => handleClose("notes")}>Notes</MenuItem>
            <MenuItem onClick={() => handleClose("navigations")}>Navigations</MenuItem>
            <MenuItem onClick={() => handleClose("personas")}>Personas</MenuItem>
          </MenuList>
        </ClickAwayListener>
      </Popup>
    </div>
  );
}