import * as React from 'react';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import ReactLoading from 'react-loading';

export default function LoadingBubble() {
  return (
    <Box sx={{ maxWidth: '60%', minWidth: 'auto' }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{ mb: 0.25 }}
      >
        <ReactLoading type='bubbles' color='rgb(11, 107, 203)' height={50} width={50} />
      </Stack>
    </Box>
  );
}