import { Stack, Box, Button } from "@mui/joy";
import ExploreTagCard from "../ExploreTagCard";

function BrowseTag({tag}) {
  return (
    <Stack sx={{ flex: 1, width: '100%', px: { xs: 1, lg: 2, xl: 35 }, pb: 3 } }>
        <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box className="tag-filters" sx={{ display: 'flex', flexGrow: 1 }}>
                {/* Filters */}
            </Box>
            <Button size="sm" variant="solid" color="primary">
                Add to Insight
            </Button>
        </Box>
        <Box>
            {/* Loop through Tag.references content */}
            { tag && tag.references && tag.references.map((reference) => {
                return (
                    <Box key={reference.id}>
                        <ExploreTagCard reference={reference} tag={tag} />
                    </Box>
                )
            })}
            
        </Box>
    </Stack>
  );
}

export default BrowseTag;