import { v4 } from 'uuid';

export const emptyPersonaFields = {
  name: "New Persona",
  type: "persona",
  data: [
  {
    id: v4(),
    type: 'bigtext',
    field_title: 'Role',
    field_value: '',
    block_location: 'tr_1_1',
    cantDelete: true
  },
  {
    id: v4(),
    type: 'text',
    field_title: 'Name',
    field_value: '',
    block_location: 'tr_2_1',
    cantDelete: true
  },
  {
    id: v4(),
    type: 'text',
    field_title: 'Age',
    field_value: '',
    block_location: 'tr_2_2'
  },
  {
    id: v4(),
    type: 'text',
    field_title: 'Location',
    field_value: '',
    block_location: 'tr_3_2'
  },
  {
    id: v4(),
    type: 'textarea',
    field_title: 'Bio',
    field_value: '',
    block_location: 'tr_4_1'
  },
  {
    id: v4(),
    type: 'image',
    field_title: 'Image',
    field_value: '',
    block_location: 'tl_1_1',
    cantDelete: true
  },
  {
    id: v4(),
    type: 'quote',
    field_title: 'Quote',
    field_value: '',
    block_location: 'tl_2_1'
  },
  {
    id: v4(),
    type: 'list',
    field_title: 'Values',
    items: [
      "Time",
      "Reliability",
      "Relationships",
      "Innovation",
      "Balance",
      "Responsibility"
    ],
    block_location: 'tr_5_1'
  },
  {
    id: v4(),
    type: 'slider',
    field_title: 'Personality',
    items: [
      {
        left_label: "Extroverted", 
        right_label: "Introverted",
        value: 50
      },
      {
        left_label: "Agreeable", 
        right_label: "Disagreeable",
        value: 30
      },
      {
        left_label: "Conscientious", 
        right_label: "Careless",
        value: 80
      },
      {
        left_label: "Neurotic", 
        right_label: "Emotionally stable",
        value: 100
      },
      {
        left_label: "Open", 
        right_label: "Closed",
        value: 10
      }
    ],    
    block_location: 'tr_5_2'
  },
  {
    id: v4(),
    type: 'list',
    field_title: 'Goals',
    items: [
      "Grow his business",
      "Sell more",
      "Build new relationships"
    ],
    block_location: 'tr_6_1'
  },
  {
    id: v4(),
    type: 'list',
    field_title: 'Pain points',
    items: [
      "No online space to sell his products",
      "Spends too much time looking for new partners"
    ],
    block_location: 'tr_6_2'
  }]
}