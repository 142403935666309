import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function retrieveSitemapsFromFirestore({accountId,selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/sitemaps`)
    .where("project", "==", selectedProjectId ? selectedProjectId : '')
    .orderBy("updated", "desc").get()

    const sitemaps = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      sitemaps.push({
        id: doc.id,
        title: data.title ? data.title : '',
        project: data.project ? data.project : '',
        description: data.description ? data.description : '',
        items: data.items ? data.items : [],
        created: data.created ? data.created.toDate() : null,
        updated: data.updated ? data.updated.toDate() : null
      });
    });

    //console.log("Retrieved features from Firestore:", features);
    return sitemaps;
  } catch (error) {
    console.error("Error retrieving sitemaps from Firestore:", error);
    return [];
  }
}

export default async function saveToFirestore({ sitemap, accountId, selectedProjectId }) {  
  const id = sitemap && sitemap.id ? sitemap.id : null 

  console.log("saveToFirestore with id, and sitemap", id, sitemap)

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/sitemaps`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/sitemaps`).doc(id).update({
          title: sitemap.title,
          description: sitemap.description,
          project: selectedProjectId ? selectedProjectId : '',
          items: sitemap.items,
          updated: new Date(),
        });
        //console.log("Feature updated in Firestore!");
      } catch(error) {
        console.error("Error saving sitemap to Firestore:", error);
      }
    } else {
      console.error("No document found with id ", id)
    } 
  } else {
    try {
      await db.collection(`accounts/${accountId}/sitemaps`).add({
        title: sitemap.title, 
        description: sitemap.description,
        project: selectedProjectId ? selectedProjectId : '',
        items: sitemap.items,
        created: new Date(),
        updated: new Date(),
      });
      //console.log("New feature saved to Firestore!");
    } catch (error) {
      console.error("Error saving sitemap to Firestore:", error);
    }
  }
}

export async function deleteSitemap({ sitemapId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/sitemaps`).doc(sitemapId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Feature deleted from Firestore with ID:", featureId);

      // Optionally, you can return the deleted Feature data if needed
      const deletedSitemapData = snapshot.data();
      return { id: sitemapId, ...deletedSitemapData };
    } else {
      console.error("No document found with ID:", sitemapId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting sitemap from Firestore:", error);
    return null;
  }
}

export { saveToFirestore }