import React from 'react'
import Box from '@mui/joy/Box'

const backgroundColourStrength = 50
const hoverColourStrength = 100
const textColourStrength = 500

export default function TagsListings({tag}) {
  return (
    tag && tag.colour &&
      <>
      <Box
        sx={{
          width: '8px',
          height: '8px',
          borderRadius: '99px',
          bgcolor: tag.colour[textColourStrength],
          marginLeft: 1
        }}
      />
      </>
  )
}