import React, { useEffect, useState } from "react";
import exampleTheme from "./../../Editor/themes/ExampleTheme";
import { LexicalComposer, setEditable } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
// import TreeViewPlugin from "./plugins/TreeViewPlugin";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { LoadTranscriptPlugin } from "./plugins/LoadTranscript";
import TranscriptFormatPlugin from "./plugins/TranscriptFormatPlugin";
import { CustomSentenceNode } from "./CustomSentenceNode";
import { HighlightNode } from "../plugins/nodes/Highlight";
import FloatingHighlightEditorPlugin from './../plugins/FloatingHighlightEditorPlugin';
import FloatingTextFormatToolbarPlugin from './../plugins/FloatingTextFormatToolbarPlugin';
import { HandleSaveTranscript } from "./plugins/HandleSaveTranscript";
import OnChangePlugin from './../plugins/OnChangePlugin';
import { useNotes } from "../../../contexts/notesContext";
//import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
// import { TRANSFORMERS } from "@lexical/markdown";
import { CustomSentenceClickPlugin } from "./CustomSentenceNode";
import { ImageNode } from '../nodes/ImageNode/ImageNode';
import { LoadNotePlugin } from "../plugins/LoadNotePlugin";

function Placeholder() {
  return <div className="editor-placeholder">No text</div>;
}

export default function TranscriptEditor({ content, processedSentences, onSentenceClick, reset }) {

  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  const [isLinkEditMode, setIsLinkEditMode] = useState(false);

  const { onEditorChange, editorRef } = useNotes();

  const editorConfig = {
    // The editor theme
    theme: exampleTheme,
    // Editable or not
    editable: true,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      CustomSentenceNode,
      AutoLinkNode,
      LinkNode,
      HighlightNode,
      ImageNode,
      ListNode,
      ListItemNode,
    ]
  };

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      // console.log('floatingAnchorElem', _floatingAnchorElem);
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <div className="editor-shell">
    <LexicalComposer initialConfig={editorConfig}>
      <div ref={editorRef} className={`editor-container`} tabIndex="3">
        <div className="editor-scroller">
          <div className="editor transcript-editor" tabIndex="3">
            <div className="editor-inner" ref={onRef}>
              <RichTextPlugin
                contentEditable={<ContentEditable className="" />}
                placeholder={processedSentences.length === 0 ? <Placeholder /> : null}
                ErrorBoundary={LexicalErrorBoundary}
              />
              <LoadNotePlugin
                contentSource={content}
                editable={true}
                context="main"
              />
              <LoadTranscriptPlugin processedSentences={processedSentences} reset={reset} />
              <CustomSentenceClickPlugin onSentenceClick={onSentenceClick} />
              <TranscriptFormatPlugin />
              <OnChangePlugin onChange={onEditorChange}/>
              {floatingAnchorElem &&
                <>
                <FloatingHighlightEditorPlugin 
                  noteToDisplay={content}
                  anchorElem={floatingAnchorElem} 
                />
                <FloatingTextFormatToolbarPlugin 
                  anchorElem={floatingAnchorElem} 
                  isLinkEditMode={isLinkEditMode}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
                </>
              }
              {/* <HandleSaveTranscript /> */}
              {/* <MarkdownShortcutPlugin transformers={TRANSFORMERS} /> */}
            </div>
          </div>
        </div>
      </div>
    </LexicalComposer>
    </div>
  );
}