import { List, ListItem, ListItemDecorator } from '@mui/joy';
import Check from '@mui/icons-material/Check';

function PlanPoints ({ points }) {
    return (
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
            {points.map((point, index) => (
                <ListItem key={index}>
                    <ListItemDecorator>
                        {point.icon ? point.icon : <Check />}
                    </ListItemDecorator>
                    {point.title}
                </ListItem>
            ))}
        </List>
    );
};

export default PlanPoints;