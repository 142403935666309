import React from 'react';
import { Box, Card, Typography, Divider, FormLabel, CardOverflow, CardActions, Button, Stack } from '@mui/joy';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../../../../configs/firebaseConfig';
import { v4 } from 'uuid';
import { useAuth } from '../../../../authContext';
import InputFileUpload from '../../MessagesPane/InputFileUpload';
import { getConfig, updateConfig } from '../../../database/configs-db';

function SuperAdminTab({ showSnackbar, setShowSnackbar, snackbarSeverity, setSnackbarSeverity, snackbarMsg, setSnackbarMsg }) {
  const { currentUser } = useAuth()
  const [logo, setLogo] = React.useState('')
  const [loginImage, setLoginImage] = React.useState('')
  const [signupImage, setSignupImage] = React.useState('')
  const [homeImage, setHomeImage] = React.useState('') // Added homeImage field
  const [featuresImage, setFeaturesImage] = React.useState('') // Added featuresImage field
  const [journeysImage, setJourneysImage] = React.useState('') // Added journeysImage field
  const [navigationImage, setNavigationImage] = React.useState('') // Added navigationImage field
  const [pagesImage, setPagesImage] = React.useState('') // Added pagesImage field
  const [personasImage, setPersonasImage] = React.useState('') // Added personasImage field
  const [acceptInviteImage, setAcceptInviteImage] = React.useState('') // Added acceptInviteImage field
  const [forgotPasswordImage, setForgotPasswordImage] = React.useState('') // Added forgotPasswordImage field
  const [imageUpload, setImageUpload] = React.useState(null)
  const [imageUploading, setImageUploading] = React.useState(false)
  const [uploadType, setUploadType] = React.useState('')

  React.useEffect(() => {
    const fetchImages = async () => {
      const config = await getConfig()
      console.log("config", config)
      if (config) {
        setLogo(config.logo || '')
        setLoginImage(config.loginImage || '')
        setSignupImage(config.signupImage || '')
        setHomeImage(config.homeImage || '') // Added homeImage initialization
        setFeaturesImage(config.featuresImage || '') // Added featuresImage initialization
        setJourneysImage(config.journeysImage || '') // Added journeysImage initialization
        setNavigationImage(config.navigationImage || '') // Added navigationImage initialization
        setPagesImage(config.pagesImage || '') // Added pagesImage initialization
        setPersonasImage(config.personasImage || '') // Added personasImage initialization
        setAcceptInviteImage(config.acceptInviteImage || '') // Added acceptInviteImage initialization
        setForgotPasswordImage(config.forgotPasswordImage || '') // Added forgotPasswordImage initialization
      }
    }
    fetchImages()
  }, [])

  console.log("logo", logo)

  const handleSubmit = async () => {
    const updates = {
      logo,
      loginImage,
      signupImage,
      homeImage, // Added homeImage to updates
      featuresImage, // Added featuresImage to updates
      journeysImage, // Added journeysImage to updates
      navigationImage, // Added navigationImage to updates
      pagesImage, // Added pagesImage to updates
      personasImage, // Added personasImage to updates
      acceptInviteImage, // Added acceptInviteImage to updates
      forgotPasswordImage // Added forgotPasswordImage to updates
    }
    try {
      const result = await updateConfig({ updates })
      setSnackbarSeverity('success')
      setSnackbarMsg('Updates saved successfully')
    } catch (error) {
      console.error("Error updating config:", error)
      setSnackbarSeverity('error')
      setSnackbarMsg(`Error saving updates: ${error.message || 'Unknown error'}`)
    }
    setShowSnackbar(true)
  }

  const handleImageUpload = (file, type) => {
    setImageUpload(file)
    setUploadType(type)
  }

  React.useEffect(() => {
    const uploadImage = async (imageUpload) => {
      console.log("imageUpload", imageUpload)
      const imageRef = ref(storage, `public/` + v4())
      try {
        const result = await uploadBytes(imageRef, imageUpload)
        console.log("image uploaded", result)
        const imageUrl = await getDownloadURL(result.ref)
        console.log("downloadUrl", imageUrl)
        switch (uploadType) {
          case 'logo':
            setLogo(imageUrl)
            break
          case 'login':
            setLoginImage(imageUrl)
            break
          case 'signup':
            setSignupImage(imageUrl)
            break
          case 'home': // Added case for homeImage
            setHomeImage(imageUrl)
            break
          case 'features': // Added case for featuresImage
            setFeaturesImage(imageUrl)
            break
          case 'journeys': // Added case for journeysImage
            setJourneysImage(imageUrl)
            break
          case 'navigation': // Added case for navigationImage
            setNavigationImage(imageUrl)
            break
          case 'pages': // Added case for pagesImage
            setPagesImage(imageUrl)
            break
          case 'personas': // Added case for personasImage
            setPersonasImage(imageUrl)
            break
          case 'acceptInvite': // Added case for acceptInviteImage
            setAcceptInviteImage(imageUrl)
            break
          case 'forgotPassword': // Added case for forgotPasswordImage
            setForgotPasswordImage(imageUrl)
            break
        }
        setImageUploading(false)
      } catch (error) {
        console.log("error uploading image", error)
        setImageUploading(false)
      }
    }

    if (imageUpload && imageUpload.name) {
      setImageUploading(true)
      uploadImage(imageUpload)
    }
  }, [imageUpload, uploadType])

  return (
    <Stack
      spacing={4}
      sx={{
        display: 'flex',
        maxWidth: '800px',
        mx: 'auto',
        px: { xs: 2, md: 6 },
        py: { xs: 2, md: 3 },
      }}
    >
      {/* Insert Edit Account Module (if account owner) here */}
      <Card>
        <Box sx={{ mb: 1 }}>
          <Typography level="title-md">Public Assets</Typography>
        </Box>
        <Divider />
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Smrt-Cookie Logo</FormLabel>
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'logo')} size="sm" />
          <Typography level="body-sm">Logo image path: {logo}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Login Page Image</FormLabel>
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'login')} size="sm" />
          <Typography level="body-sm">Image image path: {loginImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Signup Page Image</FormLabel>
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'signup')} size="sm" />
          <Typography level="body-sm">Signup image path: {signupImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Home Page Image</FormLabel> {/* Added homeImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'home')} size="sm" />
          <Typography level="body-sm">Home image path: {homeImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Features Page Image</FormLabel> {/* Added featuresImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'features')} size="sm" />
          <Typography level="body-sm">Features image path: {featuresImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Journeys Page Image</FormLabel> {/* Added journeysImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'journeys')} size="sm" />
          <Typography level="body-sm">Journeys image path: {journeysImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Navigation Page Image</FormLabel> {/* Added navigationImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'navigation')} size="sm" />
          <Typography level="body-sm">Navigation image path: {navigationImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Pages Page Image</FormLabel> {/* Added pagesImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'pages')} size="sm" />
          <Typography level="body-sm">Pages image path: {pagesImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Personas Page Image</FormLabel> {/* Added personasImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'personas')} size="sm" />
          <Typography level="body-sm">Personas image path: {personasImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Accept Invite Page Image</FormLabel> {/* Added acceptInviteImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'acceptInvite')} size="sm" />
          <Typography level="body-sm">Accept Invite image path: {acceptInviteImage}</Typography>
        </Box>
        <Box sx={{ mb: 4 }}>
          <FormLabel>Upload Forgot Password Page Image</FormLabel> {/* Added forgotPasswordImage upload section */}
          <InputFileUpload setFile={(file) => handleImageUpload(file, 'forgotPassword')} size="sm" />
          <Typography level="body-sm">Forgot Password image path: {forgotPasswordImage}</Typography>
        </Box>
        <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
          <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
            <Button onClick={handleSubmit} size="sm" variant="solid" disabled={imageUploading}>
              {imageUploading ? 'Uploading...' : 'Save'}
            </Button>
          </CardActions>
        </CardOverflow>
      </Card>
    </Stack>
  );
}

export default SuperAdminTab;
