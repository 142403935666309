import React, { useState } from "react";
import { Button, Box, Switch, Stack, Typography } from "@mui/joy";
import ToDoCard from "./ToDoCard";
import AddIcon from '@mui/icons-material/Add';
import { useParams, useNavigate } from 'react-router-dom';
import {DndContext,closestCenter} from '@dnd-kit/core';
import {SortableContext} from '@dnd-kit/sortable';
import SortableToDoItem from './SortableToDoItem';
import {
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import FilterToDosByTag from "./FilterToDosByTag";
import { useAuth } from './../../../authContext'; 
import FeedbackSnackbar from './../Snackbar/index';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import { useMultiTasking } from "../../contexts/multiTaskingContext";
import { useTodos } from "../../contexts/todoContext";

function ToDos({ mode = "", panelId }) {
  const { 
    toDos,
    completedToDos,
    filteredToDos,
    showCompleted,
    showOverdue,
    selectedTags,
    setShowCompleted,
    setShowOverdue,
    setSelectedTags,
    setFilteredToDos,
    handleUpdateToDo,
    handleCreateToDo,
    handleDeleteToDo,
    handleDragEnd,
    handleMoveToTop,
  } = useTodos();

  const [pendingValues, setPendingValues] = useState('');
  const [pendingTag, setPendingTag] = React.useState([]);
  
  // Snackbar state
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMsg, setSnackbarMsg] = useState('');

  const { currentUser } = useAuth();
  const { accountId, selectedProjectId } = useParams();
  const navigate = useNavigate();
  const { removePanel } = useMultiTasking();

  const handleToDoUpdate = async (values) => {
    await handleUpdateToDo({ 
      values, 
      accountId: accountId, 
      selectedProjectId: selectedProjectId, 
      currentUser 
    });
  };

  const handleToDoDelete = async (id) => {
    const result = await handleDeleteToDo({ 
      todoId: id, 
      accountId, 
      selectedProjectId 
    });
    
    if (result.success) {
      setSnackbarMsg('To Do deleted successfully');
      setSnackbarSeverity('success');
      setShowSnackbar(true);
    }
  };

  const handleToDoCreate = async () => {
    const result = await handleCreateToDo({ 
      accountId, 
      selectedProjectId 
    });
    
    setSnackbarMsg(result.success ? 'To Do created successfully' : 'Error creating to do');
    setSnackbarSeverity(result.success ? 'success' : 'error');
    setShowSnackbar(true);
  };

  const handleTitleClick = () => {
    // Find and remove the todos panel
    removePanel(panelId);
    const todosPath = `${BASE_PRIVATEAPP_URL}${accountId}/${selectedProjectId}/todos`;    
    navigate(todosPath);
  };

  return (
    <Stack sx={{ backgroundColor: '#f4f1f8', flex: 1, width: '100%', px: { xs: 1, lg: 2, xl: mode === "panel" ? 3 : 35 }, pb: 3 } }>
      <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
      { mode !== "panel" ?
      <Box id="title" sx={{ mt: { lg: 7, xs: 2 }, mr: { lg: 2, xs: 0 }, mb: { lg: 4, xs: 2 }, ml: { lg: 2, xs: 0 }, width: '100%' }} tabIndex={1}>        
        <Typography variant="h6" color="text.secondary" sx={{ fontSize: { xs: '1.6rem', md: '2.25rem' }, mt: 1, ml: 1, textAlign: 'center' }}>
          { showCompleted && completedToDos.length + " Completed To Dos" }
          { !showCompleted && filteredToDos.length > 0 && filteredToDos.length + " Filtered To Dos" }
          { !showCompleted && filteredToDos.length === 0 && toDos.length + " To Dos" }
          </Typography>
      </Box>
      : 
      <Stack 
        direction="row" 
        justifyContent="space-between" 
        alignItems="center"
        sx={{ width: '100%', mt: { lg: 1, xs: 2 }, mr: { lg: 1, xs: 0 }, mb: { xs: 1.5 }, ml: { lg: 0, xs: 0 } }}
      >
        <Typography 
          variant="h6" 
          color="text.secondary" 
          onClick={handleTitleClick}
          sx={{ 
            fontSize: { xs: '1rem', md: '1.5rem' }, 
            mt: 1, 
            ml: 0, 
            textAlign: 'left',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          }}
        >
          { showCompleted && completedToDos.length + " Completed To Dos" }
          { !showCompleted && filteredToDos.length > 0 && filteredToDos.length + " Filtered To Dos" }
          { !showCompleted && filteredToDos.length === 0 && toDos.length + " To Dos" }
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          {/* Filter by overdue */}
          <Typography sx={{ mt: .75 }} component="label" endDecorator={<Switch variant={showOverdue ? 'solid' : 'outlined'} color={showOverdue ? "primary" : "neutral"} onClick={() => setShowOverdue(!showOverdue)} checked={showOverdue} sx={{ ml: 1 }} />}>
            { showOverdue ? "All" : "Overdue" }
          </Typography>
          <Button 
            startDecorator={<AddIcon />} 
            size="sm" 
            variant="solid" 
            color="primary" 
            onClick={handleToDoCreate}
            sx={{ height: 'fit-content' }}
          >
            Add
          </Button>
        </Stack>
      </Stack>
      }
      { mode !== "panel" &&
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box className="done-and-filters" sx={{ display: 'flex', flexGrow: 1 }}>
          <Typography component="label" endDecorator={<Switch variant={showCompleted ? 'solid' : 'outlined'} color={showCompleted ? "primary" : "neutral"} onClick={() => setShowCompleted(!showCompleted)} checked={showCompleted} sx={{ ml: 1 }} />}>
            { showCompleted ? "Show To Do" : "Show Done" }
          </Typography>
          { !showCompleted &&
            <>
            {/* Filter by overdue */}
            <Typography sx={{ ml: 3 }} component="label" endDecorator={<Switch variant={showOverdue ? 'solid' : 'outlined'} color={showOverdue ? "primary" : "neutral"} onClick={() => setShowOverdue(!showOverdue)} checked={showOverdue} sx={{ ml: 1 }} />}>
              { showOverdue ? "Unfilter overdue" : "Filter overdue" }
            </Typography>
            <Box sx={{ ml: 3, display: 'flex', alignItems: 'center' }}>
              <Typography component="span" sx={{ mr: 1 }}>Filter by tag</Typography>
              <FilterToDosByTag 
                toDos={toDos} 
                setFilteredToDos={setFilteredToDos} 
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                pendingTag={pendingTag}
                setPendingTag={setPendingTag}
              />
            </Box>
            {/* Sort by order or overdue date */}


            </>
          }
        </Box>
        <Button startDecorator={<AddIcon />} size="sm" variant="solid" color="primary" onClick={handleToDoCreate}>
          Add
        </Button>
      </Box>
      }
      <Box>
        { showCompleted 
          ?
            completedToDos.length > 0
            ? 
              completedToDos.map(todo => (
                <ToDoCard 
                  key={todo.id} 
                  todo={todo} 
                  mode={mode}
                  handleUpdateToDo={handleToDoUpdate} 
                  pendingValues={pendingValues} 
                  setPendingValues={setPendingValues} 
                  handleDeleteToDo={handleToDoDelete}
                />
              ))
            :
              <Typography variant="plain" sx={{ mt: 1, ml: 1, textAlign: 'center' }}>
                No completed to dos as yet...
              </Typography>
          : 
            <>
            <DndContext onDragEnd={handleDragEnd}>
              <SortableContext items={toDos.map(item => item.id)} collisionDetection={closestCenter} strategy={verticalListSortingStrategy}>
                { filteredToDos.length > 0 ? filteredToDos.map(todo => ( 
                  <SortableToDoItem
                    key={todo.id} 
                    id={todo.id}
                    todo={todo}
                    mode={mode}
                    handleDragEnd={handleDragEnd}
                    handleMoveToTop={handleMoveToTop}
                    handleUpdateToDo={handleToDoUpdate} 
                    pendingValues={pendingValues} 
                    setPendingValues={setPendingValues} 
                    handleDeleteToDo={handleToDoDelete}
                  />
                )) : toDos.map(todo => (
                  <SortableToDoItem
                    key={todo.id} 
                    id={todo.id}
                    todo={todo}
                    mode={mode}
                    handleDragEnd={handleDragEnd}
                    handleMoveToTop={handleMoveToTop}
                    handleUpdateToDo={handleToDoUpdate} 
                    pendingValues={pendingValues} 
                    setPendingValues={setPendingValues} 
                    handleDeleteToDo={handleToDoDelete}
                  />
                ))}
              </SortableContext>
            </DndContext>
            <Box sx={{ mt: 4 }}>
              <Button onClick={handleToDoCreate} startDecorator={<AddIcon />} variant="solid" color="primary" size="lg">Add</Button>
            </Box> 
            </>
        }
      </Box>
    </Stack>
  );
}

export default ToDos;