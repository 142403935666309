import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { deleteSitemap, retrieveSitemapsFromFirestore, saveToFirestore } from '../database/sitemaps-db';
import { useParams } from 'react-router-dom';
import { useContextualPane } from './contextualPane';

// Create the context
const NavigationsContext = createContext();

// Create a provider component
export function NavigationsProvider({ children }) {

  const { accountId, selectedProjectId } = useParams()
  const [ sitemaps, setSitemaps ] = useState()
  const [ open, setOpen ] = useState(false);
  const { activeItem, setActiveItem } = useContextualPane();

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = useState('')

  const handleCreateSitemap = () => {
    setOpen(true)
  }

  const handleShowSnackbar = ({ reason, message }) => {
    if (reason === 'success') {
      setSnackbarSeverity('success')
      setSnackbarMsg(message)
      setShowSnackbar(true)
    }
  }

  const handleUpdateCurrentSitemap = async (values) => {
    // handles remote save
    const response = await saveToFirestore({ sitemap: values, accountId: accountId, selectedProjectId: selectedProjectId })
    ////console.log("response from saving persona", response)
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Sitemap updated'})
    const newSitemaps = await retrieveSitemapsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newSitemaps) {
      setSitemaps(newSitemaps)
    }
  }

  const handleSitemapDeletion = async (values) => {
    // handles remote deletion
    const response = await deleteSitemap({ sitemapId: values.id, accountId: accountId })
    ////console.log("response from saving feature", response)
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Sitemap deleted'})
    const newSitemaps = await retrieveSitemapsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newSitemaps) {
      setSitemaps(newSitemaps)
    }
  }

  const setSitemap = (value) => {
    setActiveItem({ itemType: 'sitemap', item: value })
  }

  const handleSetSitemap = (value) => {
    setActiveItem({ itemType: 'sitemap', item: value })
    setOpen(true)
  }

  useEffect(() => {
    const fetchSitemaps = async () => {
      const results = await retrieveSitemapsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
      // console.log("sitemap fetched", results)
      if (results.length > 0) { setSitemaps(results)} 
    }
    //setActiveItem({ itemType: 'sitemap', item: { title: '', items: [] } })
    fetchSitemaps()
  },[])

  useEffect(() => {
    if (!open) {
      setActiveItem({ itemType: 'sitemap', item: { title: '', items: [] } })
    }
  },[open])

  useEffect(() => {
    //console.log("Selected project changed, refetch personas")
    const fetchData = async () => {
      try {
        const data = await retrieveSitemapsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId});
        return data;
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
        throw error; // Rethrow the error so that the outer catch block can handle it
      }
    };
    if (selectedProjectId) {
      const getData = async () => {
        try {
          const data = await fetchData();
          // if item was deleted, then select something again
          // if item was updated, then maintain selection
          // selectedNote will be emptied on deletion of record, so use this to tell
          if (data.length > 0) {
            setSitemaps(data);
          } else {
            setSitemaps([]);
          }
        } catch (error) {
          // Handle the error if needed
          //console.log("Error fetching features", error)
        }
      };
      getData();
    }
  },[selectedProjectId])

  const value = {
    sitemaps,
    activeItem,
    setSitemaps,
    handleCreateSitemap,
    handleSetSitemap,
    handleUpdateCurrentSitemap,
    handleSitemapDeletion,
    setSitemap,
    open,
    setOpen,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMsg,
    setSnackbarMsg
  };

  return <NavigationsContext.Provider value={value}>{children}</NavigationsContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useNavigations() {
    return useContext(NavigationsContext);
}