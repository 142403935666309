import VersionHistory from "../../../DrawerRight/Notes/VersionHistory";
import { Box } from "@mui/joy";

function SaveStatusAndVersionHistory() {

  return (
    <Box className="versionHistoryBtn" sx={{ 
        background: '#fff', 
        borderRadius: '10px', 
        color: '#bbb', 
        zIndex: 9999,
        px: 1.25, 
        py: 0, 
        display: 'flex', 
        alignItems: 'center', 
        position: 'fixed', 
        bottom: '4%', 
        right: '1%', 
        textAlign: 'right', 
        fontSize: '12px' 
    }}>
        <Box sx={{ mt: .7 }}>
            <VersionHistory />
        </Box>
    </Box>
  )
}

export default SaveStatusAndVersionHistory;
