import { useDroppable } from '@dnd-kit/core';
import { Card, Typography } from '@mui/joy';

export default function ColumnDropZone({ id, setOverDropZone }) {
  const {isOver, setNodeRef} = useDroppable({
    id,
  });

  const style = {
    backgroundColor: isOver ? 'lightblue' : 'white',
    opacity: isOver ? .75 : .5,
    // Add other styles as needed
  };

  return (
    <Card
      style={style}
      data-resizable
      onMouseEnter={() => setOverDropZone(true)}
      onMouseLeave={() => setOverDropZone(false)}
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        m: 1,
        height: '50px',
        opacity: isOver ? 0.5 : 1,
      }}
      ref={setNodeRef}
    >
      <Typography level="title-sm">Drop here</Typography>
    </Card>
  )
};