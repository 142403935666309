import * as React from 'react';
import { Box, Typography, Tabs, TabList, Tab, TabPanel, tabClasses, Button } from '@mui/joy';
import { useNavigate, useLocation } from 'react-router-dom';
import FeedbackSnackbar from './../Snackbar/index';
import SettingsTab from './SettingsTab';
import { redirectToStripeCheckout, retrievePlansFromFirestore } from '../../database/plans-db';
import ComparePlans from '../ComparePlans';
import SubscriptionsTable from '../SubscriptionsTable';
import { useAuth } from '../../../authContext';
import TeamTab from './TeamTab';
import { isAdminAndAbove, isSuperAdmin } from '../../utils/utils';
import SuperAdminTab from './SuperAdminTab';

// Function to parse tiers data from Stripe
function parseTiersData(data) {
  return data.map(item => {
      // Case 1: Flat amount pricing
      if (item.flat_amount !== null) {
          return {
              price_type: 'flat',
              amount: item.flat_amount / 100, // Convert from pennies to pounds
              up_to: item.up_to,  // Only for specific quantity limit if applicable
              decimal_amount: item.flat_amount_decimal
          };
      }
      // Case 2: Per unit pricing
      if (item.unit_amount !== null) {
          return {
              price_type: 'unit',
              amount_per_unit: item.unit_amount / 100, // Convert from pennies to pounds
              decimal_amount: item.unit_amount_decimal
          };
      }
      // Default case: Fallback or unknown type
      return {
          price_type: 'unknown',
          amount: 0
      };
  });
}

export default function MyProfile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [ selectedTab, setSelectedTab ] = React.useState(0)
  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')

  const { currentUser } = useAuth();

  const [monthly, setMonthly] = React.useState(true);
  const [plans, setPlans] = React.useState(null);
  const [freePriceData, setFreePriceData] = React.useState(null);
  const [paidPriceData, setPaidPriceData] = React.useState(null);
  const [teamPriceData, setTeamPriceData] = React.useState(null);
  const [freePlanName, setFreePlanName] = React.useState(null);
  const [paidPlanName, setPaidPlanName] = React.useState(null);
  const [teamPlanName, setTeamPlanName] = React.useState(null);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    // Update the url also with react-router-dom useSearchParams
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('selectedTab', newValue);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  }

  const handleManageSubscription = () => {
    redirectToStripeCheckout();
  }

  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabFromUrl = searchParams.get('selectedTab');
    if (tabFromUrl) {
      // Map the URL parameter to the corresponding tab index
      const tabIndex = {
        'settings': 0,
        'team': 1,
        'plan': 2,
        'billing': 3,
        'super': 4
      }[tabFromUrl.toLowerCase()];
      
      if (tabIndex !== undefined) {
        setSelectedTab(tabIndex);
      }
    }
  }, [location]);

  React.useEffect(() => {
    // if tab is changed then navigate to update the url
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('selectedTab', selectedTab);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  },[selectedTab])

  React.useEffect(() => {
    // If pricing array is greater than 0, then parse it based on the price.metadata.firebaseRole === 'free' || 'paid' || 'team'
    if (plans && plans.length > 0) {
      plans.forEach(plan => {
        if (plan.metadata.firebaseRole === 'free') {
          setFreePlanName(plan.name);
        } else if (plan.metadata.firebaseRole === 'paid') {
          setPaidPlanName(plan.name);
        } else if (plan.metadata.firebaseRole === 'team') {
          setTeamPlanName(plan.name);
        }

        const monthlyItem = plan.prices.find(p => p.interval === 'month');
        const annualItem = plan.prices.find(p => p.interval === 'year');

        const priceData = {
          monthly: monthlyItem || null,
          annual: annualItem || null,
        };

        if (priceData.monthly) {
          priceData.monthly.priceId = priceData.monthly.id;
          priceData.monthlyCost = priceData.monthly.unit_amount / 100;
          priceData.currency = priceData.monthly.currency;
        }

        if (priceData.annual) {
          priceData.annual.priceId = priceData.annual.id;
          priceData.annualCost = priceData.annual.unit_amount / 100;
          priceData.currency = priceData.annual.currency;
        }

        if (priceData.monthly && priceData.monthly.tiers && priceData.monthly.tiers.length > 0) {
          priceData.monthlyFlatAmountData = parseTiersData(priceData.monthly.tiers);
          priceData.monthlyUnitAmountData = parseTiersData(priceData.monthly.tiers);
        }

        if (priceData.annual && priceData.annual.tiers && priceData.annual.tiers.length > 0) {
          priceData.annualFlatAmountData = parseTiersData(priceData.annual.tiers);
          priceData.annualUnitAmountData = parseTiersData(priceData.annual.tiers);
        }

        if (plan.metadata.firebaseRole === 'free') {
          setFreePriceData(priceData);
        } else if (plan.metadata.firebaseRole === 'paid') {
          setPaidPriceData(priceData);
        } else if (plan.metadata.firebaseRole === 'team') {
          setTeamPriceData(priceData);
        }
      });
    }
  }, [plans]);

  React.useEffect(() => {
    async function fetchData() {
      if (plans === null) {
        const retrievedPlans = await retrievePlansFromFirestore();
        setPlans(retrievedPlans);
      }
    }
    fetchData();
  }, []);

  return (
    <Box sx={{ flex: 1, width: '100%' }}>
      <Box
        sx={{
          position: 'sticky',
          top: { sm: -100, md: -110 },
          bgcolor: 'background.body',
        }}
      >
        <Box sx={{ px: { xs: 2, md: 6 } }}>
          <Typography level="h2" component="h1" sx={{ mt: 1, mb: 2 }}>
            Account
          </Typography>
        </Box>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: { xs: 0, md: 4 },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
              Settings
            </Tab>
            {/* Only show if plan is team */}
            {currentUser && currentUser.plan === 'team' && (
              <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
                Team
              </Tab>
            )}
            {/* Only show if role is admin */}
            {currentUser && isAdminAndAbove(currentUser) && (
              <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={2}>
                Plans &amp; Billing
              </Tab>
            )}
            {/* Only show if role is super */}
            {currentUser && isSuperAdmin(currentUser) && (
              <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={4}>
                Super Admin
              </Tab>
            )}
          </TabList>
          <TabPanel value={0}>
            {/* TO DO add settings for account name and avatar */}
            <SettingsTab 
              showSnackbar={showSnackbar}
              setShowSnackbar={setShowSnackbar}
              snackbarSeverity={snackbarSeverity}
              setSnackbarSeverity={setSnackbarSeverity}
              snackbarMsg={snackbarMsg}
              setSnackbarMsg={setSnackbarMsg}
            />
          </TabPanel>
          {currentUser && currentUser.plan === 'team' && (
            <TabPanel value={1}>
              <TeamTab allPlans={plans} />
            </TabPanel>
          )}
          {currentUser && isAdminAndAbove(currentUser) && (
            <TabPanel value={2}>
              <Box sx={{ mx: 5, my: 4, pb: 5 }}>
                <Typography level="h3" sx={{ mb: 2 }} component="h2">Your Subscriptions</Typography>
                <Box sx={{ mt: 5, mb: 8 }}>
                  <SubscriptionsTable allPlans={plans} />
                </Box>
                <Box sx={{ mt: 5 }}>
                  <Typography level="h3" sx={{ mb: 6 }} component="h2">Update Plan</Typography>
                  <ComparePlans 
                    monthly={monthly} 
                    setMonthly={setMonthly} 
                    freePlanName={freePlanName} 
                    paidPlanName={paidPlanName} 
                    teamPlanName={teamPlanName} 
                    freePriceData={freePriceData} 
                    paidPriceData={paidPriceData} 
                    teamPriceData={teamPriceData} 
                  />
                </Box>
              </Box>
            </TabPanel>
          )}
          {currentUser && isSuperAdmin(currentUser) && (
            <TabPanel value={4}>
              <SuperAdminTab 
                showSnackbar={showSnackbar}
                setShowSnackbar={setShowSnackbar}
                snackbarSeverity={snackbarSeverity}
                setSnackbarSeverity={setSnackbarSeverity}
                snackbarMsg={snackbarMsg}
                setSnackbarMsg={setSnackbarMsg}
              />
            </TabPanel>
          )}
        </Tabs>
      </Box>
      <FeedbackSnackbar openSetting={showSnackbar} setShowSnackbar={setShowSnackbar} message={snackbarMsg} colorSetting={snackbarSeverity} />
    </Box>
  );
}