import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

export default function LoadVersionPlugin({ currentNoteState }) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {

    const editorState = editor.getEditorState()
  
    if (editor && currentNoteState) {
      setTimeout(() => {
        editorState.read(() => {
          // set editor
          const newState = editor.parseEditorState(currentNoteState)
          editor.setEditorState(newState)
        })
      }, 0);
    }

    editor.setEditable(false)
  
  },[currentNoteState])

}