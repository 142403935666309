import { useState, useEffect } from 'react';
import ListItem from '@mui/joy/ListItem';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import ProjectModal from './ProjectModal';
import { useProjects } from '../../../../contexts/projectsContext';

export default function SwitchProject() {

  const { 
      accountProjects,
      setAccountProjects,
      projectTitleEditMode,
      setProjectTitleEditMode,
      projectModalOpen,
      setProjectModalOpen,
      selectedProject,
      setSelectedProject,
      projectName,
      setProjectName,
      running,
      setRunning,
      handleEditProjectName,
      handleSingleClickOfProject,
      handleSaveNewProjectName,
      handleOpenProjectModal,
      handleDeleteProject,
      handleNewProject,
      handleSwitchSelectedProject,
      handleCloseEditProjectName
   } = useProjects()  

  return (
    <>
    <ListItem
      className="list-item-force-startend-icon-visibility"
      startAction={
        !projectTitleEditMode &&
        <Box sx={{ borderRadius: '4px', display: 'flex', alignItems: 'center', '&:hover': { ml: "-1px", backgroundColor: '#ece6f2', border: '1px solid #ece6f2' } }}>
          <IconButton aria-label="Switch Project" size="sm" onClick={handleOpenProjectModal}>
            <UnfoldMoreIcon />
          </IconButton>
          {/* <Typography sx={{ ml: 1 }}>Switch Project</Typography> */}
          {
            selectedProject && selectedProject.name && selectedProject.name !== "" 
            ? 
              <Typography sx={{ ml: 1, pr: 4, '&:hover': { cursor: 'pointer' } }} onDoubleClick={handleEditProjectName} onClick={() => handleSingleClickOfProject() } level="title-sm">{selectedProject.name}</Typography>
            :
              <Typography sx={{ ml: 1, pr: 4, '&:hover': { cursor: 'pointer' } }} onDoubleClick={handleEditProjectName} onClick={() => handleSingleClickOfProject() } level="title-sm">Untitled Project</Typography>
          
          }
        </Box>
      }
      endAction={
        projectTitleEditMode ? 
        <>
          <IconButton aria-label="Rename Project" size="sm" onClick={handleSaveNewProjectName}>
            <DoneIcon fontSize='md' />
          </IconButton>
          <IconButton aria-label="Exit Project Edit" size="sm" onClick={handleCloseEditProjectName}>
            <CloseIcon fontSize='md' />
          </IconButton>
        </>
        :
        accountProjects && accountProjects.length > 0 && 
        <IconButton aria-label="Rename Project" size="sm" onClick={handleEditProjectName}>
          <EditIcon fontSize='md' />
        </IconButton>
      }>

      { selectedProject &&
        projectTitleEditMode && (
          <Input
            autoFocus={true}
            onChange={(e) => setProjectName(e.target.value)}
            defaultValue={selectedProject.name}
            size="sm"
            sx={{ mr: 8 }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleSaveNewProjectName();
              } else if (e.keyCode === 27) {
                setProjectTitleEditMode(false);
              }
            }}
          />
        )
      }
    </ListItem>
    <ProjectModal open={projectModalOpen} setOpen={setProjectModalOpen} mode="switch" />
    </>
  )
}