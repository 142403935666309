import React, { useState, useEffect } from 'react';
import { Box } from '@mui/joy';
import Grid from '@mui/joy/Grid';
import { useNotes } from '../../../contexts/notesContext';
import Editor from '../../Editor';
import NotesPaneHeaderEdit from '../../MessagesPane/NotesPaneHeaderEdit';
import { useAuth } from '../../../../authContext';
import NoteTitle from '../../MessagesPane/NoteTitle';
import ModalEditorFrame from '../../Layouts/ModalEditorFrame';
import ModalWithSidePane from '../../Layouts/ModalWithSidePane';
import Sidepane from '../../Sidepane';

export default function NoteEditor() {
    const { selectedNote, noteEditorOpen, setNoteEditorOpen, handleSave, checkNoteEditPermissions } = useNotes();
    const [allowEditing, setAllowEditing] = useState(false);
    const [ sidePaneOpen, setSidePaneOpen ] = React.useState(false)
    const { currentUser } = useAuth();

    useEffect(() => {
      if (currentUser && selectedNote) {
        const canEdit = checkNoteEditPermissions({
          content: selectedNote,
          currentUser,
          isEditorOpen: noteEditorOpen
        });
        setAllowEditing(canEdit);
      }
    }, [currentUser, selectedNote, noteEditorOpen]);

    return (
      <ModalEditorFrame setOpen={setNoteEditorOpen} open={noteEditorOpen}>
        <ModalWithSidePane 
          sidePaneOpen={sidePaneOpen} 
          setSidePaneOpen={setSidePaneOpen} 
          sideWidth='20%' 
          SideContent={<Sidepane />} 
          MainContent={
            <>
            <NotesPaneHeaderEdit 
              sidePaneOpen={sidePaneOpen} 
              setSidePaneOpen={setSidePaneOpen} 
              allowEditing={allowEditing} 
              noteToDisplay={selectedNote}
            />
            <Box>
              <Editor editable={allowEditing} contentSource={selectedNote} />
            </Box>
            </>
          }
        />
      </ModalEditorFrame>
    );
}
