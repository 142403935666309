import database from '../../configs/firebaseConfig'; 
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

const db = database.firestore(); // Access Firestore database
export const DEFAULT_PLAN_ID = "price_1Q7xDIHsOYvVvcv0urPfciI0";

export async function redirectToStripeCheckout(url) {
    const functionRef = database
    .app()
    .functions("europe-west2")
    .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
    const { data } = await functionRef({
        returnUrl: window.location.origin,
        // locale: "auto", // Optional, defaults to "auto"
        //configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
    });
    window.location.assign(data.url);
}

export async function retrievePlansForAccount({ accountId }) {
    const accountDocRef = doc(db, 'accounts', accountId);
    const accountDocSnapshot = await getDoc(accountDocRef);
    if (accountDocSnapshot.exists()) {
        const account = accountDocSnapshot.data();
        // Use the account createdBy field to get the user ID
        const userId = account.createdBy;
        // Use the user ID to get the plans
        const plans = await retrievePlansForUser({ userId });
        return plans;
    }
    return null;
}

export async function retrievePlansForUser({ userId }) {
    console.log("retrievePlansForUser", userId)
    const userDocRef = doc(db, 'users', userId);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
        // Retrieve the subscriptions sub collection from the user's document
        const subscriptionsCollection = collection(userDocSnapshot.ref, "subscriptions");
        const subscriptionsSnap = await getDocs(subscriptionsCollection);
        const subscriptions = subscriptionsSnap.docs.map(doc => doc.data());
        return subscriptions;
    }
    return null;
}

export async function retrievePlansFromFirestore() {
    try {
        const querySnapshot = await db.collection("plans")
            .where("active", "==", true)
            .get();

        const plans = [];

        for (const doc of querySnapshot.docs) {
            const plan = doc.data();

            const priceSnap = await doc.ref.collection("prices").where("active", "==", true).get();
            const prices = priceSnap.docs.map(priceDoc => {
                // Return the priceDoc id also
                return {
                    id: priceDoc.id,
                    ...priceDoc.data()
                };
            });

            plan.id = doc.id;

            plan.prices = prices;
            plans.push(plan);
        }

        return plans;
    } catch (error) {
        console.error("Error retrieving plans:", error);
        throw error;
    }
}

// TO DO Create a subscription given a user ID and selected plan
export async function createSubscription({ userId, priceId }) {
    if (!userId || !priceId) {
        throw new Error("User ID and price ID are required");
    }
    try {
        const docRef = await db
        .collection("users")
        .doc(userId)
        .collection("checkout_sessions")
        .add({
            price: priceId,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
        });
        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
            const { error, url } = snap.data();
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }    
        });
    } catch (error) {
        console.log("Error creating Stripe session", error)
    }
}
