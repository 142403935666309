import database from '../../configs/firebaseConfig'; 
import Firebase from '../../configs/firebaseConfig';
import { checkTeamMembership } from './utilities-db';
import CitationOptions from './../Components/DrawerRight/IncitesForTypeInstance/ManageAssociationModal/SearchAssociationsList/SearchForAssociations/CitationOptions/index';

const db = database.firestore(); // Access Firestore database

// Fetch all records
export async function fetchAssociationsFromFirestore({ accountId }) {
  try {
    const associationsRef = await db.collection(`accounts/${accountId}/associations`).get();
    const associations = associationsRef.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    return { associations }
  }
  catch (error) {
    console.error("Error fetching associations from Firestore:", error);
  }
}

// Add new association to Firestore
export async function addAssociationToFirestore({ accountId, project, association }) {
  try {
    const docRef = await db.collection(`accounts/${accountId}/associations`).add({
      ...association,
      project: project,
      created: new Date(),
      updated: new Date(),
    });
    //console.log("Association added to Firestore!", docRef);
    const doc = await docRef.get();
    const data = doc.data()
    return { ...data, id: doc.id }
  } catch(error) {
    console.error("Error adding association to Firestore:", error);
  }
}

// Update an association in Firestore
export async function updateCitationInStrongAssociation({ accountId, associationId, association }) {
  try {
    // Get the current document
    const docRef = db.doc(`accounts/${accountId}/associations/${associationId}`);
    const doc = await docRef.get();

    if (!doc.exists) {
      // If the document doesn't exist, create it
      await docRef.set({
        ...association,
        created: new Date(),
        updated: new Date(),
      });
    } else {
      // If the document exists, update it
      const data = doc.data();
      if (Array.isArray(data.citations)) {
        //console.log("citations is an array", ...association.citations)
        // If citations is an array, add the new citation object to the last item of the data.citations array
        if (Array.isArray(data.citations)) {
          data.citations.push(...association.citations);
        }
        // data.citations[data.citations.length - 1] = {
        //   ...data.citations[data.citations.length - 1],
        //   ...association.citations,
        // };
      } else {
        //console.log("citations is NOT an array", association.citations)
        // If citations is not an array, replace it with the new citations
        data.citations = association.citations;
      }
      await docRef.update({
        ...data,
        updated: new Date(),
      });
    }

    return { ...association, id: associationId }
  } catch(error) {
    console.error("Error updating association in Firestore:", error);
  }
}

// TO DO: Fetch all citations and definitions that match either a toId or fromId for the given type

// Search associations collection for all fromId's that match the provided fromId and return in a citations array, also
// search fpr all toId's that match the provided toId and return in a definitions array
export async function retrieveAllDefinitionsForId({accountId, typeId}) {
  //console.log("Retrieving all citations and definitions for type: ", typeId, " in account: ", accountId)
  try {
    if (typeId) {
      const definitions = await db.collection(`accounts/${accountId}/associations`)
      .where("associationType", "==", "strong")
      .where("fromId", "==", typeId)
      .get()
      
      // Fetch each of the documents in the citations array
      const definitionDocs = await Promise.all(definitions.docs.map(async doc => {
        const data = doc.data()

        let docRef;
        let docDataForPage;
        let typeData;

        if (data.toType === 'page') {
          const pagesetsRef = await db.collection(`accounts/${accountId}/pagesets`).get();
          const pagesets = pagesetsRef.docs.map(doc => doc.data());

          const pageset = pagesets.find(pageset => 
            pageset.pages.some(page => page.id === data.toId)
          );

          if (pageset) {
            const page = pageset.pages.find(page => page.id === data.toId);
            docDataForPage = page;
          }

          if (docDataForPage) { console.log("docDataForPage: ", docDataForPage)}

        } else {
          docRef = await db.doc(`accounts/${accountId}/${data.toType}s/${data.toId}`).get();
          typeData = docRef.data()
        }

        let citationData = []
        let citationsDataWithReferenceData = []

        if (data.citations && data.citations.length > 0) {
          // Loop through data.citations and fetch each citation
          citationData = await Promise.all(data.citations.map(async citation => {
            const citationRef = await db.doc(`accounts/${accountId}/associations/${citation}`).get()
            return { ...citationRef.data(), id: citationRef.id }
          }))

          // Fetch data for each citationData
          citationsDataWithReferenceData = await Promise.all(citationData.map(async citation => {
            if (citation.referenced && citation.referenced.length > 0) {
              const citationDataWithReferenceData = await Promise.all(citation.referenced.map(async ref => {
                let typeData; // Declare typeData here
                if (ref.from.fromType !== "" && ref.from.fromId !== "") {
                  const docRef = await db.doc(`accounts/${accountId}/${ref.from.fromType}s/${ref.from.fromId}`).get()
                  typeData = docRef.data() // Set typeData here
                }
                if (!typeData) {
                  return { ...ref, referenceData: {} }
                } else {
                  if (ref.from.fromType === "page") {
                    return { ...ref, referenceData: { ...docDataForPage } }
                  } else {
                    return { ...ref, referenceData: { ...typeData } }
                  }
                }
              }))
              return { ...citation, referenced: [ ...citationDataWithReferenceData ]}
            }
          }))
        }

        if (data.toType === 'page') {
          return { ...data, id: doc.id, citationsData: [ ...citationsDataWithReferenceData ], referenceData: { ...docDataForPage } }
        } else {
          return { ...data, id: doc.id, citationsData: [ ...citationsDataWithReferenceData ], referenceData: { ...typeData } }
        }
      }))
      //console.log("Definitions: ", definitions)
      return { definitions: definitionDocs }
    } else {
      return { definitions: [] }
    }
  } catch (error) {
    console.error("Error retrieving definitions: ", error)
  }
}

// Get the doc with associationId, and use the doc.citations array to fetch each citation. Then, use the citation.referenced array to fetch each reference and delete the array item (not the whole record) where referenced.toId === associationId. Only after this, should the association be deleted.
export async function deleteAssociationFromFirestore({ accountId, associationId }) {
  try {
    const docRef = db.doc(`accounts/${accountId}/associations/${associationId}`);
    const doc = await docRef.get();
    const data = doc.data()

    if (data.citations && data.citations.length > 0) {
      // Loop through data.citations and fetch each citation
      const citationData = await Promise.all(data.citations.map(async citation => {
        const citationRef = await db.doc(`accounts/${accountId}/associations/${citation}`).get()
        return { ...citationRef.data(), id: citationRef.id }
      }))

      // Loop through citationData and fetch each referenced
      const referencedData = await Promise.all(citationData.map(async citation => {
        if (citation.referenced && citation.referenced.length > 0) {
          const referencedData = await Promise.all(citation.referenced.map(async ref => {
            if (ref.to.toId === associationId) {
              const docRef = db.doc(`accounts/${accountId}/associations/${citation.id}`);
              // Note this only deletes the reference, not the whole record
              await docRef.update({
                referenced: Firebase.firestore.FieldValue.arrayRemove(ref)
              });
            }
          }));
        }
      }))
    }

    await docRef.delete();
    return { id: associationId }
  } catch (error) {
    console.error("Error deleting association from Firestore: ", error)
  }
}