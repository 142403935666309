import database from '../../configs/firebaseConfig';

const db = database.firestore(); // Access Firestore database

export async function addInvitation({ accountId, email, role, message, inviteBy }) {
    try {
        const invitation = {
            accountId,
            email,
            role,
            status: "pending",
            createdAt: new Date(),
            message,
            inviteBy: inviteBy,
        };

        const invitationDoc = await db.collection(`invitations`).add(invitation);
        return invitationDoc.id;
    } catch (error) {
        console.error("Error adding invitation:", error);
        throw error; // Re-throw the error for the caller to handle
    }
}

export async function retrieveInvitationsForAccount({ accountId }) {
    //console.log("Retrieving invitations for account", accountId);
    try {      
        const userQuery = db.collection(`invitations`).where('accountId', '==', accountId);
        const userDoc = await userQuery.get();
        
        if (userDoc.empty) {
            return [];
        }
        
        const invitations = userDoc.docs.map(doc => ({id: doc.id, ...doc.data()}));
        return invitations;
    } catch (error) {
        console.error("Error retrieving invitations:", error);
        throw error; // Re-throw the error for the caller to handle
    }
}

export async function retrieveAcceptedInvitationsForAccount({ accountId }) {
    const invitations = await retrieveInvitationsForAccount({ accountId });
    return invitations.filter(invitation => invitation.status === "accepted");
}

export async function cancelInvitation({ accountId, invitationId }) {
    try {
        const invitationDoc = await db.collection(`invitations`).doc(invitationId);
        await invitationDoc.delete();
        console.log("Invitation deleted");
    } catch (error) {
        console.error("Error deleting invitation:", error);
        throw error; // Re-throw the error for the caller to handle
    }
}
