import * as React from 'react';
import AspectRatio from '@mui/joy/AspectRatio';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import FormHelperText from '@mui/joy/FormHelperText';
import Input from '@mui/joy/Input';
import IconButton from '@mui/joy/IconButton';
import Textarea from '@mui/joy/Textarea';
import Stack from '@mui/joy/Stack';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Typography from '@mui/joy/Typography';
import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import RadioGroup from '@mui/joy/RadioGroup';
import Radio from '@mui/joy/Radio';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListDivider from '@mui/joy/ListDivider';
import Card from '@mui/joy/Card';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalClose from '@mui/joy/ModalClose';
import DialogContent from '@mui/joy/DialogContent';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import Add from '@mui/icons-material/Add';
import MosCow from '../Tags/MoSCoW';
import TagPicker from '../Tags/Editor/TagPicker';

export default function FeatureModalContent({featureFields, setFeatureFields}) {
  const [newField, setNewField] = React.useState(false)
  const [newFieldTitle, setNewFieldTitle] = React.useState('')
  const [newFieldTextarea, setNewFieldTextarea] = React.useState('')
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [pendingTag, setPendingTag] = React.useState([]);

  const handleUpdateTags = async (tags = pendingTag) => {
    setSelectedTags(tags);
    setFeatureFields({ ...featureFields, tags: tags });
  }
  
  const handleAddNewField = () => {
    setNewFieldTitle('')
    setNewFieldTextarea('')
    setNewField(true)
  }

  const handleTextAreaChange = (e) => {
    setNewFieldTextarea(e.target.value)
    setFeatureFields({ ...featureFields, additionalFields: {...featureFields.additionalFields, [newFieldTitle]: e.target.value } })
  }

  const handleAddField = () => {
    setNewFieldTitle('')
    setNewFieldTextarea('')
    setNewField(false)
  }

  const handleEditField = ({title,value}) => {
    // TO DOs
    // Show newField modal
    setNewField(true)
    // Set title and textarea fields
    setNewFieldTitle(title)
    setNewFieldTextarea(value)
  }

  const handleDeleteField = () => {
    const updatedAdditionalFields = { ...featureFields.additionalFields };
    // Find the title of the property with the given value
    const titleToDelete = Object.keys(updatedAdditionalFields).find(
      (title) => updatedAdditionalFields[title] === newFieldTextarea
    );
    // If a matching title is found, delete the property
    if (titleToDelete) {
      delete updatedAdditionalFields[titleToDelete];

      // Update the state with the modified additionalFields
      setFeatureFields({
        ...featureFields,
        additionalFields: updatedAdditionalFields,
      });
    }

    // Close the modal and wipe the values
    handleAddField()
  }

  React.useEffect(() => {
    if (featureFields) {
      setSelectedTags(featureFields && featureFields.tags ? featureFields.tags : []);
    }
  }
  ,[featureFields])

  return (
    <Box
      sx={{
        flex: 1,
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: {
            sm: -100,
            md: -110,
          },
          zIndex: 9995,
        }}
      >
        <Box
          sx={{
            px: {
              xs: 2,
              md: 6,
            },
          }}
        >
          <Typography
            level="h2"
            sx={{
              mt: 1,
              mb: 2,
            }}
          >
            { featureFields.id ? 'Update Feature' : 'Create Feature' } 
          </Typography>
        </Box>
        <Tabs
          defaultValue={0}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: {
                xs: 0,
                md: 4,
              },
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                flex: 'initial',
                bgcolor: 'transparent',
                [`&.${tabClasses.selected}`]: {
                  fontWeight: '600',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={0}>
              Attributes
            </Tab>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={1}>
              Link
            </Tab>
            <Tab sx={{ borderRadius: '6px 6px 0 0' }} indicatorInset value={2}>
              Comment
            </Tab>
          </TabList>
        </Tabs>
      </Box>

      <Stack
        spacing={4}
        sx={{
          display: 'flex',
          maxWidth: '800px',
          mx: 'auto',
          px: {
            xs: 2,
            md: 6,
          },
          py: {
            xs: 2,
            md: 3,
          },
        }}
      >
        <Card>
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>Feature Title</FormLabel>
                <FormControl
                  sx={{
                    display: {
                      sm: 'flex-column',
                      md: 'flex-row',
                    },
                    gap: 2,
                  }}
                >
                  <Input size="sm" onChange={(e) => setFeatureFields({ ...featureFields, title: e.target.value })} value={featureFields && featureFields.title ? featureFields.title : ''} placeholder="Title" />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        <Card>
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: 'none', md: 'flex' }, my: 1 }}
          >
            <Stack spacing={2} sx={{ flexGrow: 1 }}>
              <Stack spacing={1}>
                <FormLabel>Feature Priority</FormLabel>
                <RadioGroup
                    aria-labelledby="example-payment-channel-label"
                    overlay
                    name="example-payment-channel"
                    defaultValue="Paypal"
                  >
                  <List
                    component="div"
                    variant="outlined"
                    orientation={'row'}
                    sx={{
                      borderRadius: 'sm',
                      boxShadow: 'sm',
                    }}
                  >
                    {['4', '3', '2', '1'].map((value, index) => (
                      <React.Fragment key={value}>
                        {index !== 0 && <ListDivider />}
                        <ListItem>
                          <Radio 
                            onChange={(e) => setFeatureFields({ ...featureFields, priority: e.target.value })} 
                            defaultValue={featureFields && featureFields.priority ? featureFields.priority : '1'} 
                            value={value}
                            id={index} 
                            label={
                              value === '4' ? <MosCow priority={'4'} /> 
                              : value === '3' ? <MosCow priority={'3'} />  
                              : value === '2' ? <MosCow priority={'2'} />  
                              : value === '1' && <MosCow priority={'1'} /> 
                            }/>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </RadioGroup>
              </Stack>
            </Stack>
          </Stack>
        </Card>
        <Card>
          <Box sx={{ mb: 1 }}>
            <Typography level="title-md">Feature Description</Typography>
            <Typography level="body-sm">
              Write a few words about this feature
            </Typography>
          </Box>
          <Divider />
          <Stack spacing={2} sx={{ my: 1 }}>
            <Textarea
              size="sm"
              minRows={4}
              sx={{ mt: 1.5 }}
              onChange={(e) => setFeatureFields({ ...featureFields, description: e.target.value })}
              value={featureFields && featureFields.description ? featureFields.description : ''}
            />
            <FormHelperText sx={{ mt: 0.75, fontSize: 'xs' }}>
              275 characters left
            </FormHelperText>
          </Stack>
        </Card>
        <Card>
          <Box className="show-on-hover" sx={{ ml: .5 }}>
            <TagPicker 
              handleUpdateTags={handleUpdateTags} 
              setPendingTag={setPendingTag}
              pendingTag={pendingTag}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
          </Box>
        </Card>
        {/* Loop through other fields */}
        {featureFields && featureFields.additionalFields && Object.entries(featureFields.additionalFields).map(([title, value], index) => (
          <Card key={index}>
            <Box sx={{ mb: 1 }}>
              <Typography level="title-md">{title}</Typography>
            </Box>
            <Divider />
            <Box>
              <FormControl
                sx={{
                  display: {
                    sm: 'flex-column',
                    md: 'flex-row',
                  },
                  gap: 2,
                }}
              >
                <Typography level="title-sm">{value}</Typography>
                <Button variant="outlined" onClick={() => handleEditField({title:title,value:value})} >Edit</Button>
              </FormControl>
            </Box>
          </Card>
        ))}
        { newField &&
          <React.Fragment>
            <Modal open={newField} onClose={(_event, reason) => {
              setNewField(false);
            }}>
            <ModalDialog layout="center" sx={{ width: 750 }}>
            <ModalClose />
              <Card>
              <Box sx={{ mb: 1 }}>
                <Typography level="title-md">{newFieldTitle !== "" ? newFieldTitle : 'Name your new field'}</Typography>
              </Box>
              <Divider />
              <Box>
                <Input onChange={(e) => setNewFieldTitle(e.target.value)} size="sm" value={newFieldTitle !== "" ? newFieldTitle : ''} placeholder={newFieldTitle === "" && 'Give your new field a title'} />  
                <FormControl
                  sx={{
                    display: {
                      sm: 'flex-column',
                      md: 'flex-row',
                    },
                    gap: 2,
                  }}
                >
                  <Textarea
                    size="sm"
                    minRows={4}
                    sx={{ mt: 1.5 }}
                    defaultValue={newFieldTextarea}
                    id="newTextFieldTextarea"
                    onChange={(e) => handleTextAreaChange(e)}
                    placeholder="Type any text you like"
                  />
                  <Stack direction="row" justifyContent="space-between">
                    <Button variant="soft" color="danger" onClick={() => handleDeleteField()}>Delete</Button>
                    <Button variant="outlined" onClick={() => handleAddField()} startDecorator={<Add />}>Add</Button>
                  </Stack>  
                </FormControl>
              </Box>
            </Card>
            </ModalDialog>
          </Modal>
          </React.Fragment>
        }
        <Box>
          <Button variant="outlined" onClick={() => handleAddNewField()} startDecorator={<Add />}>Create new field</Button>
        </Box>
      </Stack>
    </Box>
  );
}
