import * as React from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import { Box, CardActions } from '@mui/joy';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import Button from '@mui/joy/Button';
import Add from '@mui/icons-material/Add';

export default function DefinitionBanner({handleCreate, title, text, btnTxt, srcImage}) {
  
  return (
    <Card sx={{ minHeight: '200px', minWidth: '100%' }}>
      <CardCover>
        { srcImage &&
        <img
          src={srcImage}
          srcSet={srcImage}
          loading="lazy"
          alt=""
          className='greyscale-image'
        />
        }
      </CardCover>
      <CardCover
        sx={{
          background:
            'linear-gradient(to top, rgba(244,241,248,1), rgba(244,241,248,0) 400px), linear-gradient(to top, rgba(244,241,248,0.9), rgba(244,241,248,0) 700px)',
        }}
      />
      <CardContent sx={{ justifyContent: 'flex-end' }}>
        <Typography level="title-lg" sx={{
          fontSize: '2.5rem',
          background:
          'linear-gradient(-30deg, var(--joy-palette-primary-500), var(--joy-palette-primary-400))',
          // `Webkit*` properties must come later.
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}>
          {title && title}
        </Typography>
        { text &&
        <Typography
          sx={{ width: '40%' }}
        >
          {text ? text : 'No description provided.'}
        </Typography>
        }
      </CardContent>
      <CardActions>
      <Box sx={{ }}>
          <Button size="sm" startDecorator={<Add />} onClick={handleCreate}>
            {btnTxt && btnTxt}
          </Button>
        </Box>
      </CardActions>
    </Card>
  );
}