import database from '../../configs/firebaseConfig'; 
import { checkTeamMembership } from './utilities-db';

const db = database.firestore(); // Access Firestore database

export async function retrieveUserAccountId({ userId }) {
    const userDoc = await db.collection('users').doc(userId).get();
    return userDoc.data().accountId;
}

export async function retrieveUserInvitation({ userId }) {
    const userDoc = await db.collection('users').doc(userId).get();
    if (userDoc.exists) {
      const userData = userDoc.data();
      return { invitationId: userData.invitationId };
    }
    return null;
}

export async function retrieveUserPlans({ userId }) {
    try {
        const userDoc = await db.collection("users").doc(userId).get();
        const subscriptionsSnapshot = await userDoc.ref.collection("subscriptions").get();
        const subscriptions = subscriptionsSnapshot.docs.map(doc => doc.data());
        return subscriptions            
    } catch (error) {
        console.log("Error with retrieving user plans")
    }
}

export async function retrieveUserRole({ userId }) {
    try {
        const userDoc = await db.collection("users").doc(userId).get();
        const userRole = userDoc.data().role;
        return userRole;
    } catch (error) {
        console.log("Error with retrieving user role")
    }
}

export async function updateUser({ userId, name, email, photoURL }) {
    try {
        const userDoc = await db.collection("users").doc(userId).update({ displayName: name, email: email, photoURL: photoURL });
        return userDoc;
    } catch (error) {
        console.log("Error with updating user")
    }
}
