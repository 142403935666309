import React from 'react'
import { Box, Typography } from '@mui/joy'

export default function NavigationTile({ title, description, link }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        p: 2,
        borderRadius: 1,
        boxShadow: 1,
        backgroundColor: '#F4F1F8',
        '&:hover': {
          backgroundColor: '#F4F1F8',
          cursor: 'pointer',
        },
      }}
      onClick={() => window.location.href = link}
    >
      <Box>
        <Typography level="title-md">{ title }</Typography>
        <Typography level="body-md">{ description }</Typography>
      </Box>
    </Box>
  )
}