import React from 'react'
import { Box, Button, Grid, Sheet, Switch, Typography } from '@mui/joy'
import { SortableTree } from '../../../SitemapTree';
import PreviewSitemap from './Preview';

function renderLayout(layout, data, setData) {
    switch(layout) {
        case "default":
            return <SortableTree removable indicator collapsible sitemap={data} setSitemap={setData} />;
        case "preview":
            return <SortableTree indicator collapsible sitemap={data} setSitemap={setData} showAddBtn={false} mode={"preview"} />;
        default:
            return null;
    }
}

export default function SitemapLayout({ layout = "default", data, setData }) {

    return (
        renderLayout(layout, data, setData)
    )
  
}