// AvatarSelector.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Stack from '@mui/joy/Stack';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import { Box, Button, Typography } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Grid from '@mui/joy/Grid';
import CardOverflow from '@mui/joy/CardOverflow';
import AspectRatio from '@mui/joy/AspectRatio';
import { Tabs, TabList, TabPanel, Tab } from '@mui/joy';
import SvgIcon from '@mui/joy/SvgIcon';
import { styled } from '@mui/joy';
import { base64ToBlob } from '../../../utils/utils';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from "firebase/storage"
import { v4 } from 'uuid';
import { storage } from '../../../../configs/firebaseConfig';
import { set } from 'date-fns';
import { useAuth } from './../../../../authContext';  
import IconButton from '@mui/joy/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const AvatarSelector = ({ onAvatarSelect }) => {
  const {currentUser} = useAuth()
  const [imageList, setImageList] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const [searchPersona, setSearchPersona] = React.useState('')
  const [imageUpload,setImageUpload] = useState(null);
  const [uploading, setUploading] = useState(false);

  console.log("existing images", existingImages)

  const fetchImages = async () => {
    //console.log("search term is ", searchPersona)
    try {
      const response = await axios.get(
        `https://api.unsplash.com/search/photos?query=${searchPersona}&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
      );
      //console.log("response updated", response)

      setImageList(response.data.results);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  const fetchExistingPersonaAvatars = async () => {
    const listRef = ref(storage, `users/` + currentUser._delegate.uid + `/personaAvatars/`)
    const existingImages = await listAll(listRef)
    if (existingImages.items.length > 0) {
      const imageUrls = await Promise.all(existingImages.items.map(item => getDownloadURL(item)));
      setExistingImages(imageUrls);
    } else {
      setExistingImages([]);
    }
  }

  const onFileUploadChangeHandler = (file) => {
    const reader = new FileReader();
    reader.onloadend = function() {
      setImageUpload(reader.result);
    }
    reader.readAsDataURL(file);
  }

  const handleSearch = () => {
    fetchImages()
  }

  useEffect(() => {
    fetchImages();
    fetchExistingPersonaAvatars();
  },[])

  useEffect(() => {
    if (imageUpload && imageUpload !== null) {
      setUploading(true)
      try {  
        // Split the base64 string into data and actual base64 parts
        const parts = imageUpload.split(';base64,');
        const mimeType = parts[0].split(':')[1];
        const imageBase64 = parts[1];
  
        // Convert base64 to Blob
        const blob = base64ToBlob(imageBase64, mimeType);
  
        const imageRef = ref(storage, `users/` + currentUser._delegate.uid + `/personaAvatars/` + v4() )

        uploadBytes(imageRef, blob).then((result) => {
          const downloadUrl = getDownloadURL(result.ref).then((imageUrl) => {
            handleAvatarSelect(imageUrl)            
          })
        })
        setUploading(false)
      } catch (error) {
        setUploading(false)
      }        
    }
  },[imageUpload])

  useEffect(() => {
    console.log("Uploading", uploading)
  },[uploading])

  const handleAvatarSelect = (imageUrl) => {
    onAvatarSelect(imageUrl);
  };

  const handleDeleteImage = async (imageUrl) => {
    const imageRef = ref(storage, imageUrl)
    try {
      await deleteObject(imageRef); // Use deleteObject instead of delete
      fetchExistingPersonaAvatars();
    } catch (error) {
      console.log("Error deleting image", error)
    }
  }

  const VisuallyHiddenInput = styled('input')`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  return (
    <>
    <Tabs
      aria-label="Pipeline"
      defaultValue={'findImage'}
    >
      <TabList variant="plain">
        <Tab value="findImage">
          Find image
        </Tab>
        { existingImages && existingImages.length > 0 &&
        <Tab value="selectImage">
          Select image
        </Tab>
        }
        <Tab value="uploadImage">
          Upload image
        </Tab>
        <Tab disabled>
          Generate image (coming soon)
        </Tab>
      </TabList>

      {/* SELECT IMAGE */}
      { existingImages && existingImages.length > 0 &&
      <TabPanel value="selectImage">
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">Select an image from the list below</Typography>
          <Grid container spacing={2} justifyContent="flex-start">
            {existingImages && existingImages.length > 0 && existingImages.map((image,key) => (
              <Grid item key={key} xs={12} sm={4} md={4} lg={4}>
                <Card variant="outlined">
                  <CardOverflow>
                    <AspectRatio minHeight="120px" maxHeight="200px">
                      <img
                        src={image}
                        alt={image}
                        style={{ maxWidth: 300, cursor: 'pointer' }}
                        onClick={() => handleAvatarSelect(image)}
                      />
                    </AspectRatio>
                  </CardOverflow>
                  <CardContent>
                    <IconButton onClick={() => handleDeleteImage(image)}>
                      <DeleteIcon />
                    </IconButton>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </TabPanel>
      }

      {/* FIND IMAGE */}
      <TabPanel value="findImage">
        <Box sx={{ p: 2 }}>
          <Stack>
            <FormControl>
              <FormLabel>Search for images on UnSplash</FormLabel>
              <Input onChange={(e) => setSearchPersona(e.target.value)} size="md" onKeyDown={(e) => {if (e.key === 'Enter') { handleSearch(); } }} endDecorator={<Button onClick={handleSearch}>Search</Button>} />
            </FormControl>
          </Stack>
          {imageList && imageList.length > 0 && (
            <>
            <h2>Choose an Avatar</h2>      
            </>
          )}
          <Box>
            <Grid container spacing={2} justifyContent="flex-start">
              {imageList && imageList.length > 0 && imageList.map((image,key) => (
                <Grid item key={image.id} xs={12} sm={4} md={4} lg={4}>  
                  <Card variant="outlined">
                    <CardOverflow>
                      <AspectRatio minHeight="120px" maxHeight="200px">
                        <img
                          key={image.id}
                          src={image.urls.small}
                          alt={image.alt_description}
                          style={{ maxWidth: 300, cursor: 'pointer' }}
                          onClick={() => handleAvatarSelect(image.urls.small)}
                        />
                      </AspectRatio>
                    </CardOverflow>
                  </Card>
                </Grid>
              ))}
              </Grid>
          </Box>
        </Box>
      </TabPanel>

      {/* UPLOAD IMAGE */}
      <TabPanel value="uploadImage">
        <Box sx={{ p: 2 }}>
        <Button
          component="label"
          role={undefined}
          tabIndex={-1}
          variant="outlined"
          color="neutral"
          startDecorator={
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
            </SvgIcon>
          }
        >
          Upload a file
          <VisuallyHiddenInput type="file" onChange={(event => { onFileUploadChangeHandler(event.target.files[0])}) } />
        </Button>
        </Box>
      </TabPanel>
    </Tabs>
    </>
  );
};

export default AvatarSelector;