import { useEffect, useState } from "react";
import { Modal, ModalDialog, DialogTitle, Box, FormControl, FormLabel, Input, Button } from "@mui/joy";
import { useParams } from "react-router-dom";
import { useNotes } from "../../../contexts/notesContext";

function MakePrivate({ item, makePrivateModal, setMakePrivateModal }) {
  const [password, setPassword] = useState('');
  const { accountId } = useParams();
  const { handleMakeNotePrivate } = useNotes();

  const handleSetPassword = async () => {
    console.log("handleSetPassword", password)
    console.log("for item ", item)
    if (item.type === "note") {
      await handleMakeNotePrivate({ noteId: item.id, password })
      setMakePrivateModal(false)
    }
    else if (item.type === "link" || item.type === "upload") {
      await handleMakeNotePrivate({ noteId: item.id, password })
      setMakePrivateModal(false)
    }
  }

  useEffect(() => {
    setPassword('')
  }, [makePrivateModal])

  return (
    <Modal open={makePrivateModal} onClose={() => setMakePrivateModal(false)}>
      <ModalDialog size="md" sx={{ width: 600 }}>
        <DialogTitle>Privacy settings</DialogTitle>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl>
            <FormLabel sx={{ mb: .75 }}>Set password to view file</FormLabel>
            <Input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          {/* TO DO If team account, have an option to hide this file from other team members in listings and search and any emails sent to them */}
          <Button
            variant={password ? "solid" : "outlined"}
            color={password ? "primary" : "neutral"}
            disabled={!password}
            size="sm"
            onClick={() => handleSetPassword()}
          >
            Set Password
          </Button>
        </Box>
      </ModalDialog>
    </Modal>
  )
}

export default MakePrivate;