import { useState } from "react";
import { Link, Box, Button, Card, IconButton, Select, Option, Input, Textarea } from "@mui/joy"
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { saveToDoToFirestore } from "../../../../../database/todos-db";
import { useParams, useNavigate } from "react-router-dom";
import FeedbackSnackbar from './../../../../Snackbar/index';
import { BASE_PRIVATEAPP_URL } from './../../../../../../redirects/index';

function ToDoResults({ result, setResult }) {
  const { accountId, selectedProjectId } = useParams();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMsg, setSnackbarMsg] = useState("");
  const navigate = useNavigate();

  const deleteToDo = (index) => {
    // Delete item from result array based on index
    const updatedResult = result.filter((_, i) => i !== index);
    setResult(updatedResult);
  }

  const handleClickLink = (id) => {
      // TO DO - need to handle a passed in feature
      navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/todos/');
  }

  const addToDo = async (todo,index) => {
    // save to firestore
    try {
      await saveToDoToFirestore({
        todo: {
          text: todo.text,
          isCompleted: todo.isCompleted,
          dueBy: new Date(),
          order: 1,
          created: new Date(),
          updated: new Date(),
          project: selectedProjectId
        }, 
        accountId, 
        selectedProjectId
      })
      // on success of adding to firestore, show toaster and delete from result
      setSnackbarMsg(<span>
        <Link size="sm" sx={{ fontSize: 14, textDecoration: 'underline', color: "var(--joy-palette-common-white)" }} onClick={() => handleClickLink()}>To do added</Link> successfully
      </span>)
      setSnackbarSeverity("success")
      setShowSnackbar(true)
      deleteToDo(index)
    } catch (error) {
      console.error("Error saving to do to Firestore:", error);
    }    
  }

  return (
    result && result.length > 0 && result.map((todo, index) => (
      <>
      <Card color="neutral" variant="plain" sx={{ p: 3, pr: 2, mb: 1, boxShadow: 'md', backgroundColor: 'var(--joy-palette-background-card-surface)' }} key={index}>
        <Box sx={{ display: 'flex', mb: 1.5, alignItems: 'center' }}>
          {/* <Input sx={{ width: '85%' }} variant="plain" color="neutral" size="md" value={todo.text} /> */}
          <IconButton onClick={() => { deleteToDo(index)}} variant="plain" color="neutral" size="sm" sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Textarea size="md" sx={{ mb: 1.5 }} color="neutral" variant="plain" defaultValue={todo.text} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Button onClick={() => { addToDo(todo,index) }} variant="plain" color="primary">
            <Box sx={{ mt: .5, mr: 1.25, alignItems: "center" }}>
              <AddIcon />
            </Box> Add                
          </Button>
        </Box>
      </Card>
      <FeedbackSnackbar openSetting={showSnackbar} showSnackbar={showSnackbar} setShowSnackbar={setShowSnackbar} colorSetting={snackbarSeverity} message={snackbarMsg} />
      </>
    ))
  )
}

export default ToDoResults