import React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Textarea from '@mui/joy/Textarea';
import IconButton from '@mui/joy/IconButton';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import FormatBold from '@mui/icons-material/FormatBold';
import FormatItalic from '@mui/icons-material/FormatItalic';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Check from '@mui/icons-material/Check';
import { useContextualPane } from '../../../../../contexts/contextualPane';
import CommentTextArea from './CommentTextArea';
import InfoIcon from '@mui/icons-material/Info';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Alert from '@mui/joy/Alert';
import Typography from '@mui/joy/Typography';

export default function AddComment() {

    const { 
        handleSetNewDraftAssociation, 
        newDraftAssociation, 
        handleSubmitNewAssociationToDb, 
        setProgressStep,
        secondaryAssociationMode,
        handleStepFourSubmitSecondary
    } = useContextualPane();

    const [selectedValue, setSelectedValue] = React.useState('');

    const handleChange = (event) => {
        //console.log("handleChange", event.target.value)
        setSelectedValue(event.target.value);
    };

    const handleStepFourSubmit = () => {
        handleSubmitNewAssociationToDb({ ...newDraftAssociation })
    }

    const handleStepFourBack = () => {
        //console.log("handleStepFourBack")
        setProgressStep(3)
    }

    const handleStepFourAddaLink = () => {
        //console.log("handleStepFourAddaLink")
        setProgressStep(2)
    }

    return (
        <>
        <CommentTextArea handleChange={handleChange} />
        {/* List existing pending links */}
        <Box sx={{ display: 'flex', gap: 2, width: '100%', flexDirection: 'column' }}>
            <Alert
                key={1}
                sx={{ mt: 1, alignItems: 'flex-start' }}
                startDecorator={<InfoIcon />}
                variant="soft"
                color='neutral'
                endDecorator={
                    <IconButton variant="soft" color='neutral'>
                    </IconButton>
                }
            >
                <div>
                    <div>Link title</div>
                    <Typography level="body-sm" color='neutral'>
                    This is a time-sensitive Alert.
                    </Typography>
                </div>
            </Alert>
        </Box>
        <Box sx={{ display: 'flex', mt: 2, justifyContent: 'space-between' }}>
            { secondaryAssociationMode ?
                <>
                <Button onClick={() => handleStepFourAddaLink(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="primary">
                    Add a link
                </Button>
                <Button onClick={() => handleStepFourSubmitSecondary(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                    Add Association
                </Button>
                </>
            :
                <>
                <Button onClick={() => handleStepFourAddaLink(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-start', maxWidth: 'fit-content' }} variant="outlined" color="primary">
                    Add a link
                </Button>
                <Button onClick={() => handleStepFourSubmit(selectedValue)} sx={{ mt: 2, alignSelf: 'flex-end', maxWidth: 'fit-content' }} variant="solid" color="primary">
                    Add Association
                </Button>
                </>
            }
        </Box>
        </>
    )
}