import React, { useState } from 'react';
import { Alert, IconButton } from '@mui/joy';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InfoIcon from '@mui/icons-material/Info';

export default function Info() {
  const [ alertOpen, setAlertOpen ] = useState(true)

  return (
    alertOpen &&
    <Alert 
      variant="outlined" 
      startDecorator={<InfoIcon />}
      endDecorator={
        <IconButton onClick={ () => setAlertOpen(!alertOpen) } variant="soft" color='neutral'>
          <CloseRoundedIcon />
        </IconButton>
      }>
      Click and drag pages to change their order and depth, double-click on a title to change.
    </Alert>
  );
}