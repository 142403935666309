import { Box, Typography, Input } from '@mui/joy'
import { useNotes } from '../../contexts/notesContext'

function NoteTitle({mode = "view", noteToDisplay}) {
    const { titleRef, updateTitle, handleOpenNoteInEditor } = useNotes();

    return (
        mode === "view" ?
            <Box 
                onClick={() => handleOpenNoteInEditor(noteToDisplay)} 
                id="title" 
                sx={{ mt: 0, mb: 2, mr: 2, ml: { xl: 1.25, xs: 0 }, width: '90%', cursor: 'pointer' }} 
                tabIndex={1}
            >
                <Typography variant="h6" color="text.secondary" sx={{ fontSize: { xs: '1.6rem', md: '2.25rem' }, mt: 1, ml: 1 }}>{noteToDisplay.noteTitle ? noteToDisplay.noteTitle : ""}</Typography>
            </Box>
        : mode === "edit" &&
        <Box id="title" sx={{ mt: 2, mb: 2, mr: 2, ml: { xl: 2, xs: 0 }, width: '90%' }} tabIndex={1}>
            <Input 
                ref={titleRef} 
                sx={{ border: 'none', fontSize: { xs: '1.6rem', md: '2.25rem' }, boxShadow: 'none', backgroundColor: '#fff' }} 
                size="lg" 
                defaultValue={noteToDisplay.noteTitle} 
                placeholder={ noteToDisplay.noteTitle ? noteToDisplay.noteTitle : "Title this note"} 
                onChange={updateTitle} 
            />
        </Box>
    )
}

export default NoteTitle