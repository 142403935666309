import React from 'react';
import { Box } from '@mui/joy';
import { usePersonas } from '../../../contexts/personasContext';
import PersonaEditorLayout from '../components/Layouts';

function PersonaPane({ persona }) {
  const { setPersonaFields } = usePersonas();
  // console.log("PersonaPane", persona)

  return (
    <Box sx={{ p: 2, height: '100%', minHeight: '100%', overflow: 'auto' }}>
      <PersonaEditorLayout personaFields={persona.data} setPersonaFields={setPersonaFields} editChecked={false} />
    </Box>
  );
}

export default PersonaPane;