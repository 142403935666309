import React, { useState, useEffect, createContext, useContext } from 'react';

// Create the context
const DrawerRightContext = createContext();

// Possible reasons for DrawerRight
const reasons = {
  reason: 'noteVersions',
}

// Create a provider component
export function DrawerRightProvider({ children }) {
    const [drawerRightOpen, setDrawerRightOpen] = useState(false);
    const [reason, setReason] = useState('')

    const toggleDrawer = () => {  
      setDrawerRightOpen(!drawerRightOpen);
    };
    
    useEffect(() => {
        //console.log("drawerRightOpen ", drawerRightOpen)
    },[drawerRightOpen])

    useEffect(() => {
        //console.log("reason ", reason)
        if (drawerRightOpen) {
          // Check reason
          // If reason = noteVersions, then load noteVersionsContent
          if (reason === 'noteVersions') {
            //console.log("Loading note versions content")
            // TO DO: Load note versions from DB
          } else if (reason === 'incites') {
            //console.log("Loading incites content")
            // TO DO: 
          }
        }
    },[reason])

    const value = {
        drawerRightOpen,
        setDrawerRightOpen,
        toggleDrawer,
        reason,
        setReason,
    };

  return <DrawerRightContext.Provider value={value}>{children}</DrawerRightContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function useDrawerRight() {
  return useContext(DrawerRightContext);
}