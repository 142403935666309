import React, { useState } from 'react';
import { useAuth } from '../../../authContext'; 
import { useNavigate, useParams } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';
import { DEFAULT_PLAN_ID } from '../../../PrivateSite/database/plans-db';
import { checkUserExistsbyEmail } from '../../../PrivateSite/database/utilities-db';
import Alert from '@mui/joy/Alert';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';
import { jwtDecode } from 'jwt-decode';
import database from '../../../configs/firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import FeedbackSnackbar from '../../../PrivateSite/Components/Snackbar';
import { getConfig } from '../../../PrivateSite/database/configs-db';

const db = database.firestore(); // Access Firestore database

export default function Signup() {

  const { signup } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [continueClicked, setContinueClicked] = useState(false);
  const [retryAttempt, setRetryAttempt] = useState(0);
  const [banner, setBanner] = useState('')
  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = React.useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = React.useState('')

  const stripeCheckout = async (user) => {
    console.log("stripe checkout called")
    // Fetch the user's accountId and projectId
    const userDocRef = doc(db, 'users', user._delegate.uid);
    const userDocSnapshot = await getDoc(userDocRef);
    if (userDocSnapshot.exists()) {
      try {      
        const docRef = await db
        .collection("users")
        .doc(user._delegate.uid)
        .collection("checkout_sessions")
        .add({
          price: DEFAULT_PLAN_ID,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        });

        // Wait for the CheckoutSession to get attached by the extension
        docRef.onSnapshot((snap) => {
          const { error, url } = snap.data();
          if (error) {
            // Show an error to your customer and
            // inspect your Cloud Function logs in the Firebase console.
            console.log(`An error occured: ${error.message}`);
            // price_1Q7xDIHsOYvVvcv0urPfciI0
            setShowSnackbar(true);
            setSnackbarSeverity('danger');
            setSnackbarMsg('There was an error our side, please refresh the page and try again.');
          }
          if (url) {
            // We have a Stripe Checkout URL, let's redirect.
            window.location.assign(url);
          }
        });
      } catch (error) {
        console.error('Error creating checkout session', error);
      }
    } else {
      if (retryAttempt < 8) {
        console.log("user doc does not exist yet, retrying in 1.5 seconds")
        setRetryAttempt(retryAttempt + 1);
        setTimeout(stripeCheckout, 1500, user);
      } else {
        console.log("user doc does not exist yet, and retry attempts are exhausted")
        setRetryAttempt(0);
        setShowSnackbar(true);
        setSnackbarSeverity('danger');
        setSnackbarMsg('There was an error our side, please refresh the page and try again.');
      }
    }
  };

  const handleSignup = async (e) => {
    //console.log("handle signup")
    e.preventDefault();

    setContinueClicked(true);
    try {
      const { user } = await signup(email, password);
      try {
        console.log("user signed up, now creating checkout session", user)
        // Step 5: Create a checkout session
        stripeCheckout(user)
      } catch (error) {
        console.error('Error creating checkout session', error);
      }
    } catch (error) {
      if (error.code == 'auth/email-already-in-use') {
        setError('There is already an account with this email. Would you like to log in instead?');
      } else if (error.code == 'auth/invalid-email') {
        setError('The email was invalid. Please retry entering your email address.');
      } else {
        setError('There was an error our side, please try again.');
      }
      console.error(error.code);
    }
  };

  React.useEffect(() => {
    getConfig().then(config => setBanner(config.signupImage))
  }, [])

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: '#F4F1F8',
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}
        >
          <Header />
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >
            <Stack gap={4} sx={{ mb: 2 }}>
              <Stack gap={1}>
                <Typography level="h3">Sign up</Typography>
                <Typography level="body-sm">
                  Existing user of smrt cookie?{' '}
                  <Link to="/login" level="title-sm">
                    Sign in
                  </Link>
                </Typography>
              </Stack>

              {/* <Button
                variant="soft"
                color="neutral"
                fullWidth
                startDecorator={<GoogleIcon />}
                onClick={() => loginWithGoogle()}
              >
                Continue with Google
              </Button> */}

            </Stack>

            { error !== '' &&
              <Alert variant='soft' color="warning">
                {error}
              </Alert>
            }
            <Stack gap={4} sx={{ mt: 2 }}>
              <form
                onSubmit={handleSignup}
              >
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </FormControl>
                <FormControl required>
                  <FormLabel>Password</FormLabel>
                  <Input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </FormControl>
                <Stack gap={4} sx={{ mt: 2 }}>
                  <Button loading={continueClicked} loadingIndicator="Loading..." disabled={continueClicked} color={continueClicked ? "neutral" : "primary"} type="submit" fullWidth>
                    {continueClicked ? "Submitted" : "Continue"}
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © smrt cookie {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',

          backgroundColor: 'background.level1', // Ivory: FFFFF0, Lavender: F4F1F8
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            `url(${banner})`,
          [theme.getColorSchemeSelector('dark')]: {
            backgroundImage:
              `url(${banner})`,
          },
        })}
      />
      <FeedbackSnackbar
        openSetting={showSnackbar}
        setShowSnackbar={setShowSnackbar}
        variantSetting={snackbarSeverity}
        colorSetting={snackbarSeverity}
        message={snackbarMsg}
      />
    </CssVarsProvider>
  );
}