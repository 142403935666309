import React from 'react';
import ReactDOM from 'react-dom';
import { Card, CardContent, CardOverflow, Box, Typography } from '@mui/joy';
import LinkIcon from '@mui/icons-material/Link';

export default function JourneyLinks({ illustrationOnly = false, links, ...props }) {
    return (
        illustrationOnly ?
            <Card
                className="journey-step-card"
                data-resizable
                sx={{
                textAlign: 'left',
                alignItems: 'center',
                m: 1,
                overflow: 'auto',
                borderLeft: `4px solid #A0D663`,
                '--icon-size': '100px',
                }}
            >
                <CardOverflow variant="soft" color="neutral">
                    <CardContent sx={{ maxWidth: '40ch', whiteSpace: 'normal', position: 'relative' }}>
                        <Box display="flex" sx={{ mb: 1 }}>
                            <Box>
                                <LinkIcon />
                            </Box>
                            <Box className="boardTitleEle" sx={{ flex: 1, overflow: 'auto' }}>
                                <Typography level="title-sm" sx={{ ml: 1, pr: 3 }}>Link title</Typography>
                            </Box>
                        </Box>
                    </CardContent>
                </CardOverflow>
            </Card>
        :
            <h1>Test</h1>
    )
}