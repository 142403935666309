import React from 'react'
import { Box, Button, Grid, Sheet, Switch, Typography } from '@mui/joy'
import PersonaEditorDefaultLayout from './Default';
import UserJourneyGrid from './Default';
import PreviewJourneyGrid from './Preview';

function renderLayout(viewPane, setViewPane, layout, data, setData, columns, columnsSpan, width, columnsOffset, setColumnsOffset) {
    switch(layout) {
        case "default":
            return <UserJourneyGrid columnsOffset={columnsOffset} setColumnsOffset={setColumnsOffset} viewPane={viewPane} setViewPane={setViewPane} initialData={data} setInitialData={setData} columns={columns} columnsSpan={columnsSpan} defaultWidth={width} />;
        case "preview":
            return <PreviewJourneyGrid columnsOffset={columnsOffset} setColumnsOffset={setColumnsOffset} viewPane={viewPane} setViewPane={setViewPane} initialData={data} layout={layout} columns={columns} columnsSpan={columnsSpan} defaultWidth={width} />;
        default:
            return null;
    }
}

export default function JourneyEditorLayout({ columnsOffset, setColumnsOffset, viewPane, setViewPane, layout = "default", data, setData, columns, columnsSpan, width }) {

    return (
        renderLayout(viewPane, setViewPane, layout, data, setData, columns, columnsSpan, width, columnsOffset, setColumnsOffset)
    )
  
}