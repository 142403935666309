import LabelIcon from '@mui/icons-material/Label'; // tag
import RouteIcon from '@mui/icons-material/Route'; // user journey
import WebIcon from '@mui/icons-material/Web'; // content
import LinkIcon from '@mui/icons-material/Link'; // link
import SignpostIcon from '@mui/icons-material/Signpost'; // user flow
import ArticleIcon from '@mui/icons-material/Article'; // templates
import AccountTreeIcon from '@mui/icons-material/AccountTree'; // sitemap
import AddReactionIcon from '@mui/icons-material/AddReaction'; // feature
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'; // notes
import HighlightIcon from '@mui/icons-material/Highlight'; // highlight
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // persona
import BoltIcon from '@mui/icons-material/Bolt'; // incite
import NorthWestIcon from '@mui/icons-material/NorthWest'; // Open Create Docs (collapsed)
import SouthIcon from '@mui/icons-material/South'; // Close Create Docs (expanded)

export const definitionTypes = [
    {
        "name" : "features",
        "label": "Features",
        "icon" : <AddReactionIcon />
    },
    {
        "name" : "notes",
        "label": "Notes",
        "icon" : <AssignmentRoundedIcon />
    },
    {
        "name" : "personas",
        "label": "Personas",
        "icon" : <PersonAddIcon />
    },
    {
        "name" : "journeys",
        "label": "Journeys",
        "icon" : <RouteIcon />
    },
    {
        "name" : "flows",
        "label": "Flows",
        "icon" : <SignpostIcon />
    },
    {
        "name" : "navigations",
        "label": "Navigations",
        "icon" : <AccountTreeIcon />
    },
    {
        "name" : "pages",
        "label": "Pages & Content",
        "icon" : <WebIcon />
    },
]