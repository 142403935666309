import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      "smrt-cookie.com",
      "www.smrt-cookie.com",
      "noteduxai.vercel.app",
    ],
    silent: true,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // Add these lines
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV !== 'production', // Enable debug mode in non-production environments
    beforeSend(event) {
      if (event.exception) {
        console.error("Sentry caught an exception:", event.exception);
      }
      return event;
    },
    // Add this to handle transport errors
    transport: options => {
      const defaultTransport = Sentry.makeFetchTransport(options);
      return {
        ...defaultTransport,
        send: async (request) => {
          try {
            return await defaultTransport.send(request);
          } catch (error) {
            console.error("Sentry transport error:", error);
            return Promise.resolve({});
          }
        }
      };
    }
  });
} else {
  // Mock Sentry for development
  window.Sentry = {
    init: () => {},
    captureException: () => {},
    captureMessage: () => {},
    // Add other Sentry methods you use in your app
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
