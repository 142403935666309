import { v4 } from 'uuid';

const rowId1 = v4();
const rowId2 = v4();
const rowId3 = v4();
const columnId1 = v4();
const columnId2 = v4();
const columnId3 = v4();
const columnId4 = v4();
const columnId5 = v4();
const columnId6 = v4();
const columnId7 = v4();

// Initial Items
export const initialDefaultData = [{
  title: "",
  description: "",
  columns: 16, 
  columnsSpan: 2,
  items: [
    {
      id: rowId1,
      type: "rowHeaders", // rowHeaders | "topHeader" | "row" | "rowSpacer" | "rowHeader" | "columnCell"
      types: "",
      children: [
        {
          id: columnId2,
          title: "Search",
          type: "topHeader",
        },
        {
          id: columnId3,
          title: "Compare",
          type: "topHeader",
        },
        {
          id: columnId4,
          title: "Buy",
          type: "topHeader",
        },
        {
          id: columnId5,
          title: "Delivery",
          type: "topHeader",
        },
        {
          id: columnId6,
          title: "Beyond",
          type: "topHeader",
        },
        {
          id: columnId7,
          title: "Beyond",
          type: "topHeader",
        },
      ]
    },
    {
      id: rowId2,
      type: "rowHeader", // "topHeader" | "row" | "rowSpacer" | "rowHeader" | "columnCell"
      title: "Links",
      types: "journeyStepCard", // each row can only have one set type, this is set at the row level. // journeyStepCard | journeyExperienceLine | journeyHighlightCard | journeyImage | journeyLink | journeyTouchpoint
      children: [
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",
              satisfaction: "somewhatnot",
              title: "Filtering out items with low stock",
              description: "This step filters out items that are low in stock from the list of items to be displayed."
            },
            {
              id: v4(),
              type: "journeyStepCard",
              satisfaction: "satisfied",
              title: "Finding opportunities to upsell",
              description: "This step finds opportunities to upsell items to the customer."
            },
          ],
        },
        
        {
          id: v4(),
          type: "columnCell", 
          children: [
            {
              id: v4(),
              type: "journeyStepCard",
              satisfaction: "neutral",
              title: "Filtering out items with low ratings",
              description: "This step filters out items that have low ratings from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",
              satisfaction: "neutral",
              title: "Filtering out items with low ratings",
              description: "This step filters out items that have low ratings from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",            
              satisfaction: "neutral",
              title: "Filtering out items with low ratings",
              description: "This step filters out items that have low ratings from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",            
              satisfaction: "neutral",
              title: "Filtering out items with low ratings",
              description: "This step filters out items that have low ratings from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",   
              satisfaction: "neutral",
              title: "Filtering out items with low ratings",
              description: "This step filters out items that have low ratings from the list of items to be displayed."
            }
          ],
        },
      ]
    },
    {
      id: rowId3,
      type: "rowHeader", // "topHeader" | "row" | "rowSpacer" | "rowHeader" | "columnCell"
      title: "Data Points",
      types: "journeyStepCard", // each row can only have one set type, this is set at the row level. // journeyStepCard | journeyExperienceLine | journeyHighlightCard | journeyImage | journeyLink | journeyTouchpoint
      children: [
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",   
              satisfaction: "not",
              title: "Filtering out items with low stock",
              description: "This step filters out items that are low in stock from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",   
              satisfaction: "somewhatnot",
              title: "Filtering out items with low stock",
              description: "This step filters out items that are low in stock from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",   
              satisfaction: "somewhatnot",
              title: "Filtering out items with low stock",
              description: "This step filters out items that are low in stock from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",   
              satisfaction: "somewhatnot",
              title: "Filtering out items with low stock",
              description: "This step filters out items that are low in stock from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            {
              id: v4(),
              type: "journeyStepCard",   
              satisfaction: "somewhatnot",
              title: "Filtering out items with low stock",
              description: "This step filters out items that are low in stock from the list of items to be displayed."
            }
          ],
        },
        {
          id: v4(),
          type: "columnCell",
          children: [
            // {
            //   id: v4(),
            //   type: "journey-step-card",   
            //   satisfaction: "somewhatnot",
            //   title: "Filtering out items with low stock",
            //   description: "This step filters out items that are low in stock from the list of items to be displayed."
            // }
          ],
        },
      ]
    },
  ]
}]