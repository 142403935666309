import database from '../../configs/firebaseConfig';

const db = database.firestore(); // Access Firestore database

export async function retrieveUsersForAccount({ accountId }) {
    try {
        const userQuery = db.collection("users").where("accountId", "==", accountId);
        const userDoc = await userQuery.get();
        
        if (userDoc.empty) {
            console.log("No users found for account", accountId);
            return [];
        }
        
        const users = userDoc.docs.map(doc => ({id: doc.id, ...doc.data()}));
        return users;
    } catch (error) {
        console.error("Error retrieving users:", error);
        throw error; // Re-throw the error for the caller to handle
    }
}

export async function retrieveUserByEmail({ email }) {
    const userQuery = db.collection("users").where("email", "==", email);
    const userDoc = await userQuery.get();
    return userDoc.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function retrieveActiveUsersForAccount({ accountId }) {
    const users = await retrieveUsersForAccount({ accountId });
    return users.filter(user => user.status === "active");
}