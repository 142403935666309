import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import equals from 'fast-deep-equal'
import { useNotes } from "../../../contexts/notesContext";
import { useDockableContent } from "../../../contexts/dockableContentContext";

export function LoadNotePlugin({editable, context, contentSource}) {
  const [editor] = useLexicalComposerContext()
  const { setNoteTitle } = useNotes()

  useEffect(() => {
    if (!contentSource || !contentSource.editorState) return;
    
    const editorState = editor.getEditorState()
  
    setTimeout(() => {
      editorState.read(() => {
        // Set title if it exists
        if (contentSource.noteTitle) {
          setNoteTitle(contentSource.noteTitle)
        }
        
        // Set editor content
        const newState = editor.parseEditorState(contentSource.editorState)
        editor.setEditorState(newState)
      })
    }, 0);

    editor.setEditable(editable)
  
  }, [contentSource, editable])
}
