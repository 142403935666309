import * as React from 'react';
import { useRef, useEffect } from 'react';
import { Box, Typography, Sheet, Button } from '@mui/joy';
import NotesPane from '../MessagesPane/NotesPane';
import ListPane from './ListPane';
import NoteHeader from './NoteHeader.js';
import BinnedNotesHeader from './BinnedNotesHeader.js';
import { useNotes } from '../../contexts/notesContext.js';
import NoteEditor from './../Modals/NoteEditor/index';
import { useTheme } from '@mui/joy';

import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";

export default function NotesPage({ binPageDefaultValue = false, content }) {
  const { selectedNote, binPage } = useNotes();
  const [showLeftPanel, setShowLeftPanel] = React.useState(true);
  const [showRightPanel, setShowRightPanel] = React.useState(true);
  const theme = useTheme();
  const isLargeScreen = window.matchMedia('(min-width: 1200px)').matches;
  const isMediumScreen = window.matchMedia('(min-width: 900px)').matches;


  // Use content if provided, otherwise use selectedNote
  const noteToDisplay = content || selectedNote;

  return (
    <Sheet sx={{ flex: 1, width: '100%', height: '100%' }}>
      <Box sx={{ 
        display: { xs: 'none', sm: 'none', md: 'flex' },
        height: '100%',
        backgroundColor: '#F4F1F8',
      }}>
        <PanelGroup direction="horizontal" id="notes-panel-group">
          {showLeftPanel && !content && (
            <>
              <Panel
                id="left-panel"
                collapsible={true}
                defaultSize={isLargeScreen ? 25 : 35}
                minSize={0}
                maxSize={isLargeScreen ? 25 : 35}
                order={1}
              >
                <Sheet className="list-pane" sx={{ p: 1, pt: 0, backgroundColor: '#F4F1F8' }}>
                  {binPage ? (
                    <>
                      <BinnedNotesHeader />
                      {noteToDisplay && <ListPane />}
                    </>
                  ) : (
                    <>
                      <NoteHeader />
                      {noteToDisplay && <ListPane />}
                    </>
                  )}
                </Sheet>
              </Panel>
              <PanelResizeHandle />
            </>
          )}
          
          <Panel
            id="middle-panel"
            defaultSize={content ? 100 : 40}
            minSize={0}
            maxSize={100}
            order={2}
          >
            <Box sx={{ p: 0 }}>
              <NotesPane showSidePane={showLeftPanel} content={content} />
            </Box>
          </Panel>
        </PanelGroup>
      </Box>

      {/* Mobile view */}
      <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
        <NotesPane showSidePane={showLeftPanel} content={content} />
      </Box>

    </Sheet>
  );
}
