import * as React from 'react';
import { Transition } from 'react-transition-group';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Input from '@mui/joy/Input';
import SitemapModalContent from './SitemapModalContent';
import Stack from '@mui/joy/Stack';
import CardActions from '@mui/joy/CardActions';
import CardOverflow from '@mui/joy/CardOverflow';
import Button from '@mui/joy/Button';
import SwitchToLinks from '../LinksComponent/components/SwitchToLinks';
import SaveDropdown from '../Buttons/SaveDropdown';
import { retrieveTemplatesFromFirestore } from '../../database/saveAsTemplate-db';
import { useParams } from 'react-router-dom';
import Templates from '../Selects/Templates';

export default function SitemapModal({ open, setOpen, handleUpdateCurrentSitemap, handleSitemapDeletion, sitemap, setSitemap }) {
  const [ viewPane, setViewPane ] = React.useState(0)
  const [ data, setData ] = React.useState([])
  const { accountId, selectedProjectId } = useParams()

  const fetchData = async () => {
    const result = await retrieveTemplatesFromFirestore({ accountId, selectedProjectId, type: "sitemap" })
    if (result){  
        setData(result)
    }        
  }

  const handleUpdateSitemapItems = (passedItems) => {
    setSitemap({ ...sitemap, items: [...passedItems] })
  }

  React.useEffect(() => {
    fetchData()
  },[])

  return (
    <React.Fragment>
      <Transition in={open} timeout={400}>
        {(state) => (
          <Modal
            
          keepMounted
            open={!['exited', 'exiting'].includes(state)}
            onClose={() => setOpen(false)}
            slotProps={{
              backdrop: {
                sx: {
                  opacity: 0,
                  backdropFilter: 'none',
                  transition: `opacity 400ms, backdrop-filter 400ms`,
                  ...{
                    entering: { opacity: 1, backdropFilter: 'blur(2px)' },
                    entered: { opacity: 1, backdropFilter: 'blur(2px)' },
                  }[state],
                },
              },
            }}
            sx={{
              visibility: state === 'exited' ? 'hidden' : 'visible',
            }}
          >
            <ModalDialog
              layout={ 'fullscreen' }
              sx={{
                opacity: 0,
                backgroundColor: '#f4f1f8',
                transition: `opacity 300ms`,
                ...{
                  entering: { opacity: 1 },
                  entered: { opacity: 1 },
                }[state],
              }}
            >
              <DialogContent sx={{ width: '60%', m: '0 auto' }}>
                <SitemapModalContent viewPane={viewPane} sitemap={sitemap} setSitemap={setSitemap} open={open} setViewPane={setViewPane} />
              </DialogContent>
              <Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                  <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', pt: 2 }}>
                    <div style={{ flex: 1 }}>
                      <Button size="lg" sx={{ mr: 2 }} variant="outlined" color="neutral" onClick={() => setOpen(false)}>
                        Cancel
                      </Button>
                      { sitemap && sitemap.id &&
                      <Button size="lg" variant='outlined' color="danger" onClick={() => handleSitemapDeletion({...sitemap})}>
                        Delete navigation
                      </Button>
                      }
                    </div>
                    {/* INSERT SWITCH
                    <div style={{ flex: 1 }}>
                      <SwitchToLinks viewPane={viewPane} setViewPane={setViewPane} />
                    </div> */}
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                      <Templates type="sitemap" data={data} fetchData={fetchData} updateDataHandler={handleUpdateSitemapItems} /> 
                      <SaveDropdown type="sitemap" fetchData={fetchData} saveHandler={handleUpdateCurrentSitemap} fields={sitemap} id={sitemap && sitemap.id ? sitemap.id : null} />
                    </div>
                  </CardActions>
                </CardOverflow>
              </Stack>
            </ModalDialog>
          </Modal>
        )}
      </Transition>
    </React.Fragment>
  );
}