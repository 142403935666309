import { Sheet, Box, Typography, ModalClose } from "@mui/joy";
import { useDockableContent } from "../../contexts/dockableContentContext";
import { useNotes } from "../../contexts/notesContext";
import DockedNote from "./Notes";
import DockedLink from "./Links";
import DockedUpload from "./Uploads";

function DockContentPanel({ children }) {
  const { dockableContentOpen, dockableContentVisibility, dockableContentType } = useDockableContent();

  return (
    <Sheet
      sx={{
        alignItems: 'center',
        px: 1.5,
        maxHeight: dockableContentVisibility ? '500px' : 50,
        overflowY: dockableContentVisibility ? 'auto' : 'hidden',
        py: 1.5,
        ml: 'auto',
        width: { xs: '86dvw', md: 600 },
        flexGrow: 1,
        border: '1px solid',
        borderRadius: '8px 8px 0 0',
        // backgroundColor: 'background.level1',
        borderColor: 'neutral.outlinedBorder',
        boxShadow: 'lg',
        zIndex: 1000,
        position: 'fixed',
        bottom: 0,
        right: 24,
        transform: dockableContentOpen ? 'translateY(0)' : 'translateY(100%)',
        transition: 'transform 0.3s ease',
      }}
    >
      { dockableContentType === 'note' &&
        <DockedNote />
      }
      { dockableContentType === 'link' &&
        <DockedLink />
      }
      { dockableContentType === 'upload' &&
        <DockedUpload />
      }
    </Sheet>
  );
}

export default DockContentPanel;