import React, { useEffect } from 'react';
import FeatureCard from './FeatureCard';
import { Stack, Box, List, ListItem, ListItemContent, ListItemButton } from '@mui/joy';

function FeaturesListCards({ features, handleSetFeature }) {
  useEffect(() => {
    //console.log("features inside FeatureCards", features);
  }, [features]);

  const handleFeatureClick = (feature) => {
    handleSetFeature(feature)
  }

  return (
    features && features.map((feature, index) => (
      // If yes, start a new row with List, ListItem, and ListItemContent
      <Box key={index}>
        {/* <ListItem>
          <ListItemButton variant="soft" sx={{ pt: 1, pb: 1, borderRadius: 10, bgcolor: 'transparent' }} onClick={() => handleFeatureClick(feature)}>
            <ListItemContent>                   */}
              {/* Render your content here */}
              <FeatureCard handleFeatureClick={handleFeatureClick} feature={feature} />                  
            {/* </ListItemContent>
          </ListItemButton>
        </ListItem> */}
      </Box>
    ))
  );
}

export default FeaturesListCards;
