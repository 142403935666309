import React from 'react'
import { Box, AspectRatio, Modal, ModalOverflow, ModalDialog, DialogContent, Typography, Button } from '@mui/joy'
import AvatarSelector from '../../Avatar'

export default function ImageComponent({ gridColumns, data, id, updateHandler, deleteHandler, editMode = true }) {

  const [changePersonaImageModal, setChangePersonaImageModal] = React.useState(false)

  const onAvatarSelect = (imageUrl) => {
    updateHandler(prevState => {
      const updatedArray = prevState.map(item => {
        if (item.id === id) {
          return { ...item, field_value: imageUrl };
        }
        return item;
      });
    
      return updatedArray;
    });
    setChangePersonaImageModal(false);
  };

  const handleEdithumbnail = () => {
    //console.log("Thumbnail clicked")
    setChangePersonaImageModal(true)
  }

  return (
    <>
    <Box sx={{
      borderBottom: data.block_location !== 'tl_1_1' ? '1px solid #e0e0e0' : 'none',
      ml: gridColumns === 1 ? 0 : 4, 
      mr: gridColumns === 1 ? 0 : 4, 
      pb: 2.5
    }}>
    {data.field_value ? (
      <AspectRatio
        ratio="4/6"
        maxHeight={344}
        sx={{ flex: 1, minWidth: 120, borderRadius: '100%', borderRadius: 8, cursor: editMode ? 'pointer' : 'default' }}
        onClick={editMode ? handleEdithumbnail : null}
      >
        <img
          src={data.field_value}
          srcSet={data.field_value}
          loading="lazy"
          alt=""
        />
      </AspectRatio>
    ) : (
      <AspectRatio
        ratio="4/6"
        maxHeight={344}
        sx={{ flex: 1, minWidth: 120, borderRadius: 8, cursor: 'pointer', '&:hover img': { opacity: 0.8 } }}
        onClick={editMode ? handleEdithumbnail : null}
      >
        <img
          src="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286"
          srcSet="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?auto=format&fit=crop&w=286&dpr=2 2x"
          loading="lazy"
          alt=""
          style={{ opacity: 0.8 }}
        />
        { editMode ?
          <Button size='sm' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} onClick={handleEdithumbnail}>Add Image</Button>
        : <Typography level="title-md" sx={{ position: 'absolute', top: '5%', left: '8%', transform: 'translate(-5%, -8%)' }}>Switch to edit to choose image</Typography> }
      </AspectRatio>
    )}
    </Box>
    <Modal open={changePersonaImageModal} onClose={() => setChangePersonaImageModal(false)}>
      <ModalOverflow>
        <ModalDialog
        sx={{
          width: '40%',
          minHeight: 200,
        }}
        >
          <DialogContent>
            <AvatarSelector onAvatarSelect={onAvatarSelect} />
          </DialogContent>
        </ModalDialog>
      </ModalOverflow>
    </Modal>
    </>
  )
}