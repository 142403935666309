import Chip from '@mui/joy/Chip';
import { Avatar, Tooltip, Badge, Button, IconButton, Link } from '@mui/joy';
import { blue, red, pink, purple, indigo, lightBlue, teal, green, lightGreen, lime, yellow, orange, brown, grey } from '@mui/material/colors';
import BoltIcon from '@mui/icons-material/Bolt'; // incite
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../redirects';

function TagInstances({ tag, textColourStrength }) {
    return (
        <Badge 
            size="sm"
            badgeContent={
                tag && tag.references ?  tag.references.length : 0
            }
            badgeInset="6px -8px 0 0"
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            slotProps={{
                badge: {
                    sx: {
                        backgroundColor: tag.colour[textColourStrength],  // Custom background color
                        color: '#fff', // You may want to set text color for better contrast
                        boxShadow: 'none',
                        border: 'none',
                    },
                },
            }}>
            <BoltIcon />
        </Badge>
    )
}

export default function TagChip({ tag, textColourStrength = 500 }) {
    const navigate = useNavigate();
    const { accountId, selectedProjectId } = useParams();

    const handleExploreTagClick = (e, tag) => {
        e.stopPropagation();
        console.log('Explore tag:', tag);
        // redirect to explore tag page
        navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/tags/b/' + tag.boardId + '/g/' + tag.groupId + '/t/' + tag.id);
    }

    return (
        <>
        { tag && tag.colour &&
            <Tooltip variant="outlined" color="neutral" key={tag.id} size="sm" title={`Explore tag`} arrow>
                <IconButton 
                    onClick={(e) => handleExploreTagClick(e, tag)}
                >
                    <Chip 
                        endDecorator={tag && tag.references && tag.references.length > 0 && <TagInstances textColourStrength={textColourStrength} tag={tag} />} 
                        size="sm" 
                        sx={{ border: 'none', px: 1.5, pr: tag && tag.references && tag.references.length > 0 ? 3: 2, py: .5, color: '#fff', borderRadius: 6, fontWeight: 600, backgroundColor: tag.colour[textColourStrength] }} 
                        variant="soft"
                    >
                        {tag.title}
                    </Chip>
                </IconButton>
            </Tooltip>
        }
        </>
    )
}