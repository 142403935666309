import Home from "./Pages/Home";
import Signup from "./Pages/Signup";
import Login from "./Pages/Login";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ForgotPassword from "./Pages/ForgotPassword";
import AcceptInvite from "./Pages/AcceptInvite";
import { palette } from "../incitefulTheme";
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import '../PrivateSite/styles/styles.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useAuth } from "../authContext"; 
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAccountId, fetchSelectedProjectId } from "../PrivateSite/database/utilities-db";
import { BASE_PRIVATEAPP_URL } from "../redirects";

const theme = extendTheme({ 
  fontFamily: {
    display: '"Poppins", var(--joy-fontFamily-fallback)',
    body: '"Poppins", var(--joy-fontFamily-fallback)',
  },
  body: {
    backgroundColor: '#F4F1F8',
  },
  colorSchemes: {
    light: { palette },
    dark: { palette },
  },
});

export default function PublicSite() {

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {

    try {
      if (currentUser._delegate.uid) {
        const userDetails = await fetchAccountId(currentUser._delegate.uid)
        if (userDetails) {
          const selectedProjectDetails = await fetchSelectedProjectId(currentUser._delegate.uid)
          //console.log("selectedProjectDetails", selectedProjectDetails)
          if (selectedProjectDetails) { 
            navigate(BASE_PRIVATEAPP_URL + userDetails + '/' + selectedProjectDetails)
          } else {
            //console.log("No selected project for user" + result.user.uid)
            console.error('There has been an issue logging in, please try doing so via the login page.');
          }
        } else {
          //console.log("No matching account for user" + result.user.uid)
        }
      }
    } catch (error) {
      console.error(error.message);
      console.error(error.code);
    }
  };

  useEffect(() => {
    // if user is logged in, redirect to dashboard
    if (currentUser) {
      console.log("User logged in ", currentUser)
      handleLogin()
    } else {
      console.log("User not logged in ", currentUser)
    }
  },[currentUser])

  return (
    <CssVarsProvider disableTransitionOnChange theme={theme}>
      <CssBaseline />
      <HelmetProvider>
      <Helmet>
          <title>The Notes App for Digital Product People: smrt cookie</title>
          <meta name="description" content="The Notes App for Digital Product People: smrt cookie" />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/accept-invite" element={<AcceptInvite />} />
      </Routes>
      </HelmetProvider>
    </CssVarsProvider>      
  )
}
