import React from 'react'
import { styled } from '@mui/joy/styles'
import Dropdown from '@mui/joy/Dropdown'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import IconButton from '@mui/joy/IconButton'
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded'
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded'
import Input from '@mui/joy/Input'
import ChooseColourSelector from './ChooseColourSelector'
import Box from '@mui/joy/Box'

export default function EditTagDropdown({ setCurrentlyEditingGroup, handleDeleteTag, handleTagTitleChange, handleTagColourChange, handleSaveTagTitle, tag, groupId }) {
  
  const [ open, setOpen ] = React.useState(false)

  const handleOpenChange = React.useCallback((event, isOpen) => {
    setOpen(isOpen);
  }, []);
  
  const handleClickOnDropdown = () => {
    //console.log("handleClickOnDropdown")
    setCurrentlyEditingGroup(groupId)
  }
  return (
    <Dropdown onOpenChange={handleOpenChange} open={open}>
      <MenuButton
        variant="plain"
        size="sm"
        sx={{
          maxWidth: '32px',
          maxHeight: '32px',
          borderRadius: '9999999px',
        }}
        onClick={handleClickOnDropdown}
      >
        <IconButton
          component="span"
          variant="plain"
          color="neutral"
          size="sm"
        >
          <MoreVertRoundedIcon />
        </IconButton>
      </MenuButton>
      <Menu
        placement="bottom-end"
        size="sm"
        sx={{
          zIndex: '99999',
          p: 1,
          maxWidth: '175px',
          gap: 1,
          '--ListItem-radius': 'var(--joy-radius-sm)',
        }}
      >
        {/* INSERT TITLE INPUT */}
        <Input
          autoFocus={true}
          onChange={(e) => handleTagTitleChange(e.target.value)}
          defaultValue={tag.title}
          size="sm"
          sx={{ flexGrow: 1 }}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              handleSaveTagTitle(tag, groupId);
              setOpen(false);
            }
          }}
        />
        {/* INSERT COLOUR SWITCHER */}
        <Box>
          <ChooseColourSelector colourStrength={400} groupId={groupId} setOpen={setOpen} handleColourChange={handleTagColourChange} tag={tag} selectedColour={tag.colour} />
        </Box>
        {/* INSERT COPY TO */}
        {/* INSERT MOVE TO */}
        {/* DELETE */}
        <MenuItem sx={{ color: 'danger' }} onClick={() => { setOpen(false); handleDeleteTag(tag,groupId)} }>
          <DeleteRoundedIcon />
          Delete tag
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}