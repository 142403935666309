import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Card, Sheet, CardOverflow, Input, RadioGroup, Radio } from '@mui/joy';
import { useContextualPane } from '../../../../contexts/contextualPane';
import AddReactionIcon from '@mui/icons-material/AddReaction'; // feature
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // persona
import RouteIcon from '@mui/icons-material/Route'; // user journey
import BoltIcon from '@mui/icons-material/Bolt'; // incite
import SignpostIcon from '@mui/icons-material/Signpost'; // user flow
import SouthIcon from '@mui/icons-material/South'; // Close Create Docs (expanded)

function ChildContent ({modalMode}) {

  const { 
    setManageAssociationModalOpen, 
    setProgressStep, 
    setSecondaryAssociationMode, 
    setSecondNewDraftAssociation, 
    quickAddMode, 
    setQuickAddMode, 
    createDocsOpen, 
    setCreateDocsOpen, 
    activeItem, 
    newDraftAssociation, 
    setNewDraftAssociation, 
    handleAddingTypeAndAssociation 
  } = useContextualPane();

  const [inputValue, setInputValue] = React.useState('');

  const handleChangeOfInput = (event) => {
    //console.log("handleChange", event.target.value)
    setInputValue(event.target.value);
  }

  const handleAddCitationInline = () => {
    //console.log("handleAddCitationInline")
    setSecondaryAssociationMode(true)
    setSecondNewDraftAssociation({
      fromId: '',
      fromType: '',
      toType: quickAddMode,
      toId: '',
      associationType: 'citation',
    })
    setProgressStep(2)
    setManageAssociationModalOpen(true)

  }

  useEffect(() => {
    // Clear selectedValue on each change of quickAddMode
    setInputValue('')
  },[quickAddMode])

  return (
    <Sheet sx={{ p: 3, mt: 0, borderRadius: 'sm', backgroundColor: '#fff' }}>
        <Box>
          <CardOverflow>
            { !modalMode &&
            <Box sx={{ display: 'flex', mb: 2, flexDirection: 'row', justifyContent: "space-between" }}>
               <Typography level="body-lg" sx={{ mt: 0, ml: 0, maxWidth: 'fit-content' }}>What do you want to create?</Typography>
                <Button variant="outlined" onClick={() => setCreateDocsOpen(false)} sx={{ alignItems: 'flex-end', whiteSpace: 'nowrap' }} startDecorator={<SouthIcon />}>
                  Minimize
                </Button>
            </Box>
            }
          </CardOverflow>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <RadioGroup
              orientation="horizontal"
              aria-labelledby="segmented-controls-example"
              name="justify"
              value={quickAddMode}
              onChange={(event) => setQuickAddMode(event.target.value)}
              defaultValue={activeItem.itemType}
              sx={{
                minHeight: 40,
                pY: .5,
                pX: 1,
                borderRadius: '12px',
                bgcolor: 'neutral.softBg',
                '--RadioGroup-gap': '4px',
                '--Radio-actionRadius': '8px',
              }}
            >
              {['feature', 'persona', 'user-flow', 'user-journey'].map((item) => (
                <Radio
                  key={item}
                  color="neutral"
                  value={item}
                  disableIcon
                  label={item}
                  variant="plain"
                  sx={{
                    px: 2,
                    alignItems: 'center',
                  }}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: {
                        ...(checked && {
                          bgcolor: 'background.surface',
                          boxShadow: 'sm',
                          '&:hover': {
                            bgcolor: 'background.surface',
                          },
                        }),
                      },
                    }),
                  }}
                />
              ))}
            </RadioGroup>
          </Box>
          {/* TO DO: Print 'just-saved' values below */}
          <Box>
            
          </Box>
          {/* TO DO Hook up Adding type (feature in first instance) to feature collection and add a record in the association collection also */}
          <Box>
            { quickAddMode === "feature" &&
            <>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Input 
                sx={{ mt: 3, mb: 2 }} 
                size="lg"
                placeholder="Type a feature title..." 
                onChange={handleChangeOfInput}
                value={inputValue}
              />
              {/* <CommentTextArea handleChange={handleChange} /> */}
              {/* Add citations at the same time */}
              <Box sx={{ justifyContent: 'space-evenly', display: 'flex' }}>
                <Button variant="outlined" sx={{ mt: 0, mr: 'auto' }} onClick={() => handleAddCitationInline()}>
                  Add incite
                </Button>
                <Button variant="solid" sx={{ mt: 0 }} 
                  onClick={() => {
                    handleAddingTypeAndAssociation({ 
                      type: "feature", 
                      typeValues: { title: inputValue }, 
                      association: newDraftAssociation, 
                    });
                    // Clear on 'Add X[type]' and clear on 'Add Association' click
                    setNewDraftAssociation({})
                    setInputValue("");
                  }}
                  startDecorator={<AddReactionIcon />}>
                  Add feature
                </Button>
              </Box>
              <Typography sx={{ ml: 'auto', mt: 1, textAlign: 'right', justifyContent: 'flex-end' }}>
                  { activeItem.itemType === 'persona' ?
                    `This will be associated with the ${activeItem.item.role} ${ activeItem.itemType }.`
                  :
                    `This will be associated with the ${ activeItem.itemType } (${activeItem.item.title}).`
                  }
              </Typography>
            </Box>
            </>
            }
            { quickAddMode === "personas" &&
            <Input 
              endDecorator={<Button startDecorator={<PersonAddIcon />}>Add</Button>} 
              sx={{ mt: 3, mb: 2 }} 
              size="lg"
              placeholder="Type a persona role title..." 
            />
            }
            { quickAddMode === "user-journeys" &&
            <Input 
              endDecorator={<Button startDecorator={<RouteIcon />}>Add</Button>} 
              sx={{ mt: 3, mb: 2 }} 
              size="lg"
              placeholder="Type a user journey title..." 
            />
            }
            { quickAddMode === "user-flows" &&
            <Input 
              endDecorator={<Button startDecorator={<SignpostIcon />}>Add</Button>} 
              sx={{ mt: 3, mb: 2 }} 
              size="lg"
              placeholder="Type a user flow title..." 
            />
            }
          </Box>
        </Box>
      </Sheet>
  )
}

export default function CreateTypeFromInstance({modalMode = false}) {

  const { 
    createDocsOpen, 
  } = useContextualPane();

  return (
    !modalMode ?
    <Card sx={{
      position: 'fixed', 
      transform: createDocsOpen ? 'translateY(0)' : 'translateY(0)', 
      transition: 'transform 0.4s ease-in-out', 
      right: 32, 
      bottom: createDocsOpen ? 0 : 0,
      border: 'solid 1.5px',
      borderColor: '#d8cbe6'
    }} className="createDocumentsFixedPanel">
      <ChildContent modalMode={modalMode} />
    </Card>
      :
    <Box>
      <ChildContent modalMode={modalMode} />
    </Box>
  );
}