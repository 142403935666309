import Box from "@mui/joy/Box"
import { Link } from "react-router-dom"
import { IncitefulLogo } from '../IncitefulLogo';
import Typography from "@mui/joy/Typography"

export default function Header() {
  return (
    <Box
      component="header"
      sx={{
        py: 3,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          gap: 2,
          display: 'flex',
          alignItems: 'center',
          ml: 3,
          width: 180
        }}
      >
        <Link to="/">
          <IncitefulLogo width={72} height={58} />
        </Link>
        <Typography sx={{ color: '#50174E', fontSize: 10, fontWeight: '700', mt: -2.5, ml: -1 }}>BETA</Typography>
      </Box>
    </Box>
  )
}