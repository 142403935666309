import * as React from 'react';
import { Box, Grid, Typography } from '@mui/joy';
import DoneIcon from '@mui/icons-material/Done';
import InlineLoader from '../../../Loaders/InlineLoader';

function Generating({
  projectLoading,
  summarisingFileLoading,
  summarisedProjectNoteLoading,
  suggestingTodosLoading,
  suggestingPersonasLoading,
  suggestingUserJourneysLoading,
  suggestingFeaturesLoading,
  suggestingPagesAndContentLoading,
  suggestingNavigationsLoading
}) {
  return (
    <Grid lgOffset={3} item xs={6}>
      {/* Heading */}
      <Box sx={{ mt: 5, justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
        <Typography variant="h6" color="text.secondary" sx={{ color: '#724F9B', textAlign: 'center', fontSize: '2.5rem', mt: 1, ml: 1 }}>Getting to work</Typography>
        <Box sx={{ mt: 2, ml: 2 }}><InlineLoader loading={true} /></Box>
      </Box>
      {/* Loading Content */}
      <Box sx={{ mt: 5, p: 2, backgroundColor: '#ffffff', borderRadius: '10px', boxShadow: '0px 2px 10px rgba(3,3,3,0.1)' }}>
        <Typography level="title-md">Progress... 20% Done</Typography>
        <Box sx={{ pb: 2 }}>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { projectLoading && summarisedProjectNoteLoading ? 
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating project...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Project created.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { summarisingFileLoading ?
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Summarising files...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Summarised files.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { suggestingTodosLoading ?
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating to dos...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Generated to dos.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { suggestingPersonasLoading ? 
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating personas...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Generated personas.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { suggestingUserJourneysLoading ? 
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating user journeys...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Generated user journeys.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { suggestingFeaturesLoading ? 
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating features...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Generated features.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { suggestingPagesAndContentLoading ? 
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating pages and content...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Generated pages and content.</Typography></>
            }
          </Box>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
            { suggestingNavigationsLoading ? 
              <><InlineLoader color={"#000"} width={24} height={24} loading={true} /><Typography sx={{ pt: .1, pl: .75 }} level="title-sm">Generating navigations...</Typography></>
              :
              <><DoneIcon sx={{ color: '#724F9B' }} /><Typography sx={{ pt: .5, pl: .75, color: '#724F9B' }} level="title-sm">Generated navigations.</Typography></>
            }
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}

export default Generating;