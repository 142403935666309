import {$isAtNodeEnd, getNodes } from '@lexical/selection'
import {$getSelection,$isElementNode, $createNodeSelection, $getRoot, $createRangeSelection, $setSelection} from 'lexical'

const doesEditorHaveFocus = (editorState) => {
  const selectionState = editorState.getSelection()
  const focusOnEditor = selectionState.getHasFocus()

  return focusOnEditor
}

const didUserPressCtrlandStoSave = (e) => {
  if (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey && e.keyCode == 83) {
    return true
  } else {
    return false
  }
}

export function getDOMRangeRect(
  nativeSelection,
  rootElement,
) {
  const domRange = nativeSelection.getRangeAt(0);

  let rect;

  if (nativeSelection.anchorNode === rootElement) {
    let inner = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild;
    }
    rect = inner.getBoundingClientRect();
  } else {
    rect = domRange.getBoundingClientRect();
  }

  return rect;
}

export function getElementNodesInSelection( selection ) {
  const nodesInSelection = selection.getNodes();

  if (nodesInSelection.length === 0) {
    return new Set([
      selection.anchor.getNode().getParentOrThrow(),
      selection.focus.getNode().getParentOrThrow(),
    ]);
  }

  return new Set(
    nodesInSelection.map((n) => ($isElementNode(n) ? n : n.getParentOrThrow())),
  );
}

export function $elementHasChildren(elementNode) {
  if (elementNode && elementNode.__children.length > 0) {
    return true
  } else {
    return false
  }
}

/* If at the end of the input and user presses down or right on keypad */
const detectIfUserTryingToMoveFromTitle = (e) => {

  const numberOfLettersinInput = e.target.value.length
  const currentCursorPositionInInput = e.target.selectionStart

  /* If right key is being pressed */
  if (e.keyCode == 39) {
    if (currentCursorPositionInInput == numberOfLettersinInput) {
      return true
    }
  }

  /* If down key is being pressed */
  if (e.keyCode == 40) {
    return true
  }
}

const VERTICAL_GAP = 10;
const HORIZONTAL_OFFSET = 5;

export function setFloatingElemPosition(
  targetRect,
  floatingElem,
  anchorElem,
  verticalGap = VERTICAL_GAP,
  horizontalOffset = HORIZONTAL_OFFSET,
) {
  const scrollerElem = anchorElem.parentElement;

  if (targetRect === null || !scrollerElem) {
    floatingElem.style.opacity = '0';
    floatingElem.style.top = '-10000px';
    floatingElem.style.left = '-10000px';

    return;
  }

  const floatingElemRect = floatingElem.getBoundingClientRect();
  const anchorElementRect = anchorElem.getBoundingClientRect();
  const editorScrollerRect = scrollerElem.getBoundingClientRect();

  let top = targetRect.top - floatingElemRect.height - verticalGap;
  let left = targetRect.left - horizontalOffset;

  if (top < editorScrollerRect.top) {
    top += floatingElemRect.height + targetRect.height + verticalGap * 2;
  }

  if (left + floatingElemRect.width > editorScrollerRect.right) {
    left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
  }

  top -= anchorElementRect.top;
  left -= anchorElementRect.left;

  floatingElem.style.opacity = '1';
  floatingElem.style.top = `${top}px`;
  floatingElem.style.left = `${left}px`;
}

export function getSelectedNode(selection){
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

const sanitizeUrl = (url) => {
  /** A pattern that matches safe  URLs. */
  const SAFE_URL_PATTERN =
    /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi

  /** A pattern that matches safe data URLs. */
  const DATA_URL_PATTERN =
    /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+/]+=*$/i

  url = url.trim()

  if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN)) return url

  return `https://`
}

/* Check if cursor is on at the start of first line, if so skip up to name field */
const isCursorOnFirstCharacter = (editorState,selection) => {
  const node = selection.anchor.getNode()
  //console.log("node is ", node)
  if (node.__parent == 1) {
    const offset = selection.anchor.offset
    if (offset == 0) {
      return true
    }
  }
}

const getCurrentlySelectedText = (editorState) => {
  // Get block for current selection
  let selection = editorState.getSelection();
  const anchorKey = selection.getAnchorKey();
  const currentContent = editorState.getCurrentContent();
  const currentBlock = currentContent.getBlockForKey(anchorKey);

  //Then based on the docs for SelectionState -
  const start = selection.getStartOffset();
  const end = selection.getEndOffset();
  const selectedText = currentBlock.getText().slice(start, end);

  return selectedText
}

const getCurrentBlock = (editorState) => {
  const currentSelection = editorState.getSelection();
  const blockKey = currentSelection.getStartKey();
  return(editorState.getCurrentContent().getBlockForKey(blockKey));
}

const findWithRegex = (regex, contentBlock, callback) => {
  const text = contentBlock.getText()
  let matchArr, start
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index
    callback(start, start + matchArr[0].length)
  }
}

const getSelectionStartAndEndPositions = (editorState) => {
  const currentBlock = getCurrentBlock(editorState)
  const selection = editorState.getSelection()
  const startOffset = selection.getStartOffset()
  const endOffset = selection.getEndOffset()
  return [startOffset,endOffset]
}

// ** Gets all entities for the entire editor
const getEntities = (editorState, entityType = null) => {
  const content = editorState.getCurrentContent()
  const entities = []
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity())
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            }
            return true
          }
        }
        return false
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end })
      })
  })
  return entities;
}

const checkIfKeyPressedInVeryFirstPosition = (editor,event,setTitleHasFocus) => {
  // get outline of node keys in editor
  const map = editor.getEditorState()._nodeMap
  const mapKeys = map.keys()
  const firstNodeKey = mapKeys.next().value
  const firstNodeKeyAfterRoot = mapKeys.next().value
  
  // get current position
  const selection = $getSelection()
  const node = selection.anchor.getNode()
  const parentNode = node.getParent()
  const parentsParentNode = parentNode.getParent()  
  const offsets = selection.getCharacterOffsets()
      
  // Three nodes deep
  if (parentsParentNode.__parent == "root" && parentsParentNode.__key == firstNodeKeyAfterRoot) {
    if (parentsParentNode.__children[0] == parentNode.__key) {
      if (offsets[0] == 0) {
        setTitleHasFocus(true)
      }
    }
    return false
  }
  // Two nodes deep
  else if (parentNode.__parent == "root" && parentNode.__key == firstNodeKeyAfterRoot) {
    if (parentNode.__children[0] == node.__key) {
      if (offsets[0] == 0) {
        setTitleHasFocus(true)
      }
    }
    return false
  }
  // One node deep
  else if (parentNode.__key == firstNodeKeyAfterRoot) {
    if (parentNode.__children[0] == node.__key) {
      if (offsets[0] == 0) {
        setTitleHasFocus(true)
      }
    }
    return false
  }
  else {
    return false
  }
  
}

export { 
  doesEditorHaveFocus, 
  didUserPressCtrlandStoSave, 
  detectIfUserTryingToMoveFromTitle, 
  isCursorOnFirstCharacter,
  getCurrentBlock,
  findWithRegex,
  getCurrentlySelectedText,
  getEntities,
  getSelectionStartAndEndPositions,
  sanitizeUrl,
  checkIfKeyPressedInVeryFirstPosition
}