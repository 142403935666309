import React from 'react';
import { getConfig } from '../../../PrivateSite/database/configs-db';

export const IncitefulLogo = ({ width = 72, height = 58 }) => {
  const [logo, setLogo] = React.useState('')

  React.useEffect(() => {
    getConfig().then(config => setLogo(config.logo))
  }, [])

  return (
    <img src={logo} alt="smrt cookie logo" width={width} height={height} />
  )
} 