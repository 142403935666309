import Editor from '../';
import Box from '@mui/joy/Box';

function PreviewNoteForTemplate({ data }) {
    return (
        <Box sx={{ p: 2}}>
            <Editor editable={false} contentSource={data} />
        </Box>
    )
}

export default PreviewNoteForTemplate;