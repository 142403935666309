import database from '../../configs/firebaseConfig'; 

const db = database.firestore(); // Access Firestore database

export async function retrievePageSetsFromFirestore({accountId,selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/pagesets`)
    .where("project", "==", selectedProjectId ? selectedProjectId : '')
    .orderBy("updated", "desc").get()

    const pageSets = [];
    snapshot.forEach((doc) => {
      const data = doc.data();
      pageSets.push({
        id: doc.id,
        title: data.title ? data.title : '',
        project: data.project ? data.project : '',
        description: data.description ? data.description : '',
        pages: data.pages ? data.pages : [],
        created: data.created ? data.created.toDate() : null,
        updated: data.updated ? data.updated.toDate() : null
      });
    });

    //console.log("Retrieved features from Firestore:", features);
    return pageSets;
  } catch (error) {
    console.error("Error retrieving pageSets from Firestore:", error);
    return [];
  }
}

export default async function savePageSetToFirestore({ pageSet, accountId, selectedProjectId }) {  
  const id = pageSet && pageSet.id ? pageSet.id : null 

  console.log("savePageSetToFirestore with id, and pageSet", id, pageSet)

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/pagesets`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/pagesets`).doc(id).update({
          title: pageSet.title,
          description: pageSet.description,
          project: selectedProjectId ? selectedProjectId : '',
          pages: pageSet.pages,
          updated: new Date(),
        });
        //console.log("Feature updated in Firestore!");
      } catch(error) {
        console.error("Error saving pageSet to Firestore:", error);
      }
    } else {
      console.error("No pageSet document found with id ", id)
    } 
  } else {
    try {
      await db.collection(`accounts/${accountId}/pagesets`).add({
        title: pageSet.title, 
        description: pageSet.description,
        project: selectedProjectId ? selectedProjectId : '',
        pages: pageSet.pages,
        created: new Date(),
        updated: new Date(),
      });
      //console.log("New feature saved to Firestore!");
    } catch (error) {
      console.error("Error saving pageSet to Firestore:", error);
    }
  }
}

export async function deletePageSet({ pageSetId, accountId }) {
  try {
    const docRef = db.collection(`accounts/${accountId}/pagesets`).doc(pageSetId);
    const snapshot = await docRef.get();

    if (snapshot.exists) {
      await docRef.delete();
      //console.log("Feature deleted from Firestore with ID:", featureId);

      // Optionally, you can return the deleted Feature data if needed
      const deletedPageSetData = snapshot.data();
      return { id: pageSetId, ...deletedPageSetData };
    } else {
      console.error("No pageSetId found with ID:", pageSetId);
      return null;
    }
  } catch (error) {
    console.error("Error deleting pageSet from Firestore:", error);
    return null;
  }
}

export { savePageSetToFirestore }