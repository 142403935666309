import database from '../../configs/firebaseConfig'; 
import { checkTeamMembership } from './utilities-db';
const db = database.firestore(); // Access Firestore database
db.settings({ ignoreUndefinedProperties: true })

export async function retrieveChatsFromFirestore({accountId,selectedProjectId}) {
  try {
    const snapshot = await db.collection(`accounts/${accountId}/chats`)
    .where("project", "==", selectedProjectId)
    .get();
    
    //console.log("results is ", snapshot.docs)
    if (snapshot.docs.length > 0) {
      const chats = [];
      snapshot.forEach((doc) => {
        const data = doc.data();
        chats.push({
          id: doc.id,
          chatTitle: data.chatTitle,
          assistantId: data.assistantId ? data.assistantId : '',
          messages: data.messages ? data.messages : [],
          //created: data.timestamp.toDate(),
          //updated: data.updated ? data.updated.toDate() : null
        });
      });

      //console.log("returning ", chats)

      return chats;
    } else {
      return false
    }
    
  } catch (error) {
    console.error("Error retrieving chats from Firestore:", error);
    return [];
  }
}

export async function saveChatToFirestore({ selectedChat = {}, newMessages = [], user, accountId }) {  
  //console.log("newMessages passed in", newMessages)
  //console.log("selectedChat passed in", selectedChat)
  const id = selectedChat && selectedChat.id ? selectedChat.id : null 
  //console.log("id is ", id)

  if (id) {
    const existingDoc = await db.collection(`accounts/${accountId}/chats`).doc(id).get();
    if (existingDoc.exists) {
      try {
        // If the document exists, update specific fields
        await db.collection(`accounts/${accountId}/chats`).doc(id).update({
          messages: newMessages,
          updated: new Date(),
          lastUpdatedBy: user._delegate.uid,
          assistantId: selectedChat.assistantId ? selectedChat.assistantId : ''
        });
        //console.log("Chat updated in Firestore!");
        const record = await db.collection(`accounts/${accountId}/chats`).doc(id).get(id)
        const snapshot = record.data()
        // //console.log("Returning id", id, " and snapshot ", snapshot)
        return { id: id, snapshot };
      } catch(error) {
        console.error("Error saving chat to Firestore:", error);
      }
    } else {
      console.error("No document found with id", id)
    } 
  } else {
    try {
      // Assuming you have a collection named 'chats'
      const numberOfMsgs = newMessages.length -1
      //console.log("numberOfMsgs", numberOfMsgs)
      //console.log("using the last message content as the title newMessages[numberOfMsgs]", newMessages[numberOfMsgs].content)
      let chatTitle = ''
      if (newMessages[numberOfMsgs].content !== '') {
        chatTitle = String(newMessages[numberOfMsgs].content)
      }      
      //console.log("newMessages:", newMessages);
      //console.log("chatTitle:", chatTitle);
      //console.log("created:", new Date());
      //console.log("updated:", new Date());
      const docRef = await db.collection(`accounts/${accountId}/chats`).add({
        messages: newMessages,
        chatTitle: chatTitle,
        lastUpdatedBy: user._delegate.uid,
        created: new Date(),
        updated: new Date(),
      });
      //console.log("New chat saved to Firestore!");
      const newChatId = docRef.id;
      const record = await docRef.get()
      const snapshot = record.data()
      return { id: newChatId, ...snapshot };
    } catch (error) {
      console.error("Error saving chat to Firestore:", error);
    }
  }
}