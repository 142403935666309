import React from "react";
import { Button, Box, Stack, Typography } from "@mui/joy";
import Search from "../../../PrivateSite/Components/Search";

export default function Header() {

  const [ value, setValue ] = React.useState();

  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
        px: 2,
        pl: 1.8,
        py: 1,
        backgroundColor: '#F4F1F8', // Ivory: FFFFF0, Lavender: F4F1F8
        borderBottom: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Box flex={1} sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography level="title-lg">[Project name]</Typography>
      </Box>
      <Box flex={1} sx={{ display: 'flex' }}>
        <Search value={value} setValue={setValue} mode="pub" />
      </Box>
      <Box flex={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="outlined">Share</Button>
      </Box>
    </Box>
  )
}