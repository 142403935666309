import { useState, useEffect } from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';
import LinearProgress from '@mui/joy/LinearProgress';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useAuth } from '../../../../authContext';
import { useNavigate, useParams } from 'react-router-dom';
import { BASE_PRIVATEAPP_URL } from '../../../../redirects';

export default function UpgradeSidebar({ setUpgradeHidden }) {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { accountId, selectedProjectId } = useParams();

  const handleViewPlansClick = () => {
    navigate(BASE_PRIVATEAPP_URL + accountId + '/' + selectedProjectId + '/account?selectedTab=plan');
  }

  const handleCloseClick = () => {
    setUpgradeHidden(true);
    // store the upgradeVisible state in a cookie for 7 days
    document.cookie = "upgradeHidden=true; max-age=" + 7 * 24 * 60 * 60 + "; path=/";
  }

  return (
    currentUser && currentUser.plan && (currentUser.plan === 'free' || currentUser.plan === 'paid') && (
      <Card
        variant="soft"
        size="sm"
        sx={{ boxShadow: 'none', color: '#fff' }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography level="title-sm">{ (currentUser.plan === 'free' || currentUser.plan === 'paid') && 'Want More?'}</Typography>
          <IconButton size="sm" onClick={handleCloseClick}>
            <CloseRoundedIcon />
          </IconButton>
        </Stack>
        <Typography level="body-xs" >
          { currentUser.plan === 'free' && 'Unlimited projects, storage, publishing and more with our Smart Cookie plan.' }
          { currentUser.plan === 'paid' && 'Collaborate on research and co define documents with your team.' }
        </Typography>
        {/* TO DO: Use for remaining storage representation */}
        {/* <LinearProgress variant="soft" value={80} determinate sx={{ my: 1 }} /> */}
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <Button onClick={() => handleViewPlansClick() } size="sm" variant='outlined' sx={{ mt: 1, mb: 1, width: 'auto', display: 'inline-flex' }}>
            Compare plans
          </Button>
        </Box>
      </Card>
    )
  )
}