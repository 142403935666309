import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { v4 } from 'uuid';
import { retrievePageSetsFromFirestore, savePageSetToFirestore, deletePageSet } from '../database/pagesets-db';
import { useParams } from 'react-router-dom';
import { useContextualPane } from './contextualPane';
import { useProjects } from './projectsContext';
import { pagesData } from '../Components/Pages/pagesData';

// Create the context
const PagesContext = createContext();

// Create a provider component
export function PagesProvider({ children }) {

  const { accountId, selectedProjectId } = useParams()
  const [ pages, setPages ] = useState()
  const [ open, setOpen ] = useState(false);
  const { activeItem, setActiveItem } = useContextualPane();
  const { projects } = useProjects();

  // Snackbar user feedback states
  const [showSnackbar, setShowSnackbar] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState('neutral')
  const [snackbarMsg, setSnackbarMsg] = useState('')

  const handleCreatePagesSet = () => {
    setOpen(true)
    setActiveItem({ itemType: 'pages', item: { title: "New page set title", description: "", pages: [] } })
  }

  const handleShowSnackbar = ({ reason, message }) => {
    if (reason === 'success') {
      setSnackbarSeverity('success')
      setSnackbarMsg(message)
      setShowSnackbar(true)
    }
  }

  const handleUpdateCurrentPageSet = async (values) => {
    // handles remote save
    const response = await savePageSetToFirestore({ pageSet: values, accountId: accountId, selectedProjectId: selectedProjectId })
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Pages updated'})
    const newPageSets = await retrievePageSetsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newPageSets) {
      setPages(newPageSets)
    }
  }

  const handlePageSetDeletion = async (values) => {
    // handles remote deletion
    const response = await deletePageSet({ pageSetId: values.id, accountId: accountId })
    setOpen(false)
    handleShowSnackbar({reason: 'success', message: 'Pages deleted'})
    const newPageSets = await retrievePageSetsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    if (newPageSets) {
      setPages(newPageSets)
    }
  }

  const setPageSet = (value) => {
    setActiveItem({ itemType: 'pages', item: value })
  }

  const fetchPagesData = async () => {
    const results = await retrievePageSetsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId})
    return results;
  }

  const handleSetPages = (value) => {
    setActiveItem({ itemType: 'pages', item: value })
    setOpen(true)
  }

  const fetchAndSetPages = async () => {
    const results = await fetchPagesData()
    setPages(results)
  }

  const handleSetActiveItemForPages = (value) => {
    setActiveItem({ itemType: 'pages', item: value })
  }

  useEffect(() => {
    fetchAndSetPages()
  },[])

  useEffect(() => {
    fetchAndSetPages()
  },[projects])

  useEffect(() => {
    if (!open) {
      setActiveItem({ itemType: 'pages', item: null })
    } 
  },[open])

  useEffect(() => {
    //console.log("Selected project changed, refetch personas")
    const fetchData = async () => {
      try {
        //const data = await retrieveSitemapsFromFirestore({accountId: accountId, selectedProjectId: selectedProjectId});
        //return data;
      } catch (error) {
        //console.error('Error fetching data from Firestore:', error);
        //throw error; // Rethrow the error so that the outer catch block can handle it
      }
    };
    if (selectedProjectId) {
      const getData = async () => {
        try {
          const data = await fetchData();
          // if item was deleted, then select something again
          // if item was updated, then maintain selection
          // selectedNote will be emptied on deletion of record, so use this to tell
          if (data.length > 0) {
            //setSitemaps(data);
          } else {
            //setSitemaps([]);
          }
        } catch (error) {
          // Handle the error if needed
          //console.log("Error fetching features", error)
        }
      };
      getData();
    }
  },[selectedProjectId])

  const value = {
    pages,
    activeItem,
    handleSetActiveItemForPages,
    setPages,
    handleCreatePagesSet,
    handleSetPages,
    handleUpdateCurrentPageSet,
    handlePageSetDeletion,
    open,
    setOpen,
    showSnackbar,
    setShowSnackbar,
    snackbarSeverity,
    setSnackbarSeverity,
    snackbarMsg,
    setSnackbarMsg
  };

  return <PagesContext.Provider value={value}>{children}</PagesContext.Provider>;
}

// Create a custom hook that components can use to access the context
export function usePages() {
    return useContext(PagesContext);
}